<template>
  <card
    class="p-5 mb-4 card-border"
    @click="$emit('click')"
  >
    <div class="d-flex">
      <StarIcon
        class="mr-3 mt-1 flex-shrink-0 cursor-pointer"
        :class="{
          'text-muted': !caseItem.starred,
          'text-primary': caseItem.starred,
          'fill-primary': caseItem.starred,
        }"
        size="20"
        @click="toggleStarred"
      />
      <div class="row flex-grow-1 mw-100">
        <div class="col-4">
          <h6
            class="text-white my-0 text-truncate cursor-pointer"
            @click="viewCase"
          >
            {{ caseItem.title }} #{{ caseItem.name }}
          </h6>
          <div class="mt-7 d-flex flex-wrap">
            <span
              class="bg-primary px-3 py-2 font-weight-bold rounded-pill mr-3 mb-3"
            >
              {{ caseItem.status }}</span>
            <span
              class="bg-dark-100 text-lightblue px-3 py-2 font-weight-bold rounded-label mb-3"
            >
              New Uploads: {{ caseItem.newUploadsCount }}
            </span>
          </div>
        </div>
        <div class="col-8">
          <div class="d-flex">
            <div class="flex-grow-1 d-flex">
              <div style="width: 150px">
                <div class="text-lightblue">
                  Case Officer
                </div>
                <div class="text-white text-truncate">
                  {{ caseItem.caseOfficer ? caseItem.caseOfficer.fullName : caseItem.createdBy }}
                </div>
              </div>
              <div style="width: 150px">
                <div class="text-lightblue">
                  Opened On
                </div>
                <div class="text-white text-truncate">
                  {{ $date(caseItem.createdOn).tz(userTimezone).format("MMM DD, YYYY") }}
                </div>
              </div>
            </div>
            <base-button
              link
              class="m-0"
              @click="viewCase"
            >
              View
            </base-button>
          </div>
          <div class="d-flex pt-3">
            <base-input
              v-model="remark"
              class="mb-0 flex-grow-1"
              @keyup.enter="persistRemark"
            />
            <base-button
              simple
              class="m-0 ml-3 border-secondary text-secondary"
              style="height: 38px"
              :loading="loading"
              @click="persistRemark"
            >
              Update Remark
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <!-- <h6 class="font-weight-bold">{{ caseItem.title }} #{{ caseItem.name }}</h6>
    <p class="case-description font-16 my-3 text-lightblue prewrap">
      {{ caseItem.description }}
    </p>
    <div class="d-flex case-stats px-2 py-1 bg-dark-100">
      <div class="flex-1 text-lightblue">Evidence: {{ uploadsCount }}</div>
      <div class="flex-1 text-lightblue">People: {{ peopleCount }}</div>
      <div class="flex-1 text-lightblue">Keywords: {{ keywordCount }}</div>
      <div v-if="0" class="flex-1 text-lightblue">
        New Uploads: {{ newUploadsCount }}
      </div>
    </div>-->
  </card>
</template>

<script>
import {StarIcon} from "vue-feather-icons";
import BaseButton from "@/components/BaseButton.vue";
import Card from "@/components/Cards/Card.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import {putCase} from "../../../api";
import {mapGetters, mapMutations} from "vuex";
import {isDefined} from "../../../api/helpers";
import {ethosRouteNames} from "../../../routes/routeNames";

export default {
  components: {Card, StarIcon, BaseButton, BaseInput},
  props: {
    caseItem: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      loading: false,
      remark: "",
    };
  },
  watch: {
    caseItem: {
      immediate: true,
      handler(to) {
        if (isDefined(to.remark)) {
          this.remark = to.remark;
        }
      },
    },
  },
  computed: {
    ...mapGetters("data", ["userTimezone"]),
    uploadsCount() {
      return this.caseItem.uploadsCount;
    },
    keywordCount() {
      return this.caseItem.caseKeywordCount;
    },
    peopleCount() {
      return (
        this.caseItem.userAttributionCount + this.caseItem.actorAttributionCount
      );
    },
    newUploadsCount() {
      return 0;
      // / Replace with a rollup?
      // return (this.caseItem.uploads ?? []).filter((e) => e.status === "new").length;
    },
  },
  methods: {
    ...mapMutations("data", ["putCaseSummary"]),
    persistRemark() {
      if (this.remark.length < 1 || this.loading) return; // Ignore.
      this.loading = true;
      this.doPutCase(this.remark, undefined).then((model) => {
        this.putCaseSummary(model);
      }).catch((ex) => {
        this.$notifyError("Failed to set remark", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    async doPutCase(remark, starred) {
      return await putCase(undefined, undefined, this.caseItem.name, undefined, remark, starred);
    },
    toggleStarred() {
      if (this.loading) return; // Ignore.
      this.loading = true;
      this.doPutCase(undefined, !this.caseItem.starred).then((model) => {
        this.putCaseSummary(model);
      }).catch((ex) => {
        this.$notifyError("Unable to update favorite status", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    viewCase() {
      this.$router.replace({
        name: ethosRouteNames.CaseUploads,
        params: {
          caseId: this.caseItem.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.case-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.case-stats {
  border-radius: 8px;
  div {
    text-align: center;
    color: white;
  }
}
.case-card--active {
  background: black !important;
  border: 1px solid var(--primary) !important;
}
</style>
