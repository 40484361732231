<template>
  <ul
    class="breadcrumb"
    :class="{ 'bg-transparent': transparent }"
  >
    <slot />
  </ul>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "breadcrumb",
  props: {
    transparent: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style></style>
