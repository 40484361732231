import BaseInput from "src/components/Inputs/BaseInput.vue";
import BaseDropdown from "src/components/BaseDropdown.vue";
import Card from "src/components/Cards/Card.vue";
import BaseButton from "src/components/BaseButton.vue";
import BaseSwitch from "src/components/BaseSwitch.vue";
import BaseCheckbox from "src/components/Inputs/BaseCheckbox.vue";
import BaseRadio from "src/components/Inputs/BaseRadio.vue";
import {Input, InputNumber, DatePicker, Table, TableColumn, Tooltip, Popover, Pagination, Button, Tabs, Tag, TabPane, Select, Option, OptionGroup, Collapse, CollapseItem, Menu, MenuItem, MenuItemGroup, Submenu, RadioButton, RadioGroup, Dropdown, DropdownMenu, DropdownItem} from "element-ui";
import {ValidationProvider} from "vee-validate";
import {ValidationObserver} from "vee-validate";
import {RouteBreadCrumb, Modal, BaseProgress} from "src/components";
import CollapseContainer from "../../src/pages/Components/CollapseContainer/CollapseContainer.vue";
import RtfEditor from "../../src/pages/Ethos/Rtf/RtfEditor/RtfEditor.vue";
import RtfViewer from "../../src/pages/Ethos/Rtf/RtfViewer/RtfViewer.vue";
import AV from "vue-audio-visual";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(Card.name, Card);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(Modal.name, Modal);
    Vue.component(Input.name, Input);
    Vue.component(InputNumber.name, InputNumber);
    Vue.component(DatePicker.name, DatePicker);
    Vue.component(RouteBreadCrumb.name, RouteBreadCrumb);
    Vue.component(Modal.name, Modal);
    Vue.use(Tooltip);
    Vue.use(Popover);
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Pagination);
    Vue.use(Button);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(Tag);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(OptionGroup);
    Vue.use(Collapse);
    Vue.use(CollapseItem);
    Vue.use(RadioButton);
    Vue.use(RadioGroup);
    Vue.use(Menu);
    Vue.use(MenuItem);
    Vue.use(MenuItemGroup);
    Vue.use(Submenu);
    Vue.use(Dropdown);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    Vue.use(AV);
    Vue.component(CollapseContainer.name, CollapseContainer);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
    Vue.component(RtfEditor.name, RtfEditor);
    Vue.component(RtfViewer.name, RtfViewer);
  },
};

export default GlobalComponents;
