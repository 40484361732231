<template>
  <div class="dashboard-v2" :class="isMini ? 'dashboard-v2--minidrawer' : ''">
    <template v-if="!hideNav">
      <notifications />
      <upload-manager-overlay ref="uploadoverlay" />
      <nav-bar />
    </template>
    <div class="dashboard-v2--layout">
      <template v-if="isFull">
        <div class="dashboard-v2--full">
          <slot name="full" />
        </div>
      </template>
      <template v-else>
        <div class="dashboard-v2--left">
          <div :class="{ 'text-center': isMini }">
            <base-button
              type="link"
              class="m-0"
              @click="isMini = !isMini"
              :iconOnly="isMini"
            >
              <span
                style="font-size: 18px"
                class="material-symbols-outlined mr-1"
              >
                dock_to_right
              </span>
              {{ !isMini ? "Hide Menu" : "" }}
            </base-button>
          </div>
          <div class="h-divider"></div>
          <slot name="left" />
        </div>
        <div class="dashboard-v2--right">
          <slot name="right" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import NavBar from "./NavBar.vue";
import {mapGetters} from "vuex";
import {isDefined} from "../../api/helpers";
import UploadManagerOverlay from "../../pages/Ethos/Dashboard/UploadManagerOverlay.vue";
import { computed } from "vue";
export default {
  components: {NavBar, UploadManagerOverlay},
  props: {
    hideNav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMini: false,
    };
  },
  watch: {
    getUploadResponse(to, from) {
      if (to && (!from || to.lengh !== from.length))
        this.$refs.uploadoverlay.display();
    },
  },
  provide() {
    return {
      isMini: computed(() => this.isMini),
    };
  },
  computed: {
    ...mapGetters("auth", [
      "established",
    ]),
    ...mapGetters("data", [
      "getUploadResponse",
    ]),
    authEstablished() {
      return this.$auth.isAuthenticated && this.established;
    },
    isFull() {
      return isDefined(this.$slots.full && this.$scopedSlots.full);
    },
  },
};
</script>

<style lang="scss">
.dashboard-v2--right {
  overflow-y: auto;
}
.dashboard-v2--minidrawer {
  .dashboard-v2--left {
    width: 66px !important;
  }
  .dashboard-v2--right {
    width: calc(100% - 66px) !important;
  }
}
</style>
