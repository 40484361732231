<template>
  <div class="note-viewer h-100">
    <div class="my-3">
      <label
        for="note-title"
        class="form-label"
      > Title </label>
      <input
        id="note-title"
        v-model="updatedTitle"
        type="text"
        class="form-control"
        placeholder="Note Title"
      >
    </div>

    <label
      for="note-content"
      class="form-label"
    > Content </label>
    <div>
      <rtf-editor
        v-if="json"
        :key="randomKey"
        :json="json"
        @contentsChanged="updated"
        @onContentEmpty="contentEmpty"
      />
    </div>
    <div class="row">
      <base-button
        class="mr-auto ml-3"
        type="secondary"
        simple
        :disabled="!hasChanges"
        :loading="saving"
        @click="reset"
      >
        Discard Changes
      </base-button>
      <base-button
        class="ml-auto mr-3"
        type="primary"
        :disabled="!hasChanges"
        :loading="saving"
        @click="save"
      >
        Save Changes
      </base-button>
    </div>
  </div>
</template>

<script>
import RtfEditor from "../../Rtf/RtfEditor/RtfEditor.vue";
import {updateNote} from "../../../../api";
import {isDefined} from "../../../../api/helpers";

export default {
  components: {RtfEditor},
  name: "note-viewer",
  props: {
    id: {
      type: Number,
      default: null,
    },
    json: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isDisplayed: false,
      readonly: false,
      loading: false,
      saving: false,
      updatedTitle: "",
      updatedJson: null,
      randomKey: Math.random(),
    };
  },
  watch: {
    title: {
      handler(n, o) {
        if (n !== o) {
          this.updatedTitle = n;
        }
      },
      immediate: true,
    },
    json: function(n, o) {
      if (JSON.stringify(n) !== JSON.stringify(o)) {
        this.reset();
      }
    },
  },
  computed: {
    hasChanges() {
      return this.hasTitleChanged || this.hasContentChanged;
    },
    hasTitleChanged() {
      return this.title !== this.updatedTitle;
    },
    hasContentChanged() {
      return this.updatedJson !== null && JSON.stringify(this.updatedJson) !== JSON.stringify(this.json);
    },
  },
  methods: {
    display() {
      this.isDisplayed = true;
    },
    toggle_readOnly() {
    },
    reset() {
      if (!this.json) return false;
      this.updatedTitle = this.title;
      this.updatedJson = null;
      this.randomKey = Math.random();
    },
    save() {
      if (this.saving || !isDefined(this.id)) return;
      this.saving = true;

      const title = this.hasTitleChanged ? this.updatedTitle : null;
      const content = this.hasContentChanged ? JSON.stringify(this.updatedJson) : null;
      updateNote(this.id, null, null, title, content).then((n) => {
        this.$notifySuccess(`Successfully Updated ${this.title}`);
        this.$emit("updated", n);
        this.saving = false;
      }).catch((ex) => {
        this.$notifyError(`Unable to Update ${this.title}`, ex);
        this.saving = false;
      });
    },
    updated(json) {
      this.updatedJson = json;
    },
    contentEmpty() {
      // DO NOT SAVE ON EMPTY
      this.updatedJson = null;
    },
  },
};
</script>

<style scoped>
.rtf-editor {
  height: 93%;

}
</style>
