<template>
  <div>
    <label
      for="fullDate"
      class="form-label"
    > {{ label }} </label>
    <div class="custom-date-input">
      <input
        id="millis"
        type="text"
        class="form-control"
        placeholder="Date"
        :value="output ? $date(output.iso).format('MMM DD, YYYY [at] HH:mm A') : null"
        @click.prevent="() => modalDisplayed = true"
      >
      <calendar-icon size="1.5x" />
    </div>
    <modal
      :show.sync="modalDisplayed"
      header-classes="justify-content-center"
      footer-classes="px-4 py-3"
      :show-close="false"
      :centered="true"
      class="modal-default modal-wide"
    >
      <h4
        slot="header"
        class="title title-up"
      >
        {{ header }}
      </h4>

      <div class="mb-3">
        <label
          for="date"
          class="form-label"
        > Date </label>
        <div class="custom-date-input">
          <el-date-picker
            v-model="date"
            type="date"
            effect="dark"
            class="dark"
            :clearable="false"
            popper-class="select-primary"
            placeholder="Pick a day"
            format="M/dd/yyyy"
          />
          <calendar-icon size="1.5x" />
        </div>
      </div>
      <div class="d-flex flex-fill">
        <div class="mb-3 mr-3">
          <label
            for="hours"
            class="form-label"
          > Hours </label>
          <el-select
            id="hours"
            v-model="hours"
            class="select-primary dark d-block"
            filterable
            effect="dark"
            popper-class="select-primary"
            placeholder="00"
          >
            <el-option
              v-for="item in hrOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="mb-3 mr-3">
          <label
            for="mins"
            class="form-label"
          > Minutes </label>
          <el-select
            id="mins"
            v-model="mins"
            class="select-primary dark d-block"
            filterable
            effect="dark"
            popper-class="select-primary"
            placeholder="00"
          >
            <el-option
              v-for="item in minOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="mb-3 mr-3">
          <label
            for="secs"
            class="form-label"
          > Seconds </label>
          <el-select
            id="secs"
            v-model="secs"
            class="select-primary dark d-block"
            filterable
            effect="dark"
            popper-class="select-primary"
            placeholder="00"
          >
            <el-option
              v-for="item in secOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="mb-3 flex-grow-1">
          <label
            for="millis"
            class="form-label"
          > Milliseconds </label>
          <input
            id="millis"
            v-model="millis"
            type="number"
            :min="0"
            :max="999"
            class="form-control"
            placeholder="000"
          >
        </div>
      </div>
      <!-- <div class="mb-3">
        <timezone-field
          ref="tz"
          :name="tz"
          @changed="(t) => tz = t"
        />
      </div> -->

      <template slot="footer">
        <base-button
          size="sm"
          class="mr-auto"
          secondary
          simple
          @click="cancel"
        >
          Cancel
        </base-button>
        <base-button
          size="sm"
          class="ml-auto"
          @click="ok"
        >
          OK
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {Modal} from "src/components";
import {CalendarIcon} from "vue-feather-icons";
import {stringsForRange} from "../../../util/util";
// import TimezoneField from "./TimezoneField.vue";
import {mapGetters} from "vuex";

export default {
  name: "date-time-offset-form",
  components: {
    // TimezoneField,
    Modal,
    CalendarIcon,
  },
  props: {
    originalDateIsoUtc: {
      type: String,
      default: null,
    },
    header: {
      type: String,
      default: "Select Date",
    },
    label: {
      type: String,
      default: "Date",
    },
  },
  data() {
    return {
      modalDisplayed: false,
      date: this.$date(new Date()),
      hours: "00",
      mins: "00",
      secs: "00",
      millis: 0,
      tz: "America/Vancouver",
    };
  },
  computed: {
    ...mapGetters("data", [
      "userTimezone",
    ]),
    tzOffset() {
      return this.$tz.now.find((tz) => tz.name === this.tz).currentTimeOffsetInMinutes;
    },
    hrOptions() {
      return stringsForRange(0, 24, 2);
    },
    minOptions() {
      return stringsForRange(0, 60, 2);
    },
    secOptions() {
      return stringsForRange(0, 60, 2);
    },
    output() {
      if (this.date != null) {
        let d = this.$date(this.date);
        d = d.hour(parseInt(this.hours));
        d = d.minute(parseInt(this.mins));
        d = d.second(parseInt(this.secs, 10));
        d = d.millisecond(this.millis);
        d = d.tz(this.tz, true); // `true` shifts the timezone only - without adjusting the hour
        return {
          date: d.toDate(),
          iso: d.toISOString(),
        };
      }
      return null;
    },
  },
  mounted() {
    this.set(this.originalDateIsoUtc);
  },
  methods: {
    set(dateIso) {
      if (dateIso != null) {
        const date = this.$date(dateIso).tz(this.userTimezone);
        this.date = date;
        this.hours = this.hrOptions[date.$H];
        this.mins = this.minOptions[date.$m];
        this.secs = this.secOptions[date.$s];
      }
      const tz = this.$tz.now.find((tz) => tz.name === this.userTimezone);
      if (tz) {
        this.tz = tz.name;
      } else {
        this.tz = this.$date.tz.guess()?.name;
      }
    },
    ok() {
      this.modalDisplayed = false;
      this.$emit("changed", this.output);
    },
    cancel() {
      this.set(this.originalDateIsoUtc);
      this.modalDisplayed = false;
    },
  },
};
</script>
