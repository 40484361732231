
import {attributesOf, getActors, getCaseSummaries, getUsersOverview} from "../../../api";
import {isDefined} from "../../../api/helpers";

const actions = {
  async loadCasePeopleAttributions({commit, state}, payload) {
    if (!isDefined(payload.caseId)) throw new Error("Need caseId");
    const response = await attributesOf("case", payload.caseId);
    const actors = response.filter((r) => isDefined(r.actorId));
    const users = response.filter((r) => isDefined(r.userId));
    commit("putCaseActorAttributions", {caseId: payload.caseId, actors});
    commit("putCaseUserAttributions", {caseId: payload.caseId, users});
  },
  async loadCaseSummaries({commit, state}, payload) {
    const {pageNumber, pageSize, filterText, archived, recent, userId} = payload;
    const {caseSummaries: {pagination, filter, loading}} = state;
    if (loading) return;
    commit("putCaseSummariesLoading", true);
    const query = Object.keys(filter)
      .filter((k) => isDefined(filter[k]) && (!Array.isArray(filter[k]) || filter[k].length > 0))
      .map((k) => {
        let key = k;
        let v = filter[key];
        if (Array.isArray(v)) v = v.join(",");
        if (key == "Recent") {
          // Switching, as looking up locally.
          key = "In";
          v = window.localStorage.getItem(`recent-${v}`);
        }
        return `${key}=${v}`;
      })
      .join("&");
    const response = await getCaseSummaries(
      pageNumber || pagination.CurrentPage,
      pageSize || pagination.PageSize,
      filterText,
      archived || false,
      recent || false,
      userId || null,
      query.length > 0 ? query : null
    );
    commit("putCaseSummariesPagination", response.pagination);
    commit("putCaseSummaries", response.data);
    commit("putCaseSummariesLoading", false);
  },
  async updateCaseSummaryFilterCriteria({dispatch, commit}, payload) {
    commit("updateCaseSummarySearchFilter", payload);
    dispatch("loadCaseSummaries", {});
  },
  async updateCaseSummaryPagination({dispatch, state, commit}, payload) {
    const {caseSummaries: {pagination}} = state;
    commit("putCaseSummariesPagination", payload);
    if (payload.PageSize !== pagination.PageSize || payload.CurrentPage !== pagination.CurrentPage) {
      dispatch("loadCaseSummaries", {});
    }
  },
  async loadUsers({commit}, _) {
    return getUsersOverview().then((response) => {
      const payload = {};
      response.forEach((e) => payload[e.id] = e);
      commit("putUserMap", payload);
    });
  },
  async loadActors({commit}, _) {
    return getActors().then((response) => {
      const payload = {};
      response.forEach((e) => payload[e.id] = e);
      commit("putActorMap", payload);
    });
  },
  async loadPeople(store, payload) {
    await Promise.allSettled([actions.loadActors(store, payload), actions.loadUsers(store, payload)]);
  },
};

export default actions;
