// See: https://vuex.vuejs.org/guide/modules.html
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const data = {
  namespaced: true,
  state: {
    hasCalled: false,
    id: null,
    email: null,
    fullName: null,
    firstName: null,
    lastName: null,
    photoUrl: null,
    roles: [],
  },
  actions,
  getters,
  mutations,
};

export default data;
