<template>
  <div
    v-if="loaded"
    class="d-flex flex-column pt-0 flex-1 position-relative"
    :class="{alignItemsCenter: !hasKeywords}"
  >
      <case-back-div :to="backTo" v-if="false"/>
      <route-breadcrumb />

    <div
      v-if="hasKeywords"
      class="p-5"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h6 class="text-white case-keywords--tile mb-0">
            Keywords
          </h6>
          <h5 class="text-lightblue mb-10">
            Total Keywords: {{ keywords.length }}
          </h5>
        </div>
        <base-button
          v-if="hasKeywords"
          type="simple"
          @click="openAddKeywordModal"
        >
          <plus-icon
            size="1.5x"
            class="mr-1"
          />
          Keyword
          <add-keyword-modal
            ref="addKeyword"
            @created="loadKeywords"
          />
        </base-button>
      </div>
      <div class="my-5 row">
        <div class="col">
          <card class="p-3 cursor-pointer mb-0">
            <p class="case-menu-title font-16 text-white mb-0">
              Signals Detected
            </p>
            <div class="d-flex mt-3 justify-content-between">
              <h2 class="font-20 text-white mb-0 font-weight-bold">
                0
              </h2>
              <ArrowRightIcon class="text-white" />
            </div>
          </card>
        </div>

        <div class="col">
          <card class="p-3 cursor-pointer mb-0">
            <p class="case-menu-title font-16 text-white mb-0">
              New Keywords
            </p>
            <div class="d-flex mt-3 justify-content-between">
              <h2 class="font-20 text-white mb-0 font-weight-bold">
                0
              </h2>
              <ArrowRightIcon class="text-white" />
            </div>
          </card>
        </div>
      </div>
      <div class="mb-3">
        <h6 class="text-white case-keywords--tile mb-0">
          Keywords
        </h6>
        <h5 class="text-lightblue mb-10">
          Total Keywords: {{ keywords.length }}
        </h5>
      </div>

      <div class="d-flex flex-wrap">
        <div
          v-for="keyword in keywords"
          :key="keyword.id"
          class="chip ml-0 mr-2"
        >
          {{ keyword.word }}
          <XCircleIcon
            class="ml-2 cursor-pointer"
            @click="deleteConfirmation(keyword.id)"
          />
        </div>
      </div>
    </div>
    <wizard-container
      v-else
      ref="wizard"
      show-only="keywords"
      @created="loadKeywords"
    />

    <modal
      :show.sync="showDeleteConfirmation"
      header-classes="justify-content-center"
      footer-classes="px-4 py-3"
      class="modal-default"
    >
      <template v-if="showDeleteConfirmation">
        <p
          slot="header"
          class="title"
        >
          Are you sure to delete this keyword?
        </p>
        <div class="row d-flex justify-content-center">
          <base-button
            size="sm"
            type="primary"
            @click="deleteKeyword()"
          >
            Yes
          </base-button>
          <base-button
            size="sm"
            type="danger"
            @click="showDeleteConfirmation = false"
          >
            No
          </base-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
// TODO: - Wire up newKeywords count && signalsDetectedCounts (this needs to start getting tracked in the db)
import {PlusIcon, ArrowRightIcon, XCircleIcon} from "vue-feather-icons";
import {deleteCaseKeywords, getCaseKeywords} from "../../../api";
import {isDefined} from "../../../api/helpers";
import BaseButton from "../../../components/BaseButton.vue";
import AddKeywordModal from "../../../components/DashboardV2/Case/AddKeywordModal.vue";
import CaseBackDiv from "../../../components/DashboardV2/Case/CaseBackDiv.vue";
import WizardContainer from "../../../components/DashboardV2/Wizard/CaseWizard.vue";
import {ethosRouteNames} from "../../../routes/routeNames";
export default {
  components: {WizardContainer, BaseButton, PlusIcon, ArrowRightIcon, AddKeywordModal, CaseBackDiv, XCircleIcon},
  data() {
    return {
      loading: true,
      loaded: false,
      showDeleteConfirmation: false,
      keywordId: "",
      keywords: [],
    };
  },
  computed: {
    hasKeywords() {
      return this.keywords.length > 0;
    },
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    backTo() {
      return ethosRouteNames.DashboardV2;
    },
  },
  mounted() {
    this.loadKeywords();
  },
  methods: {
    loadKeywords() {
      if (!isDefined(this.caseId)) return;
      this.loading = true;
      getCaseKeywords(this.caseId).then((response) => {
        this.keywords = response;
        this.loaded = true;
      }).catch((ex) => {
        this.$notifyError("Failed to load Case Keywords", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    deleteConfirmation(id) {
      this.keywordId = id;
      this.showDeleteConfirmation = true;
    },
    deleteKeyword() {
      if (isDefined(this.keywordId)) {
        deleteCaseKeywords(this.keywordId).then((response) => {
          this.keywords = this.keywords.filter((k) => k.id !== this.keywordId);
        });
        this.showDeleteConfirmation = false;
      }
    },
    openAddKeywordModal() {
      this.$refs.addKeyword.showModal = true;
    },
  },
};
</script>

<style lang="scss">
h6 {
  &--title {
    font-size: 20px;
  }
}
</style>
