<template>
  <modal
    :show.sync="isDisplayed"
    header-classes="justify-content-center"
    body-classes="modal-center"
    footer-classes="px-4 py-3"
    :centered="true"
    class="modal-default"
    @close="() => $emit('close')"
  >
    <!-- Header Slot -->
    <h4
      slot="header"
      class="title title-up"
    >
      {{ header }}
    </h4>

    <template>
      {{ body }}
    </template>

    <!-- Footer Slot -->
    <template slot="footer">
      <base-button
        size="sm"
        class="ml-auto"
        @click="reset"
      >
        Continue Uploads
      </base-button>
      <base-button
        size="sm"
        class="ml-auto"
        @click="() => $emit('cancelAllUploads')"
      >
        Cancel Uploads
      </base-button>
    </template>
  </modal>
</template>


<script>
import {Modal} from "src/components";

export default {
  name: "upload-message-modal",
  components: {
    Modal,
  },
  props: {
  },
  data() {
    return {
      isDisplayed: false,
      body: "Your uploads are not complete.  Would you like to cancel all ongoing uploads?",
      header: "Cancel all uploads?",
    };
  },
  methods: {
    display(props) {
      if (props) {
        this.body = props.body;
        this.header = props.header;
      }
      this.isDisplayed = true;
    },
    reset() {
      this.isDisplayed = false;
      this.$emit("close");
    },
    cancelUploads() {
      this.$emit("cancelAllUploads");
    },
  },
};
</script>

<style lang="scss">
  .modal-center {
    text-align: center;
  }
</style>
