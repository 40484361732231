<template>
  <div class="dashboard-empty">
    <card>
      <FolderIcon size="36" />
      <p class="mt-2">
        No Case Found
      </p>
    </card>
  </div>
</template>

<script>
import Card from "../../Cards/Card.vue";
import {FolderIcon} from "vue-feather-icons";
export default {
  components: {Card, FolderIcon},
};
</script>

<style lang="scss">
.dashboard-empty {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  .card {
    width: 350px;
    height: 150px;
    text-align: center;
    &-body {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      color: white !important;
      p {
        font-weight: bold;
      }
    }
  }
}
</style>
