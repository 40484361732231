import {doRequest, jsonRequest, jsonRequestPaginated, isDefined, GET, POST, PUT, DELETE} from "./helpers";

import {v4 as uuidv4} from "uuid";

export const getCase = async (caseId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequest(GET, `Cases/${caseId}/`);
};

export const getCaseSummary = async (caseId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequest(GET, `Cases/${caseId}/Summary`);
};

export const getCaseUploads = async (caseId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequest(GET, `Cases/${caseId}/Uploads`);
};

export const getCaseUploadSummaries = async (caseId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequest(GET, `Cases/${caseId}/UploadSummaries`);
};

export const getCaseUploadSummariesPaged = async (caseId, pageNumber, pageSize, additionalQuery) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequestPaginated(
    GET,
    `Cases/${caseId}/UploadSummariesPaged`,
    {pageNumber, pageSize},
    additionalQuery ? `&${additionalQuery}` : ""
  );
};

export const getCaseUploadClipSummariesPaged = async (caseId, pageNumber, pageSize, additionalQuery) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequestPaginated(
    GET,
    `Cases/${caseId}/UploadClipSummariesPaged`,
    {pageNumber, pageSize},
    additionalQuery ? `&${additionalQuery}` : ""
  );
};

export const getCaseUploadEvidenceSummariesPaged = async (caseId, pageNumber, pageSize, additionalQuery) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequestPaginated(
    GET,
    `Cases/${caseId}/UploadEvidenceSummariesPaged`,
    {pageNumber, pageSize},
    additionalQuery ? `&${additionalQuery}` : ""
  );
};

export const postCaseUploadsSummary = async (caseId, uploadIds, name, dir) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  if (!isDefined(uploadIds) || uploadIds.length === 0) throw new Error("Upload Id Required");
  const data = {
    uploadIds,
  };
  if (isDefined(name) && name.length > 0) data.name = name;
  if (isDefined(dir)) data.dir = dir;
  return await jsonRequest(POST, `Cases/${caseId}/Uploads/Summary`, {
    body: JSON.stringify(data),
  });
};

export const deleteCaseUploadsSummary = async (caseId, uploadId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  return await jsonRequest(DELETE, `Cases/${caseId}/Uploads/Summary/${uploadId}`);
};

export const getCaseClips = async (caseId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequest(GET, `Cases/${caseId}/Clips`);
};

export const getCases = async (pageNumber, pageSize, filter, archived, recent, userId) => {
  let query = archived === true ? "&Archived=true" : "";
  if (recent == true) query += "&Recent=true";
  if (isDefined(userId)) query += `&UserId=${userId}`;
  return await jsonRequestPaginated(
    GET,
    "Cases",
    {pageNumber, pageSize, filter},
    query
  );
};

export const getCaseSummaries = async (pageNumber, pageSize, filter, archived, recent, userId, additionalQuery) => {
  let query = archived === true ? "&Archived=true" : "";
  if (additionalQuery) query += `&${additionalQuery}`;
  if (recent == true) query += "&Recent=true";
  if (isDefined(userId)) query += `&UserId=${userId}`;
  return await jsonRequestPaginated(
    GET,
    "Cases/Summaries",
    {pageNumber, pageSize, filter},
    query
  );
};

export const getCaseProjects = async (caseId) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  return await jsonRequest(GET, `Cases/${caseId}/Projects`);
};

export const putCase = async (originalCaseNumber, title, caseNumber, description, remark, starred, status) => {
  const safeCaseNumber = caseNumber || uuidv4();
  const data = {
    name: safeCaseNumber,
  };
  if (isDefined(title)) data.title = title;
  if (isDefined(description)) data.description = description;
  if (isDefined(remark)) data.remark = remark;
  if (isDefined(starred)) data.starred = starred;
  if (isDefined(status)) data.status = status;

  return await jsonRequest(PUT, `Cases/${originalCaseNumber || caseNumber}`, {
    body: JSON.stringify(data),
  });
};

export const getAllCases = async () => {
  return await jsonRequest(GET, "Cases/GetAll");
};

export const archiveCase = async (caseId, archive) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  if (!isDefined(archive)) throw new Error("achive(true|false) required");
  const data = {
    archive,
  };
  return await doRequest(POST, `Cases/${caseId}/ArchiveCase`, {
    body: JSON.stringify(data),
  });
};

export const getArchiveCount = async () => {
  return await jsonRequest(GET, "Cases/Archived/Count");
};

export const addUploadIdsToCase = async (caseId, projectId, uploadIds) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  if (!isDefined(uploadIds) || uploadIds.length < 1) throw new Error("uploadIds required");
  const data = {
    uploadIds,
  };
  if (isDefined(projectId)) data.projectId = projectId;
  return await doRequest(POST, `Cases/${caseId}/Link`, {
    body: JSON.stringify(data),
  });
};

