var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table tablesorter mb-0"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"width":"55px"}},[_c('base-checkbox',{attrs:{"checked":_vm.isSelectAll},on:{"input":_vm.onSelectAll}})],1),_vm._l((_vm.columns),function(col,i){return _c('th',{key:i,staticClass:"text-capitalize font-weight-bold text-white cursor-pointer",class:col.class || '',attrs:{"width":col.width},on:{"click":function($event){return _vm.selectSort(col)}}},[_c('div',{staticClass:"d-flex"},[_vm._t(`header-${col.value}`,function(){return [_vm._v(" "+_vm._s(col.label)+" ")]}),(_vm.sortBy === col.value)?_c('material-icon',[_vm._v("swap_vert")]):_vm._e()],2)])})],2)]),_c('tbody',[_vm._l((_vm.list),function(item,index){return [_c('tr',{key:index,staticClass:"cursor-pointer",class:_vm.rowClass(index,item)},[_c('td',{staticClass:"text-center"},[_c('base-checkbox',{attrs:{"checked":_vm.isSelected(item)},on:{"input":function($event){return _vm.onCheckboxChange($event, item)}}})],1),_vm._l((_vm.columns),function(col,i){return _c('td',{key:i,staticClass:"font-weight-bold",class:col.tdClass,on:{"click":function($event){return _vm.$emit('clicked', {item, index})}}},[_vm._t(`data-${col.value}`,function(){return [_c('span',{on:{"click":function($event){return _vm.triggerNameClick(item)}}},[_vm._v(" "+_vm._s(item[col.value])+" ")])]},{"data":{
              item,
              value: item[col.value],
              index,
              selected: _vm.isSelected(item),
            }})],2)})],2),_vm._t("nested-data",null,{"data":{
          item,
          index,
        }})]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }