var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ethos-container",class:{
    bordered: !_vm.hasChildRoute,
    'm-3': !_vm.hasChildRoute,
    'no-bg': _vm.hasChildRoute && !_vm.hasProjectRoute,
  }},[_c('router-view'),(!_vm.hasChildRoute || _vm.reuse)?_c('div',[_c('div',{staticClass:"paginated-table"},[(!_vm.reuse)?_c('div',{staticClass:"my-3 d-flex align-items-center"},[_c('h2',{staticClass:"mr-3 d-inline-block mb-0"},[_vm._v(" "+_vm._s(_vm.casesNameLabel)+" ")]),_c('base-button',{staticClass:"m-0",attrs:{"size":"sm","type":"primary","link":""},on:{"click":function($event){_vm.$refs.caseForm && _vm.$refs.caseForm.display()}}},[_vm._v(" Add New "+_vm._s(_vm.caseNameLabel)+" "),_c('plus-icon',{staticClass:"ml-3",attrs:{"size":"1.5x"}})],1),_c('base-button',{staticClass:"ml-4",attrs:{"size":"s","type":"secondary","link":""},on:{"click":function($event){_vm.showArchived = !_vm.showArchived}}},[_vm._v(" "+_vm._s(_vm.archiveButtonCopy)+" ")])],1):_vm._e(),_c('el-table',{class:{
          'mt-4': _vm.reuse,
        },staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"prop":"title","label":"Title","min-width":"200","sortable":""}}),_c('el-table-column',{attrs:{"prop":"name","label":"Number","min-width":"200","sortable":""}}),_c('el-table-column',{attrs:{"prop":"createdOn","label":"Created On","width":"250","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.$date(scope.row.createdOn).tz(_vm.userTimezone).format("MMM DD, YYYY [at] hh:mm a"))+" ")]}}],null,false,4063497971)}),_c('el-table-column',{attrs:{"prop":"createdBy","label":"Created By","min-width":"200","sortable":""}}),_c('el-table-column',{attrs:{"prop":"modifiedOn","label":"Modified On","width":"250","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.$date(scope.row.modifiedOn).tz(_vm.userTimezone).format("MMM DD, YYYY [at] hh:mm a"))+" ")]}}],null,false,2817931346)}),_c('el-table-column',{attrs:{"prop":"modifiedBy","label":"Modified By","min-width":"200","sortable":""}}),_c('el-table-column',{attrs:{"width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{staticClass:"dropdown-actions",attrs:{"size":"small","split-button":"","type":"primary"},on:{"click":function($event){return _vm.handleCaseView(scope.row.id)}}},[_vm._v(" View "+_vm._s(_vm.caseNameLabel)+" "),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[_c('a',{on:{"click":function($event){return _vm.archive(scope.row.id, !_vm.showArchived)}}},[_vm._v(" "+_vm._s(_vm.archiveCaseOptionCopy)+" ")])])],1)],1)]}}],null,false,712794513)})],1),_c('table-pagination',{ref:"pagination",attrs:{"pagination":_vm.pagination},on:{"changed":_vm.handlePaginationChange}})],1)]):_vm._e(),_c('case-form',{ref:"caseForm",attrs:{"header":"New !!"},on:{"updated":(c) => _vm.handleCaseView(c.id)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }