<template>
  <div class="rtf-editor">
    <div v-if="label">
      {{ label }}
    </div>
    <div
      v-if="!readonly"
      ref="editor"
    />
    <rtf-viewer
      v-else
      :json="json"
    />
  </div>
</template>
<script>
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import RtfViewer from "../RtfViewer/RtfViewer.vue";
export default {
  name: "rtf-editor",
  components: {
    RtfViewer,
  },
  props: {
    json: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null,
      isEmpty: null,
    };
  },
  watch: {
    "$props.json": function(to) {
      if (to) {
        this.editor.setContents(to, "silent");
      }
    },
  },
  mounted() {
    if (this.readonly) {
      return;
    }
    this.editor = new Quill(this.$refs.editor, {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block", "image"],
          [{"list": "ordered"}, {"list": "bullet"}],
          [{"script": "sub"}, {"script": "super"}],
          [{"indent": "-1"}, {"indent": "+1"}],
          [{"header": [1, 2, 3, false]}],
          [{"color": []}, {"background": []}],
          [{"align": []}],
          ["clean"],
        ],
      },
      placeholder: "Add note content...",
      theme: "snow",
    });
    if (this.$props.json) {
      this.editor.setContents(this.$props.json, "silent");
    }
    this.checkIsEmpty();
    this.editor.on("text-change", this.textChanged);
  },
  methods: {
    textChanged() {
      this.$emit(
        "contentsChanged",
        JSON.parse(JSON.stringify(this.editor.getContents()))
      );
      this.checkIsEmpty();
    },
    checkIsEmpty() {
      const isEmpty = this.editor.getText().trim().length == 0;
      if (isEmpty !== this.isEmpty) {
        this.$emit("onContentEmpty", isEmpty);
        this.isEmpty = isEmpty;
      }
    },
  },
};
</script>
