<template><div v-html="icon"></div></template>

<script>
const feather = require("feather-icons");
export default {
  props: {
    size: {
      type: String,
      default: "",
    },
  },
  computed: {
    icon() {
      let i = this.$slots.default[0].text.trim();
      if (feather.icons[`${i}`]) {
        let attrs = {};
        if (this.size) {
          attrs = { width: this.size, height: this.size };
        }
        return feather.icons[`${i}`].toSvg(attrs);
      }
      return "";
    },
  },
};
</script>

<style></style>
