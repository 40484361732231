<template>
  <div
    class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate"
    :class="switchClass"
  >
    <div
      class="bootstrap-switch-container"
      @click.stop="triggerToggle()"
    >
      <span class="bootstrap-switch-handle-on ">
        <slot name="on"> {{ onText }} </slot>
      </span>
      <span class="bootstrap-switch-label" />
      <span class="bootstrap-switch-handle-off bootstrap-switch-default">
        <slot name="off"> {{ offText }} </slot>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "base-switch",
  props: {
    value: {
      type: [Array, Boolean],
      default: false,
    },
    onText: {
      type: String,
      default: null,
    },
    offText: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    switchClass() {
      const base = "bootstrap-switch-";
      const state = this.model ? "on" : "off";
      const classes = `${base}${state} ${base}-${this.type}`;
      return classes;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    triggerToggle() {
      this.model = !this.model;
    },
  },
};
</script>
<style></style>
