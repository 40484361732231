import Vue from "vue";
import {isDefined} from "../../../api/helpers";
import {ethosRouteNames} from "../../../routes/routeNames";
import {EMPTY_PAGINATION, EMPTY_SEARCH_WRAPPER} from "../../../util/consts";
export const changeReqIsOnKey = "changeReqIsOn";

const mutations = {
  initialiseStore(state) {
    state.showUploadsPopover = window.localStorage.getItem(changeReqIsOnKey) === "true";
  },
  setCaseClip(state, value) {
    state.caseClip = value;
  },
  toggleUploadsPopover(state, value) {
    window.localStorage.setItem(changeReqIsOnKey, value ? "true" : "false");
    state.showUploadsPopover = value;
  },
  setConfig(state, config) {
    if (!config) return;
    state.config.labels = [];
    ["case", "cases", "project", "uploads", "people", "person", "incident", "starred", "timeline"].forEach((s) => {
      const name = `${s}NameLabel`;
      state.config.labels.push({name, value: config[name]});

      state.searchRadioOpts.forEach((o) => {
        if (o.name.toLowerCase() === s.toLowerCase()) o.label = config[name];
      });
    });

    if (config.availableWorkflowsCsv) {
      const parsedGroups = [];
      config.availableWorkflowsCsv.split("##")
        .filter((g) => g.length > 3)
        .forEach((g) => {
          const parts = g.split("!!!");
          if (parts.length = 2) {
            parsedGroups.push({
              name: parts[0],
              workflows: parts[1].split(","),
            });
          }
        });
      state.config.availableWorkflows = [];
      state.config.availableWorkflows.push(...parsedGroups);
    }
    if (config.selectedWorkflowsCsv) {
      state.config.selectedWorkflows = [];
      state.config.selectedWorkflows.push(
        ...config.selectedWorkflowsCsv.split(",")
      );
    }
    if (config.availableResultWorkflowsCsv) {
      state.config.availableResultWorkflows = [];
      state.config.availableResultWorkflows.push(
        ...config.availableResultWorkflowsCsv.split(",")
      );
    }
    if (config.selectedResultWorkflowsCsv) {
      state.config.selectedResultWorkflows = [];
      state.config.selectedResultWorkflows.push(
        ...config.selectedResultWorkflowsCsv.split(",")
      );
    }
    if (config.availableLanguagesCsv) {
      state.config.availableLanguages = [];
      state.config.availableLanguages.push(
        ...config.availableLanguagesCsv.split(",")
      );
    }
    if (config.availablePeopleGroupsCsv) {
      state.config.availablePeopleGroups = [];
      state.config.availablePeopleGroups.push(
        ...config.availablePeopleGroupsCsv.split(",")
      );
    }
    if (config.autoApprovalRoles) {
      state.config.autoApprovalRoles = [];
      state.config.autoApprovalRoles.push(
        ...config.autoApprovalRoles.split(",")
      );
    }
    if (config.availableElementStatesCsv) {
      state.config.availableElementStates = [];
      state.config.availableElementStates.push(
        ...config.availableElementStatesCsv.split(",")
      );
    }
    if (config.availableCaseStatesCsv) {
      state.config.availableCaseStates = [];
      state.config.availableCaseStates.push(
        ...config.availableCaseStatesCsv.split(",")
      );
    }
    if (config.availableUploadTypesCsv) {
      state.config.availableUploadTypes = [];
      state.config.availableUploadTypes.push(
        ...config.availableUploadTypesCsv.split(",")
      );
    }
    if (config.availableCaseRolesCsv) {
      state.config.availableCaseRoles = [];
      state.config.availableCaseRoles.push(
        ...config.availableCaseRolesCsv.split(",").map((s) => {
          const parts = s.split(":");
          const name = parts.pop();
          let value = parts.pop();
          if (!isDefined(value)) {
            value = 0;
          } else {
            value = parseInt(value, 10);
          }
          if (isNaN(value)) value = 0;
          return {
            name,
            value,
          };
        })
      );
    }
    if (config.timeZone) {
      state.config.timeZone = config.timeZone;
    }
    if (isDefined(config.displayNotes)) {
      state.config.displayNotes = config.displayNotes;
    }
    if (isDefined(config.displayIncidents)) {
      state.config.displayIncidents = config.displayIncidents;
    }
    if (isDefined(config.displayIncidentColors)) {
      state.config.displayIncidentColors = config.displayIncidentColors;
    }
  },
  setAutoApproveRoles(state, data) {
    if (data && Array.isArray(data)) {
      state.config.autoApprovalRoles = data.slice();
    }
  },
  setBreadcrumbHint(state, data) {
    Vue.set(state.breadcrumbHints, data.name, data.value);
  },
  setHighlight(state, data) {
    if (!data) return;
    let newPaths = [];
    const toMap = Array.isArray(data) ? data : [data];
    toMap.forEach((pair) => {
      if (!pair.key || pair.id === null || pair.id === undefined) return;
      let path = `${pair.id}`;
      if (pair.key === "transcriptions") {
        const uploadId = state[pair.key][pair.id].uploadId;
        if (state.uploads[uploadId]) {
          path = `${uploadId}.${path}`;
          try {
            const caseId = state.uploads[uploadId].caseIds[0];
            if (state.cases[caseId]) {
              path = `${caseId}.${path}`;
            }
          } catch (ex) {
            console.log(ex);
          }
        }
      } else if (pair.key === "transcriptions") {
        try {
          const caseId = state[pair.key][pair.id].caseIds[0];
          if (state.cases[caseId]) {
            path = `${caseId}.${path}`;
          }
        } catch (ex) {
          console.log(ex);
        }
      }
      if (pair.state === 2) {
        newPaths = [path];
      } else {
        newPaths.push(path);
      }
      state.highlightPaths = newPaths;
    });
  },
  putSearchResults(state, data) {
    if (!data) throw new Error("Failed to find data to set");
    const type = data.type;
    const query = data.query;
    const result = data.result;

    const opt = state.searchRadioOpts.filter((o) => o.name === type)[0];
    const key = opt ? opt.key : null;

    const search = {
      id: state.searchCount,
      type: opt ? opt.label : type,
      query,
      count: result ? result.length : 0,
      error: null, // TODO: - Map this? or only log success this way?
      active: true,
      transcriptions: [],
      cases: [],
      uploads: [],
    };
    state.searchCount++;
    if (result) {
      if (Array.isArray(result)) {
        result.forEach((val) => {
          putEach(state, key, val, search);
        });
      } else {
        putEach(state, key, result, search);
      }
    }
    state.searches.push(search);
  },
  setUploadsAdded(state, data) {
    Vue.set(state, "uploadsListRefreshToken", Date.now());
  },
  setSearchKeyword(state, data) {
    if (!data) throw new Error("Failed to find data to set");
    state.searchKeyword = data.keyword;
    // Set the title
    Vue.set(state.breadcrumbHints, ethosRouteNames.Search, `Search results for "${data.keyword}"`);
  },
  setUploadResponse(state, response) {
    const existsAtIndex = state.uploadResponses.findIndex((u) => u.id === response.id);
    if (existsAtIndex !== -1) {
      state.uploadResponses[existsAtIndex] = response;
    } else {
      state.uploadResponses.push(response);
    }
    // Clone to prompt
    state.uploadResponses = [...state.uploadResponses];
  },
  popUploadResponse(state, uploadResponseId) {
    const existsAtIndex = state.uploadResponses.findIndex((w) => w.id === uploadResponseId);
    if (existsAtIndex !== -1) {
      state.uploadResponses.splice(existsAtIndex);
    }
  },
  setWorkflowResponse(state, response) {
    const existsAtIndex = state.workflowResponses.findIndex((w) =>
      w.workflowPersistenceId === response.workflowPersistenceId);
    if (existsAtIndex !== -1) {
      state.workflowResponses[existsAtIndex] = response;
    } else {
      state.workflowResponses.push(response);
    }
    // Clone to prompt
    state.workflowResponses = [...state.workflowResponses];
  },
  popWorkflowResponse(state, workflowPersistenceId) {
    const existsAtIndex = state.workflowResponses.findIndex((w) =>
      w.workflowPersistenceId === workflowPersistenceId);
    if (existsAtIndex !== -1) {
      state.workflowResponses.splice(existsAtIndex);
    }
  },
  putCodeWordGroups(state, codeWordGroups) {
    if (codeWordGroups && Array.isArray(codeWordGroups)) {
      codeWordGroups.forEach((group) => {
        Vue.set(state.codeWordGroups, group.id, group);
      });
    }
  },
  putCodeWordGroup(state, codeWordGroup) {
    if (codeWordGroup) {
      Vue.set(state.codeWordGroups, codeWordGroup.id, codeWordGroup);
    }
  },
  putCodeWordCollection(state, data) {
    if (
      !isDefined(data) ||
      !isDefined(data.groupId) ||
      !isDefined(data.collection)
    ) return;
    const collectionIndex = state.codeWordGroups[data.groupId]
      .collections.findIndex((a) => a.id === data.collection.id);
    if (collectionIndex === -1) {
      state.codeWordGroups[data.groupId].collections.push(data.collection);
    } else {
      state.codeWordGroups[data.groupId].collections[collectionIndex] = data.collection;
    }
  },
  putWord(state, data) {
    if (
      !isDefined(data) ||
      !isDefined(data.groupId) ||
      !isDefined(data.collectionId) ||
      !isDefined(data.word)
    ) return;
    const collectionIndex = state.codeWordGroups[data.groupId]
      .collections.findIndex((a) => a.id === data.collectionId);
    if (collectionIndex === -1) return;
    const wordIndex = state.codeWordGroups[data.groupId].collections[collectionIndex]
      .words.findIndex((w) => w.id === data.word.id);
    if (wordIndex === -1) {
      state.codeWordGroups[data.groupId].collections[collectionIndex].words.push(data.word);
    } else {
      state.codeWordGroups[data.groupId].collections[collectionIndex].words[wordIndex] = data.word;
    }
  },
  putPeople(state, peopleList) {
    if (peopleList && Array.isArray(peopleList)) {
      peopleList.forEach((people) => {
        Vue.set(state.people, people.id, people);
      });
    }
  },
  setLoadedPeople(state, bool) {
    state.loadedPeople = bool;
    if (bool) {
      state.loadedPeopleGroups = state.config.availablePeopleGroups;
    }
  },
  setLoadedPeopleGroup(state, groupName) {
    state.loadedPeopleGroups.push(groupName);
    state.loadedPeopleGroups = [...state.loadedPeopleGroups];
  },
  putUserMap(state, users) {
    state.userMap = users;
  },
  putUserInMap(state, user) {
    Vue.set(state.userMap, user.id, user);
  },
  putActorMap(state, actors) {
    state.actorMap = actors;
  },
  putActorInMap(state, actor) {
    Vue.set(state.actorMap, actor.id, actor);
  },
  putActor(state, actor) {
    Vue.set(state.actor, actor.id, actor);
  },
  putUpload(state, uploads) {
    Vue.set(state.uploads, uploads.id, uploads);
  },
  putTranscription(state, transcription) {
    Vue.set(state.transcriptions, transcription.id, transcription);
  },
  putCase(state, caseModel) {
    Vue.set(state.cases, caseModel.id, caseModel);
  },
  putCaseSummary(state, caseSummaryModel) {
    Vue.set(state.caseSummaries, caseSummaryModel.id, caseSummaryModel);
  },
  putCaseUploadSummaries(state, caseUploadSummariesModel) {
    const wrapper = Object.assign(
      emptySearchWrapper(),
      state.caseUploadSummaries[caseUploadSummariesModel.caseId] || {}
    );
    if (caseUploadSummariesModel.filter) {
      wrapper.filter = caseUploadSummariesModel.filter;
      wrapper.missedTerm = null;
    }
    if (caseUploadSummariesModel.pagination) {
      wrapper.pagination = paginationConverter(caseUploadSummariesModel.pagination);

      const term = wrapper.pagination.SearchQuery;
      const results = wrapper.pagination.ResultsFor;
      if (
        isDefined(term) && term.length > 0 &&
        isDefined(results) && results.length > 0) {
        wrapper.filter = Object.assign({}, wrapper.filter, {Term: results});
        wrapper.missedTerm = term;
      }
    }
    if (caseUploadSummariesModel.data) wrapper.data = caseUploadSummariesModel.data;
    if (isDefined(caseUploadSummariesModel.loading)) wrapper.loading = caseUploadSummariesModel.loading;
    Vue.set(state.caseUploadSummaries, caseUploadSummariesModel.caseId, wrapper);
  },
  putCaseUploadSummary(state, model) {
    const wrapper = Object.assign(
      emptySearchWrapper(),
      state.caseUploadSummaries[model.caseId] || {}
    );

    const summaries = wrapper.data.slice();
    const index = summaries.findIndex((s) => s.uploadId === model.data.uploadId);
    if (index === -1) {
      summaries.push(model.data);
    } else {
      summaries[index] = model.data;
    }
    wrapper.data = summaries;
    Vue.set(state.caseUploadSummaries, model.caseId, wrapper);
  },
  putCaseUploadClipSummaries(state, caseUploadClipSummariesModel) {
    const wrapper = Object.assign(
      emptySearchWrapper(),
      state.caseUploadClipSummaries[caseUploadClipSummariesModel.caseId] || {}
    );
    if (caseUploadClipSummariesModel.filter) {
      wrapper.filter = caseUploadClipSummariesModel.filter;
      wrapper.missedTerm = null;
    }
    if (caseUploadClipSummariesModel.pagination) {
      wrapper.pagination = paginationConverter(caseUploadClipSummariesModel.pagination);

      const term = wrapper.pagination.SearchQuery;
      const results = wrapper.pagination.ResultsFor;
      if (
        isDefined(term) && term.length > 0 &&
        isDefined(results) && results.length > 0) {
        wrapper.filter = Object.assign({}, wrapper.filter, {Term: results});
        wrapper.missedTerm = term;
      }
    }
    if (caseUploadClipSummariesModel.data) wrapper.data = caseUploadClipSummariesModel.data;
    if (isDefined(caseUploadClipSummariesModel.loading)) wrapper.loading = caseUploadClipSummariesModel.loading;
    Vue.set(state.caseUploadClipSummaries, caseUploadClipSummariesModel.caseId, wrapper);
  },

  putCaseUploadEvidenceSummaries(state, caseUploadEvidenceSummariesModel) {
    const wrapper = Object.assign(
      emptySearchWrapper(),
      state.caseUploadEvidenceSummaries[caseUploadEvidenceSummariesModel.caseId] || {}
    );
    if (caseUploadEvidenceSummariesModel.filter) {
      wrapper.filter = caseUploadEvidenceSummariesModel.filter;
      wrapper.missedTerm = null;
    }
    if (caseUploadEvidenceSummariesModel.pagination) {
      wrapper.pagination = paginationConverter(caseUploadEvidenceSummariesModel.pagination);

      const term = wrapper.pagination.SearchQuery;
      const results = wrapper.pagination.ResultsFor;
      if (
        isDefined(term) && term.length > 0 &&
        isDefined(results) && results.length > 0) {
        wrapper.filter = Object.assign({}, wrapper.filter, {Term: results});
        wrapper.missedTerm = term;
      }
    }
    if (caseUploadEvidenceSummariesModel.data) wrapper.data = caseUploadEvidenceSummariesModel.data;
    if (isDefined(caseUploadEvidenceSummariesModel.loading)) wrapper.loading = caseUploadEvidenceSummariesModel.loading;
    Vue.set(state.caseUploadEvidenceSummaries, caseUploadEvidenceSummariesModel.caseId, wrapper);
  },
  putCaseClipSummaries(state, caseClipSummariesModel) {
    Vue.set(state.caseClipSummaries, caseClipSummariesModel.caseId, caseClipSummariesModel.summaries);
  },
  putCaseActorAttributions(state, caseActorAttributionsModel) {
    Vue.set(state.caseActorAttributions, caseActorAttributionsModel.caseId, caseActorAttributionsModel.actors);
  },
  putCaseUserAttributions(state, caseUserAttributionsModel) {
    Vue.set(state.caseUserAttributions, caseUserAttributionsModel.caseId, caseUserAttributionsModel.users);
  },
  putCaseSummariesLoading(state, loading) {
    state.caseSummaries.loading = loading;
  },
  putCaseSummariesPagination(state, pagination) {
    const p = paginationConverter(pagination);
    state.caseSummaries.pagination = p;
    const term = p.SearchQuery;
    const results = p.ResultsFor;
    if (
      isDefined(term) && term.length > 0 &&
      isDefined(results) && results.length > 0) {
      state.caseSummaries.filter = Object.assign({}, state.caseSummaries.filter, {Term: results});
      state.caseSummaries.missedTerm = term;
    }
  },
  updateCaseSummarySearchFilter(state, filter) {
    state.caseSummaries.filter = Object.assign({}, state.caseSummaries.filter, filter);
    state.caseSummaries.missedTerm = null;
  },
  putCaseSummaries(state, caseSummaries) {
    if (caseSummaries && Array.isArray(caseSummaries)) {
      state.caseSummaries.data = caseSummaries;
    }
  },
  putCaseSummary(state, caseSummary) {
    const summaries = state.caseSummaries.data.slice();
    const index = summaries.findIndex((s) => s.id === caseSummary.id);
    if (index === -1) {
      summaries.push(caseSummary);
    } else {
      summaries[index] = caseSummary;
    }
    state.caseSummaries.data = summaries;
  },
  putHighlightSnippet(state, snippet) {
    state.highlightSnippet = snippet;
  },
};


export const emptySearchWrapper = () => {
  return Object.assign({}, EMPTY_SEARCH_WRAPPER);
};

const paginationConverter = (pagination) => {
  let model = Object.assign({}, EMPTY_PAGINATION);
  if (typeof pagination === "string") {
    try {
      model = JSON.parse(pagination);
    } catch (ex) {
      console.log("Unable to parse pagination", ex);
    }
  } else {
    model = pagination;
  }
  return model;
};

const putEach = (state, key, val, search) => {
  search[key].push(val.id);
  let obj;
  switch (key) {
    case "uploads":
      obj = putUpload(state, val, search);
      break;
    case "cases":
      obj = putCase(state, val, search);
      break;
    case "transcriptions":
      obj = putTranscription(state, val, search);
      break;
    // case "actors":
    //   obj = putActor(state, val, search);
    //   break;
    default: break;
  }
  console.info(obj);
};

// Model Mappers
const putCase = (state, data, search, enforceIds) => {
  if (!data) return null;
  if (search.cases.indexOf(data.id) === -1) search.cases.push(data.id);
  const eCase = {
    highlight: 0,
    id: data.id,
    name: data.name,
    uploadIds: enforceIds ? enforceIds : [],
  };
  if (data.uploads && data.uploads.length > 0) {
    data.uploads
      .map((uploads) => putUpload(state, uploads, search))
      .filter((uploads) => uploads !== null)
      .forEach((uploads) => {
        if (eCase.uploadIds.indexOf(uploads.id) === -1) {
          eCase.uploadIds.push(uploads.id);
        }
      });
  }

  if (state.cases[data.id]) {
    state.cases[data.id].uploadIds.forEach((id) => {
      if (eCase.uploadIds.indexOf(id) === -1) {
        eCase.uploadIds.push(id);
      }
    });
  }
  Vue.set(state.cases, data.id, eCase);

  return eCase;
};

const putUpload = (state, data, search) => {
  if (!data) return null;
  if (search.uploads.indexOf(data.id) === -1) search.uploads.push(data.id);
  const uploads = {
    highlight: 0,
    id: data.id,
    name: data.name,
    language: data.language,
    storagePath: data.storagePath,
    caseIds: [],
  };

  if (data.cases && data.cases.length > 0) {
    data.cases
      .map((eCase) => putCase(state, eCase, search, [data.id]))
      .filter((eCase) => eCase !== null)
      .forEach((eCase) => {
        if (uploads.caseIds.indexOf(eCase.id) === -1) {
          uploads.caseIds.push(eCase.id);
        }
      });
  }

  if (state.uploads[data.id]) {
    state.uploads[data.id].caseIds.forEach((id) => {
      if (uploads.caseIds.indexOf(id) === -1) {
        uploads.caseIds.push(id);
      }
    });
  }
  Vue.set(state.uploads, data.id, uploads);

  return uploads;
};

const putTranscription = (state, data, search) => {
  if (!data) return null;
  if (search.transcriptions.indexOf(data.id) === -1) search.transcriptions.push(data.id);
  if (data.uploads) {
    putUpload(state, data.uploads, search);
  }

  const transcription = {
    highlight: 0,
    id: data.id,
    name: data.name,
    language: data.language,
    content: data.content,
    uploadId: data.uploadId,
    parentTranscriptionId: data.parentTranscriptionId,
    workflowId: data.workflowId,
    workflow: workflowFromId(state, data.workflowId),
    signedUrl: data.signedUrl ?? "",
  };
  Vue.set(state.transcriptions, data.id, transcription);
  return transcription;
};

const workflowFromId = (state, id) => {
  let matchingName = id;
  if (id === null || id === undefined || !state || !state.config || !state.config.availableWorkflows) return id;
  state.config.availableWorkflows.forEach((g) => {
    g.workflows.forEach((w) => {
      if (w.replace(/\W/g, "") === id) matchingName = w;
    });
  });
  return matchingName;
};

export default mutations;
