<template>
  <bread-crumb :transparent="false">
    <BreadCrumbItem
      v-for="(route, index) in breadcrumbs"
      :key="route.name"
      style="display: inline-block"
    >
      <span
        :class="{
          'breadcrumb-current': index === breadcrumbs.length - 1,
        }"
      >
        <home-icon
          v-if="route.name === 'Home' || route.name == 'Dashboard'"
          size="1.5x"
          class="pr-1"
        />
        <template v-if="index < breadcrumbs.length - 1">
          <router-link
            :to="breadRoute(route)"
            class="breadcrumb-link"
          >
            {{ breadName(route) }}
          </router-link>
          <chevron-right-icon
            size="1.5x"
            class="mx-3"
          />
        </template>
        <template v-else>
          {{ breadName(route) }}
        </template>
      </span>
    </BreadCrumbItem>
  </bread-crumb>
</template>

<script>
import BreadCrumb from "./Breadcrumb.vue";
import BreadCrumbItem from "./BreadcrumbItem";
import {HomeIcon, ChevronRightIcon} from "vue-feather-icons";
import {mapGetters} from "vuex";
import {ethosRouteNames} from "../../routes/routeNames";
import {isDefined} from "../../api/helpers";

export default {
  name: "route-breadcrumb",
  components: {
    BreadCrumb,
    BreadCrumbItem,
    HomeIcon,
    ChevronRightIcon,
  },
  computed: {
    ...mapGetters("data", [
      "casesNameLabel",
      "caseNameLabel",
      "uploadsNameLabel",
      "projectNameLabel",
      "peopleNameLabel",
      "breadcrumbHints",
      "caseClip",
    ]),
    breadcrumbs() {
      const routes = this.$route.matched.slice();
      const initialRoute =routes[0];
      if (initialRoute && initialRoute.name == "ViewCase") {
        routes.unshift({
          name: "Dashboard",
        });
      }
      return routes;
    },
  },
  methods: {
    breadName(route) {
      switch (route.name) {
        case ethosRouteNames.Cases:
          return this.casesNameLabel;
        case ethosRouteNames.ClipsV2:
          return "Clips";
        case ethosRouteNames.ClipV2:
          return this.caseClip.title ? this.caseClip.title : "Clip";
        case ethosRouteNames.Project:
          return this.projectNameLabel;
        case ethosRouteNames.Uploads:
        case ethosRouteNames.ProjectUploads:
          return this.uploadsNameLabel;
        case ethosRouteNames.People:
        case ethosRouteNames.UploadsPeople:
        case ethosRouteNames.ProjectPeople:
          return this.peopleNameLabel;
        default:
          if (isDefined(this.breadcrumbHints[route.name])) {
            return this.breadcrumbHints[route.name];
          }
          break;
      }
      return route.name.split("_").pop();
    },
    breadRoute(route) {
      if (route.name == "ViewCase") {
        return {name: "CaseUploads", params: this.$route.params};
      }
      return {name: route.name, params: this.$route.params};
    },
  },
};

</script>

<style scoped></style>
