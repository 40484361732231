<template>
  <div class="d-flex align-items-bottom">
    <div class="flex-grow-1">
      <label
        for="tz"
        class="form-label"
      > Time Zone </label>
      <el-select
        id="tz"
        ref="timezoneSelect"
        v-model="tz"
        class="select-primary dark d-block"
        filterable
        effect="dark"
        popper-class="select-primary"
        placeholder="Time Zone"
      >
        <el-option
          v-for="item in $tz.now"
          :key="item.name"
          :label="item.currentTimeFormat"
          :value="item.name"
        />
      </el-select>
    </div>
    <base-button
      v-if="!hideCurrent"
      class="mt-auto mb-0 mr-0"
      type="secondary"
      simple
      @click="tz = $date.tz.guess()"
    >
      Current
    </base-button>
  </div>
</template>

<script>
export default {
  name: "timezone-field",
  props: {
    name: {
      type: String,
      default: null,
    },
    hideCurrent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tz: "America/Vancouver",
    };
  },
  watch: {
    name: {
      handler(to, from) {
        if (to && to !== from) {
          this.tz = this.name;
        }
      },
      immediate: true,
    },
    tz: {
      handler() {
        this.$emit("changed", this.tz);
      },
      immediate: true,
    },
  },
};
</script>
