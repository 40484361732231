<template>
  <base-filter
    title="Uploads"
    item-name="Uploads"
    :missed-term="missedTerm"
    :filter="filter"
    :pagination="pagination"
    :status-options="statusOptions"
    :type-options="typeOptions"
    :subject-options="subjectOptions"
    :case-id="caseId"
    date-opened-label="Date Uploaded"
    show-upload
    @changed="(e) => $emit('changed', e)"
    @created="(e) => $emit('created', e)"
  >
    <template slot="actions">
      <slot name="actions" />
    </template>
  </base-filter>
</template>

<script>
import {mapGetters} from "vuex";
import BaseFilter from "@/components/BaseFilter.vue";
import {EMPTY_PAGINATION, EMPTY_SEARCH_WRAPPER} from "../../../util/consts";

export default {
  components: {
    BaseFilter,
  },
  props: {
    caseId: {
      type: Number,
      default: -1,
    },
    missedTerm: {
      type: String,
      default: null,
    },
    filter: {
      type: Object,
      default: () => Object.assign({}, EMPTY_SEARCH_WRAPPER.filter),
    },
    pagination: {
      type: Object,
      default: () => Object.assign({}, EMPTY_PAGINATION),
    },
    subjects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showFilterDetails: false,
    };
  },
  computed: {
    ...mapGetters("data", ["caseSummaries", "availableUploadTypes", "availableElementStates"]),
    typeOptions() {
      return this.availableUploadTypes.map((t) => {
        return {
          name: t,
          value: t,
        };
      });
    },
    statusOptions() {
      return this.availableElementStates.map((t) => {
        return {
          name: t,
          value: t,
        };
      });
    },
    subjectOptions() {
      return this.subjects.map((s) => {
        return {
          name: s.fullName,
          value: s.actorId,
        };
      });
    },
  },
};
</script>
<style>
.search-menu--container {
  max-width: 650px;
}
</style>
