<template>
  <div class="d-flex p-5 items-center align-items-center">
    <h6 class="text-white mb-0">Notes</h6>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
