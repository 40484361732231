<template>
  <div
    v-if="headlines.length > 0"
    class="bg-dark-100 p-3 mt-5 rounded-lg"
  >
    <div class="d-flex align-items-center">
      <div class="text-lightblue flex-grow-1">
        Key Excerpts: {{ headlines.length }}
      </div>
      <base-button
        link
        class="m-0"
        @click="showExcerpts = !showExcerpts"
      >
        {{ showExcerpts ? "Hide" : "Show" }}
      </base-button>
    </div>
    <div
      v-if="showExcerpts"
      class="text-white mt-1"
    >
      <div
        v-for="h, i in headlines"
        :key="h+i"
        class="mb-2 cursor-pointer"
        @click="() => $emit('clicked', h)"
      >
        <span
          v-for="s, j in h"
          :key="s+j"
          :class="{keyword: j%2}"
        >
          {{ s }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  props: {
    headlines: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {BaseButton},
  data() {
    return {
      showExcerpts: false,
    };
  },
};
</script>
<style lang="scss" scoped>
span.keyword {
  color: #FFD480;
  background-color: #4D330080;
  border-radius: 2px;
  font-weight: 600;
  padding: 0 1px;
}
</style>
