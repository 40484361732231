<template>
  <div
    v-if="cr"
    class="pending-changes px-3 pt-2 pb-0 pointer-none"
  >
    <h4 class="text-100">
      {{ header }}
    </h4>
    <p class="text-100">
      <template v-if="isSpeaker">
        <template v-if="isRemove">
          Reset Selection
        </template>
        <template v-else>
          <b>To:</b> {{ people && people.displayName || 'New Selection' }}
          <template v-if="cr.atm === true">
            <br><b>Applies To All Matching</b>
          </template>
        </template>
      </template>
      <template v-else>
        <div><b>To:</b> {{ cr.t }}</div>
        <div><b>From:</b> {{ cr.f }}</div>
      </template>
    </p>
    <p class="mt-4 text-100">
      <b class="text-100">Requested By</b> <br>
      {{ cr.mb }} <br>
      <b class="text-100">Requested At</b> <br>
      {{ cr.mo }} <br>
    </p>
    <p
      v-if="cr.rb != null"
      class="mt-4 text-100"
    >
      <b class="text-100">Rejected By</b> <br>
      {{ cr.rb }} <br>
      <b class="text-100">Rejected At</b> <br>
      {{ cr.ro }} <br>
      <!-- TODO: - Date display -->
    </p>
    <p
      v-else-if="cr.ab != null"
      class="mt-4 text-100"
    >
      <b class="text-100">Approved By</b> <br>
      {{ cr.ab }} <br>
      <b class="text-100">Approved At</b> <br>
      {{ cr.ao }} <br>
    </p>
    <template v-else>
      <div class="mt-5">
        <label
          for="reason"
          class="form-label"
        > Approval/Rejection Reason (Optional)</label>
        <input
          id="reason"
          v-model="reason"
          type="text"
          class="form-control"
          placeholder="Rejected because..."
        >
      </div>
      <div class="actions d-flex justify-content-between">
        <base-button
          size="xs"
          type="danger"
          simple
          class="btn-outlined"
          @click="reject"
        >
          Reject
        </base-button>

        <base-button
          size="xs"
          type="primary"
          simple
          class="btn-outlined"
          @click="approve"
        >
          Approve
        </base-button>
      </div>
    </template>
    <p
      v-if="cr.r"
      class="mt-4 text-100"
    >
      <b class="text-100">Reason</b> <br>
      {{ cr.r }} <br>
    </p>
  </div>
</template>

<script>
export default {
  name: "change-request",
  props: {
    cr: {
      type: Object,
      default: null,
    },
    people: {
      type: Object,
      default: null,
    },
    isSpeaker: {
      type: Boolean,
      default: false,
    },
    isRemove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reason: "",
    };
  },
  computed: {
    header() {
      if (this.cr.rb != null) {
        return "Rejected Request";
      } else if (this.cr.ab != null) {
        return "Approved change";
      }
      return "Pending Change Request";
    },
  },
  methods: {
    approve() {
      const r = this.reason && this.reason.length > 0 ? this.reason : null;
      this.$emit("approve", {
        cr: this.cr,
        reason: r,
      });
    },
    reject() {
      const r = this.reason && this.reason.length > 0 ? this.reason : null;
      this.$emit("reject", {
        cr: this.cr,
        reason: r,
      });
    },
  },
};
</script>
