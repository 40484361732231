<template>
  <div
    v-if="isDisplayed"
    class="upload-manager-overlay"
  >
    <upload-message-modal
      ref="uploadmodal"
      @cancelAllUploads="cancelAllUploads"
    />
    <div
      id="upload-manager-alert"
      class="alert"
    >
      <div class="alert-title">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <b><span v-html="title" /></b>
        <button
          type="button"
          aria-hidden="true"
          class="close minimize col-xs-1"
          @click="toggleMaximized"
        >
          <chevron-down-icon
            v-if="isMaximized"
            size="1x"
          />
          <chevron-up-icon
            v-if="!isMaximized"
            size="1x"
          />
        </button>
        <button
          type="button"
          aria-hidden="true"
          class="close col-xs-1"
          @click="close"
        >
          <x-icon size="1x" />
        </button>
      </div>
      <div class="alert-message ">
        <el-table
          v-if="isMaximized"
          :data="tableData"
          :show-header="false"
          :show-overflow-tooltip="true"
          style="width: 100%"
          class="upload-table"
        >
          <el-table-column
            prop="name"
            label="Name"
            :min-width="90"
          >
            <div
              slot-scope="{ row }"
              class="d-inline-block w-100"
            >
              <span class="upl-filename">{{ row.name }}</span>
              <div class="d-flex align-items-center">
                <div class="d-inline-block w-100">
                  <base-progress
                    :type="row.status === 'Failed' ? 'danger' : row.status === 'Canceled' ? 'secondary' : 'primary'"
                    class="w-100"
                    :value="row.progress"
                    value-position="none"
                  />
                </div>
                <div
                  class="d-inline-block"
                  style="width: 100px;text-align:right"
                >
                  {{ row.status }}
                </div>
              </div>
            </div>
          </el-table-column>
          <el-table-column :min-width="10">
            <div
              v-if="['Uploading', 'Preparing'].includes(row.status)"
              slot-scope="{ row }"
            >
              <el-tooltip
                content="Cancel"
                placement="top"
              >
                <button
                  type="button"
                  aria-hidden="true"
                  class="close cancel col-xs-1"
                  @click="() => cancel(row)"
                >
                  <x-circle-icon size="1x" />
                </button>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {ChevronDownIcon, ChevronUpIcon, XCircleIcon, XIcon} from "vue-feather-icons";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UploadMessageModal from "./UploadMessageModal.vue";
import {dragElement, stopDragElement} from "../../../util/util";

export default {
  name: "upload-manager-overlay",
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    XCircleIcon,
    XIcon,
    UploadMessageModal,
  },
  computed: {
    ...mapGetters("data", [
      "getUploadResponse",
      "getWorkflowResponse",
      "uploadsNameLabel",
    ]),
    totalInProgress() {
      return this.tableData.filter((x) => ["Uploading", "Preparing"].includes(x.status))?.length;
    },
    title() {
      let msg = "";
      const totalComplete = this.tableData.filter((x) => ["Complete"].includes(x.status))?.length;
      if (this.totalInProgress > 0) {
        msg = `Uploading ${this.totalInProgress} item${this.maybePlural(this.totalInProgress)}<br>`;
      } else if (totalComplete > 0) {
        msg = `${totalComplete} upload${this.maybePlural(totalComplete)} complete<br>`;
      }
      const totalFailed = this.tableData.filter((x) => ["Failed"].includes(x.status))?.length;
      const totalCanceled = this.tableData.filter((x) => ["Canceled"].includes(x.status))?.length;
      if (totalFailed > 0) {
        msg += `${totalFailed} upload${this.maybePlural(totalFailed)} failed<br>`;
      }
      if (totalCanceled > 0) {
        msg += `\n${totalCanceled} upload${this.maybePlural(totalCanceled)} canceled`;
      }

      if (msg === "") msg = "No uploads in progress";
      return msg;
    },
  },
  data() {
    return {
      isDisplayed: false,
      isMaximized: true,
      uploading: false,
      tableData: [],
      workflowTableData: [],
      files: [],
    };
  },
  watch: {
    getUploadResponse(to, from) {
      if (to && (!from || to.lengh !== from.length)) this.setUploadedFiles();
    },
  },
  mounted() {
    this.setUploadedFiles();
  },
  methods: {
    ...mapMutations("data", ["popUploadResponse", "popWorkflowResponse"]),
    ...mapActions(["pollWorkflow"]),
    maybePlural(n) {
      return n === 1 ? "" : "s";
    },
    display(eventData) {
      this.isDisplayed = true;
      dragElement(document.getElementById("upload-manager-alert"));
    },
    containerClicked() {
    },
    cancel(item) {
      item.uploadController.abort();
    },
    cancelAllUploads() {
      this.tableData.forEach((item) => {
        if (!item) return;
        item.uploadController?.abort();
      });
      this.$refs.uploadmodal.reset();
    },
    toggleMaximized() {
      this.isMaximized = !this.isMaximized;
    },
    close() {
      if (this.uploading) {
        this.$refs.uploadmodal && this.$refs.uploadmodal.display();
      } else {
        this.tableData = [];
        this.isDisplayed = false;
        stopDragElement(document.getElementById("upload-manager-alert"));
      }
    },
    setUploadedFiles() {
      if (
        Array.isArray(this.getUploadResponse) &&
        this.getUploadResponse.length > 0
      ) {
        this.uploading = true;
        const pop = this.popUploadResponse;
        this.getUploadResponse.forEach((item) => {
          const existsAtIndex = this.tableData.findIndex(
            (e) => e.id === item.id
          );
          if (existsAtIndex !== -1) {
            Object.assign(this.tableData[existsAtIndex], item);
          } else {
            this.tableData.unshift(item);
          }
          if (item.progress >= 100) {
            window.setTimeout(() => {
              this.uploading = false;
              pop(item.id);
            }, 1000);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.upload-manager-overlay {
  .alert {
    position: absolute;
    bottom: 0;
    z-index: 100;
    right: 20px;
    max-width: 400px;
    width: 400px;
    padding: 0;

    .alert-title {
      padding: 10px;
    }


    .alert-message {
      overflow-y: auto;
      max-height: 40vh;
    }


    &[data-notify='container'] {
      width: 400px;
    }

    button.close {
      right: 15px;
      top: 20px;
    }

    button.minimize {
      right: 55px;
      top: 20px;
    }

    button.cancel {
      width: 25px;
      height: 25px;
      padding: 3px;
      opacity: .9;
      text-shadow: none;
      line-height: 0;
      outline: 0;
    }
  }

}

.upl-filename {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}


.upload-manager-overlay .upcancel {
  &>span:not(.sr-only) {
    line-height: 17px;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 50%;
    font-size: 1.25rem;
    display: block;
  }
}

.upload-manager-overlay {
  .el-table {
    border-radius: 0;
    outline: none;

    table {
      margin-bottom: 0;
      border: 0px;

      td {
        border-top: none;
      }

      .cell {
        //padding: 0;
        white-space: nowrap;
      }
    }

    .el-table__header-wrapper {
      .cell {
        text-transform: capitalize;
      }
    }
  }
}
</style>
