<template>
  <div class="p-3">
    <drop-file
      types="audio/*,video/*"
      :center-button="true"
      :center-button-text="`Upload ${uploadsNameLabel}`"
      @filesSelected="multipleNewUploads"
    />

    <!-- UPLOADS -->
    <div class="border rounded-lg w-100">
      <div class="d-flex flex-row filter-list-header align-items-center ml-4 my-3">
        <h4 class="m-0">
          Uploads
        </h4>
        <el-select
          v-model="uploadingFilterSelection"
          class="select-primary dark ml-4"
          effect="dark"
          popper-class="select-primary"
        >
          <el-option
            v-for="option in filterOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </el-option>
        </el-select>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          label="Name"
          sortable
        >
          <div
            slot-scope="{ row }"
            class="d-inline-block w-100"
          >
            <span>{{ row.name }}</span>
            <base-progress
              :type="row.status === 'Failed' ? 'danger' : 'primary'"
              class="w-50"
              :value="row.progress"
              value-position="none"
            />
          </div>
        </el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          width="200"
          sortable
        >
          <div
            slot-scope="{ row }"
            class="d-inline-block w-100"
          >
            <!-- <el-tag
              v-if="row.status === 'Complete' && !uploading"
              class="cursor-pointer"
              effect="dark"
              type="secondary"
              @click="navigateToUploads(row.uploadId)"
            >
              View Completed
            </el-tag> -->
            <span>
              {{ row.status }}
            </span>
          </div>
        </el-table-column>
        <el-table-column
          prop="size"
          label="Size"
          width="200"
          sortable
        >
          <div slot-scope="{ row }">
            {{ `${row.size.toFixed(1)} MB` }}
          </div>
        </el-table-column>
        <el-table-column
          prop="dateAdded"
          label="Date Added"
          width="200"
          sortable
        />
      </el-table>
    </div>

    <!-- WORKFLOWS -->
    <div class="border mt-4 rounded-lg">
      <div
        class="
          d-flex
          flex-row
          filter-list-header
          align-items-center
          ml-4
          my-3
        "
      >
        <h4 class="m-0">
          AI Analysis
        </h4>
        <el-select
          v-model="uploadingFilterSelection"
          class="select-primary dark ml-4"
          effect="dark"
          popper-class="select-primary"
        >
          <el-option
            v-for="option in filterOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </el-option>
        </el-select>
      </div>
      <el-table
        :data="workflowTableData"
        style="width: 100%"
      >
        <el-table-column
          prop="uploadName"
          label="Name"
          sortable
        >
          <div
            slot-scope="{ row }"
            class="d-inline-block w-100"
          >
            <span>{{ row.uploadName }}</span>
            <base-progress
              :type="
                row.status === 'Failed'
                  ? 'danger'
                  : row.status === 'Requesting'
                    ? 'secondary'
                    : 'primary'
              "
              class="w-50"
              :value="row.progress"
              value-position="none"
            />
            <base-button
              v-if="row.progress >= 100 && row.name == 'Create Transcription'"
              size="sm"
              type="primary"
              simple
              @click="$router.push(`/uploads/${row.uploadId}`)"
            >
              View {{ uploadsNameLabel }}
            </base-button>
          </div>
        </el-table-column>
        <el-table-column
          prop="name"
          label="Type"
          width="300"
          sortable
        />
        <el-table-column
          prop="status"
          label="Status"
          width="200"
          sortable
        />
        <el-table-column
          prop="dateAdded"
          label="Date Added"
          width="200"
          sortable
        />
      </el-table>
    </div>

    <add-new-uploads
      ref="uploadsForm"
      :uploaded-files="files"
      header="New !!!"
      @close="reset"
    />
  </div>
</template>

<script>
import AddNewUploads from "./AddNewUploads.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import DropFile from "../DropFile.vue";
import {isDefined} from "../../../api/helpers";

export default {
  name: "upload-manager",
  components: {AddNewUploads, DropFile},
  data() {
    return {
      files: [],
      tableData: [],
      workflowTableData: [],
      loading: true,
      dragover: false,
      uploading: false,
      uploadingFilterSelection: "Recent",
      aiFilterSelection: "Recent",
      filterOptions: ["Recent"], // , "Historical"],
    };
  },
  watch: {
    getUploadResponse(to, from) {
      if (to && (!from || to.lengh !== from.length)) this.setUploadedFiles();
    },
    getWorkflowResponse(to, from) {
      if (to && (!from || to.lengh !== from.length)) {
        this.setWorkflowResponses();
      }
    },
  },
  computed: {
    ...mapGetters("data", [
      "getUploadResponse",
      "getWorkflowResponse",
      "uploadsNameLabel",
    ]),
  },
  mounted() {
    this.setUploadedFiles();
    this.setWorkflowResponses();
  },
  // beforeRouteLeave(to, from, next) {
  //   if (this.uploading) {
  //     if (confirm("Are you sure you want to leave this page? Uploading of uploads is not completed yet.")) {
  //       next();
  //     } else {
  //       next(false);
  //     }
  //   } else {
  //     next();
  //   }
  // },
  methods: {
    ...mapMutations("data", ["popUploadResponse", "popWorkflowResponse"]),
    ...mapActions(["pollWorkflow"]),

    reset() {
      this.files = [];
    },

    // Still one at a time, but we push to an array
    multipleNewUploads(file) {
      if (file) {
        this.files.push(file);
        this.$refs.uploadsForm.display();
      }
    },

    navigateToUploads(id) {
      if (isDefined(id)) {
        this.$router.push(`/uploads/${id}`);
      }
    },

    showModal() {
      this.$refs.uploadsForm.display();
    },

    setUploadedFiles() {
      if (
        Array.isArray(this.getUploadResponse) &&
        this.getUploadResponse.length > 0
      ) {
        this.uploading = true;
        this.getUploadResponse.forEach((item) => {
          const existsAtIndex = this.tableData.findIndex(
            (e) => e.id === item.id
          );
          if (existsAtIndex !== -1) {
            Object.assign(this.tableData[existsAtIndex], item);
          } else {
            this.tableData.push(item);
          }
        });
      }
    },

    setWorkflowResponses() {
      if (
        Array.isArray(this.getWorkflowResponse) &&
        this.getWorkflowResponse.length > 0
      ) {
        const poll = this.pollWorkflow;
        const pop = this.popWorkflowResponse;
        this.getWorkflowResponse.forEach((item) => {
          const existsAtIndex = this.workflowTableData.findIndex(
            (w) => w.id === item.id
          );
          if (existsAtIndex !== -1) {
            this.workflowTableData[existsAtIndex];
            if (
              this.workflowTableData[existsAtIndex].workflowId === null &&
              item.workflowId !== null
            ) {
              this.workflowTableData[existsAtIndex].workflowId =
                item.workflowId;
              this.uploading = true;
              const interv = window.setInterval(() => {
                poll(item)
                  .then((finished) => {
                    this.uploading = !finished;
                    if (finished) {
                      window.clearInterval(interv);
                      window.setTimeout(() => {
                        pop(item.id);
                      }, 3000);
                    }
                  })
                  .catch((ex) => {});
              }, 10 * 1000);
              this.workflowTableData[existsAtIndex].interval = interv;
            }
            this.workflowTableData[existsAtIndex].status = item.status;
            this.workflowTableData[existsAtIndex].progress = item.progress;
          } else {
            const objToAdd = {
              id: item.id,
              workflowId: item.workflowId,
              name: item.name,
              uploadName: item.uploadName,
              status: item.status,
              progress: item.progress,
              dateAdded: item.dateAdded,
              uploadId: item.uploadId,
            };
            if (item.workflowId) {
              objToAdd.interval = window.setInterval(() => {
                poll(item)
                  .then((finished) => {
                    if (finished) {
                      window.clearInterval(objToAdd.interval);
                      window.setTimeout(() => {
                        pop(objToAdd.id);
                      }, 3000);
                    }
                  })
                  .catch((ex) => {});
              }, 10 * 1000);
            }
            this.workflowTableData.push(objToAdd);
          }
        });
      }
    },
  },
};
</script>
