export default {
  inserted: function(el, binding) {
    const onResizeCallback = binding.value;
    window.addEventListener("resize", () => {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      const elWidth = el.clientWidth;
      const elHeight = el.clientHeight;
      onResizeCallback({width, height, elWidth, elHeight});
    });
  },
};
