import {doRequest, jsonRequest, jsonRequestPaginated, isDefined, GET, POST, PUT, DELETE} from "./helpers";

export const addPeople = async (
  realName, displayName, description, folders,
  caseId, uploadId, projectId,
  eCase, uploads, project
) => {
  if (!isDefined(realName)) throw new Error("Real Name Required");
  if (!isDefined(displayName)) throw new Error("Display Name Required");
  if (!isDefined(description)) throw new Error("Description Id Required");
  if (!isDefined(folders)) throw new Error("A Folder Required");
  const ids = buildIds(eCase, uploads, project);
  const data = {
    realName,
    displayName,
    description,
    folders,
    caseId,
    uploadId,
    projectId,
    caseIds: ids.caseIds,
    uploadIds: ids.uploadIds,
    projectIds: ids.projectIds,
  };
  return await jsonRequest(POST, "People", {body: JSON.stringify(data)});
};

export const getPeople = async () => await jsonRequest(GET, "People");

export const getPeopleSearch = async (pageNumber, pageSize, filter, caseId) => {
  return await jsonRequestPaginated(
    GET,
    "People/Search",
    {pageNumber, pageSize, filter},
    isDefined(caseId) ? `CaseId=${caseId}` : undefined
  );
};

export const getPeopleById = async (id) => {
  if (!isDefined(id)) throw new Error("Id Required");
  return await jsonRequest(GET, `People/${id}`);
};

export const updateIndividual = async (
  id, realName, displayName, description, folders,
  caseId, uploadId, projectId,
  eCase, uploads, project
) => {
  if (!isDefined(id)) throw new Error("Id Required");
  const ids = buildIds(eCase, uploads, project);
  const data = {
    realName: realName || null,
    displayName: displayName || null,
    description: description || null,
    folders: folders || null,
    caseId: caseId || null,
    uploadId: uploadId || null,
    projectId: projectId || null,
    caseIds: ids.caseIds,
    uploadIds: ids.uploadIds,
    projectIds: ids.projectIds,
  };

  return await jsonRequest(PUT, `People/${id}`, {body: JSON.stringify(data)});
};

const buildIds = (eCase, uploads, project) => {
  const caseIds = [];
  const uploadIds = [];
  const projectIds = [];
  if (uploads) {
    uploadIds.push(uploads.id);
    uploads.cases && uploads.cases.forEach((c) => caseIds.push(c.id));
    uploads.projects && uploads.projects.forEach((p) => projectIds.push(p.id));
  }
  if (eCase) {
    caseIds.push(eCase.id);
    eCase.uploads && eCase.uploads.forEach((e) => uploadIds.push(e.id));
    eCase.projects && eCase.projects.forEach((p) => projectIds.push(p.id));
  }
  if (project) {
    projectIds.push(project.id);
    project.cases && project.cases.forEach((e) => uploadIds.push(e.id));
    project.uploads && project.uploads.forEach((p) => projectIds.push(p.id));
  }
  return {
    caseIds: caseIds.length > 0 ? caseIds : null,
    uploadIds: uploadIds.length > 0 ? uploadIds : null,
    projectIds: projectIds.length > 0 ? projectIds : null,
  };
};

export const deleteIndividual = async (id) => {
  if (!isDefined(id)) throw new Error("Id Required");
  return await doRequest(DELETE, `/People/${id}`);
};

export const getAllFromPeopleGroup = async (groupName) => {
  if (!isDefined(groupName)) throw new Error("Group Name Required");
  return await jsonRequest(GET, `People/Grouped?groupName=${groupName}`);
};

export const getPeopleIncidents = async (peopleId) => {
  if (!isDefined(peopleId)) throw new Error("People Id Required");
  return await jsonRequest(GET, `People/${peopleId}/Incidents`);
};
