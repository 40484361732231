<template>
  <modal
    :show.sync="isDisplayed"
    header-classes="justify-content-center"
    footer-classes="px-4 py-3"
    :centered="true"
    class="modal-default"
  >
    <!-- Header Slot -->
    <h4
      slot="header"
      class="title title-up"
    >
      {{ headerCopy }}
    </h4>

    <div class="mb-3">
      <!--
        If your entry contains multiple words, separate each word with a hyphen (-);
          do not use spaces. For example, Andorra-la-Vella or Los-Angeles.
        For acronyms, any pronounced letters must be separated by a period.
          If your acronym is plural, you must use a hyphen between the acronym and the 's'.
          For example, 'CLI' is C.L.I. and 'ABCs' is A.B.C.-s.
        If your phrase consists of both a word and an acronym, these two components must be separated by a hyphen.
          For example, 'DynamoDB' is Dynamo-D.B..
        Do not use spaces in this column.
      -->
      <label
        for="phrase"
        class="form-label"
      >
        Word or Phrase *Required*
      </label>
      <input
        id="phrase"
        v-model="phrase"
        type="text"
        class="form-control"
        placeholder="Phrase"
      >
    </div>

    <div class="mb-3">
      <!-- Optional - If Empty - resorts to Phrase -->
      <label
        for="display-as"
        class="form-label"
      >
        Override Phrase in transcript as...
      </label>
      <input
        id="display-as"
        v-model="displayAs"
        type="text"
        class="form-control"
        placeholder="Display Name"
      >
    </div>

    <div class="mb-3">
      <label
        for="sounds-like"
        class="form-label"
      >
        Sounds Like
      </label>
      <input
        id="sounds-like"
        v-model="soundsLike"
        type="text"
        class="form-control"
        placeholder="ee-thos"
      >
    </div>

    <div class="mb-3">
      <label
        for="ipa"
        class="form-label"
      >
        I.P.A (International Phonetic Alphabet)
      </label>
      <input
        id="ipa"
        v-model="ipa"
        type="text"
        class="form-control"
        placeholder="ˈaelfəˌehɛt"
      >
    </div>

    <!-- Footer Slot -->
    <template slot="footer">
      <base-button
        size="sm"
        :loading="loading"
        class="ml-auto"
        @click="submit"
      >
        Submit
      </base-button>
    </template>
  </modal>
</template>

<script>
import {Modal} from "src/components";
import {mapMutations} from "vuex";
import {createCodeWord, updateCodeWord} from "../../../api";
import {isDefined} from "../../../api/helpers";

export default {
  name: "word-form",
  components: {
    Modal,
  },
  data() {
    return {
      loading: false,
      isDisplayed: false,
      groupId: null,
      collectionId: null,
      wordId: null,
      headerDisplay: null,
      phrase: "",
      displayAs: null,
      soundsLike: null,
      ipa: null,
    };
  },
  computed: {
    headerCopy() {
      return this.wordId !== null ? `Update ${this.headerDisplay}` : "Add New Word";
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations("data", ["putWord"]),
    display(groupId, collectionId, word) {
      this.groupId = groupId;
      this.collectionId = collectionId;
      if (word) {
        this.wordId = word.id;
        this.phrase = word.phrase;
        this.displayAs = word.displayAs;
        this.headerDisplay = word.displayAs;
        this.soundsLike = word.soundsLike;
        this.ipa = word.ipa;
      }
      this.isDisplayed = true;
    },
    reset() {
      this.isDisplayed = false;
      this.loading = false;
      this.groupId = null;
      this.collectionId = null;
      this.wordId = null;
      this.headerDisplay = null;
      this.phrase = "";
      this.displayAs = null;
      this.soundsLike = null;
      this.ipa = null;
      this.$emit("close");
    },
    submit() {
      if (this.loading) return;
      this.loading = true;
      if (isDefined(this.wordId)) {
        updateCodeWord(
          this.groupId, this.collectionId, this.wordId, this.phrase, this.displayAs, this.soundsLike, this.ipa
        ).then((word) => {
          this.loading = false;
          this.putWord({word, collectionId: this.collectionId, groupId: this.groupId});
          this.$emit("updated", word);
          this.$notifySuccess(`Updated: ${word.phrase}`);
          this.reset();
        }).catch((ex) => {
          this.loading = false;
          this.$notifyError("Failed to update entry", ex);
        });
      } else {
        createCodeWord(
          this.groupId, this.collectionId, this.phrase, this.displayAs, this.soundsLike, this.ipa
        ).then((word) => {
          this.loading = false;
          this.putWord({word, collectionId: this.collectionId, groupId: this.groupId});
          this.$emit("created", word);
          this.$notifySuccess(`Created new entry: ${word.phrase}`);
          this.reset();
        }).catch((ex) => {
          this.loading = false;
          this.$notifyError("Failed to create new entry", ex);
        });
      }
    },
  },
};
</script>
