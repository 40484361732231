<template>
  <div>
    <h3
      v-if="heading"
      class="heading"
    >
      {{ heading }}
    </h3>
    <div
      v-for="group in groupedWorkflows"
      :key="group.name"
      class="mb-3"
    >
      <label class="text-200">{{ group.name }}</label>
      <div class="d-flex flex-row flex-wrap">
        <base-checkbox
          v-for="item in group.workflows"
          :key="item.name"
          v-model="item.checked"
          class="pull-left mr-3 mt-0 text-200"
          :name="item.name"
          @input="showActions = true"
        >
          {{ item.name }}
        </base-checkbox>
      </div>
    </div>

    <div v-if="allowActions && showActions">
      <base-button
        size="sm"
        type="primary"
        @click="$emit('save')"
      >
        Save Changes
      </base-button>
      <base-button
        size="sm"
        type="secondary"
        @click="reset"
      >
        Cancel
      </base-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "workflows-selection",
  props: {
    allowActions: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      groupedWorkflows: [],
      showActions: false,
    };
  },
  watch: {
    availableWorkflows: {
      handler() {
        this.setupWorkflows();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("data", [
      "availableWorkflows",
      "selectedWorkflows",
    ]),
  },
  methods: {
    setupWorkflows() {
      const me = this;
      this.groupedWorkflows = this.availableWorkflows
        .map((g) => {
         return { 
          name: g.name,
          workflows: g.workflows
        .map((w) => {
          return {
            name: w,
            checked: me.selectedWorkflows.indexOf(w) !== -1,
          };
        })
    };
  })
  .filter((x) => {
    return x.name !== 'Video Analysis Settings';
  });
    },
    reset() {
      this.setupWorkflows();
      this.showActions = false;
    },
    checked() {
      const checkedWorkflows = [];
      this.groupedWorkflows.forEach((g) => {
        g.workflows.forEach((w) => {
          if (w.checked) checkedWorkflows.push(w.name);
        });
      });
      return checkedWorkflows;
    },
  },
};
</script>

<style>

</style>
