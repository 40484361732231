<template>
  <div>
    <base-button
      v-if="!hideMove"
      link
      class="m-0"
      @click.stop="$emit('move')"
    >
      <MoveIcon
        size="16"
        class="mr-1"
      />
      Move
    </base-button>
    <base-button
      v-if="!hideShare"
      link
      class="m-0"
      @click.stop="$emit('share')"
    >
      <ShareIcon
        size="16"
        class="mr-1"
      />
      Share Case
    </base-button>
    <base-button
      v-if="!hideDelete"
      link
      class="m-0 mr-10"
      @click.stop="$emit('delete')"
    >
      <TrashIcon
        size="16"
        class="mr-1"
      />
      Delete
    </base-button>
  </div>
</template>

<script>
import {MoveIcon, ShareIcon, TrashIcon} from "vue-feather-icons";
import BaseButton from "../../BaseButton.vue";
export default {
  components: {BaseButton, ShareIcon, TrashIcon, MoveIcon},
  props: {
    hideMove: {
      type: Boolean,
      default: false,
    },
    hideShare: {
      type: Boolean,
      default: false,
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
