<template>
  <div
    :class="'cursor-pointer color-dot ' + classes"
    @click="(e) => $emit('click', e)"
  >
    <div :style="`background-color:${hex}`" />
  </div>
</template>

<script>
import {defaultColorOptions} from "../../util/util";
export default {
  name: "color-dot",
  props: {
    hex: {
      type: String,
      default: defaultColorOptions()[0],
    },
    classes: {
      type: String,
      default: "",
    },
  },
};
</script>
