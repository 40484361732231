<template>
  <div class="h-100">
    <router-view />
    <div
      v-if="viewCase"
      class="view-case"
    >
      <section
        v-if="caseData"
        class="project-list mb-3"
      >
        <div class="d-inline-flex mr-5 align-items-center">
          <h2 class="mb-0 mr-3">
            {{ caseData.title }}
          </h2>
          <base-button
            size="sm"
            type="neutral"
            link
            @click="editCase"
          >
            <edit-icon size="1.2x" />
          </base-button>
        </div>
        <p class="mb-0 mr-5">
          {{ caseData.description || 'No description' }}
        </p>
      </section>
      <section class="project-list mb-3">
        <div class="mb-4 d-inline-flex align-items-center">
          <h2 class="mb-0 mr-5">
            {{ projectNameLabel }}s
          </h2>
          <base-button
            size="sm"
            type="primary"
            link
            @click="showAddNewProjectModal = true"
          >
            Add New {{ projectNameLabel }}
            <plus-icon
              size="1.5x"
              class="ml-3"
            />
          </base-button>
        </div>

        <div class="mb-5">
          <div class="project-contents d-flex flex-wrap">
            <project-card
              v-for="project in projects"
              :key="project.id"
              :project="project"
              @updated="projectChanged"
            />
            <card
              v-if="projects.length < 1"
              style="max-width: 300px; text-align: center"
            >
              No {{ projectNameLabel }}
            </card>
          </div>
        </div>

        <modal
          :show.sync="showAddNewProjectModal"
          header-classes="justify-content-center"
          footer-classes="px-4 py-3"
          class="modal-default"
        >
          <h4
            slot="header"
            class="title title-up"
          >
            Add New {{ projectNameLabel }}
          </h4>
          <template>
            <input
              v-model="projectTitle"
              type="text"
              class="form-control mb-3"
              placeholder="Title"
            >

            <input
              v-model="projectDescription"
              type="text"
              class="form-control"
              placeholder="Description"
            >
          </template>
          <template slot="footer">
            <base-button
              size="sm"
              type="primary"
              class="ml-auto"
              @click="createNewProject"
            >
              Submit
            </base-button>
          </template>
        </modal>
      </section>
      <section class="uploads">
        <div class="mb-4 d-inline-flex align-items-center">
          <h2 class="mb-0 mr-5">
            {{ uploadsNameLabel }}
          </h2>
          <base-button
            size="sm"
            type="primary"
            link
            @click="() => $refs.uploadsForm.display()"
          >
            Add {{ uploadsNameLabel }}
            <plus-icon
              size="1.5x"
              class="ml-3"
            />
          </base-button>
        </div>
        <uploads
          ref="uploadsTable"
          :case-id="caseId"
          :show-workflows="false"
          reuse
        />
      </section>
      <add-new-uploads
        ref="uploadsForm"
        :case-id="caseId"
        header="Add !!!"
        @loadUploads="loadUploads"
      />
      <case-form
        ref="caseForm"
        header="Edit !! #"
        @updated="updateCaseData"
      />
    </div>
  </div>
</template>

<script>
import CaseForm from "./CaseForm.vue";
import Uploads from "../Uploads/Uploads.vue";
import AddNewUploads from "../Uploads/AddNewUploads.vue";
import ProjectCard from "../Projects/ProjectCard.vue";
import {Modal} from "src/components";
import {
  getCase,
  addProject,
  getCaseProjects,
} from "../../../api";
import swal from "sweetalert2";
import {PlusIcon, EditIcon} from "vue-feather-icons";
import {mapGetters, mapMutations} from "vuex";
import {ethosRouteNames} from "../../../routes/routeNames";
import {hasChildRoute} from "../../../util/util";
import {isDefined} from "../../../api/helpers";

export default {
  name: "view-case",
  components: {
    CaseForm,
    Uploads,
    AddNewUploads,
    ProjectCard,
    Modal,
    PlusIcon,
    EditIcon,
  },
  data() {
    return {
      caseData: null,
      showAddNewProjectModal: false,
      projectTitle: "",
      projectDescription: "",
      projects: [],
      swalCustom: swal.mixin({
        customClass: {
          confirmButton: "btn btn-success px-3 mr-3",
          cancelButton: "btn btn-danger px-3 ml-3",
        },
        buttonsStyling: false,
      }),
    };
  },
  computed: {
    ...mapGetters("data", ["caseNameLabel", "projectNameLabel", "uploadsNameLabel"]),
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    viewCase() {
      return !hasChildRoute(this.$route.path, `cases/${this.caseId}`);
    },
  },
  mounted() {
    this.loadCase();
    this.getProjects();
  },
  methods: {
    ...mapMutations("data", ["setBreadcrumbHint"]),
    loadCase() {
      getCase(this.caseId)
        .then(this.updateCaseData)
        .catch((ex) => {
          this.$notifyError(`Unable to find ${this.caseNameLabel}`, ex);
        });
    },
    updateCaseData(data) {
      this.setBreadcrumbHint({name: ethosRouteNames.Case, value: `${this.caseNameLabel} ${data.name}`});
      this.caseData = data;
    },
    createNewProject() {
      if (this.projectTitle === "") return;
      addProject(this.caseId, null, this.projectTitle, this.projectDescription)
        .then((project) => {
          this.loading = false;
          this.showAddNewProjectModal = false;
          this.getProjects();
          this.$notifySuccess(`${this.projectNameLabel} Added Successfully`);
        })
        .catch((ex) => {
          this.$notifyError(`Adding New ${this.projectNameLabel} Failed`, ex);
        });

      this.projectTitle = "";
      this.projectDescription = "";
    },

    getProjects() {
      getCaseProjects(this.caseId)
        .then((response) => {
          this.projects = response.projects;
        })
        .catch((ex) => {
          this.$notifyError(`Loading ${this.projectNameLabel} Failed`, ex);
        });
    },
    addUploads() {
      console.log(`Adding ${this.uploadsNameLabel}`);
    },
    projectChanged() {
      this.getProjects();
    },
    loadUploads() {
      this.$refs.uploadsTable.loadUploads();
    },
    editCase() {
      if (this.$refs.caseForm) {
        this.$refs.caseForm.display(this.caseData.title, this.caseData.name, this.caseData.description);
      }
    },
  },
};
</script>
