<template>
  <base-nav type="dark">
    <div style="width: 285px">
      <img
        src="img/ethos-logo-name.svg"
        height="40"
        style="margin: 8px 0"
        name="brand"
      />
    </div>

    <div class="d-inline-flex align-items-center px-2 nav-search-bar ml-0">
      <SearchIcon class="mr-5 text-white" size="18" />
      <input
        type="text"
        class="form-control ethosSearch border-0"
        placeholder="SEARCH"
        @keyup.enter="search"
      />
    </div>
    <div
      slot="navbar-actions"
      class="d-flex align-items-center"
    >
      <base-button
        v-for="(item, i) in navItems"
        :key="i"
        nav-button
        :active="isActive(item)"
        @click="() => $router.push(item.path)"
      >
        {{ item.name }}
      </base-button>
      <ul
        class="navbar-nav"
        :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'"
      >
        <base-dropdown
          tag="li"
          :menu-on-right="!$rtl.isRTL"
          title-tag="a"
          class="nav-item"
          title-classes="nav-link"
          menu-classes="dropdown-navbar"
        >
          <template slot="title">
            <div class="photo">
              <img 
                v-if="userProfilePhotoUrl"
                src="img/mike.jpg"
              >       
              <div
                v-else-if="initials && initials.length > 0"
                class="initials"
              >
                {{ initials }}
              </div>
            </div>
            <p class="d-lg-none">
              Log out
            </p>
          </template>
          <!-- <li class="nav-link">
            <a
              href="#"
              class="nav-item dropdown-item"
            >Profile</a>
          </li>
          <li class="nav-link">
            <a
              href="#"
              class="nav-item dropdown-item"
            >Settings</a>
          </li> 
          <div class="dropdown-divider" />
          -->
          <li class="nav-link">
            <a
              href="#"
              class="nav-item dropdown-item"
              @click="() => logout()"
            >Log out</a>
          </li>
        </base-dropdown>
      </ul>
    </div>
  </base-nav>
</template>

<script>
import BaseButton from "../BaseButton.vue";
import BaseNav from "./Navbar/BaseNav.vue";
import {SearchIcon} from "vue-feather-icons";
import {mapGetters, mapMutations, mapActions} from "vuex";
import {ethosRouteNames} from "../../routes/routeNames";
export default {
  components: {BaseNav, BaseButton, SearchIcon},
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters("auth", ["userFirstName", "userLastName", "userProfilePhotoUrl", "isRoot", "isAdmin"]),
    initials() {
      let initials = "";
      if (this.userFirstName && this.userFirstName.length > 0) initials += this.userFirstName[0];
      if (this.userLastName && this.userLastName.length > 0) initials += this.userLastName[0];
      return initials.toUpperCase();
    },
    navItems() {
      const items = [
        {
          name: ethosRouteNames.DashboardV2,
          path: "/v2/dashboard",
        },
        // {
        //   name: ethosRouteNames.People,
        //   path: "/v2/people",
        // },
        {
          name: ethosRouteNames.Settings,
          path: "/v2/settings",
        },
        {
          name: ethosRouteNames.Support,
          path: "/v2/support",
        },
        // {
        //   name: ethosRouteNames.Contact,
        //   path: "/v2/contact",
        // },
      ];
      if (this.isRoot || this.isAdmin) {
        items.splice(2, 0,
          {
            name: ethosRouteNames.Admin,
            path: "/v2/admin",
          });
      }
      return items;
    },
  },
  methods: {
    ...mapMutations("data", [
      "setSearchKeyword",
    ]),
    ...mapActions('auth', ['logout']),
    isActive({name}) {
      return name == this.$route.name;
    },
    search(e) {
      const path = "/v2/search";
      this.setSearchKeyword({
        keyword: e.target.value,
      });
      if (e.target.value != "" && this.$route.path !== path) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style>
.nav-btn {
  height: 100%;
}
.nav-search {
  color: white !important;
  font-size: 18px !important;
}
</style>
