<template>
  <div class="case-container">
    <slot name="filter" />
    <div class="case-container--list px-5">
      <slot />
    </div>
    <slot name="pagination">
      <div class="h-divider" />
      <div class="px-5 d-flex align-items-center">
        <div class="text-lightblue mr-5">
          Showing {{ minIndex }}-{{ maxIndex }} of {{ pagination.TotalCount }} items
        </div>

        <base-pagination
          v-model="currentPage"
          :total="pagination.TotalCount"
          :per-page="pagination.PageSize"
          :show-arrows="true"
          class="mb-0 border-0"
          @input="emitChange"
        />
        <div class="flex-grow-1" />
        <div class="text-lightblue">
          Go to page
        </div>
        <base-input
          v-model.number="currentPageInput"
          class="m-0 mx-3"
          style="width: 70px"
          @keyup.enter="emitInputChange"
        />
        <base-button
          simple
          style="height: 38px"
          class="m-0"
          @click="emitInputChange"
        >
          Go
        </base-button>
      </div>
    </slot>
  </div>
</template>

<script>
import BaseButton from "../../BaseButton.vue";
import BasePagination from "../../BasePagination.vue";
import BaseInput from "../../Inputs/BaseInput.vue";
import {EMPTY_PAGINATION} from "../../../util/consts";

export default {
  components: {BasePagination, BaseInput, BaseButton},
  props: {
    pagination: {
      type: Object,
      default() {
        return Object.assign({}, EMPTY_PAGINATION);
      },
    },
  },
  data() {
    return {
      currentPage: 1,
      currentPageInput: 1,
    };
  },
  watch: {
    pagination: {
      immediate: true,
      handler(to) {
        this.currentPage = to.CurrentPage;
        this.currentPageInput = to.CurrentPage;
      },
    },
  },
  computed: {
    minIndex() {
      return Math.min(
        this.pagination.TotalCount,
        Math.max(0, this.pagination.CurrentPage - 1) * this.pagination.PageSize + 1
      );
    },
    maxIndex() {
      return Math.min(
        this.pagination.TotalCount,
        this.pagination.CurrentPage * this.pagination.PageSize
      );
    },
  },
  methods: {
    emitInputChange() {
      if (
        this.currentPageInput > 0 &&
        this.currentPageInput <= this.pagination.TotalPages &&
        this.currentPageInput != this.currentPage
      ) {
        this.emitChange(this.currentPageInput);
      } else {
        this.currentPageInput = this.currentPage;
      }
    },
    emitChange(value) {
      this.$emit("changed", Object.assign({}, this.pagination, {CurrentPage: value}));
    },
  },
};
</script>

<style lang="scss">
.case-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &--list {
    overflow-y: auto;
    flex: 1;
  }
}
</style>
