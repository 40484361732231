<template>
  <div
    v-if="sources.length > 0"
    class="custom-media-controls"
  >
    <div class="d-flex mb-8" />
    <div class="seek-bar">
      <slider
        :key="durationSecs"
        :value="currentSecs"
        :range="{min:0, max: Math.max(durationSecs, 1)}"
        :options="{animate: false}"
        type="primary"
        @changed="sliderFired"
      />
      <div class="signals">
        <div
          v-for="(marker, i) in signals"
          :key="marker.start + i"
          :style="`position:absolute;bottom:0;left:${calcSignalLeftStyle(marker)}%;width:10px;height:23px;`"
          @click="seekTo(marker.startOffset)"
        >
          <el-tooltip
            transition=""
            placement="top"
            popper-class="rounded-tooltip"
          >
            <timeline-marker type="signal" />
            <template slot="content">
              <div
                class="p-2 text-center"
                @click="seekTo(marker.startOffset)"
              >
                <h4>Signal</h4>
                <div>{{ marker.content.trim() }}</div>
              </div>
            </template>
          </el-tooltip>
        </div>
        <!-- TODO: - Hook up incidents for timelines -->

        <div
          v-for="marker in incidents"
          :key="marker.start"
          :style="`position:absolute;bottom:0;left:${marker.start/durationSecs*100}%;width:10px;height:23px;`"
          @click="seekTo(marker.start)"
        >
          <el-tooltip
            transition=""
            placement="top"
            popper-class="rounded-tooltip"
          >
            <timeline-marker
              type="incident"
              :color="marker.color"
            />
            <template slot="content">
              <div
                class="py-2 px-5 text-center position-relative"
                @click="seekTo(marker.start)"
              >
                <h4>{{ incidentName }}</h4>
                <h5>{{ marker.title }}</h5>
                <div v-if="marker.description">
                  {{ marker.description }}
                </div>
                <base-button
                  size="xxs"
                  icon-only
                  no-fill
                  type="secondary"
                  class="position-absolute"
                  style="top:-0.675rem;right:-0.75rem"
                  @click="(e) => showIncident(e, marker)"
                >
                  <external-link-icon size="1x" />
                </base-button>
              </div>
            </template>
          </el-tooltip>
        </div>
      </div>
      <div
        v-if="clipping"
        class="clippers"
      >
        <slider
          :key="'c' + durationSecs"
          :value="[clipIn, clipOut]"
          :range="{min: clipOffset / 1000, max: Math.max(durationSecs + (clipOffset / 1000), 1)}"
          :options="{animate: false, margin: 1}"
          :connect="true"
          classes="clip-slider"
          type="secondary"
          @changed="clipSliderFired"
        />
      </div>
    </div>
    <div class="controls-row d-flex justify-content-between">
      <div class="current-time">
        <span>{{ currentTime }} / {{ duration }}</span>
      </div>
      <div class="seek-controls">
        <base-button
          size="xxs"
          icon-only
          type="primary"
          @click="toStart"
        >
          <skip-back-icon size="1x" />
        </base-button>
        <base-button
          size="xxs"
          icon-only
          type="primary"
          @click="to10Bk"
        >
          <skip-10-backward-icon size="1x" />
        </base-button>
        <base-button
          v-if="hasVideo"
          size="xxs"
          icon-only
          type="primary"
          @click="toFrameBk"
        >
          <rewind-icon size="1x" />
        </base-button>
        <base-button
          v-if="isPlaying"
          size="xxs"
          icon-only
          type="primary"
          @click="pause"
        >
          <pause-icon size="3.5x" />
        </base-button>
        <base-button
          v-else
          size="xxs"
          icon-only
          type="primary"
          @click="play"
        >
          <play-icon size="1.5x" />
        </base-button>
        <base-button
          v-if="hasVideo"
          size="xxs"
          icon-only
          type="primary"
          @click="toFrameFwd"
        >
          <fast-forward-icon size="1x" />
        </base-button>

        <base-button
          size="xxs"
          icon-only
          type="primary"
          @click="to10Fwd"
        >
          <skip-10-forward-icon size="1x" />
        </base-button>
        <base-button
          size="xxs"
          icon-only
          type="primary"
          @click="toEnd"
        >
          <skip-forward-icon size="1x" />
        </base-button>
      </div>
      <div class="additional-controls d-flex">
        <div
          style="width:80px;"
          class="d-flex align-items-center"
        >
          <p class="mb-0 mr-1">
            T
          </p>
          <slider
            v-model="fontSize"
            class="w-100"
            :range="{min:1, max: 10}"
            :options="{animate: false,step:1}"
            type="primary"
            @changed="onFontChange"
          />
          <h6 class="mb-0 mx-1">
            T
          </h6>
        </div>
        <base-button
          size="xxs"
          class="mr-2"
          icon
          :simple="!isRepeating"
          type="primary"
          @click="toggleRepeat"
        >
          <repeat-icon size="1x" />
        </base-button>
        <div class="playback-wrapper">
          <el-select
            id="playbackrate"
            v-model="currentPlayBackRate"
            class="select-primary dark mr-2"
            effect="dark"
            size="mini"
            popper-class="select-primary"
            @change="setPlaybackRate"
          >
            <el-option
              v-for="opt in playbackRateOptions"
              :key="opt.value"
              :label="opt.label"
              :value="opt.value"
            />
          </el-select>
          <img src="img/playback-rate.svg">
        </div>
        <el-tooltip
          transition=""
        >
          <base-button
            size="xxs"
            icon
            simple
            type="secondary"
            @click="() => isMuted ? unmute() : mute()"
          >
            <volume-x-icon
              v-if="isMuted"
              size="1.25x"
            />
            <volume-2-icon
              v-else-if="currentVolume > 0.5"
              size="1.25x"
            />
            <volume-1-icon
              v-else-if="currentVolume > 0.05"
              size="1.25x"
            />
            <volume-icon
              v-else
              size="1.25x"
            />
          </base-button>
          <template slot="content">
            <slider
              :value="isMuted ? 0 : currentVolume"
              :range="{min:0, max: 1}"
              :options="{animate: false, orientation: 'vertical', direction: 'rtl'}"
              :disabled="isMuted"
              type="secondary"
              style="height: 100px;"
              @changed="setVolume"
            />
          </template>
        </el-tooltip>
      </div>
    </div>
    <!-- <modal
      :show.sync="showResumeConfirmation"
      header-classes="justify-content-center"
      footer-classes="px-4 py-3"
      class="modal-default"
      @close="cancelModal"
    >
      <template v-if="showResumeConfirmation">
        <p
          slot="header"
          class="title"
        >
          Resume from last playback position?
        </p>
        <div class="row d-flex justify-content-center">
          <base-button
            size="sm"
            type="primary"
            @click="resumePlayBack"
          >
            Yes
          </base-button>
          <base-button
            size="sm"
            type="danger"
            @click="cancelModal"
          >
            No
          </base-button>
        </div>
      </template>
    </modal> -->
  </div>
</template>

<script>
// MediaEl Events we could use
// canplay <-- it can be started but may buffer
// canplaythrough <-- it can be started, and should not need to buffer
// durationchange <-- if adopting the above, we can listen to this to update the durations
// import {playBackPosition} from "../../../../api";
import Skip10ForwardIcon from "../../../../components/CustomIcon/Skip10ForwardIcon.vue";
import Skip10BackwardIcon from "../../../../components/CustomIcon/Skip10BackwardIcon.vue";
import {PlayIcon, PauseIcon, RewindIcon, FastForwardIcon, SkipBackIcon, SkipForwardIcon, VolumeXIcon, VolumeIcon, Volume1Icon, Volume2Icon, RepeatIcon, ExternalLinkIcon} from "vue-feather-icons";
import Slider from "../../../../components/Slider.vue";
import TimelineMarker from "./TimelineMarker.vue";
import {isDefined} from "../../../../api/helpers";
import {mapGetters} from "vuex";
import {secsToTimeString} from "../../../../util/util";
const userPrefFontSizeKey = "user_pref_transcript_fontSize";
export default {
  name: "custom-media-controls",
  components: {
    Slider,
    PlayIcon,
    PauseIcon,
    RewindIcon,
    FastForwardIcon,
    SkipBackIcon,
    SkipForwardIcon,
    VolumeXIcon,
    VolumeIcon,
    Volume1Icon,
    Volume2Icon,
    Skip10ForwardIcon,
    Skip10BackwardIcon,
    RepeatIcon,
    ExternalLinkIcon,
    TimelineMarker,
  },
  props: {
    sources: {
      type: Array,
      default() {
        return [];
      },
    },
    playBack: {
      type: Object,
      default() {
        return {};
      },
    },
    signals: {
      type: Array,
      default() {
        return [];
      },
    },
    incidents: {
      type: Array,
      default() {
        return [];
      },
    },
    canClip: {
      type: Boolean,
      default: true,
    },
    clipOffset: {
      type: Number,
      default: 0,
    },
    transcriptionId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    sources: {
      handler(to, from) {
        if (to == null) return;
        // prevent reloading audio if it's the same
        if (this.arraysEqual(to, from)) return;
        if (to) to.forEach(this.buildElement);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("data", [
      "displayIncidents",
    ]),
    ...mapGetters("auth", [
      "userEmail",
    ]),
    allReady() {
      return Object.entries(this.elements).filter((e) => !e[1].el.ready).length === 0;
    },
    hasVideo() {
      return Object.entries(this.sources).filter((e) => !e[1].isAudio).length > 0;
    },
    incidentName() {
      return this.displayIncidents ? "Incident" : "Of Interest";
    },
  },
  data() {
    return {
      elements: {},
      isPlaying: false,
      currentTime: "00:00:00.000",
      duration: "00:00:00.000",
      currentSecs: 0,
      durationSecs: 0,
      currentPlayBackRate: 1,
      playbackRateOptions: [
        {label: ".1", value: 0.1},
        {label: ".25", value: 0.25},
        {label: ".5", value: 0.5},
        {label: ".75", value: 0.75},
        {label: "1", value: 1},
        {label: "1.5", value: 1.5},
        {label: "2", value: 2},
        {label: "3", value: 3},
      ],
      isMuted: false,
      isRepeating: false,
      currentVolume: 1,
      smallestFrameRate: 60,
      now: Date.now(),
      clipping: false,
      clipIn: 0,
      clipOut: 0,
      updateInterval: null,
      playElement: null,
      showResumeConfirmation: false,
      shownResumeConfirmation: false,
      fontSize: 1,
      signalsOffset: 0,
    };
  },
  methods: {
    onFontChange() {
      localStorage.setItem(userPrefFontSizeKey, parseInt(this.fontSize));
      this.$emit("fontChange", parseInt(this.fontSize));
    },
    arraysEqual(arr1, arr2) {
      if (arr1 && !arr2) return false;
      if (arr1.length !== arr2.length) return false;

      return arr1.every((obj, index) => {
        const obj2 = arr2[index];
        return Object.keys(obj).every((key) => obj[key] === obj2[key]);
      });
    },
    buildElement(source) {
      if (!source) return;
      let existingEl = this.elements[source.id];

      // Update if existing
      if (existingEl) {
        // Type changed - need to rebuild;
        if (existingEl.isAudio != source.isAudio) {
          this.$emit("destroying", existingEl);
          existingEl = null;
        } else {
          if (existingEl.el.src !== source.source) {
            existingEl.el.src = source.source;
            existingEl.ready = false;
          }
          if (existingEl.offset !== source.offset) {
            existingEl.offset = source.offset;
          }
        }
      }

      // Build a new element - hooking up events
      if (!isDefined(existingEl)) {
        const el = document.createElement(source.isAudio ? "AUDIO" : "VIDEO");
        el.src = source.source;
        el.addEventListener("loadeddata", () => this.elReady(source.id));
        el.addEventListener("play", () => this.elPlay(source.id));
        el.addEventListener("playing", () => this.elPlay(source.id));
        el.addEventListener("pause", () => this.elPause(source.id));
        el.addEventListener("stalled", () => this.elPause(source.id));
        el.addEventListener("waiting", () => this.elPause(source.id));
        el.addEventListener("ended", () => this.elEnded(source.id));

        if (!source.isAudio && source.frameRate < this.smallestFrameRate) {
          this.smallestFrameRate = source.frameRate;
        }
        const element = {
          isAudio: source.isAudio,
          offset: source.offset,
          el,
          ready: false,
          id: source.id,
        };
        this.elements[source.id] = element;
        this.$emit("created", element);
      }
    },
    elementById(id) {
      // External lookup
      return this.elements[id];
    },
    renderUpdate() {
      const diff = (Date.now() - this.now) / 1000;
      this.now = Date.now();
      this.renderUpdateOffset(this.currentSecs + (diff * this.currentPlayBackRate));
    },
    renderUpdateOffset(offset) {
      // Update the UI.
      const min = this.clipping ? this.clipIn - (this.clipOffset / 1000) : 0;
      const max = this.clipping ? this.clipOut - (this.clipOffset / 1000) : this.durationSecs;
      let safeOffset = Math.max(min, Math.min(offset, max));
      let seek = false;
      if (this.clipping && this.currentSecs >= this.clipOut - Math.max(this.clipOffset / 1000, 0)) {
        if (this.isRepeating) {
          safeOffset = this.clipIn - Math.max(this.clipOffset / 1000, 0);
          seek = true;
        } else {
          this.pause();
          return false;
        }
      }
      this.currentSecs = safeOffset;
      this.currentTime = secsToTimeString(safeOffset + (this.clipOffset / 1000));
      this.$emit("time", this.currentSecs);
      if (seek) {
        this.seekTo(safeOffset);
        return false;
      }
      return true;
    },
    playCorrectElements() {
      const me = this;

      // Check if we need to start other elements
      Object.keys(me.elements).forEach((id) => {
        const element = me.elements[id];
        if (me.currentSecs >= element.offset && me.currentSecs <= element.offset + element.el.duration) {
          // This element should be playing
          if (element.el.paused) {
            element.el.play();
          }
        } else {
          // This element should be paused
          if (!element.el.paused) element.el.pause();
        }
      });
    },
    seekTo(offset) {
      const safeOffset = Math.max(
        0,
        Math.min(
          offset - (this.clipping ? 0 : (this.clipOffset / 1000)),
          this.durationSecs
        )
      );
      console.warn("seekTo", safeOffset);
      const me = this;
      Object.keys(me.elements).forEach((id) => {
        // Pause
        if (!me.elements[id].el.paused) me.elements[id].el.pause();
        // Set new time
        const newTime = safeOffset - me.elements[id].offset;
        const safeNewTime = Math.max(0, Math.min(newTime, me.elements[id].el.duration));
        me.elements[id].el.currentTime = safeNewTime;
      });
      const shouldPlay = this.renderUpdateOffset(safeOffset);
      shouldPlay && this.playCorrectElements();
      this.$emit("seekTo");
    },
    pause() {
      console.warn("pause");
      const me = this;
      for (let i = 0; i < Object.keys(me.elements).length; i++) {
        const id = Object.keys(me.elements)[i];
        if (!me.elements[id].el.paused) {
          me.elements[id].el.pause();
          console.log(`check - ${ me.elements[id].offset}`);
          // this.postPlayback();
          break;
        }
      }
    },
    play() {
      console.warn("play");
      // if (this.playBack && !this.shownResumeConfirmation) {
      //   this.showResumeConfirmation = true && !this.shownResumeConfirmation;
      // } else {
      //   this.playCorrectElements();
      // }
      this.playCorrectElements();
    },
    toStart() {
      console.warn("toStart");
      this.seekTo(0);
      this.renderUpdateOffset(0);
    },
    toEnd() {
      const me = this;
      Object.keys(me.elements).forEach((id) => {
        const duration = me.elements[id].el.duration;
        console.warn("toEnd", id, duration);
        me.elements[id].el.currentTime = duration;
      });
      this.renderUpdateOffset(this.durationSecs);
    },
    to10Bk() {
      console.warn("to10Bk");
      this.seekTo(this.currentSecs - 10);
    },
    to10Fwd() {
      console.warn("to10Fwd");
      this.seekTo(this.currentSecs + 10);
    },
    toFrameBk() {
      console.warn("toFrameBk");
      this.seekTo(this.currentSecs - (1 / this.smallestFrameRate));
    },
    toFrameFwd() {
      console.warn("toFrameFwd");
      this.seekTo(this.currentSecs + (1 / this.smallestFrameRate));
    },
    elReady(id) {
      console.warn("elReady", id);
      // Collect all ready
      const el = this.elements[id];
      if (!el) return;
      const endSecs = el.offset + el.el.duration;
      if (endSecs > this.durationSecs) {
        this.durationSecs = endSecs;
        this.clipOut = endSecs + Math.max(this.clipOffset / 1000, 0);
        this.duration = secsToTimeString(endSecs + (this.clipOffset / 1000));
      }
      el.ready = true;
      el.el.pause();
      if (this.playBack) {
        this.playElement = el;
      }
    },
    resumePlayBack() {
      const oldValues = JSON.parse(this.playBack.position);
      const oldOffset = oldValues.oldOffSet;
      this.currentSecs = oldValues.currentSecs;
      this.currentTime = oldValues.currentTime;
      this.seekTo(oldOffset);
      this.showResumeConfirmation = false;
      // disable resume for EM-114
      // this.shownResumeConfirmation = true;
    },
    cancelModal() {
      this.showResumeConfirmation = false;
      // disable resume for EM-114
      // this.shownResumeConfirmation = true;
      this.playCorrectElements();
    },
    // postPlayback() {
    //   const min = this.clipping ? this.clipIn : 0;
    //   const max = this.clipping ? this.clipOut : this.durationSecs;
    //   const diff = (Date.now() - this.now) / 1000;
    //   const temp = this.currentSecs + (diff * this.currentPlayBackRate);
    //   const safeOffset = Math.max(min, Math.min(temp, max));
    //   const payload = {
    //     currentSecs: this.currentSecs,
    //     currentTime: this.currentTime,
    //     oldOffSet: safeOffset,
    //   };
    //   playBackPosition(this.sources[0].id, this.userEmail, payload)
    //     .then((result) => {
    //       console.log(result);
    //     })
    //     .catch((ex) => {
    //       this.$notifyError("Failed to load", ex);
    //     });
    // },
    elPlay(id) {
      console.warn("elPlay", id);
      this.startUiUpdates();
    },
    elPause(id) {
      console.warn("elPause", id);
      // Check if allPaused
      const me = this;
      let allPaused = true;
      Object.keys(me.elements).forEach((id) => {
        if (allPaused && !me.elements[id].el.paused) allPaused = false;
      });
      if (allPaused) {
        this.endUiUpdates();
        this.isPlaying = false;
      }
    },
    elEnded(id) {
      console.warn("elEnded", id);
      const el = this.elements[id];
      // Check its the last element
      if (el && el.el.duration + el.offset >= this.durationSecs) {
        if (this.isRepeating) {
          this.seekTo(0);
        } else {
          this.endUiUpdates();
          this.isPlaying = false;
        }
      }
    },
    sliderFired(x) {
      console.warn("slider", x);
      console.warn("currentSec", this.currentSecs);
      console.warn("currentTime", this.currentTime);
      this.seekTo(x);
    },
    startUiUpdates() {
      this.isPlaying = true;
      this.now = Date.now();
      if (this.updateInterval) window.clearInterval(this.updateInterval);
      this.updateInterval = window.setInterval(this.renderUpdate, 1000 / 30);
    },
    endUiUpdates() {
      this.isPlaying = false;
      if (this.updateInterval) window.clearInterval(this.updateInterval);
      this.updateInterval = null;
    },
    setPlaybackRate(x) {
      const me = this;
      Object.keys(me.elements).forEach((id) => {
        me.elements[id].el.playbackRate = x;
      });
    },
    setVolume(x) {
      const volume = Math.max(0, Math.min(1, x));
      const me = this;
      Object.keys(me.elements).forEach((id) => {
        me.elements[id].el.volume = volume;
      });
      this.currentVolume = volume;
    },
    setMute(muted) {
      const me = this;
      Object.keys(me.elements).forEach((id) => {
        me.elements[id].el.muted = muted;
      });
      this.isMuted = muted;
    },
    mute() {
      this.setMute(true);
    },
    unmute() {
      this.setMute(false);
    },
    toggleRepeat(e) {
      e && e.currentTarget && e.currentTarget.blur();
      this.isRepeating = !this.isRepeating;
      if (this.isRepeating) {
        this.onClipping();
      } else {
        this.endClipping();
      }
    },
    showIncident(e, incident) {
      e.stopPropagation();
      e.preventDefault();
      this.$emit("incident", incident);
    },
    onClipping(e) {
      this.clipping = !this.clipping;
      if (!this.clipping) {
        this.$emit("onClipRange", null);
      }
    },

    endClipping() {
      this.clipping = false;
      if (this.$refs.buttons) {
        this.$refs.buttons.clipping = false;
      }
    },
    clipSliderFired(x) {
      console.warn("clipSlider", "clipIn", x[0], "clipOut", x[1]);
      this.clipIn = parseFloat(x[0]);
      this.clipOut = parseFloat(x[1]);
      if (this.currentSecs < this.clipIn) this.seekTo(this.clipIn);
      if (this.currentSecs > this.clipOut) this.seekTo(this.clipOut);

      this.$emit("onClipRange", {in: this.clipIn, out: this.clipOut});
    },
    saveClip() {
      this.$emit("saveClip", {in: this.clipIn, out: this.clipOut});
    },
    calcSignalLeftStyle(marker) {
      return (marker.startOffset-this.signalsOffset)/this.durationSecs*100;
    },
  },
  mounted() {
    this.fontSize = parseInt(localStorage.getItem(userPrefFontSizeKey));
    this.$emit("fontChange", parseInt(this.fontSize));
    this.signalsOffset = this.clipOffset > -1 ? this.clipOffset/1000 : 0;
    // disable resume for EM-114
    // this.interval = setInterval(this.postPlayback, 10000); // 10000 milliseconds = 10 seconds
    // window.addEventListener("beforeunload", this.postPlayback);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    // disable resume for EM-114
    // this.postPlayback();
    // window.removeEventListener("beforeunload", this.postPlayback);
    this.elements && Object.values(this.elements).forEach((e) => this.$emit("destroying", e));
  },
};
</script>

