<template>
  <div v-if="isRoot || isAdmin">
    <template v-if="$route.path === '/admin'">
      <div class="ethos-dashboard">
        <div class="actions group row">
          <div class="action admin-users">
            <base-button
              size="lg"
              type="secondary"
              class="dashboard-menu-button"
              @click="$router.push('/admin/users')"
            >
              <i class="mr-2 tim-icons icon-single-02" />
              <span>User Management</span>
            </base-button>
          </div>
        </div>
        <div class="form-group mt-5 mb-3">
          <label
            for="self-approval"
            class="form-label mr-3"
          > Allow Auto-Approval For: </label>

          <el-select
            v-model="roles"
            class="select-primary dark mw-50 w-25"
            effect="dark"
            popper-class="select-primary"
            placeholder="No One Can Auto-Approve"
            multiple
          >
            <el-option
              v-for="item in roleOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>

          <base-button
            class="mr-0"
            size="xs"
            icon-only
            no-fill
            :loading="rolesSaving"
            @click="saveAutoApproveRoles"
          >
            <save-icon size="1.5x" />
          </base-button>

          <base-button
            class="ml-0"
            size="xs"
            type="danger"
            icon-only
            no-fill
            :loading="rolesSaving"
            @click="cancelAutoApproveRoles"
          >
            <x-icon size="1.5x" />
          </base-button>
        </div>
      </div>
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {postAutoApproveRoles} from "../../../api";
import {Roles} from "../../../util/enums";
import {SaveIcon, XIcon} from "vue-feather-icons";
export default {
  name: "AdminWrapper",
  components: {
    SaveIcon,
    XIcon,
  },
  data() {
    return {
      roles: [],
      roleOptions: [Roles.admin, Roles.editor, Roles.viewer],
      rolesSaving: false,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "isRoot",
      "isAdmin",
    ]),
    ...mapGetters("data", [
      "autoApprovalRoles",
    ]),
  },
  mounted() {
    this.cancelAutoApproveRoles();
  },
  methods: {
    ...mapMutations("data", [
      "setAutoApproveRoles",
    ]),
    saveAutoApproveRoles() {
      if (this.rolesSaving) return;
      this.rolesSaving = true;
      postAutoApproveRoles(this.roles).then((config) => {
        this.setAutoApproveRoles(config.autoApproveRoles);
        this.$notifySuccess("Updated Auto-Approve Roles");
        this.rolesSaving = false;
      }).catch((ex) => {
        this.$notifyError("Unable to update Auto-Approve Roles", ex);
        this.rolesSaving = false;
      });
    },
    cancelAutoApproveRoles() {
      this.roles = [];
      this.roles.push(...this.autoApprovalRoles.filter((r) => this.roleOptions.indexOf(r) > -1));
    },
  },
};
</script>
