<template>
  <component
    :is="tag"
    class="badge"
    :class="`badge-${type}`"
  >
    <slot />
  </component>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "badge",
  props: {
    tag: {
      type: String,
      default: "span",
      description: "Badge tag",
    },
    type: {
      type: String,
      default: "default",
      validator: (value) => {
        const acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
          "default",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Badge type (primary|info|success|warning|danger|default)",
    },
  },
};
</script>
<style></style>
