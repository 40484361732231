<template>
  <div
    v-show="active"
    :id="id || label"
    class="hidden tab-pane "
    :class="{ active: active }"
    :aria-expanded="active"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: "tab-pane",
  props: {
    label: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    hasSubmenu: {
      type: Boolean,
      default: false,
    },
    customHandler: {
      type: Function,
      default: null,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["addTab", "removeTab"],
  data() {
    return {
      active: false,
    };
  },
  mounted() {
    this.addTab(this);
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeTab(this);
  },
};
</script>
<style>

</style>
