<template>
  <div class="content-search p-0">
    <div class="flex-container-highlight-radio">
      <base-switch
        v-model="removeSignal"
        type="secondary"
        @input="toggleMode"
      />
      <h5 class="flex-child-highlight-radio">
        Highlight signals
      </h5>
    </div>
    <h5>Keywords</h5>
    <div>
      <span v-if="indices.length === 0">No Matches Found</span>
      <span
        v-for="(index, ix) in indices"
        :key="index.groupName + '-' + ix"
        class="cursor-pointer d-block text-100"
        @click="goTo(index)"
      >
        {{ index.groupName + ' / ' + index.collectionName + ' / ' + index.word + ' ( ' + index.indices.length + ' )' }}
      </span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {getCodeWords, getCodeWordGroups} from "../../../../api";
export default {
  name: "transcription-signals",
  props: {
    transcriptContent: {
      type: String,
      default: "",
    },
    metadata: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      current: -1,
      removeSignal: true,
    };
  },
  computed: {
    ...mapGetters("data", [
      "codeWordGroups",
    ]),
    indices() {
      const entries = this.codeWordGroups ? Object.entries(this.codeWordGroups) : [];
      if (entries.length < 1) return [];
      const matches = [];
      const allIndicies = [];
      const me = this;
      entries.forEach((g) => {
        // const id = g[0];
        const val = g[1];
        if (val.collections && val.collections.length > 0) {
          val.collections.forEach((c) => {
            if (c.words && c.words.length > 0) {
              c.words.forEach((w) => {
                const indicies = me.indicesOf(me.transcriptContent, w.phrase);
                allIndicies.push(...indicies);
                if (indicies.length > 0) {
                  matches.push({
                    groupName: val.name,
                    collectionName: c.name,
                    word: w.phrase,
                    indices: indicies,
                  });
                }
              });
            }
          });
        }
      });
      this.$emit("indices", allIndicies);
      return matches;
    },
  },
  mounted() {
    const entries = this.codeWordGroups ? Object.entries(this.codeWordGroups) : [];
    if (entries.length < 1) {
      getCodeWordGroups().then((groups) => {
        this.putCodeWordGroups(groups);
        groups.forEach((g) => this.loadGroup(g.id));
      }).catch((ex) => {
        this.$notifyError("Failed to get Groups", ex);
      });
    }
  },
  methods: {
    ...mapMutations("data", [
      "putCodeWordGroups",
      "putCodeWordGroup",
    ]),
    loadGroup(groupId) {
      getCodeWords(groupId).then((group) => {
        this.putCodeWordGroup(group);
      }).catch((ex) => {
        this.$notifyError("Failed to load Code Word details", ex);
      });
    },
    indicesOf(string, searchTerm) {
      const indexes = [];
      const regex = new RegExp(searchTerm, "gi");
      const matcher = string.matchAll(regex);
      let match = matcher.next();
      while (match && !match.done) {
        indexes.push(match.value.index);
        match = matcher.next();
      }
      return indexes;
    },
    previous() {
      this.setCurrent(this.current - 1);
    },
    next() {
      this.setCurrent(this.current + 1);
    },
    setCurrent(index) {
      this.current = Math.max(0, Math.min(this.indices.length - 1, index));
      this.$emit("current", this.current);
    },
    goTo(index) {
      const match = this.metadata && this.metadata.entries && this.metadata.entries
        .find((m) => m.s <= index.indices[0] && index.indices[0] <= m.e);
      if (match) {
        const key = `${match.s}-${match.e}`;
        const el = document.querySelector(`span[data-key="${key}"`);
        if (el) el.scrollIntoView();
      }
    },
    toggleMode(type) {
      this.$emit("removeSignal", this.removeSignal);
    },
  },
};
</script>
