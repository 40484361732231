<template>
  <div>
    <h3
      v-if="heading"
      class="heading"
    >
      {{ heading }}
    </h3>
    <timezone-field
      ref="tz"
      class="flex-grow-1"
      :name="userTimezone"
      @changed="timezoneChanged"
    />

    <div v-if="showActions">
      <base-button
        size="sm"
        type="primary"
        @click="$emit('save')"
      >
        Save Preferences
      </base-button>
      <base-button
        size="sm"
        type="secondary"
        @click="reset"
      >
        Cancel
      </base-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TimezoneField from "../Util/TimezoneField.vue";
export default {
  name: "user-preferences",
  components: {TimezoneField},
  props: {
    heading: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showActions: false,
      tz: "America/Vancouver",
    };
  },
  computed: {
    ...mapGetters("data", [
      "userTimezone",
    ]),
  },
  methods: {
    timezoneChanged(tz) {
      if (tz !== this.userTimezone) {
        this.tz = tz;
        this.showActions = true;
      }
    },
    reset() {
      this.tz = this.userTimezone;
      if (this.$refs.tz) this.$refs.tz.tz = this.userTimezone;
      this.showActions = false;
    },
  },
};
</script>
