<template>
  <div class="code-word">
    <h3 class="heading">
      {{ heading }}
    </h3>
    <div class="actions row px-2">
      <div class="action">
        <base-button
          size="sm"
          type="primary"
          @click="showGroupForm()"
        >
          Add New Group
          <plus-icon
            size="1.2x"
            class="ml-2"
          />
        </base-button>
      </div>
      <div class="action">
        <base-button
          size="sm"
          type="primary"
        >
          Audit Log
          <arrow-right-icon
            size="1.2x"
            class="ml-2"
          />
        </base-button>
      </div>
    </div>
    <collapse
      multiple-active
    >
      <collapse-item
        v-for="group in codeWordGroups"
        :id="'' + group.id"
        :key="group.id"
        @toggled="(active) => codeWordGroupToggled(group.id, active)"
      >
        <template slot="title">
          <h4>{{ group.name }}</h4>
        </template>
        <code-word-group
          :ref="`cwg${group.id}`"
          :group-id="group.id"
        />
      </collapse-item>
    </collapse>
    <group-form ref="groupForm" />
  </div>
</template>

<script>
import {getCodeWordGroups} from "../../../api";
import {ArrowRightIcon, PlusIcon} from "vue-feather-icons";
import CodeWordGroup from "./CodeWordGroup.vue";
import {mapGetters, mapMutations} from "vuex";
import Collapse from "../../../components/Collapse/Collapse.vue";
import CollapseItem from "../../../components/Collapse/CollapseItem.vue";
import GroupForm from "./GroupForm.vue";
export default {
  name: "ethos-code-word",
  components: {ArrowRightIcon, CodeWordGroup, PlusIcon, Collapse, CollapseItem, GroupForm},
  props: {
    heading: {
      type: String,
      default: "Code Word",
    },
  },
  data() {
    return {
      groupId: null,
      groupName: null,
    };
  },
  computed: {
    ...mapGetters("data", [
      "codeWordGroups",
    ]),
  },
  mounted() {
    this.loadGroups();
  },
  methods: {
    ...mapMutations("data", [
      "putCodeWordGroups",
    ]),
    showGroupForm(groupId, group) {
      this.$refs.groupForm.display(groupId, group);
    },
    loadGroups() {
      getCodeWordGroups().then((groups) => {
        this.putCodeWordGroups(groups);
      }).catch((ex) => {
        this.$notifyError("Failed to get Groups", ex);
      });
    },
    codeWordGroupToggled(groupId, isActive) {
      if (isActive) {
        const key = `cwg${groupId}`;
        if (this.$refs[key] && this.$refs[key].length > 0) this.$refs[key][0].loadWords();
      }
    },
  },
};
</script>
