<template>
  <modal
    :show.sync="isDisplayed"
    header-classes="justify-content-center"
    footer-classes="px-4 py-3"
    :centered="true"
    class="modal-default"
    @close="fullReset"
  >
    <!-- Header Slot -->
    <h4
      slot="header"
      class="title title-up"
    >
      {{ headerCopy }}
    </h4>

    <template v-if="isDisplayed">
      <div class="mb-3">
        <label
          for="title"
          class="form-label"
        > Title </label>
        <input
          id="title"
          v-model="title"
          type="text"
          class="form-control"
          placeholder="Title"
        >
      </div>

      <div
        class="mb-3"
      >
        <label
          for="case-number"
          class="form-label"
        > {{ caseNameLabel }}  Number </label>
        <input
          id="case-number"
          v-model="caseNumber"
          type="text"
          class="form-control"
          placeholder="Case Number"
        >
      </div>

      <div class="mb-3">
        <label
          for="description"
          class="form-label"
        > Description (optional) </label>
        <input
          id="description"
          v-model="description"
          type="text"
          class="form-control"
          placeholder="Description"
        >
      </div>

      <div class="d-flex">
        <base-button
          size="sm"
          type="primary"
          class="ml-auto"
          :disabled="isSaving"
          @click="save"
        >
          Save
        </base-button>
      </div>
    </template>
  </modal>
</template>

<script>
import {mapGetters} from "vuex";
import {putCase} from "../../../api";
import {isDefined} from "../../../api/helpers";

export default {
  name: "case-form",
  props: {
    header: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      title: "",
      caseNumber: "",
      originalCaseNumber: null,
      description: null,
      isSaving: false,
      isDisplayed: false,
    };
  },
  computed: {
    ...mapGetters("data", [
      "caseNameLabel",
      "casesNameLabel",
    ]),
    headerCopy() {
      return this.header.replace("!!!", this.casesNameLabel).replace("!!", this.caseNameLabel).replace("#", this.originalCaseNumber);
    },
  },
  methods: {
    display(title, caseNumber, description) {
      this.reset();
      this.originalCaseNumber = caseNumber;
      if (isDefined(title)) this.title = title;
      if (isDefined(caseNumber)) {
        this.caseNumber = caseNumber;
        this.originalCaseNumber = caseNumber;
      } else {
        this.originalCaseNumber = null;
      }
      if (isDefined(description)) this.description = description;
      this.isDisplayed = true;
    },
    reset() {
      this.title = "";
      this.caseNumber = "";
      this.originalCaseNumber = null;
      this.description = null;
      this.isSaving = false;
    },
    fullReset() {
      this.reset();
      this.isDisplayed = false;
    },
    save() {
      if (this.isSaving) return;
      if (!isDefined(this.caseNumber) || this.caseNumber.length < 1) return;
      this.isSaving = true;
      putCase(this.originalCaseNumber, this.title, this.caseNumber, this.description)
        .then((model) => {
          this.$notifySuccess(`${this.caseNameLabel} ${isDefined(this.originalCaseNumber) ? "Edited" : "Added"} Successfully`);
          this.$emit("updated", model);
          this.fullReset();
        })
        .catch((ex) => {
          const errMsg = `${isDefined(this.originalCaseNumber) ? "Editing" : "Adding New"} ${this.caseNameLabel} Failed`;
          this.$notifyError(errMsg, ex);
          this.reset();
        });
    },
  },
};
</script>
