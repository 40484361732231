var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.span && !_vm.isDeleted)?_c('span',{key:_vm.key + _vm.isEditing,class:{
    unclear: _vm.isUnclear && _vm.span.content.trim().length > 0,
    signal: _vm.hasSignal && _vm.span.content.trim().length > 0,
    incident: _vm.hasIncident,
    'incident-start': _vm.isIncidentStart,
    'incident-end': _vm.isIncidentEnd,
    incident: _vm.hasIncident,
    redacted: _vm.hasRedaction,
    keyword: _vm.keywordPosition > -1,
    current: _vm.isCurrentKeyword,
    is: _vm.isPlaying,
    playing: _vm.isPlaying,
    insideClip: _vm.isInsideClip,
    'has-change-request': _vm.hasChangeRequest,
    'cursor-pointer': _vm.audioReady || _vm.hasChangeRequest,
    'selected': _vm.isHighlighted,
  },style:(_vm.styleOverrides),attrs:{"id":_vm.span.start + 1,"data-key":_vm.key,"data-s":_vm.span.start,"data-e":_vm.span.end,"data-d":_vm.isEditing && _vm.differs,"data-o":_vm.span.content,"data-so":_vm.span.startOffset,"data-eo":_vm.span.endOffset},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.handleClick.apply(null, arguments)},"input":_vm.onInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.tabup.apply(null, arguments)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enterup.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.escup.apply(null, arguments)}]}},[_vm._v(" "+_vm._s(_vm.span.content)+" ")]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }