<template>
  <div
    class="ethos-container"
    :class="{
      bordered: !hasChildRoute,
      'm-3': !hasChildRoute,
      'no-bg': hasChildRoute && !hasProjectRoute,
    }"
  >
    <router-view />
    <div v-if="!hasChildRoute || reuse">
      <div class="paginated-table">
        <div
          v-if="!reuse"
          class="my-3 d-flex align-items-center"
        >
          <h2 class="mr-3 d-inline-block mb-0">
            {{ casesNameLabel }}
          </h2>
          <base-button
            size="sm"
            type="primary"
            link
            class="m-0"
            @click="$refs.caseForm && $refs.caseForm.display()"
          >
            Add New {{ caseNameLabel }}
            <plus-icon
              size="1.5x"
              class="ml-3"
            />
          </base-button>

          <base-button
            class="ml-4"
            size="s"
            type="secondary"
            link
            @click="showArchived = !showArchived"
          >
            {{ archiveButtonCopy }}
          </base-button>
        </div>
        <el-table
          :class="{
            'mt-4': reuse,
          }"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            prop="title"
            label="Title"
            min-width="200"
            sortable
          />
          <el-table-column
            prop="name"
            label="Number"
            min-width="200"
            sortable
          />
          <el-table-column
            prop="createdOn"
            label="Created On"
            width="250"
            sortable
          >
            <template slot-scope="scope">
              {{ $date(scope.row.createdOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createdBy"
            label="Created By"
            min-width="200"
            sortable
          />
          <el-table-column
            prop="modifiedOn"
            label="Modified On"
            width="250"
            sortable
          >
            <template slot-scope="scope">
              {{ $date(scope.row.modifiedOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
            </template>
          </el-table-column>
          <el-table-column
            prop="modifiedBy"
            label="Modified By"
            min-width="200"
            sortable
          />

          <el-table-column
            width="200"
            align="center"
          >
            <template slot-scope="scope">
              <el-dropdown
                size="small"
                split-button
                type="primary"
                class="dropdown-actions"
                @click="handleCaseView(scope.row.id)"
              >
                View {{ caseNameLabel }}
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <a @click="archive(scope.row.id, !showArchived)">
                      {{ archiveCaseOptionCopy }}
                    </a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <table-pagination
          ref="pagination"
          :pagination="pagination"
          @changed="handlePaginationChange"
        />
      </div>
    </div>
    <case-form
      ref="caseForm"
      header="New !!"
      @updated="(c) => handleCaseView(c.id)"
    />
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {getCases, archiveCase, getArchiveCount} from "../../../api";
import {hasChildRoute} from "src/util/util";
import "src/assets/sass/custom/paginated-table.scss";
import {PlusIcon} from "vue-feather-icons";
import CaseForm from "./CaseForm.vue";
import {isDefined} from "../../../api/helpers";
import TablePagination from "../../../components/TablePagination.vue";

export default {
  name: "ethos-cases",
  components: {
    CaseForm,
    PlusIcon,
    TablePagination,
  },
  props: {
    reuse: {
      type: Boolean,
      value: false,
    },
  },
  data() {
    return {
      tableData: [],
      pagination: {
        TotalCount: 0,
      },
      hasChildRoute: false,
      hasProjectRoute: false,
      archiveCount: 0,
      showArchived: false,
    };
  },
  watch: {
    "$store.state.data.searchKeyword": function() {
      this.loadCases(this.$store.state.data.searchKeyword);
    },
    // eslint-disable-next-line quote-props
    showArchived: function() {
      this.loadCases(this.$store.state.data.searchKeyword);
    },
    // eslint-disable-next-line quote-props
    currentPath: {
      handler(to, f) {
        this.hasChildRoute = hasChildRoute(to, "cases");
        this.hasProjectRoute = to.indexOf("/project/") > -1;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("data", [
      "getSearchKeyword",
      "caseNameLabel",
      "casesNameLabel",
      "userTimezone",
    ]),
    archiveButtonCopy() {
      if (this.showArchived) return "Exit Archived View";
      return `${this.archiveCount} Archived ${this.caseNameLabel}(s)`;
    },
    archiveCaseOptionCopy() {
      if (this.showArchived) return `Unarchive ${this.caseNameLabel}`;
      return `Archive ${this.caseNameLabel}`;
    },
    currentPath() {
      return this.$route.path;
    },
  },
  mounted() {
    this.loadCases(this.getSearchKeyword);
    this.doGetArchiveCount();
  },
  methods: {
    loadCases(keyword) {
      if (!this.$refs.pagination) {
        return;
      }
      getCases(this.$refs.pagination.pageNumber, this.$refs.pagination.pageSize, keyword, this.showArchived)
        .then((response) => {
          this.tableData = response.data;
          this.pagination = JSON.parse(response.pagination);
          this.loading = false;
        })
        .catch((ex) => {
          this.loading = false;
          this.$notifyError(`Loading ${this.casesNameLabel} Data Failed`, ex);
        });
    },
    handlePaginationChange() {
      this.loadCases();
    },
    handleCaseView(id) {
      if (!isDefined(id)) return;
      this.$router.push(`/cases/${id}`);
    },
    archive(id, archive) {
      archiveCase(id, archive)
        .then(() => {
          this.loading = false;
          this.$notifySuccess(
            `${archive ? "Archive" : "Unarchive"} ${this.caseNameLabel} Successfully`
          );
          this.loadCases();
          this.doGetArchiveCount();
        })
        .catch((ex) => {
          this.loading = false;
          this.$notifyError(`Archive ${this.caseNameLabel} Failed`, ex);
        });
    },
    doGetArchiveCount() {
      getArchiveCount()
        .then((response) => {
          this.archiveCount = response.count;
        })
        .catch((ex) => {
          this.loading = false;
          this.$notifyError(`Archive ${this.casesNameLabel} Failed`, ex);
        });
    },
  },
};
</script>
