<template>
  <div class="p-5">
    <div class="d-flex items-center align-items-center">
      <div>
        <h6 class="text-white mb-0">
          Exhibits
        </h6>
        <p class="mb-0 text-lightblue">
          Total: {{data && data.length }}
        </p>
      </div>
      <div class="flex-1" />
      <base-button link>
        <FilterIcon />
      </base-button>
      <base-button
        v-if="selected && selected.length > 0"
        type="simple"
      >
        <ShareIcon />
        <span v-if="selected && data.length == selected.length">Share all</span>
        <span v-else>Share selected</span>
      </base-button>
      <base-button type="simple">
        <PlusIcon />
        Exhibit
      </base-button>
    </div>
    <base-select-table
      v-model="selected"
      :columns="columns"
      :data="data"
      @input="$emit('input', selected)"
    >
      <template v-slot:header-name>
        File Name: {{ data.length }} Files
      </template>
      <template v-slot:header-signals>
        Signals
        <RepeatIcon
          size="16"
          class="ml-1 text-lightblue"
        />
      </template>
      <template v-slot:header-description>
        Description
      </template>
      <template v-slot:header-uploadsName>
        Seen In
      </template>
      <template v-slot:data-title="{ data }">
        <div
          class="font-weight-bold"
          @click="display(data.item)"
        >
          {{ data.item.title }}
        </div>
      </template>
      <template v-slot:data-description="{ data }">
        <span class='exb-desc'>{{ data.item.description}}</span>
      </template>
      <template v-slot:data-uploadName="{ data }">
        <div
          class="text-underline"
          @click="() => showUploads(data.item.uploadId)"
        >
          {{ data.item.uploadName }}
        </div>
      </template>
      <template v-slot:data-signals="{ data }">
        <span v-if="data.selected == false">{{ data.item.signals }}</span>
        <span v-else />
      </template>
      <template v-slot:data-createdOn="{ data }">
        <template v-if="data.selected">
          <base-button
            link
            class="m-0"
          >
            <ShareIcon
              size="16"
              class="mr-1"
            />
            Share
          </base-button>
        </template>
        <template v-else>
          {{ $date(data.item.createdOn).tz(userTimezone).format("MM-DD-YY") }}
        </template>
      </template>
    </base-select-table>
  </div>
</template>

<script>
import {FilterIcon, PlusIcon, RepeatIcon, ShareIcon} from "vue-feather-icons";
import {mapGetters, mapMutations} from "vuex";
import {getCaseClips} from "../../../api";
import {isDefined} from "../../../api/helpers";
import BaseButton from "../../../components/BaseButton.vue";
import BaseSelectTable from "../../../components/DashboardV2/BaseSelectTable.vue";
import {ethosRouteNames} from "../../../routes/routeNames";
export default {
  components: {
    BaseButton,
    FilterIcon,
    PlusIcon,
    BaseSelectTable,
    RepeatIcon,
    ShareIcon,
  },
  data() {
    return {
      selected: [],
      columns: [
        {
          value: "title",
          label: "File Name",
          class: " ",
          width: "200px",
        },
        {
          value: "description",
          label: "Description",
          class: " ",
          tdClass: "",
          width: "200px",
        },
        {
          value: "uploadName",
          label: "Seen In",
          class: " ",
          tdClass: " ",
          width: "200px",
        },
        {
          value: "signals",
          label: "Signals",
          class: " ",
          tdClass: " ",
          width: "200px",
        },
        {
          value: "createdOn",
          label: "Added on",
          class: "",
          tdClass: "",
          width: "200px",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("data", ["getCaseClipSummaries", "userTimezone"]),
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    data() {
      const summaries = this.getCaseClipSummaries;
      if (isDefined(summaries) && isDefined(this.caseId)) return summaries[this.caseId];
      return [];
    },
  },
  mounted() {
    this.loadClips();
  },
  methods: {
    ...mapMutations("data", ["putCaseClipSummaries"]),
    loadClips() {
      if (!isDefined(this.caseId)) return;
      getCaseClips(this.caseId).then((response) => {
        this.putCaseClipSummaries({caseId: this.caseId, summaries: response});
      }).catch((ex) => {
        this.$notifyError("Unable to load Exhibits", ex);
      });
    },
    showUploads(id) {
      this.$router.replace({
        name: ethosRouteNames.CaseViewUploads,
        params: {
          uploadId: id,
        },
      });
    },
    display(clip) {
      if (!isDefined(clip)) return;
      this.$router.replace({
        name: ethosRouteNames.ClipV2,
        params: {
          uploadId: clip.uploadId,
          clipId: clip.id,
        },
      });
    },
  },
};
</script>

<style>
.exb-desc{
   display: block;
    word-wrap:break-word;
}
.desc-content {
  display:flex;
  justify-content: center;
}
</style>
