<template>
  <dashboard-layout v-if="!loading">
    <case-menu
      slot="left"
      :case-summary="caseModel"
      @reload="reloadCaseSummary"
      @updatedCase="updatedCase"
    />
    <router-view
      v-if="isNonEmptyCase"
      slot="right"
    />
    <case-empty-state
      v-else
      slot="right"
    />
  </dashboard-layout>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import CaseMenu from "../../../components/DashboardV2/Case/CaseMenu.vue";
import DashboardLayout from "../../../components/DashboardV2/DashboardV2Layout.vue";
import {isDefined} from "@/api/helpers";
import {getCaseSummary} from "@/api";
import CaseEmptyState from "../../../components/DashboardV2/Case/CaseEmptyState.vue";

export default {
  components: {DashboardLayout, CaseMenu, CaseEmptyState},
  data() {
    return {
      loading: false,
      updatedCaseId: null,
    };
  },
  watch: {
    caseId: {
      immediate: true,
      handler(to) {
        this.loadCase();
      },
    },
    uploadsListRefreshToken(to, from) {
      this.reloadCaseSummary();
    },
  },
  computed: {
    ...mapGetters("data", [
      "getCaseSummaries",
      "uploadsListRefreshToken",
    ]),
    caseId() {
      const id = isDefined(this.updatedCaseId) ?
        this.updatedCaseId :
        this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    caseSummaries() {
      return this.getCaseSummaries.data;
    },
    caseModel() {
      if (!isDefined(this.caseId)) return null;
      const model = this.caseSummaries.filter((c) => c.id === this.caseId).pop();
      return model ? model : null;
    },
    isNonEmptyCase() {
      const cs = this.caseModel;
      let counts = 0;
      counts += (cs?.userAttributionCount || 0) - 1; // Subtracting 1, because we created one userAttribution with the case.
      counts += cs?.actorAttributionCount || 0;
      counts += cs?.uploadsCount || 0;
      counts += cs?.caseKeywordCount || 0;
      return counts > 0;
    },
  },
  methods: {
    ...mapMutations("data", ["putCaseSummary"]),
    updatedCase(id) {
      this.updatedCaseId = id;
    },
    loadCase() {
      if (this.loading) return;
      if (!isDefined(this.caseId)) {
        return;
      }
      if (isDefined(this.caseModel)) {
        this.storeRecent(this.caseModel.id);
        this.loading = false;
        return;
      }
      this.reloadCaseSummary();
    },
    reloadCaseSummary() {
      this.loading = true;
      getCaseSummary(this.caseId)
        .then((response) => {
          this.storeRecent(response.id);
          this.putCaseSummary(response);
        })
        .catch((ex) => {
          this.$notifyError("Loading Case Data Failed", ex);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    storeRecent(id) {
      const key = "recent-case";
      const strId = `${id}`;
      let current = window.localStorage.getItem(key)?.split(",") || [];
      current = current.filter((i) => i !== strId);
      current.splice(0, 0, strId);
      if (current.length >= 10) current = current.slice(0, 10);
      window.localStorage.setItem(key, current.join(","));
    },
  },
};
</script>

<style></style>
