<template>
  <div class="p-0">
    <h5>Revisions</h5>
    <div>
      <div
        v-if="requestedChanges.length < 1"
        class="d-flex align-items-center mb-3"
      >
        No revisions made yet.
      </div>
      <template
        v-else
      >
        <div
          class="d-flex align-items-center mb-3"
        >
          <el-select
            v-model="selectedRevision"
            class="select-primary dark w-100"
            effect="dark"
            popper-class="select-primary"
            placeholder="Show revision"
            clearable
          >
            <el-option
              v-for="ch in approvedRequestedChanges"
              :key="ch.id"
              :label="$date(ch.ao).tz(userTimezone).format('MMM DD, YYYY @ hh:mma')"
              :value="ch.id"
            />
          </el-select>
        </div>
        <div
          class=""
        >
          <base-button
            size="sm"
            type="secondary"
            simple
            class="btn w-100 m-0"
            :disabled="restoreDisabled"
            @click="restoreRevision"
          >
            Permenantly Restore
          </base-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getRevision, postRestoreRevision} from "../../../../api";
import {isDefined} from "../../../../api/helpers";
export default {
  name: "revisions",
  props: {
    transcript: {
      type: Object,
      default: () => {},
    },
    displayedRevision: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedRevision: null,
      loading: false,
    };
  },
  watch: {
    selectedRevision: {
      handler(to) {
        this.getRevision(to);
      },
    },
    displayedRevision: {
      immediate: true,
      handler(to) {
        if (isDefined(to) && to !== this.selectedRevision) {
          this.selectedRevision = to;
        }
      },
    },
  },
  computed: {
    ...mapGetters("data", ["userTimezone"]),
    approvedRequestedChanges() {
      return [
        {ao: this.transcript.createdOn, id: "base"},
        ...this.requestedChanges.filter((rc) => rc.ao != null && rc.ao.length > 0),
      ];
    },
    transcriptionId() {
      if (!isDefined(this.transcript)) return -1;
      return this.transcript.id;
    },
    requestedChanges() {
      if (!isDefined(this.transcript)) return [];
      return this.transcript.metadata.requestedChanges;
    },
    restoreDisabled() {
      return (
        this.selectedRevision === null ||
        this.selectedRevision.length <= 0 ||
        this.selectedRevision === this.approvedRequestedChanges[this.approvedRequestedChanges.length - 1].id
      );
    },
  },
  methods: {
    clear() {
      this.selectedRevision = null;
    },
    restoreRevision() {
      // Save revision.
      if (!isDefined(this.selectedRevision) || this.loading) return;

      this.loading = true;
      postRestoreRevision(this.transcriptionId, this.selectedRevision).then((data) => {
        this.$emit("restored", data);
        this.$notifySuccess("Successfully restored revision");
      }).catch((ex) => {
        this.$notifyError("Unable to get revision");
      }).finally(() => {
        this.loading = false;
      });
    },
    getRevision(id) {
      if (this.loading) return;
      if (!isDefined(id) || id.length <= 0) {
        this.$emit("display", null);
        return;
      }

      this.loading = true;
      getRevision(this.transcriptionId, id).then((data) => {
        this.$emit("display", {transcript: data, revisionId: id});
      }).catch((ex) => {
        this.$notifyError("Unable to get revision");
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
