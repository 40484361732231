export const ethosRouteNames = {
  Home: "Home",
  Cases: "Cases",
  Case: "Case",
  Contact: "Contact",
  Error: "Error",
  Project: "Project",
  ProjectUploads: "Project_Uploads",
  ProjectPeople: "Project_People",
  Uploads: "Uploads",
  UploadPeople: "Uploads_People",
  UploadNotes: "Uploads_Notes",
  UploadManager: "UploadManager",
  ViewUpload: "ViewUpload",
  Transcript: "Transcript",
  Incidents: "Incidents",
  Notes: "Notes",
  Signals: "Signals",
  People: "People",
  Search: "Search",
  Settings: "Settings",
  Support: "Support",
  Clips: "Clips",
  Clip: "Clip",
  Admin: "Admin",
  UserManagement: "UserManagement",
  MainContent: "MainContent",
  Sidebar: "Sidebar",
  DashboardV2: "Dashboard",
  NewCase: "NewCase",
  ViewCase: "ViewCase",
  CaseUploads: "CaseUploads",
  CaseClips: "CaseClips",
  CaseEvidence: "CaseEvidence",
  CaseKeywords: "CaseKeywords",
  CaseExhibits: "CaseExhibits",
  CasePeople: "CasePeople",
  CaseNotes: "CaseNotes",
  CaseUploadsList: "CaseUploadsList",
  CaseViewUploads: "CaseViewUploads",
  CaseSearch: "CaseSearch",
  CaseV2: "CaseV2",

  TranscriptV2: "TranscriptV2",
  IncidentsV2: "IncidentsV2",
  NotesV2: "NotesV2",
  UploadsNotesV2: "Uploads_NotesV2",
  UploadsPeopleV2: "Uploads_PeopleV2",
  SignalsV2: "SignalsV2",
  PeopleV2: "PeopleV2",
  SearchV2: "SearchV2",
  SettingsV2: "SettingsV2",
  SupportV2: "SupportV2",
  ClipsV2: "ClipsV2",
  ClipV2: "ClipV2",
  AdminV2: "AdminV2",
  UserManagementV2: "UserManagementV2",
};
