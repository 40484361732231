<template>
  <div>
    <template v-for="(cr, cri) in crs">
      <change-request
        v-if="currentVisible == cri"
        :key="cr.id"
        :cr="cr"
        :person-name="null"
        :can-undo="(length > 1 && cri === 0) || length === 1"
        @approve="(a) => $emit('approve', a)"
        @reject="(r) => $emit('reject', r)"
        @undo="(r) => $emit('undo', r)"
      />
    </template>
    <template v-for="(scr, scri) in annotatedScrs">
      <change-request
        v-if="currentVisible == (crs || []).length + scri"
        :key="scr.id"
        :cr="scr"
        :person-name="scr.personName"
        :is-remove="scr.isRemove"
        is-speaker
        @approve="(a) => $emit('approve', a)"
        @reject="(r) => $emit('reject', r)"
      />
    </template>
    <div
      v-if="length > 1"
      class="d-flex align-items-start"
    >
      <span class="result pr-sm-2">
        {{ currentVisible + 1 }}
        of
        {{ length }}
      </span>
      <div class="btn-group">
        <base-button
          size="sm"
          type="secondary"
          simple
          class="btn"
          :disabled="currentVisible <= 0"
          @click="previousCR()"
        >
          Prev
        </base-button>
        <base-button
          size="sm"
          type="secondary"
          simple
          class="btn"
          :disabled="currentVisible >= length - 1"
          @click="nextCR()"
        >
          Next
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import {isDefined} from "../../../../api/helpers";
import ChangeRequest from "./ChangeRequest.vue";

export default {
  components: {ChangeRequest},
  name: "ChangeRequests",
  props: {
    crs: {
      type: Array,
      default() {
        return [];
      },
    },
    scrs: {
      type: Array,
      default() {
        return [];
      },
    },
    actorMap: {
      type: Object,
      default() {
        return {};
      },
    },
    userMap: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    length() {
      return (this.crs || []).length + (this.scrs || []).length;
    },
    annotatedScrs() {
      return this.scrs.map((scr) => {
        scr.isRemove = this.getChangeRequestIsRemove(scr);
        scr.personName = this.getChangeRequestPersonName(scr);
        return scr;
      });
    },
  },
  data() {
    return {
      currentVisible: 0,
    };
  },
  methods: {
    nextCR() {
      this.currentVisible++;
    },
    previousCR() {
      this.currentVisible--;
    },
    getChangeRequestPersonName(speakerChangeRequest) {
      if (!isDefined(speakerChangeRequest)) return null;
      if (isDefined(speakerChangeRequest.uid)) {
        const user = this.userMap[speakerChangeRequest.uid];
        return isDefined(user) ? user.fullName : null;
      } else if (isDefined(speakerChangeRequest.aid)) {
        const actor = this.actorMap[speakerChangeRequest.aid];
        return isDefined(actor) ? actor.fullName : null;
      }
      return null;
    },
    getChangeRequestIsRemove(speakerChangeRequest) {
      if (isDefined(speakerChangeRequest.uid)) {
        return speakerChangeRequest.uid < -1000;
      } else if (isDefined(speakerChangeRequest.aid)) {
        return speakerChangeRequest.aid < -1000;
      }
      return false;
    },
  },
};
</script>
