<template>
  <modal
    :show.sync="isDisplayed"
    header-classes="justify-content-center"
    footer-classes="px-4 py-3"
    :centered="true"
    class="modal-default"
  >
    <!-- Header Slot -->
    <h4
      slot="header"
      class="title title-up"
    >
      {{ headerCopy }}
    </h4>

    <div class="mb-3">
      <label
        for="name"
        class="form-label"
      >
        Name
      </label>
      <input
        id="name"
        v-model="name"
        type="text"
        class="form-control"
        placeholder="Name"
      >
    </div>

    <!-- Footer Slot -->
    <template slot="footer">
      <base-button
        size="sm"
        class="ml-auto"
        :loading="loading"
        @click="submit"
      >
        Submit
      </base-button>
    </template>
  </modal>
</template>

<script>
import {Modal} from "src/components";
import {mapMutations} from "vuex";
import {createCodeWordCollection, updateCodeWordCollection} from "../../../api";
import {isDefined} from "../../../api/helpers";

export default {
  name: "code-word-collection-form",
  components: {
    Modal,
  },
  data() {
    return {
      loading: false,
      isDisplayed: false,
      groupId: null,
      collectionId: null,
      name: "",
      headerDisplay: null,
    };
  },
  computed: {
    headerCopy() {
      return this.collectionId !== null ? `Update ${this.headerDisplay}` : "Add New Collection";
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations("data", ["putCodeWordCollection"]),
    display(groupId, collection) {
      this.groupId = groupId;
      if (collection) {
        this.collectionId = collection.id;
        this.name = collection.name;
        this.headerDisplay = collection.name;
      }
      this.isDisplayed = true;
    },
    reset() {
      this.isDisplayed = false;
      this.loading = false;
      this.groupId = null;
      this.collectionId = null;
      this.name = "";
      this.headerDisplay = null;
      this.$emit("close");
    },
    submit() {
      if (this.loading) return;
      this.loading = true;
      if (isDefined(this.collectionId)) {
        updateCodeWordCollection(
          this.groupId,
          this.collectionId,
          this.name
        ).then((collection) => {
          this.loading = false;
          this.putCodeWordCollection({collection, groupId: this.groupId});
          this.$emit("updated", collection);
          this.$notifySuccess(`Updated: ${collection.name}`);
          this.reset();
        }).catch((ex) => {
          this.loading = false;
          this.$notifyError("Failed to update entry", ex);
        });
      } else {
        createCodeWordCollection(
          this.groupId,
          this.name
        ).then((collection) => {
          this.loading = false;
          this.putCodeWordCollection({collection, groupId: this.groupId});
          this.$emit("created", collection);
          this.$notifySuccess(`Created new entry: ${collection.name}`);
          this.reset();
        }).catch((ex) => {
          this.loading = false;
          this.$notifyError("Failed to create new entry", ex);
        });
      }
    },
  },
};
</script>
