import {getInstance} from "@/plugins/auth0-plugin";
import {reset} from './index';

const actions = {
  async logout({commit, state}, payload) {
    const authService = getInstance();
    authService.logout();
    reset(state);
  },
};

export default actions;