<template>
  <div class="content-search p-0">
    <h5>Keyword Search</h5>
    <div>
      <div class="d-flex align-items-center mb-3">
        <input
          v-model="searchTerm"
          type="text"
          class="form-control ethosSearch"
          placeholder="SEARCH"
          @keyup.enter="search"
        >
        <base-button
          size="md"
          type="primary"
          icon
          simple
          @click="search"
        >
          <search-icon size="1.1x" />
        </base-button>
      </div>
      <div
        v-if="action"
        class="d-flex align-items-center">
        <span class="result pr-sm-2"> {{ current + 1 }} of {{ indices.length }} </span>
        <div class="btn-group">
          <base-button
            size="sm"
            type="secondary"
            simple
            class="btn"
            :disabled="current <= 0"
            @click="previous"
          >
            Prev
          </base-button>
          <base-button
            size="sm"
            type="secondary"
            simple
            class="btn"
            :disabled="current >= indices.length - 1"
            @click="next"
          >
            Next
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SearchIcon} from "vue-feather-icons";
export default {
  name: "content-search",
  components: {SearchIcon},
  props: {
    transcriptContent: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchTerm: "",
      indices: [],
      current: -1,
      action: false,
    };
  },
  methods: {
    indicesOf(string, searchTerm) {
      const indexes = [];
      const regex = new RegExp(searchTerm, "gi");
      const matcher = string.matchAll(regex);
      let match = matcher.next();
      while (match && !match.done) {
        indexes.push(match.value.index);
        match = matcher.next();
      }
      return indexes;
    },
    search() {
      if (this.searchTerm !== "") {
        this.matchFound = 0;
        this.indices = this.indicesOf(this.transcriptContent, this.searchTerm);
        this.current = this.indices.length > 0 ? 0 : -1;
        this.action = this.current === -1 ? false : true;
        this.$emit("indices", this.indices);
        this.setCurrent(this.current);
      } else {
        this.action = false;
      }
    },
    previous() {
      this.setCurrent(this.current - 1);
    },
    next() {
      this.setCurrent(this.current + 1);
    },
    setCurrent(index) {
      this.current = Math.max(0, Math.min(this.indices.length - 1, index));
      this.$emit("current", this.current);
      this.$emit("scrollToNext", this.current);
    },
  },
};
</script>
