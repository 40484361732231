<template>
  <table class="table tablesorter mb-0">
    <thead>
      <tr>
        <th
          width="55px"
          class="text-center"
        >
          <base-checkbox
            :checked="isSelectAll"
            @input="onSelectAll"
          />
        </th>
        <th
          v-for="(col, i) in columns"
          :key="i"
          class="text-capitalize font-weight-bold text-white cursor-pointer"
          :class="col.class || ''"
          :width="col.width"
          @click="selectSort(col)"
        >
          <div class="d-flex">
            <slot :name="`header-${col.value}`">
              {{ col.label }}
            </slot>
            <material-icon v-if="sortBy === col.value">swap_vert</material-icon>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <template
        v-for="(item, index) in list"
      >
        <tr
          :key="index"
          class="cursor-pointer"
          :class="rowClass(index,item)"
        >
          <td class="text-center">
            <base-checkbox
              :checked="isSelected(item)"
              @input="onCheckboxChange($event, item)"
            />
          </td>
          <td
            v-for="(col, i) in columns"
            :key="i"
            class="font-weight-bold"
            :class="col.tdClass"
            @click="$emit('clicked', {item, index})"
          >
            <slot
              :name="`data-${col.value}`"
              :data="{
                item,
                value: item[col.value],
                index,
                selected: isSelected(item),
              }"
            >
              <span @click="triggerNameClick(item)"> {{ item[col.value] }} </span>
            </slot>
          </td>
        </tr>
        <slot
          name="nested-data"
          :data="{
            item,
            index,
          }"
        />
      </template>
    </tbody>
  </table>
</template>

<script>
import BaseCheckbox from "../Inputs/BaseCheckbox.vue";
import MaterialIcon from "./MaterialIcon.vue";
export default {
  components: { BaseCheckbox, MaterialIcon },
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    disableRowClass: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
      sortBy: null,
      orderBy: {},
      list: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(to, from) {
        this.$nextTick(() => {
          this.selected = to.slice();
        });
      },
    },
    data: {
      handler() {
        this.list = this.sortedList();
      },
      deep: true,
    },
  },
  computed: {
    isSelectAll() {
      return this.data.length == this.selected.length;
    },
  },

  methods: {
    rowClass(index,item) {
      if (this.disableRowClass) return "";
      if (this.isSelected(item)) return "bg-black";
      return index % 2 == 0 ? "bg-dark-100" : "bg-dark-200";;
    },
isSelected(item) {
      const selectIndex = this.selected.findIndex((i) => JSON.stringify(i) == JSON.stringify(item));
      return selectIndex != -1;
    },
onCheckboxChange(value, item) {
      const selectIndex = this.selected.findIndex((i) => JSON.stringify(i) == JSON.stringify(item));
      if (value && selectIndex == -1) {
        this.selected.push(item);
      }
      if (!value && selectIndex != -1) {
        this.selected.splice(selectIndex, 1);
      }
      this.$emit("input", this.selected);
    },
    onSelectAll(event) {
      if (event) {
        this.selected = this.data.map((item) => item);
      } else {
        this.selected = [];
      }
      this.$emit("input", this.selected);
    },
    triggerNameClick(data) {
      this.$emit("nameClick", data.data);
    },
    dynamicSort(property) {
      return function (a, b) {
        // Use the property to determine the order
        if (a[property] < b[property]) {
          return -1;
        } else if (a[property] > b[property]) {
          return 1;
        } else {
          return 0;
        }
      };
    },
    selectSort({ value }) {
      if (this.orderBy[value] == undefined) {
        this.orderBy[value] = "asc";
      } else {
        this.orderBy[value] = this.orderBy[value] == "asc" ? "desc" : "asc";
      }
      this.sortBy = value;
      this.list = this.sortedList();
    },
    sortedList() {
      if (this.sortBy) {
        let list = [...this.data].sort(this.dynamicSort(this.sortBy));
        if (this.orderBy[this.sortBy] == "desc") {
          list = list.reverse();
        }
        return list;
      }
      return this.data;
    },
  },
  mounted() {
    this.list = this.sortedList()
  }
};
</script>

<style lang="scss">
.card-table {
  tr {
    border-bottom: 5px solid #191919;
  }
  td:first-child,
  th:first-child {
    border-radius: 10px 0 0 10px;
  }
  // Set border-radius on the top-right and bottom-right of the last table data on the table row
  td:last-child,
  th:last-child {
    border-radius: 0 10px 10px 0;
  }
  tbody {
    tr:nth-child(odd) {
      background-color: #252e2d;
    }
    tr:nth-child(even) {
      background-color: #152121;
    }
  }
}
</style>
