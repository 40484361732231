import Vue from "vue";
import Router from "vue-router";
import DashboardLayout from "./../pages/Ethos/Dashboard/DashboardLayout.vue";
import Search from "./../pages/Ethos/Search/Search.vue";
import Cases from "./../pages/Ethos/Cases/Cases.vue";
import Uploads from "./../pages/Ethos/Uploads/Uploads.vue";
import ViewUpload from "./../pages/Ethos/Uploads/ViewUpload.vue";
import AudioTranscript from "./../pages/Ethos/Uploads/AudioTranscript/AudioTranscript.vue";
import Incidents from "./../pages/Ethos/Uploads/Incidents.vue";
import Clips from "./../pages/Ethos/Uploads/Clips.vue";
import Settings from "./../pages/Ethos/Settings/Settings.vue";
import People from "./../pages/Ethos/People/People.vue";
import Projects from "./../pages/Ethos/Projects/Projects.vue";
import ViewCase from "./../pages/Ethos/Cases/ViewCase.vue";
import ErrorPage from "./../pages/Ethos/Error/ErrorPage.vue";
import AdminWrapper from "./../pages/Ethos/Admin/AdminWrapper.vue";
import UserManagement from "./../pages/Ethos/Admin/Users/UserManagement.vue";
import UploadManager from "./../pages/Ethos/Uploads/UploadManager.vue";
import Notes from "./../pages/Ethos/Notes/Notes.vue";

// import MainContent from "../pages/Ethos/v2/Main-content-v2/MainContent.vue";
// import Dashboard from "../pages/Ethos/v2/Dashboard-v2/Dashboard.vue";
// import Sidebar from "./../pages/Ethos/v2/sideBarv2/Sidebar.vue";
import DashboardV2 from "./../pages/DashboardV2/Index.vue";
import CaseV2 from "./../pages/DashboardV2/Case/Case.vue";
import CaseUploads from "./../pages/DashboardV2/Case/CaseUploads.vue";
import CaseClips from "./../pages/DashboardV2/Case/CaseClips.vue";
import CaseEvidence from "./../pages/DashboardV2/Case/CaseEvidence.vue";
import CaseKeywords from "./../pages/DashboardV2/Case/CaseKeywords.vue";
import CaseExhibits from "./../pages/DashboardV2/Case/CaseExhibits.vue";
import CasePeople from "./../pages/DashboardV2/Case/CasePeople.vue";
import CaseNotes from "./../pages/DashboardV2/Case/CaseNotes.vue";
import CaseUploadsList from "./../pages/DashboardV2/Case/CaseUploadsList.vue";
import PeopleV2 from "./../pages/DashboardV2/People/People.vue";
import UploadsV2 from "./../pages/DashboardV2/Uploads/ViewUpload.vue";
import AudioTranscriptV2 from "./../pages/DashboardV2/Uploads/AudioTranscript/AudioTranscript.vue";
import IncidentsV2 from "./../pages/DashboardV2/Uploads/Incidents.vue";
import ClipsV2 from "./../pages/DashboardV2/Uploads/Clips.vue";
import NotesV2 from "./../pages/DashboardV2/Notes/Notes.vue";
import SettingsV2 from "./../pages/DashboardV2/Settings/Settings.vue";
import AdminV2 from "./../pages/DashboardV2/Admin/AdminWrapper.vue";
import UserManagementV2 from "./../pages/DashboardV2/Admin/Users/UserManagement.vue";
import SearchV2 from "./../pages/DashboardV2/Search/Search.vue";
import SupportV2 from "./../pages/DashboardV2/Support/Support.vue";
import {ethosRouteNames as names} from "./routeNames";
import {authGuard} from "./authGuard";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/error/:code",
      name: names.Error,
      component: ErrorPage,
    },
    {
      path: "/",
      redirect: "/v2/dashboard",
    },
    {
      path: "/",
      name: names.Home,
      component: DashboardLayout,
      children: [
        {
          path: "cases",
          name: names.Cases,
          components: {default: Cases},

          children: [
            {
              path: ":caseId",
              name: names.Case,
              components: {default: ViewCase},
              children: [
                {
                  path: "project/:projectId",
                  name: names.Project,
                  components: {default: Projects},
                  children: [
                    {
                      path: "uploads",
                      name: names.ProjectUploads,
                      components: {default: Uploads},
                    },
                    {
                      path: "people",
                      name: names.ProjectPeople,
                      components: {default: People},
                    },
                    {
                      path: "note/:noteId",
                      name: names.Notes,
                      components: {default: Notes},
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "uploads",
          name: names.Uploads,
          components: {default: Uploads},
          children: [
            {
              path: "upload-manager",
              name: names.UploadManager,
              components: {default: UploadManager},
            },
            {
              path: ":uploadId",
              name: names.ViewUpload,
              component: ViewUpload,
              children: [
                {
                  path: "transcript",
                  name: names.Transcript,
                  component: AudioTranscript,
                },
                {
                  path: "people",
                  name: names.UploadPeople,
                  components: {default: People},
                },
                {
                  path: "note/:noteId",
                  name: names.UploadNotes,
                  components: {default: Notes},
                },
                {
                  path: "incidents",
                  name: names.Incidents,
                  component: Incidents,
                },
                {
                  path: "clips/:clipId?",
                  name: names.Clips,
                  component: Clips,
                },
              ],
            },
          ],
        },
        {
          path: "search",
          name: names.Search,
          components: {default: Search},
        },
        {
          path: "settings",
          name: names.Settings,
          components: {default: Settings},
        },
        {
          path: "people/:peopleId?", // Optional Id, just displays user inside folder
          name: names.People,
          components: {default: People},
        },
        {
          path: "admin",
          name: names.Admin,
          components: {default: AdminWrapper},
          children: [
            {
              path: "users",
              name: name.UserManagement,
              components: {default: UserManagement},
            },
          ],
        },
      ],
      beforeEnter: authGuard,
    },
    {
      path: "/v2/dashboard",
      name: names.DashboardV2,
      component: DashboardV2,
      beforeEnter: authGuard,
    },
    {
      path: "/v2/settings",
      name: names.SettingsV2,
      component: SettingsV2,
      beforeEnter: authGuard,
    },
    {
      path: "/v2/support",
      name: names.SupportV2,
      component: SupportV2,
      beforeEnter: authGuard,
    },
    {
      path: "/v2/admin",
      name: names.AdminV2,
      component: AdminV2,
      children: [
        {
          path: "users",
          name: names.UserManagementV2,
          component: UserManagementV2,
        },
      ],
      beforeEnter: authGuard,
    },
    {
      path: "/v2/search",
      name: names.SearchV2,
      component: SearchV2,
      beforeEnter: authGuard,
    },
    {
      path: "/v2/case/:caseId?",
      name: names.ViewCase,
      component: CaseV2,
      children: [
        {
          path: "/v2/case/:caseId/uploads",
          name: names.CaseUploads,
          component: CaseUploads,
        },
        {
          path: "/v2/case/:caseId/clips",
          name: names.CaseClips,
          component: CaseClips,
        },
        {
          path: "/v2/case/:caseId/upload",
          name: names.CaseEvidence,
          component: CaseEvidence,
        },
        {
          path: "/v2/case/:caseId/view-uploads/:uploadId",
          name: names.CaseViewUploads,
          component: UploadsV2,
          children: [
            {
              path: "transcript",
              name: names.TranscriptV2,
              component: AudioTranscriptV2,
            },
            {
              path: "people",
              name: names.UploadsPeopleV2,
              components: {default: PeopleV2},
            },
            {
              path: "note/:noteId",
              name: names.UploadsNotesV2,
              components: {default: NotesV2},
            },
            {
              path: "incidents",
              name: names.IncidentsV2,
              component: IncidentsV2,
            },
            {
              path: "clips",
              name: names.ClipsV2,
              component: ClipsV2,
              children: [
                {
                  path: ":clipId", // Optional param was ignored.
                  name: names.ClipV2,
                  component: ClipsV2,
                },
              ],
            },
          ],
        },
        {
          path: "/v2/case/:caseId/uploads/:dirName/list",
          name: names.CaseUploadsList,
          component: CaseUploadsList,
        },
        {
          path: "/v2/case/:caseId/keywords",
          name: names.CaseKeywords,
          component: CaseKeywords,
        },
        {
          path: "/v2/case/:caseId/exhibits",
          name: names.CaseExhibits,
          component: CaseExhibits,
        },
        {
          path: "/v2/case/:caseId/people/:actorId?",
          name: names.CasePeople,
          component: CasePeople,
        },
        {
          path: "/v2/case/:caseId/notes",
          name: names.CaseNotes,
          component: CaseNotes,
        },
        {
          path: "/v2/case/:caseId/search",
          name: names.CaseSearch,
          component: SearchV2,
        },
      ],
      beforeEnter: authGuard,
    },
    {
      path: "/v2/people/:userType?/:peopleId?",
      name: names.PeopleV2,
      component: PeopleV2,
      beforeEnter: authGuard,
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0};
    }
  },
});
