<template>
  <div class="ethos-dashboard">
    <div class="actions group row">
      <div class="action">
        <el-dropdown
          size="large"
          split-button
          type="primary"
          class="dropdown-actions dropdown-actions-simple"
          @click="$router.push('/cases')"
        >
          <codepen-icon
            size="1.5x"
            class="mr-2"
          />
          {{ casesNameLabel }}
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <a @click="$refs.caseForm && $refs.caseForm.display()">
                  Add {{ caseNameLabel }}
                </a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <!-- <div class="action">
        <base-button
          size="lg"
          type="primary"
          simple
          class="dashboard-menu-button"
          @click="$router.push('/cases')"
        >
          <codepen-icon
            size="1.5x"
            class="mr-2"
          />
          <span> {{ casesNameLabel }} </span>
        </base-button>
      </div> -->
      <div class="action">
        <base-button
          size="lg"
          type="primary"
          simple
          class="dashboard-menu-button"
          @click="$router.push('/uploads')"
        >
          <archive-icon
            size="1.5x"
            class="mr-2"
          />
          <span> {{ uploadsNameLabel }} </span>
        </base-button>
      </div>
      <div class="action">
        <base-button
          size="lg"
          type="primary"
          simple
          class="dashboard-menu-button"
          @click="$router.push('/people')"
        >
          <user-plus-icon
            size="1.5x"
            class="mr-2"
          />
          <span>{{ peopleNameLabel }}</span>
        </base-button>
      </div>
      <!-- <div class="action">
        <base-button
          size="lg"
          type="primary"
          class="dashboard-menu-button btn-fixed"
          @click="showModal"
        >
          <span>New {{ caseNameLabel }} </span>
          <plus-icon
            size="1.5x"
            class="ml-2"
          />
        </base-button>
      </div> -->
    </div>

    <section class="project-list mb-3">
      <h2 class="mb-4">
        {{ starredNameLabel }}
      </h2>
      <div class="project-contents d-flex flex-wrap">
        <project-card
          v-for="project in starredProjects"
          :key="project.id"
          :project="project"
          @updated="reloadProjects"
        />
      </div>
      <card
        v-if="starredProjects.length < 1"
        style="max-width: 300px; text-align: center"
      >
        No {{ starredNameLabel }} {{ projectNameLabel }}s
      </card>
    </section>

    <section class="project-list mb-3">
      <h2 class="mb-4">
        Recent
      </h2>
      <div class="project-contents d-flex flex-wrap">
        <project-card
          v-for="project in recentProjects"
          :key="project.id"
          :project="project"
          @updated="reloadProjects"
        />
        <card
          v-if="recentProjects.length < 1"
          style="max-width: 300px; text-align: center"
        >
          No Recent {{ projectNameLabel }}s
        </card>
      </div>
    </section>

    <case-form
      ref="caseForm"
      header="New !!"
      @updated="(c) => handleCaseView(c.id)"
    />
  </div>
</template>

<script>
import CaseForm from "../Cases/CaseForm.vue";
import ProjectCard from "../Projects/ProjectCard.vue";
import {
  ArchiveIcon,
  CodepenIcon,
  UserPlusIcon,
} from "vue-feather-icons";
import {getAllStarredProjects, getRecentProjects} from "../../../api";
import {mapGetters} from "vuex";
import {isDefined} from "../../../api/helpers";

export default {
  name: "ethos-dashboard",
  components: {
    CaseForm,
    ProjectCard,
    ArchiveIcon,
    CodepenIcon,
    UserPlusIcon,
  },
  computed: {
    ...mapGetters("data", [
      "caseNameLabel",
      "casesNameLabel",
      "uploadsNameLabel",
      "starredNameLabel",
      "peopleNameLabel",
      "projectNameLabel",
    ]),
  },
  data() {
    return {
      starredProjects: [],
      recentProjects: [],
    };
  },
  mounted() {
    this.reloadProjects();
  },
  methods: {
    reloadProjects() {
      this.doGetStarredProjects();
      this.doGetRecentProjects();
    },
    doGetStarredProjects() {
      getAllStarredProjects()
        .then((response) => {
          this.starredProjects = response;
        })
        .catch((ex) => {
          this.$notifyError(`Loading ${this.starredNameLabel} ${this.projectNameLabel} Failed`, ex);
        });
    },
    doGetRecentProjects() {
      getRecentProjects()
        .then((response) => {
          this.recentProjects = response;
        })
        .catch((ex) => {
          // TODO: - Nomencalture
          this.$notifyError(`Loading Recent ${this.projectNameLabel} Failed`, ex);
        });
    },
    handleCaseView(id) {
      if (!isDefined(id)) return;
      this.$router.push(`/cases/${id}`);
    },
  },
};
</script>
