<template>
  <span
    v-if="span && !isDeleted"
    :id="span.start + 1"
    :key="key + isEditing"
    :class="{
      unclear: isUnclear && span.content.trim().length > 0,
      signal: hasSignal && span.content.trim().length > 0,
      incident: hasIncident,
      'incident-start': isIncidentStart,
      'incident-end': isIncidentEnd,
      incident: hasIncident,
      redacted: hasRedaction,
      keyword: keywordPosition > -1,
      current: isCurrentKeyword,
      is: isPlaying,
      playing: isPlaying,
      insideClip: isInsideClip,
      'has-change-request': hasChangeRequest,
      'cursor-pointer': audioReady || hasChangeRequest,
      'selected': isHighlighted,
    }"
    :data-key="key"
    :data-s="span.start"
    :data-e="span.end"
    :data-d="isEditing && differs"
    :data-o="span.content"
    :data-so="span.startOffset"
    :data-eo="span.endOffset"
    :style="styleOverrides"
    @click.left="handleClick"
    @input="onInput"
    @keydown.tab="tabup"
    @keyup.enter="enterup"
    @keyup.esc="escup"
  >
    {{ span.content }}
  </span>
</template>

<script>
import {isDefined} from "../../../../api/helpers";
export default {
  name: "transcription-span",
  props: {
    span: {
      type: Object,
      default: null,
    },
    clipRange: {
      type: Object,
      default: null,
    },
    speakerPos: {
      type: Number,
      default: -1,
    },
    editingSpeakerAt: {
      type: Number,
      default: -1,
    },
    incidents: {
      type: Array,
      default() {
        return [];
      },
    },
    unclearThreshold: {
      type: Number,
      default: 75,
    },
    currentPlaytime: {
      type: Number,
      default: -1,
    },
    keywordIndices: {
      type: Array,
      default() {
        return [];
      },
    },
    keywordCurrentIndex: {
      type: Number,
      default: -1,
    },
    codeWordIndices: {
      type: Array,
      default() {
        return [];
      },
    },
    audioReady: {
      type: Boolean,
      default: false,
    },
    hasChangeRequest: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    signalValue: {
      type: Boolean,
      default: true,
    },
    unclearValue: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    isCurrentKeyword: {
      handler(to, from) {
        // if (to && !from) {
        //   const el = document.querySelector(`span[data-key="${this.key}"]`);
        //   if (el) el.scrollIntoView();
        // }
      },
      immediate: true,
    },
    isPlaying: {
      handler(to, from) {
        if (to && !from) {
          const el = document.querySelector(`span[data-key="${this.key}"]`);
          this.$emit("isPlaying", el);
        }
      },
      immediate: true,
    },
  },
  computed: {
    key() {
      return `${this.span.start}-${this.span.end}`;
    },
    // isEditing() {
    //   return this.editingSpeakerAt === this.speakerPos;
    // },
    isUnclear() {
      return this.span.confidence < this.unclearThreshold && !this.unclearValue;
    },
    isPlaying() {
      return this.currentPlaytime >= this.span.startOffset && this.currentPlaytime < this.span.endOffset;
    },
    isInsideClip() {
      return this.clipRange != null && this.clipRange.in <= this.span.startOffset &&
       this.span.endOffset <= this.clipRange.out;
    },
    keywordPosition() {
      for (let i = 0; i < this.keywordIndices.length; i++) {
        const index = this.keywordIndices[i];
        if (this.span.start <= index && index <= this.span.end) return i;
      }
      return -1;
    },
    isCurrentKeyword() {
      const currentIndex = this.keywordIndices[this.keywordCurrentIndex];
      return this.span.start <= currentIndex && currentIndex <= this.span.end;
    },
    hasIncident() {
      return isDefined(this.incident);
    },
    isIncidentEnd() {
      return this.hasIncident && this.incident.endOffset <= this.span.end;
    },
    isIncidentStart() {
      return this.hasIncident && this.incident.startOffset >= this.span.start;
    },
    incident() {
      return this.incidents &&
        this.incidents.find((i) => this.span.start < i.endOffset && this.span.end > i.startOffset);
    },
    hasSignal() {
      let matches = false;
      this.codeWordIndices.forEach((ci) => {
        if (this.span.start <= ci && ci <= this.span.end && !this.signalValue) {
          matches = true;
        }
      });
      return matches;
    },
    hasRedaction() {
      return this.span.redacted;
    },
    styleOverrides() {
      if (!this.hasIncident) return "";
      if (!isDefined(this.incident.color)) return "cursor:pointer;";
      return `cursor:pointer;background-color:${this.incident.color}33;color:${this.incident.color};`;
    },
  },
  data() {
    return {
      differs: false,
      isDeleted: false,
      isHighlighted: false,
    };
  },
  methods: {
    onInput(data) {
      const eTrimmed = data.trim();
      const spanTrimmed = this.span.content.trim();
      this.differs = eTrimmed !== spanTrimmed;
    },
    handleClick(e) {
      if (this.isEditing) return;
      if (this.editingSpeakerAt != -1 && this.editingSpeakerAt != this.speakerPos) {
        const params = {
          startPos: this.span.start,
          speakerPos: this.speakerPos,
          target: e.target,
        };
        this.$emit("editAt", params);
        return;
      }
      if (this.hasIncident) {
        this.$emit("incident", this.incident);
      } else if (this.keywordPosition > -1) {
        this.$emit("index", this.keywordPosition);
      } else {
        this.$emit("seek", this.span.startOffset);
      }
    },
    tabup(e) {
      const isForwards = !e.shiftKey;
      const x = document.querySelector(`span[data-key="${this.key}"]`);
      if (isForwards && x.parentNode.lastChild === x) {
        this.$emit("nextSpeaker");
        e.preventDefault();
      } else if (!isForwards && x.parentNode.firstChild === x) {
        this.$emit("prevSpeaker");
        e.preventDefault();
      }
    },
    enterup(e) {
      this.$emit("insertSpeaker", this.span.start);
    },
    escup(e) {
      this.$emit("endEditing", this.speakerPos);
    },
    delete() {
      this.isDeleted = true;
    },
    // onMouseEnter(e) {
    //   if (this.editingSpeakerAt != -1) {
    //     const section = e.target;
    //     section.classList.add("highlight-edit-section");
    //   }
    // },
    // onMouseLeave(e) {
    //   const section = e.target;
    //   section.classList.remove("highlight-edit-section");
    // },
  },
};
</script>
