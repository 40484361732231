const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const {getTimeZones, rawTimeZones, timeZonesNames, abbreviations} = require("@vvo/tzdb");

export const DatePlugin = {
  install(Vue, options) {
    Vue.prototype.$date = dayjs;
    Vue.prototype.$tz = {
      now: getTimeZones(),
      raw: rawTimeZones,
      names: timeZonesNames,
      abbr: abbreviations,
    };
  },
};
