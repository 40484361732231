<template>
  <div class="nomenclature mb-4">
    <h3 class="heading">
      Nomenclature
    </h3>
    <div class="items d-flex flex-wrap">
      <span
        v-if="form.length === 0"
        class="no-data"
      > No Data </span>

      <div
        v-for="(label, index) in form"
        :key="label.name"
        class="config-label d-flex align-items-center"
      >
        <ValidationProvider
          v-slot="{ passed, failed, errors }"
          rules="required"
          :name="renderLabelTitle(label.name)"
        >
          <base-input
            v-model="label.value"
            :data-index="index"
            required
            type="text"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            :placeholder="renderLabelTitle(label.name)"
            :label="renderLabelTitle(label.name)"
            @keyup="hasChanged"
          />
        </ValidationProvider>
      </div>
    </div>
    <div v-if="showActions">
      <base-button
        size="sm"
        type="primary"
        @click="saveNomenclatureChanges"
      >
        Save Nomenclature Changes
      </base-button>
      <base-button
        size="sm"
        type="secondary"
        @click="cloneConfigLabel"
      >
        Cancel
      </base-button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import {setUserConfig} from "../../../api/users";

extend("required", required);

export default {
  name: "ethos-nomenclature",
  data() {
    return {
      selected: "",
      form: [],
      showActions: false,
    };
  },
  computed: {
    ...mapGetters("data", ["getConfigLabels"]),
    ...mapGetters("auth", ["userEmail"]),
  },
  mounted() {
    this.cloneConfigLabel();
  },
  methods: {
    ...mapMutations("data", [
      "setConfig",
    ]),

    renderLabelTitle(name) {
      const str = name.replace(/([A-Z])/g, " $1").trim();
      const arr = str.split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      return arr.join(" ");
    },

    hasChanged() {
      this.showActions = false;
      if (JSON.stringify(this.getConfigLabels) !== JSON.stringify(this.form)) {
        this.showActions = true;
      }
    },

    cloneConfigLabel() {
      this.form = [];
      this.showActions = false;
      if (this.getConfigLabels.length > 0) {
        const clone = JSON.parse(JSON.stringify(this.getConfigLabels));
        this.form.push(...clone);
      }
    },

    saveNomenclatureChanges() {
      const nomenclature = {};
      const existingLabels = this.getConfigLabels;
      this.form.forEach((form) => {
        const matching = existingLabels.find((l) => l.name === form.name);
        if (matching.value !== form.value) {
          nomenclature[form.name] = form.value;
        }
      });

      setUserConfig(this.userEmail, nomenclature)
        .then((res) => {
          this.$notifySuccess("Saving Nomenclature Changes Successfully");
          this.showActions = false;
          this.setConfig(res);
        })
        .catch((ex) => {
          this.$notifyError("Saving Nomenclature Changes Failed", ex);
          this.cloneConfigLabel();
        });
    },
  },
};
</script>
