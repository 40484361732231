import {jsonRequest, isDefined, GET, POST, DELETE} from "./helpers";

export const getCaseKeywords = async (caseId) => {
  return await jsonRequest(GET, `CaseKeyword/Case/${caseId}`);
};
export const postCaseKeywords = async (caseId, wordCsv) => {
  if (!isDefined(caseId)) throw new Error("Case Id Required");
  if (!isDefined(wordCsv) || wordCsv.length === 0) throw new Error("Word required");
  return await jsonRequest(POST, `CaseKeyword/Case/${caseId}`, {body: JSON.stringify({wordCsv: wordCsv.join(",")})});
};
export const deleteCaseKeywords = async (caseKeywordId) => {
  if (!isDefined(caseKeywordId)) throw new Error("Keyword Id Required");
  return await jsonRequest(DELETE, `CaseKeyword/${caseKeywordId}`);
};
