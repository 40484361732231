<template>
  <card class="admin-users">
    <div class="d-inline-block w-100 p-0">
      <div class="my-3 d-flex align-items-center float-left">
        <h2 class="mr-3 d-inline-block mb-0">
          Users
        </h2>
        <base-button
          size="sm"
          type="primary"
          simple
          class="m-0"
          @click="addNewUser"
        >
          New User
          <plus-square-icon
            size="1.5x"
            class="ml-3"
          />
        </base-button>
      </div>
      <div class="float-right pt-3">
        <div class="search">
          <base-input
            :has-icon="true"
            addon-left-icon="fas fa-search"
            placeholder="Search for user"
            value=""
            @keyup.enter="handleSearch"
          />
        </div>
      </div>
    </div>

    <div class="paginated-table scrollable-y">
      <div class="m-0">
        <div v-for="user in users">
          <user-panel
            :user="user"
            @editClicked="handleEditUser"
            @deleteClicked="handleDeleteUser"
          />
        </div>
      </div>

      <table-pagination
        class="pagination mt-5"
        ref="pagination"
        :pagination="pagination"
        @changed="handlePaginationChange"
      />
    </div>

    <modal
      :show="showUserModal"
      header-classes="justify-content-center"
      footer-classes="px-4 py-3"
      class="modal-default"
    >
      <template v-if="showUserModal">
        <p
          slot="header"
          class="title"
        >
          {{ userModalHeader }}
        </p>
        <user-form
          :key="selectedUser ? selectedUser.email : 'new'"
          :type="selectedUser ? 'edit' : 'new'"
          :user="selectedUser"
          @complete="userModalComplete"
          @handleCanceled="handleUpdateCanceled"
        />
      </template>
    </modal>

    <confirmation-dialog
      :show="showDeleteConfirmation"
      :text="`Are you sure to delete ${selectedUser ? selectedUser.email : ''}?`"
      @okClicked="handleDelete()"
      @cancelClicked="handleCancelDelete()"
    />
  </card>
</template>

<script>
import {mapGetters} from "vuex";
import {PlusSquareIcon} from "vue-feather-icons";
import {deleteUser, getUsers} from "@/api";
import TablePagination from "@/components/TablePagination.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import UserForm from "./UserForm.vue";
import UserPanel from "./UserPanel.vue";

export default {
  name: "UserManagement",
  components: {
    UserForm,
    UserPanel,
    PlusSquareIcon,
    TablePagination,
    ConfirmationDialog,
  },
  data() {
    return {
      users: [],
      pagination: {
        TotalCount: 0,
      },
      showUserModal: false,
      selectedUser: null,
      searchText: null,
      showDeleteConfirmation: false,
    };
  },
  computed: {
    userModalHeader() {
      return this.selectedUser && this.selectedUser.email ? this.selectedUser.email : "New User";
    },
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    async loadUsers() {
      if (!this.$refs.pagination) {
        return;
      }

      try {
        const response = await getUsers(
          this.$refs.pagination.pageNumber,
          this.$refs.pagination.pageSize,
          this.searchText !== null && this.searchText !== "" ? this.searchText : null,
        );
        this.users = response.data;
        this.pagination = JSON.parse(response.pagination);
        this.loading = false;
      } catch (ex) {
        this.loading = false;
        this.$notifyError("Loading Users Data Failed", ex);
      };
    },
    addNewUser() {
      this.selectedUser = null;
      this.showUserModal = true;
    },
    handleSearch(e) {
      console.log(e.target.value);
      this.searchText = e.target.value;
      this.loadUsers();
    },
    handleEditUser(user) {
      this.selectedUser = user;
      this.showUserModal = true;
    },
    handleDeleteUser(user) {
      this.selectedUser = user;
      this.showDeleteConfirmation = true;
    },
    handleCancelDelete() {
      this.selectedUser = null;
      this.showDeleteConfirmation = false;
    },
    handleUpdateCanceled() {
      this.selectedUser = null;
      this.showUserModal = false;
    },
    handlePaginationChange() {
      this.loadUsers();
    },
    userModalComplete(user) {
      if (user) {
        for (let i = 0; i < this.users.length; i++) {
          if (this.users[i].email === user.email) {
            Object.assign(this.users[i], user);
          }
        }
      }
      this.loadUsers();
      this.selectedUser = null;
      this.showUserModal = false;
    },
    async handleDelete() {
      if (this.selectedUser) {
        if (this.isLoading) return;
        this.isLoading = true;

        try {
          await deleteUser(this.selectedUser.email);
          await this.loadUsers();
          this.$notifySuccess(`${this.selectedUser.email} Deleted Successfully`);
        } catch (ex) {
          this.$notifyError("Deleting User Failed", ex);
        } finally {
          this.isLoading = false;
        }
      }
      this.selectedUser = null;
      this.showDeleteConfirmation = false;
    },
  },
};
</script>

<style>
.pagination {
  border: grey 1px solid;
  padding: 15px
}
.search {
  width: 300px;
}
.scrollable-y {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>