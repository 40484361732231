<template>
  <modal
    :show="showModal"
    modal-content-classes="bg-dark-200"
    :centered="true"
    :show-close="false"
    body-classes="p-0"
    class="loading--modal"
    @close="showModal = false"
  >
    <div
      slot="header"
      class="d-flex align-items-center"
    >
      <h6 class="text-white mb-0 font-weight-bold">
        {{ copy }}
      </h6>
    </div>
    <template v-if="additionalCopy">
      <div class="h-divider mt-6" />
      <div class="p-5 text-white">
        {{ additionalCopy }}
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "./DashboardV2/Modal.vue";
export default {
  components: {
    Modal,
  },
  props: {
    copy: {
      type: String,
      default: "Loading",
    },
    additionalCopy: {
      type: String,
      default: "Loading",
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

<style scoped>
.loading--modal .modal-dialog {
  max-width: 600px !important;
}
.loading--active {
  background: #1f3300;
  border: 1px solid #426511 !important;
}
</style>
