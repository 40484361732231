import axios from "axios";
import {getInstance} from "../plugins/auth0-plugin.js";

export const isDefined = (fieldVal) => {
  return fieldVal !== null && fieldVal !== undefined;
};

const _apiBase = null;
const apiBase = () => {
  if (_apiBase) return _apiBase;
  const base = process.env.VUE_APP_API_BASE;
  return base.endsWith("/") ? base : `${base}/`;
};

const getAuthHeader = async () => {
  const auth = getInstance();
  if (auth.loading || !auth.isAuthenticated) return {}; // Unauthorized
  const token = await auth.getTokenSilently();
  return {
    Authorization: `Bearer ${token}`,
  };
};


export const GET = "Get";
export const POST = "Post";
export const PATCH = "Patch";
export const PUT = "Put";
export const DELETE = "Delete";
const SEND_JSON = {
  "Content-type": "application/json; charset=UTF-8",
};
const PATCH_JSON = {
  "Content-type": "application/json-patch+json; charset=UTF-8",
};

export const jsonRequestPaginated = async (method, apiPath, options, additionalQuery) => {
  if (!isDefined(options)) options = {};
  const pageNumber = options.pageNumber || 0;
  const pageSize = options.pageSize || 10;
  let filterQuery = isDefined(options.filter) ? `&Filter=${options.filter}` : "";
  if (additionalQuery && additionalQuery.length > 1) {
    if (!additionalQuery.startsWith("&")) additionalQuery = `&${additionalQuery}`;
    filterQuery += additionalQuery;
  }
  const querySeparator = apiPath.indexOf("?") === -1 ? "?" : "&";
  const response = await doRequest(
    method,
    `${apiPath}${querySeparator}PageNumber=${pageNumber}&PageSize=${pageSize}${filterQuery}`,
    options
  );
  return {
    data: await response.json(),
    pagination: response.headers.get("x-pagination"),
  };
};

export const jsonRequest = async (method, apiPath, options) => {
  const response = await doRequest(method, apiPath, options);
  if (options?.uploadOpts) {
    // axios used for uploads, so return axios response
    return response.data;
  }

  const json = await response.json();
  if (response.ok) {
    return json;
  }

  return {
    status: response.status,
    message: json.message,
  };
};

export const textRequest = async (method, apiPath, options) => {
  const response = await doRequest(method, apiPath, options);
  return await response.text();
};

export const blobRequest = async (method, apiPath, options) => {
  const response = await doRequest(method, apiPath, options);
  return await response.blob();
};

export const doRequest = async (
  method,
  apiPath,
  options
) => {
  // Validate inputs
  if (apiPath && [GET, POST, PUT, PATCH, DELETE].indexOf(method) === -1) throw new Error("Invalid method");

  if (!options) options = {};

  // Acquire Auth if required
  const authHeader = !options.disableAuthHeader ? await getAuthHeader() : {};

  // Clean the path
  const path = apiPath.startsWith("/") ? apiPath.slice(1) : apiPath;

  const contentTypeHeader = method === GET ?
    {} :
    (
      method === PATCH ?
        PATCH_JSON :
        options.postContentHeader || SEND_JSON
    );

  // Setup the props
  const props = {
    method,
    headers: Object.assign(
      {},
      contentTypeHeader,
      authHeader,
      options.headers ? options.headers : {}),
  };
  if (!options.disableAuthHeader) props.credentials = "include";
  if (options.body) props.body = options.body;

  // Make request
  if (options.uploadOpts) {
    return await doUpload(`${apiBase()}${path}`, props, options);
  } else {
    return await fetch(`${apiBase()}${path}`, props); // TODO: - Catch and handle the error
  }
};


export const doUpload = async (
  apiPath,
  props,
  {uploadOpts: {uploadProgressCb, uploadController}}
) => {
  props.data = props.body;
  props.signal = uploadController.signal;
  delete props.credentials;
  if (uploadProgressCb) props.onUploadProgress = uploadProgressCb;
  return axios.post(apiPath, props.data, props);
};
