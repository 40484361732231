<template>
  <div
    class="slider"
    :class="`slider-${type} ${classes}`"
    :disabled="disabled"
  />
</template>
<script>
import noUiSlider from "nouislider";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "slider",
  props: {
    value: {
      type: [String, Array, Number],
      description: "slider value",
      default: null,
    },
    classes: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "whether the slider is disabled",
    },
    start: {
      type: [Number, Array],
      default: 0,
      description:
        "[noUi Slider start](https://refreshless.com/nouislider/slider-options/#section-start)",
    },
    connect: {
      type: [Boolean, Array],
      default: () => [true, false],
      description:
        "[noUi Slider connect](https://refreshless.com/nouislider/slider-options/#section-connect)",
    },
    range: {
      type: Object,
      default: () => {
        return {
          min: 0,
          max: 100,
        };
      },
      description:
        "[noUi Slider range](https://refreshless.com/nouislider/slider-values/#section-range)",
    },
    type: {
      type: String,
      default: "",
      description: "slider type (color) ",
    },
    options: {
      type: Object,
      default: () => {
        return {};
      },
      description:
        "[noUi Slider options](https://refreshless.com/nouislider/slider-options/)",
    },
  },
  data() {
    return {
      ignoreValue: false,
    };
  },
  methods: {
    createSlider() {
      noUiSlider.create(this.$el, {
        start: this.value || this.start,
        connect: this.connect,
        range: this.range,
        ...this.options,
      });
      const slider = this.$el.noUiSlider;
      slider.on("slide", () => {
        const value = slider.get();
        if (value !== this.value) {
          this.$emit("input", value);
        }
      });
      slider.on("start", () => {
        this.ignoreValue = true;
      });
      slider.on("end", () => {
        this.ignoreValue = false;
      });
      slider.on("change", () => {
        const value = slider.get();
        this.$emit("changed", value);
      });
    },
  },
  mounted() {
    this.createSlider();
  },
  watch: {
    value(newValue, oldValue) {
      if (this.ignoreValue) return;
      const slider = this.$el.noUiSlider;
      const sliderValue = slider.get(true);
      if (Array.isArray(sliderValue) && Array.isArray(newValue)) {
        if (
          sliderValue.length === newValue.length &&
          !oldValue.every((v, i) => v === newValue[i])
        ) {
          slider.set(newValue, false, true);
        }
      } else if (newValue !== oldValue && sliderValue !== newValue) {
        slider.set(newValue);
      }
    },
  },
};
</script>
