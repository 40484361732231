<template>
  <div class="ethos-container">
    <div class="paginated-table">
      <el-table
        class="mt-4"
        :data="data"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          label="Name"
          min-width="200"
          sortable
        >
        <template slot-scope="scope">
          <span  class="name-col-cursor"
          @click="() => $emit('view', {data: scope.row, type: type})">{{scope.row.name}}</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="data"
          label="Data"
          min-width="300"
          sortable
        >
          <template slot-scope="scope">
            <span>
              <template v-for="(nbold, nbi) in scope.row.data.split('</b>')">
                <template v-for="(part, nbii) in nbold.split('<b>')">
                  <strong
                    v-if="nbii === 1"
                    :key="scope.row.id + '-' + nbi + '-' + nbii"
                  >{{ part }}</strong>
                  <span
                    v-else
                    :key="scope.row.id + '-' + nbi + '-' + nbii"
                  >{{ part }}</span>
                </template>
              </template>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createdOn"
          label="Added On"
          width="250"
          sortable
        >
          <template slot-scope="scope">
            {{ $date(scope.row.createdOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="modifiedOn"
          label="Modified On"
          width="250"
          sortable
        >
          <template slot-scope="scope">
            {{ $date(scope.row.modifiedOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
          </template>
        </el-table-column>
      </el-table>
      <table-pagination
        ref="pagination"
        :pagination="paginationData"
        @changed="handlePaginationChange"
      />
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import "src/assets/sass/custom/paginated-table.scss";
import TablePagination from "../../../components/TablePagination.vue";

export default {
  name: "list-transcriptions",
  components: {
    TablePagination,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: null,
    },
    pagination: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      paginationData: {
        TotalCount: 0,
      },
    };
  },
  watch: {
    pagination: {
      immediate: true,
      handler(to, from) {
        this.paginationData = to;
      },
    },
  },
  computed: {
    ...mapGetters("data", ["userTimezone"]),
  },
  methods: {
    handlePaginationChange() {
      this.$nextTick(() => {
        this.paginationData.pageNumber = this.$refs.pagination.pageNumber;
        this.paginationData.pageSize = this.$refs.pagination.pageSize;
        this.$emit("paginationChanged", {pagination: this.paginationData, type: this.type});
      });
    },
  },
};
</script>
<style>
.name-col-cursor {
  cursor: pointer;
}
</style>
