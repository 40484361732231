const mutations = {
  setAuth(state, auth) {
    state.hasCalled = true;
    if (!auth) return;
    state.id = auth.id;
    state.email = auth.email;
    state.fullName = auth.fullName;
    state.firstName = auth.firstName;
    state.lastName = auth.lastName;
    state.title = auth.title;
    state.roles = auth.roles;
  },
  reset(state) {
    state.hasCalled = false;
    state.id = null;
    state.email = null;
    state.fullName = null;
    state.firstName = null;
    state.lastName = null;
    state.title = null;
    state.roles = [];
  },
};

export default mutations;
