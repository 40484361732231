<template>
  <div
    class="pager"
  >
    <el-pagination
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="sizes, slot, prev, next"
      :total="pagination.TotalCount"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
      <div
        class="oop"
      >
        {{ 1 + (pageNumber-1)*pageSize }}-{{ maxRet }} of {{ pagination.TotalCount }}
      </div>
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "table-pagination",
  // Creating object with totalcount 0, can't default null
  props: {
    pagination: {
      type: Object,
      default() {
        return {TotalCount: 0};
      },
    },
  },
  data() {
    return {
      pageSizes: [10, 25, 50, 100, 250],
      pageSize: 10,
      pageNumber: 1,
    };
  },
  computed: {
    maxRet() {
      return Math.min(this.pageNumber * this.pageSize, this.pagination.TotalCount);
    },
  },
  methods: {
    // This setPage function needs to be able to take new values and set them locally
    setPage(newPageNumber, newPageSize) {
      this.pageNumber = newPageNumber;
      this.pageSize = newPageSize;
      this.emit();
    },
    emit() {
      // Tell the parent about these new values
      this.$emit("changed", {pageNumber: this.pageNumber, pageSize: this.pageSize, maxRet: this.maxRet});
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.emit();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.emit();
    },
  },
};
</script>
