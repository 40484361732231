import Notifications from "./Notifications.vue";

const NotificationStore = {
  state: [], // here the notifications will be added
  settings: {
    overlap: false,
    verticalAlign: "top",
    horizontalAlign: "right",
    type: "info",
    timeout: 5000,
    closeOnClick: true,
    showClose: true,
    order: "reverse", // normal | reverse (When reverse, each notification will be added on top)
    persist: false,
    count: 0,
  },
  setOptions(options) {
    this.settings = Object.assign(this.settings, options);
  },
  decrement(id) {
    const nIndex = this.state.findIndex((n) => n.id === id);
    if (nIndex !== -1) {
      const n = this.state[nIndex];
      if (n && n.count > 1) {
        // Drop the notifications counter
        this.state[nIndex].count--;
      } else {
        // Finished with it - remove it
        this.state.splice(nIndex, 1);
      }
    }
  },
  removeNotification(timestamp) {
    const indexToDelete = this.state.findIndex((n) => n.timestamp === timestamp);
    if (indexToDelete !== -1) {
      this.state.splice(indexToDelete, 1);
    }
  },
  addNotification(notification) {
    if (typeof notification === "string" || notification instanceof String) {
      notification = {message: notification};
    }
    notification.timestamp = new Date();
    notification.timestamp.setMilliseconds(
      notification.timestamp.getMilliseconds() + this.state.length
    );
    // Random ID, if one is provided it will override this
    const id = Math.floor(Math.random() * 10000) + "-" + Math.floor(Math.random() * 10000);

    notification = Object.assign({id}, this.settings, notification);
    const existing = this.state.find((n) => n.id == notification.id);
    if (existing) {
      // Increment counter if persisting
      if (existing.persist && existing.count > 0) existing.count++;
      return; // Do not duplicate
    }
    if (this.settings.order === "reverse") {
      this.state.unshift(notification);
    } else {
      this.state.push(notification);
    }
  },
  notify(notification) {
    if (Array.isArray(notification)) {
      notification.forEach((notificationInstance) => {
        this.addNotification(notificationInstance);
      });
    } else {
      this.addNotification(notification);
    }
  },
};

const NotificationsPlugin = {
  install(Vue, options) {
    const app = new Vue({
      data: {
        notificationStore: NotificationStore,
      },
      methods: {
        notify(notification) {
          this.notificationStore.notify(notification);
        },
        notifyError(message, ex) {
          this.notificationStore.notify({
            type: "danger",
            message,
          });
          console.error(message, ex);
        },
        notifyWarn(message, ex) {
          this.notificationStore.notify({
            type: "warning",
            message,
          });
          console.warn(message, ex);
        },
        notifySuccess(message) {
          this.notificationStore.notify({
            type: "success",
            message,
          });
        },
      },
    });
    Vue.prototype.$notify = app.notify;
    Vue.prototype.$notifyError = app.notifyError;
    Vue.prototype.$notifyWarn = app.notifyWarn;
    Vue.prototype.$notifySuccess = app.notifySuccess;
    Vue.prototype.$notifications = app.notificationStore;
    Vue.component(Notifications.name, Notifications);
    if (options) {
      NotificationStore.setOptions(options);
    }
  },
};

export default NotificationsPlugin;
