<template>
  <div class="wrapper wrapper-full-page pt-4 px-4">
    <card class="card jumbotron text-center">
      <img
        class="mb-4"
        style="height:120px;"
        src="img/ethos-logo.svg"
      >
      <h1>{{ code }}</h1>
      <p>{{ message }}</p>
    </card>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  computed: {
    code() {
      return this.$route.params && this.$route.params.code || "ERR";
    },
    message() {
      return this.$route.query && this.$route.query.m || "Something went wrong!";
    },
  },
};
</script>
