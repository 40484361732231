// See: https://vuex.vuejs.org/guide/modules.html
import {EMPTY_SEARCH_WRAPPER} from "../../../util/consts";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const data = {
  namespaced: true,
  state: {
    config: {
      availableWorkflows: [],
      selectedWorkflows: [],
      availableResultWorkflows: [],
      selectedResultWorkflows: [],
      availableLanguages: [],
      availablePeopleGroups: [],
      availableCaseRoles: [],
      availableElementStates: [],
      availableCaseStates: [],
      availableUploadTypes: [],
      labels: [],
      autoApprovalRoles: [],
      timeZone: "America/Vancouver",
      displayNotes: false,
      displayIncidents: false,
      displayIncidentColors: false,
    },
    showUploadsPopover: false,
    highlightPaths: [],
    uploads: {},
    cases: {},
    caseSummaries: Object.assign({}, EMPTY_SEARCH_WRAPPER),
    caseUploadSummaries: Object.assign({}, EMPTY_SEARCH_WRAPPER),
    caseUploadClipSummaries: Object.assign({}, EMPTY_SEARCH_WRAPPER),
    caseUploadEvidenceSummaries: Object.assign({}, EMPTY_SEARCH_WRAPPER),
    caseClipSummaries: {},
    caseEvidenceSummaries: {},
    caseActorAttributions: {},
    caseUserAttributions: {},
    actors: {},
    actorMap: {},
    userMap: {},
    codeWordGroups: {},
    people: {},
    loadedPeople: false,
    loadedPeopleGroups: [],
    transcriptions: {},
    searchKeyword: "",
    searches: [],
    uploadResponses: [],
    workflowResponses: [],
    seachCount: 0,
    searchRadioOpts: [
      {name: "Transcriptions", label: "Transcription Results", key: "transcriptions"},
      {name: "Cases", label: "Cases", key: "cases"},
      {name: "Uploads", label: "Upload Name", key: "uploads"},
    ],
    caseClip: {},
    breadcrumbHints: {},
    highlightSnippet: null,
  },
  actions,
  getters,
  mutations,
};

export default data;
