<template>
  <div ref="editor" />
</template>
<script>
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
export default {
  name: "rtf-viewer",
  props: {
    json: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.editor = new Quill(this.$refs.editor, {
      showToolbar: false,
      readOnly: true,
    });
    if (this.$props.json) {
      this.editor.setContents(this.$props.json, "silent");
    }
  },
};
</script>
