import {jsonRequest, jsonRequestPaginated, isDefined, GET, POST, blobRequest, PUT, DELETE} from "./helpers";

export const postUpload = async (
  name, language, caseName,
  minSpeakerCount, maxSpeakerCount,
  storagePath, file, lastModifiedDate,
  uploadOpts, remark, uploadType
) => {
  const fd = new FormData();
  if (name) fd.append("name", name);
  if (language) fd.append("language", language);
  if (caseName) fd.append("caseName", caseName);
  if (isDefined(minSpeakerCount)) fd.append("minSpeakerCount", minSpeakerCount);
  if (isDefined(maxSpeakerCount)) fd.append("maxSpeakerCount", maxSpeakerCount);
  if (storagePath) fd.append("storagePath", storagePath);
  if (file) fd.append("uploadFile", file, file.name);
  if (isDefined(lastModifiedDate)) fd.append("startTimeStampISO", lastModifiedDate);
  if (isDefined(remark)) fd.append("remark", remark);
  if (isDefined(uploadType)) fd.append("uploadType", uploadType);
  return await jsonRequest(POST, "Uploads", { // TODO: - Should probably be PUT
    body: fd,
    postContentHeader: {},
    uploadOpts,
  });
};

export const putUpload = async (
  uploadId, name, description, tags, startTimeStamp, coordinates, status, remark, starred, eventOn
) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  if (!isDefined(name) && !isDefined(description) && !isDefined(tags) && !isDefined(startTimeStamp) && !isDefined(coordinates) && !isDefined(status) && !isDefined(remark) && !isDefined(starred)) throw new Error("Nothing to update");
  const data = {};
  if (isDefined(name)) data.name = name;
  if (isDefined(description)) data.description = description;
  if (isDefined(tags) && Array.isArray(tags)) data.tags = tags;
  if (isDefined(startTimeStamp)) data.startTimeStamp = startTimeStamp;
  if (isDefined(coordinates)) data.coordinates = coordinates;
  if (isDefined(status)) data.status = status;
  if (isDefined(remark)) data.remark = remark;
  if (isDefined(starred)) data.starred = starred;
  if (isDefined(eventOn)) data.eventOn = eventOn;
  return await jsonRequest(PUT, `Uploads/${uploadId}`, {body: JSON.stringify(data)});
};

export const getUploadById = async (uploadId, withTranscription) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  return await jsonRequest(GET, `Uploads/${uploadId}?Transcription=${withTranscription ? "true" : "false"}`);
};

export const getUploadByIdAndUrl = async (uploadId) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  const json = await jsonRequest(GET, `Uploads/${uploadId}`);
  if (isDefined(json) && isDefined(json.signedUrl)) {
    return {
      signedUrl: json.signedUrl,
    };
  }
  throw new Error("Unable to load Upload Url");
};

export const getUploadsForLookup = async () => await jsonRequest(GET, "Uploads/List");

export const getUploadCases = async (uploadId) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  return await jsonRequest(GET, `Uploads/${uploadId}/Cases`);
};

export const getUploadCasesData = async (uploadId) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  return await jsonRequest(GET, `Uploads/${uploadId}/Cases/Data`);
};

export const getUploadsTranscriptions = async (uploadId) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  return await jsonRequest(GET, `Uploads/${uploadId}/Transcriptions`);
};

export const getUploads = async (pageNumber, pageSize, filter, caseId) => {
  return await jsonRequestPaginated(GET, "Uploads", {pageNumber, pageSize, filter}, isDefined(caseId) ? `CaseId=${caseId}` : undefined);
};

export const getUploadIncidents = async (uploadId) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  return await jsonRequest(GET, `Uploads/${uploadId}/Incidents`);
};

export const downloadUpload = async (uploadId, original) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  return await blobRequest(GET, `Transcription/${uploadId}/Download${original === true ? "?original=true" : ""}`);
};

export const getUploadsPeopleByGroup = async (uploadId, groupName) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  if (!isDefined(groupName)) throw new Error("Group Name Required");
  return await jsonRequest(GET, `Uploads/${uploadId}/People?groupName=${groupName}`);
};

export const getClips = async (uploadId) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  return await jsonRequest(GET, `Uploads/${uploadId}/Clips`);
};

export const getClip = async (uploadId, clipId) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  if (!isDefined(clipId)) throw new Error("Clip Id Required");
  const json = await jsonRequest(GET, `Uploads/${uploadId}/Clip/${clipId}`);
  if (isDefined(json) && isDefined(json.clip) && isDefined(json.signedUrl)) {
    return {
      ...json.clip,
      signedUrl: json.signedUrl,
    };
  }
  throw new Error("Unable to load Clip");
};

export const putClip = async (uploadId, startOffset, endOffset, title, description, status, starred) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  if (!isDefined(startOffset) || !isDefined(endOffset)) throw new Error("Offsets Required");
  if (!isDefined(title) && !isDefined(description) && !isDefined(status) && !isDefined(starred)) throw new Error("Nothing to update");
  const data = {};
  if (isDefined(title)) data.title = title;
  if (isDefined(description)) data.description = description;
  if (isDefined(status)) data.status = status;
  if (isDefined(starred)) data.starred = starred;

  // We use JSON.stringify to convert the data of title and content to something we can send
  return await jsonRequest(PUT, `Uploads/${uploadId}/Clip/${startOffset}/To/${endOffset}`, {body: JSON.stringify(data)});
};

export const deleteClip = async (uploadId, clipId) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  if (!isDefined(clipId)) throw new Error("Clip Id Required");
  return await jsonRequest(DELETE, `Uploads/${uploadId}/Clip/${clipId}`);
};


export const downloadClip = async (uploadId, clipId) => {
  if (!isDefined(uploadId)) throw new Error("Upload Id Required");
  if (!isDefined(clipId)) throw new Error("Clip Id Required");
  return await blobRequest(GET, `Uploads/${uploadId}/Clip/${clipId}/Download`);
};
