<template>
  <card
    class="box-card"
    :class="{ 'is-disabled': isUserDisabled }"
  >
    <div
      slot="header"
      class="clearfix"
    >
      <div class="float-left">
        <h3>{{ user.title }} {{ user.firstName }} {{ user.lastName }}</h3>
      </div>
      <base-button
        size="sm"
        type="primary"
        simple
        class="m-0 float-right"
        :disabled="isUserDisabled || user.email === userEmail"
        @click="deleteClicked"
      >
        Delete
        <delete-icon
          size="1.0x"
          class="ml-3"
        />
      </base-button>
      <base-button
        size="sm"
        type="primary"
        simple
        class="ml-5 mt-0 float-right"
        @click="editClicked"
      >
        Edit
        <edit-icon
          size="1.0x"
          class="ml-3"
        />
      </base-button>
    </div>
    <div class="ml-4">
      <div class="m-0 float-left">
        <div class="email">
          <div class="mb-1">
            <span><b>Email Address</b></span>
          </div>
          <div>
            <span class="">{{ user.email }}</span>
          </div>
        </div>
        <div class="roles">
          <div class="mb-3">
            <span><b>Roles</b></span>
          </div>
          <div>
            <span>
              <el-tag
                v-for="role in user.roles"
                :key="role"
                effect="dark"
                type="primary"
              >
                {{ role }}
              </el-tag>
            </span>
          </div>
        </div>
      </div>
      <div class="m-0 float-left created">
        <span class="label">Created</span>
        <card class="inner-box">
          <div class="pl-2">
            <span>
              {{ $date(user.createdOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
            </span>
          </div>
          <div class="pl-2">
            <span>
              {{ user.createdBy }}
            </span>
          </div>
        </card>
      </div>
      <div class="ml-5 float-left modified">
        <span class="label">Modified</span>
        <card class="inner-box">
          <div>
            <span class="pl-2">
              {{ $date(user.modifiedOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
            </span>
          </div>
          <div class="pl-2">
            <span>
              {{ user.modifiedBy }}
            </span>
          </div>
        </card>
      </div>
    </div>
  </card>
</template>
<script>
import {mapGetters} from "vuex";
import {EditIcon, DeleteIcon} from "vue-feather-icons";

export default {
  name: "UserPanel",
  components: {
    EditIcon,
    DeleteIcon,
  },
  props: {
    user: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  computed: {
    ...mapGetters("auth", [
      "userEmail",
    ]),
    ...mapGetters("data", [
      "userTimezone",
    ]),
    isUserDisabled() {
      return this.user && this.user.roles.length === 0;
    },
  },
  methods: {
    editClicked() {
      this.$emit("editClicked", this.user);
    },
    deleteClicked() {
      this.$emit("deleteClicked", this.user);
    },
  },
};
</script>
<style scoped>
.box-card {
  width: 100%;
  margin: 20px auto;
  border:  1px solid grey;
  border-radius: 20px;
}
.box-card.is-disabled {
  border: 1px solid red;
}
.ethos .box-card {
  padding: 10px;
}
.ethos .inner-box {
  padding: 10px;
  margin-bottom: 10px;
}
.inner-box {
  padding: 5px;
  margin-left: 30px;
  margin-top: 10px;
  border: 1px solid grey;
  border-radius: 20px;
}
.email {
  float: left;
  width: 200px;
}
.roles {
  float: left;
  width: 200px;
}
.created {
  width: 300px;
}
.modified {
  width: 300px;
}
.label {
  font-weight: bold;
  margin-left: 30px;
  margin-bottom: 30px;
}
.disabled {
  background: grey;
}
</style>
