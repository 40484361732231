import { render, staticRenderFns } from "./ViewUpload.vue?vue&type=template&id=01804466"
import script from "./ViewUpload.vue?vue&type=script&lang=js"
export * from "./ViewUpload.vue?vue&type=script&lang=js"
import style0 from "./ViewUpload.vue?vue&type=style&index=0&id=01804466&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports