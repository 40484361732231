import Vuex from "vuex";
import auth from "./modules/auth";
import data from "./modules/data";
import actions from "./actions";

let _store = null;
const getStore = () => {
  if (_store === null) {
    _store = new Vuex.Store({
      state: {},
      modules: {
        auth,
        data,
      },
      actions,
    });
  }
  return _store;
};

export default getStore;
