<template>
  <div class="d-flex flex-column">
    <div class="flex-grow-1 overflow-auto">
      <div
        v-if="!isMini"
        class="p-5"
      >
        <h6
          v-if="isViewCase && !isTitleEdit"
          class="case-input--title text-white"
          @click="enableTitleEdit()"
        >
          {{ (caseModel && caseModel.title) || "Add Case Title" }}
        </h6>
        <input
          v-else
          ref="titleInput"
          v-model="caseModel.title"
          type="text"
          class="case-input case-input--title"
          placeholder="Add Case Title"
          @blur="setCaseTitle"
          @keyup.enter="setCaseTitle"
        >
        <h5 class="text-lightblue d-flex">
          <div class="nowrap">
            Case Number:
          </div>
          <input
            v-model="caseName"
            type="text"
            class="case-input font-16 text-lightblue"
            @blur="setCaseName"
            @keyup.enter="setCaseName"
          >
        </h5>
        <read-more
          v-if="isViewCase && !isDescriptionEdit"
          @onClick="enableDescriptionEdit()"
        >
          <div class="prewrap pre-wrap-adjust">
            {{
              (caseModel && caseModel.description) ||
                "Add a brief description here..."
            }}
          </div>
        </read-more>
        <textarea
          v-else
          ref="descriptionInput"
          v-model="caseModel.description"
          type="text"
          rows="3"
          class="case-input font-16"
          placeholder="Add a brief description here..."
          @blur="setCaseDescription"
        />
        <div class="my-1 form-group">
          <el-select
            v-model="caseModel.status"
            placeholder="Filter by priority"
            class="select-primary dark"
            effect="dark"
            popper-class="select-primary"
            @change="setCaseStatus"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="h-divider" />
      <div v-if="isMini">
        <base-button
          v-for="(menu, i) in menus"
          :key="i"
          link
          class="mb-0"
          :class="{ 'bg-active': isMenuActive(menu) }"
          @click="openMenu(menu)"
        >
          <component
            :is="menu.icon"
            class="text-white"
          />
        </base-button>
      </div>
      <div
        v-else
        class="p-5"
      >
        <div
          v-for="(menu, i) in menus"
          :key="i"
          class="d-flex align-items-center py-2 px-3 mb-2 rounded-lg font-weight-bold cursor-pointer"
          :class="{ 'bg-active': isMenuActive(menu) }"
          @click="openMenu(menu)"
        >
          <component
            :is="menu.icon"
            class="text-white"
          />
          <div class="flex-grow-1 text-white ml-3">
            {{ menu.name }}
          </div>
          <div v-if="menu.count">
            <span class="badge bg-primary-200 badge-pill">{{
              menu.count
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../../Cards/Card.vue";
import {
  FileIcon,
  KeyIcon,
  UsersIcon,
  SearchIcon,
  UploadIcon,
  BookmarkIcon,
  CheckCircleIcon,
} from "vue-feather-icons";
import ReadMore from "../ReadMore.vue";
import {putCase} from "../../../api";
import {isDefined} from "../../../api/helpers";
import BaseInput from "../../Inputs/BaseInput.vue";
import {mapGetters, mapMutations} from "vuex";
import {ethosRouteNames} from "../../../routes/routeNames";

export default {
  components: {Card, ReadMore, BaseInput, SearchIcon},
  inject: ["isMini"],
  props: {
    caseSummary: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isTitleEdit: false,
      isDescriptionEdit: false,
      activeMenu: 1,
      caseModel: {
        title: null,
        name: null,
        description: null,
      },
      caseName: null,
    };
  },
  watch: {
    caseSummary: {
      immediate: true,
      handler(newVal) {
        this.caseModel = newVal;
        this.caseName = newVal?.name || "";
      },
    },
  },
  computed: {
    ...mapGetters("data", ["availableCaseStates"]),
    isViewCase() {
      return this.$route.matched.find((i) => i.name == "ViewCase") != null;
    },
    statusOptions() {
      return this.availableCaseStates.map((s) => {
        return {
          name: s,
          value: s,
        };
      });
    },
    menus() {
      return [
        {
          icon: UploadIcon,
          name: "Uploads",
          count: this.caseSummary?.uploadsCount || 0,
          route: "CaseUploads",
          children: [],
        },
        {
          icon: BookmarkIcon,
          name: "Clips",
          count: this.caseSummary?.clipsCount || 0,
          route: "CaseClips",
          children: [],
        },
        {
          icon: CheckCircleIcon,
          name: "Evidence",
          count: this.caseSummary?.evidenceCount || 0,
          route: "CaseEvidence",
          children: ["CaseEvidenceList"],
        },
        {
          icon: KeyIcon,
          name: "Keywords",
          count: this.caseSummary?.caseKeywordCount || 0,
          route: "CaseKeywords",
          children: [],
        },

        {
          icon: UsersIcon,
          name: "People",
          count:
            (this.caseSummary?.userAttributionCount || 0) +
            (this.caseSummary?.actorAttributionCount || 0),
          route: "CasePeople",
          children: [],
        },
        
      ];
    },
  },
  mounted() {
    this.caseModel = this.caseSummary;
    this.caseName = this.caseSummary?.name || "";
  },
  methods: {
    ...mapMutations("data", ["setSearchKeyword"]),
    isMenuActive({route, children}) {
      const name = this.$route.name;
      return route == name || children.indexOf(name) != -1;
    },
    enableTitleEdit() {
      this.isTitleEdit = true;
      this.$nextTick(() => {
        this.$refs.titleInput.focus();
      });
    },
    enableDescriptionEdit() {
      this.isDescriptionEdit = true;
      this.$nextTick(() => {
        this.$refs.descriptionInput.focus();
      });
    },
    openMenu({route}) {
      this.$router.replace({
        name: route,
      });
    },
    setCaseName() {
      if (!isDefined(this.caseName)) return;
      this.updateCase();
    },
    setCaseTitle() {
      if (!isDefined(this.caseModel.title)) return;
      this.updateCase();
    },
    setCaseDescription() {
      if (!isDefined(this.caseName) || !isDefined(this.caseModel.title)) return;
      this.updateCase();
    },
    setCaseStatus() {
      if (!isDefined(this.caseName) || !isDefined(this.caseModel.title)) return;
      this.updateCase();
    },
    reload() {
      this.$emit("reload");
    },
    updateCase() {
      this.loading = true;
      this.doUpdateCase().then((response) => {
        this.caseModel = response;
        this.caseName = response.name || "";
        this.$emit("updatedCase", response.id);
      }).catch((ex) => {
        this.$notifyError("Saving Case Data Failed", ex);
      }).finally(() => {
        this.loading = false;
        this.isTitleEdit = false;
        this.isDescriptionEdit = false;
      });
    },
    async doUpdateCase() {
      return await putCase(
        this.caseModel.name,
        this.caseModel.title,
        this.caseName,
        this.caseModel.description,
        this.caseModel.remark,
        this.caseModel.starred,
        this.caseModel.status
      );
    },
    search(e) {
      this.setSearchKeyword({
        keyword: e.target.value,
      });
      if (e.target.value != "" && this.$route.path.indexOf("search") === -1) {
        this.$router.replace({
          name: ethosRouteNames.CaseSearch,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.case-input {
  background: transparent;
  border: 0px;
  width: 100%;
  color: white;
  outline: none;
  font-weight: 400;
  resize: none;
  &::placeholder {
    color: white;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: white;
  }

  &::-ms-input-placeholder {
    color: white;
  }
  &--title {
    font-size: 20px;
  }
}
.case-menu--active {
  background: black !important;
  border: 2px solid var(--primary) !important;
}
.pre-wrap-adjust {
  margin-left: -0.2vw;
}
</style>
