<template>
  <div class="d-flex flex-column h-100">
    <case-back-div
      v-if="false"
      :to="backTo"
    />
    <route-breadcrumb />
    <div class="d-flex h-100 main-height-calc">
      <collapse-container
        classes="d-flex flex-row-reverse"
        collapse-button-classes="position-relative"
        collapse-button-icon-classes="position-absolute top-0 w-100"
      >
        <tabs
          ref="tabs"
          type="info"
          tab-nav-wrapper-classes="p-2"
          tab-content-classes="w-100 h-100 p-0"
          vertical
          class="d-flex h-100"
          font="Roboto"
          @tab-clicked="tabClicked"
        >
          <!-- Use custom handler to avoid tab selection - just want to handle the form display -->
          <!-- hidden tab is a workaround for sort order changing during collapsing -->
          <tab-pane
            id="-1"
            is-hidden
          />
          <tab-pane id="transcript">
            <span slot="label">
              <file-text-icon size="1.5x" />
              Transcript
            </span>
          </tab-pane>
          <tab-pane id="clips">
            <span slot="label">
              <scissors-icon size="1.5x" />
              Clips
            </span>
          </tab-pane>
          <!-- <tab-pane
            id="people"
          >
            <span slot="label">
              <user-plus-icon size="1.5x" />
              People
            </span>
          </tab-pane> -->
          <!-- <tab-pane
            v-if="displayNotes"
            id="notes-container"
            has-submenu
          >
            <span slot="label">
              <el-menu
                ref="noteMenu"
                @select="menuSelected"
              >
                <el-submenu
                  index="notes-container-0"
                  vertical-align="left"
                >
                  <template slot="title">
                    <span
                      slot="title"
                    >
                      <file-icon
                        size="1.5x"
                        class="mr-2"
                      />
                      Notes
                    </span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item
                      index="notes-container-1"
                      @click="added"
                    >
                      <plus-icon
                        size="1.5x"
                        class="mr-2"
                        style="margin-left: -8px"
                      />
                      Add Note
                    </el-menu-item>
                    <el-menu-item
                      index="notes-container-2"
                      @click="viewed"
                    >
                      <eye-icon
                        size="1.5x"
                        class="mr-2"
                        style="margin-left: -8px"
                      />
                      View Notes
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>

            </span>
          </tab-pane> -->
          <tab-pane
            id="profile"
            :custom-handler="editMetadata"
          >
            <span slot="label">
              <edit-2-icon
                class="mr-2"
                size="1.5x"
              />
              Profile
            </span>
          </tab-pane>
          <tab-pane
            v-if="shouldShowExportTabs"
            id="export-audio"
            :key="dloading"
            :custom-handler="downloadOriginal"
          >
            <span slot="label">
              <loader-icon
                v-if="dloading"
                size="1.5x"
              />
              <download-icon
                v-else
                size="1.5x"
              />
              Export Audio
            </span>
          </tab-pane>
          <tab-pane
            v-if="shouldShowExportTabs"
            id="export-doc"
            :custom-handler="(e) => $refs.routerView.export(e)"
          >
            <span
              slot="label"
            >
              <download-icon
                class="mr-2"
                size="1.5x"
              />
              Export Doc
            </span>
          </tab-pane>
          <tab-pane
            v-if="displayIncidents"
            id="incidents"
          >
            <span slot="label">
              <briefcase-icon size="1.5x" />
              Incidents
            </span>
          </tab-pane>
          <span
            slot="empty"
            class="text-white font-weight-bold  ml-5"
          >
            Show Audit Info
          </span>
          <base-switch
            slot="empty"
            v-model="showChangeReq"
            class="ml-10 mt-2"
            type="secondary"
            @input="toggleMode"
          />
        </tabs>
        <add-note
          ref="addNoteForm"
          header="Add New Note"
          :upload-id="uploadId"
        />
        <metadata-form-modal
          ref="metadataForm"
        />
      </collapse-container>
      <!-- <presentation-view
      display="1x1"
      :upload-id="uploadId"
    /> -->
      <router-view
        v-if="!clickedView"
        ref="routerView"
      />
      <div
        v-else
        class="flex-grow-1"
      >
        <notes />
      </div>
      <download-blob
        ref="dlblob"
        :blob="dlFileBlob"
        :file-name="dlFileName"
        :content-type="dlFileContentType"
        :visible="false"
      />
    </div>
  </div>
</template>

<script>

import {TabPane, Tabs, BaseSwitch} from "src/components";
import {mapGetters, mapMutations} from "vuex";
import {getUploadById, downloadUpload} from "../../../api";
import {isDefined} from "../../../api/helpers";
import {ethosRouteNames} from "../../../routes/routeNames";
import Notes from "../Notes/Notes.vue";
import AddNote from "../Notes/AddNote/AddNote.vue";
import MetadataFormModal from "./MetadataFormModal.vue";
import DownloadBlob from "../../Ethos/DownloadBlob.vue";
import {DownloadIcon, FileTextIcon, ScissorsIcon, BriefcaseIcon, LoaderIcon, Edit2Icon} from "vue-feather-icons";
import "src/assets/sass/custom/paginated-table.scss";
import {storeRecentCaseUploads} from "../../../util/util";
import CaseBackDiv from "../../../components/DashboardV2/Case/CaseBackDiv.vue";

// Test
// import PresentationView from "src/pages/Ethos/Uploads/ViewUploads/PresentationView/PresentationView.vue";
export default {
  name: "view-uploads",
  components: {
    Tabs,
    BaseSwitch,
    TabPane,
    Notes,
    AddNote,
    DownloadBlob,
    ScissorsIcon,
    BriefcaseIcon,
    FileTextIcon,
    DownloadIcon,
    LoaderIcon,
    Edit2Icon,
    CaseBackDiv,
    MetadataFormModal,
    // PresentationView,
  },
  data() {
    return {
      upload: null,
      selectedTab: "0",
      lastMenuItemClicked: null,
      clickedView: false,
      dlFileBlob: null,
      dlFileName: null,
      dlFileContentType: null,
      dloading: false,
      isTranscriptDisplayed: false,
      isClipDisplayed: false,
      showChangeReq: false,
      backButtonTo: null,
    };
  },
  watch: {
    $route: {
      handler(n, o) {
        this.checkTranscriptDisplayed();
      },
    },
    uploadId: {
      handler(n, o) {
        if (isDefined(n) && (!isDefined(o) || n !== o)) this.loadUpload();
      },
      immediate: true,
    },
    hasSignedUrl(to, from) {
      if (to && !from) {
        this.$nextTick(this.bindRefs);
      }
    },
    showUploadsPopover: {
      immediate: true,
      handler(to, from) {
        this.showChangeReq = to;
      },
    },
  },
  computed: {
    ...mapGetters("data", [
      "displayNotes",
      "displayIncidents",
      "getUploads",
      "showUploadsPopover",
    ]),
    caseId() { // TODO: - Make a mixin for this
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    uploadId() {
      const id = this.$route.params.uploadId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    noteId() {
      const id = this.$route.params.noteId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    backTo() {
      return ethosRouteNames.CaseUploads;
    },
    pathPrefix() {
      return `/v2/case/${this.caseId}/view-uploads/${this.uploadId}`;
    },
    shouldShowExportTabs() {
      return (this.isTranscriptDisplayed || this.isClipDisplayed) &&
        !this.clickedView;
    },
  },
  updated() {
    const name = this.$router.currentRoute.name;
    const root = name === ethosRouteNames.CaseViewUploads ||
        name === ethosRouteNames.ViewUploads;
    if (root) this.displayTranscript();
  },
  mounted() {
    storeRecentCaseUploads(this.caseId, this.uploadId);
    let quickNavTo = null;
    if (this.$router.currentRoute.name === ethosRouteNames.IncidentsV2) {
      quickNavTo = "incidents";
    } else if (this.$router.currentRoute.name === ethosRouteNames.ClipsV2 ||
        this.$router.currentRoute.name === ethosRouteNames.ClipV2 ) {
      this.checkTranscriptDisplayed();
      this.findTabById("clips").active = true;
      return;
    }
    if (quickNavTo > 0) {
      const me = this;
      this.$nextTick(() => {
        if (me.$refs.tabs) {
          me.$refs.tabs.activateTab(this.findTabById("incidents"));
        }
      });
    } else {
      this.displayTranscript();
    }
    if (this.noteId !== null) {
      const me = this;
      this.$nextTick(() => {
        // Set Notes as the active tab
        if (me.$refs.tabs) {
          me.$refs.tabs.activateTab(this.findTabById("notes-container"));
        }
        if (me.$refs.noteMenu) {
          me.$refs.noteMenu.openMenu("3-0", 0);
          me.$refs.noteMenu.activeIndex = "3-2";
          me.clickedView = true;
        }
      });
    }
  },
  methods: {
    ...mapMutations("data", [
      "setBreadcrumbHint",
      "putUpload",
      "toggleUploadsPopover",
    ]),
    loadUpload() {
      this.dlFileBlob = null;
      this.dlFileName = null;
      this.dlFileContentType = null;
      this.upload = null;
      getUploadById(this.uploadId).then((e) => {
        this.upload = e;
        this.putUpload(e);
        this.setBreadcrumbHint({name: ethosRouteNames.CaseViewUploads, value: e.name});
      }).catch((ex) => {
        this.$notifyError("Failed to find upload", ex);
      });
    },
    downloadOriginal() {
      if (!isDefined(this.uploadId) || !isDefined(this.upload)) return;
      if (this.dloading) return;
      const uploadName = this.upload.originalFileName;
      const contentType = this.upload.originalFileContentType;
      this.dloading = true;
      // TRUE for original
      downloadUpload(this.uploadId, true).then((blob) => {
        this.dlFileBlob = blob;
        this.dlFileName = uploadName;
        this.dlFileContentType = contentType;
        this.$nextTick(() => {
          this.$refs.dlblob.directDownload();
          this.dloading = false;
        });
      }).catch((ex) => {
        this.$notifyError("Unable to download file", ex);
        this.dloading = false;
      });
    },
    displayTranscript() {
      this.$refs.tabs.activateTab(this.findTabById("transcript"));
      this.isTranscriptDisplayed = true;
      const newPath = `${this.pathPrefix}/transcript`;
      this.currentOption = "transcript";
      if (this.$route.path === newPath) return;
      this.$router.push(newPath);
    },
    menuSelected(x) {
      if (x === "3-1") {
        this.$refs.noteMenu.activeIndex = this.lastMenuItemClicked;
        return;
      }
      this.lastMenuItemClicked = x;
    },
    added() {
      this.$refs.addNoteForm.display();
    },
    viewed() {
      this.clickedView = true;
    },
    tabClicked(id) {
      switch (id) {
        case "notes-container":
          break;
        default:
          if (this.$refs.noteMenu) {
            this.$refs.noteMenu.activeIndex = null;
            this.lastMenuItemClicked = null;
            this.clickedView = false;
          }
          this.routeTo(id);
          break;
      }
      this.selectedTab = id;
    },
    routeTo(id) {
      const prefix = this.pathPrefix;
      const validSuffix = [
        "transcript",
        "clips",
        "people",
        "incidents",
      ];
      if (!validSuffix.includes(id)) return;
      const fullPath = `${prefix}/${id}`;
      if (this.$route && this.$route.path.startsWith(prefix) && this.$route.path !== fullPath) {
        this.$router.push(fullPath);
      }
    },
    checkTranscriptDisplayed() {
      const isTranscript = this.$route?.path?.endsWith("transcript");
      const isClip = this.endsWithWildcard(this.$route?.path, "clips/*");
      this.isTranscriptDisplayed = isTranscript;
      this.isClipDisplayed = isClip;
    },
    endsWithWildcard(str, pattern) {
      const regexPattern = pattern.replace("*", ".*") + "$";
      const regex = new RegExp(regexPattern);
      return regex.test(str);
    },
    editMetadata(e) {
      const upload = this.getUploads[this.uploadId];
      if (this.isClipDisplayed && isDefined(this.$refs.routerView?.clipId)) {
        this.$refs.routerView?.editMetadata(e);
      } else {
        this.$refs.metadataForm && this.$refs.metadataForm.display(upload);
      }
    },
    toggleMode(type) {
      this.toggleUploadsPopover(this.showChangeReq);
    },
    findTabById(id) {
      return this.$refs.tabs.tabs.find((x) => x._props["id"] === id);
    },
  },
};
</script>
<style lang="scss">
.main-height-calc {
  height: calc(100% - 55px) !important;
}
</style>
