<template>
  <el-tag
    v-if="detail"
    class="cursor-pointer user-select-none m-0 mb-1"
    effect="dark"
    type="secondary"
    contenteditable="false"
    :closable="detail.speakerPos > 0"
    @close="$emit('remove', detail)"
    @click.stop="$emit('select', detail)"
  >
    <img src="img/person.svg">
    {{
      detail.speakerId !== null && peopleList[detail.speakerId] ?
        peopleList[detail.speakerId].displayName :
        `Speaker: ${detail.speakerTag}`
    }}
  </el-tag>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "transcription-speaker",
  props: {
    detail: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters("data", [
      "peopleList",
    ]),
  },
  methods: {
    select() {
      this.$emit("select", this.detail);
    },
  },
};
</script>
