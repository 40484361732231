<template>
  <div class="d-flex flex-column">
    <case-back-div :to="backTo" />
    <wizard-container
      show-all
      class="mt-3"
    />
  </div>
</template>

<script>
import {ethosRouteNames} from "../../../routes/routeNames";
import WizardContainer from "../Wizard/CaseWizard.vue";
import CaseBackDiv from "./CaseBackDiv.vue";
export default {
  components: {
    CaseBackDiv,
    WizardContainer,
  },
  computed: {
    backTo() {
      return ethosRouteNames.DashboardV2;
    },
  },
};
</script>
