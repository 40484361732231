<template>
  <component
    :is="tag"
    v-bind="$attrs"
    :type="tag === 'button' ? nativeType : ''"
    :disabled="disabled || loading"
    class="btn"
    :class="[
      { 'btn-round': round },
      { 'btn-block': block },
      { 'btn-wd': wide },
      { 'btn-icon btn-fab': iconOnly || icon },
      { 'btn-icon-only': iconOnly },
      { 'btn-icon-no-fill': noFill },
      { [`btn-${type}`]: type },
      { [`btn-${size}`]: size },
      { 'btn-simple': iconOnly || simple },
      { 'btn-link': link },
      { 'btn-nav': navButton },
      { active: active },
      { disabled: disabled && tag !== 'button' },
    ]"
    @click="handleClick"
  >
    <slot name="loading">
      <i v-if="loading" class="fas fa-spinner fa-spin" />
    </slot>
    <slot />
  </component>
</template>
<script>
export default {
  inheritAttrs: false,

  name: "base-button",
  props: {
    tag: {
      type: String,
      default: "button",
      description: "Button html tag",
    },
    round: Boolean,
    icon: Boolean,
    iconOnly: Boolean,
    noFill: Boolean,
    block: Boolean,
    loading: Boolean,
    wide: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      default: "default",
      description: "Button type (primary|secondary|danger etc)",
    },
    nativeType: {
      type: String,
      default: "button",
      description: "Button native type (e.g button, input etc)",
    },
    size: {
      type: String,
      default: "",
      description: "Button size (xs|sm|lg)",
    },
    simple: {
      type: Boolean,
      description: "Whether button is simple (outlined)",
    },
    link: {
      type: Boolean,
      description: "Whether button is a link (no borders or background)",
    },
    navButton: {
      type: Boolean,
      description: "Whether button is a v2 navbar button",
    },
    active: {
      type: Boolean,
      description: "Whether button is active",
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>
<style scoped lang="scss">
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  i {
    padding: 0 3px;
  }
}
</style>
