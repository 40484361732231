<template>
  <div :class="`timeline-marker timeline-marker--${type}`">
    <div
      class="timeline-marker-head"
      :style="headStyle"
    />
    <div
      class="timeline-marker-tail"
      :style="tailStyle"
    />
  </div>
</template>

<script>
import {isDefined} from "../../../../api/helpers";
export default {
  name: "timeline-marker",
  props: {
    type: {
      type: String,
      default: "signal",
    },
    color: {
      type: String,
      default: null,
    },
  },
  computed: {
    headStyle() {
      return this.style.replace("!!!", "2px");
    },
    tailStyle() {
      return this.style.replace("!!!", "1px");
    },
    style() {
      if (!isDefined(this.color)) return "";
      return `background-color:transparent;border:!!! solid ${this.color};`;
    },
  },
};
</script>
