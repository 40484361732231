<template>
  <modal
    :show.sync="isDisplayed"
    header-classes="justify-content-center"
    footer-classes="px-4 py-3"
    :centered="true"
    class="modal-default modal-wide"
    @close="() => $emit('close')"
  >
    <!-- Header Slot -->
    <h4
      slot="header"
      class="title title-up"
    >
      Clip Details
    </h4>

    <template v-if="isDisplayed">
      <div class="mb-3">
        <label
          for="title"
          class="form-label"
        > Title </label>
        <input
          id="title"
          v-model="title"
          type="text"
          class="form-control"
          placeholder="Title"
        >
      </div>

      <div class="mb-3">
        <label
          for="description"
          class="form-label"
        > Description </label>
        <input
          id="description"
          v-model="description"
          type="text"
          class="form-control"
          placeholder="Description"
        >
      </div>
    </template>

    <!-- Footer Slot -->
    <template slot="footer">
      <base-button
        size="sm"
        class="ml-auto"
        :loading="loading"
        @click="submit"
      >
        Submit
      </base-button>
    </template>
  </modal>
</template>


<script>
import {Modal} from "src/components";
import {putClip} from "../../../api";
import {isDefined} from "../../../api/helpers";

export default {
  name: "incident-form",
  components: {
    Modal,
  },
  props: {
    uploadId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isDisplayed: false,
      loading: false,
      originalClip: null,
      title: "",
      description: "",
      inMillis: 0,
      outMillis: 0,
    };
  },
  methods: {
    display(inMillis, outMillis, clip) {
      this.reset();
      if (isDefined(clip)) {
        this.inMillis = clip.startOffset;
        this.outMillis = clip.endOffset;
        this.title = clip.title;
        this.description = clip.description;
        this.originalClip = clip;
        this.isDisplayed = true;
      } else {
        this.inMillis = inMillis;
        this.outMillis = outMillis;
        if (this.outMillis >= this.inMillis) {
          this.isDisplayed = true;
        }
      }
    },
    reset() {
      this.isDisplayed = false;
      this.title = "";
      this.description = "";
      this.inMillis = 0;
      this.outMillis = 0;
      this.loading = false;
      this.$emit("close");
    },
    submit() {
      if (this.loading) return;
      if (!isDefined(this.title) || this.title.length < 1) {
        this.$notifyWarn("Title required");
        return;
      }
      if (this.outMillis <= this.inMillis) {
        this.$notifyWarn("Clip cannot end before it starts");
        return;
      }
      this.loading = true;

      putClip(this.uploadId, Math.floor(this.inMillis), Math.ceil(this.outMillis), this.title, this.description)
        .then((c) => {
          this.$notifySuccess(`Clip ${isDefined(this.originalClip) ? "Updated" : "Created"}`);
          this.$emit("clip", c);
          this.reset();
        }).catch((ex) => {
          this.loading = false;
          this.$notifyError(`Failed to ${isDefined(this.originalClip) ? "update" : "create"} Clip`, ex);
        });
    },
  },
};
</script>
