<template>
  <base-filter
    :title="menuTitle"
    item-name="Cases"
    :missed-term="missedTerm"
    :filter="filter"
    :pagination="pagination"
    :status-options="statusOptions"
    @changed="(e) => $emit('changed', e)"
  />
</template>

<script>
import BaseFilter from "@/components/BaseFilter.vue";
import {CASE_MENU_ITEMS, EMPTY_PAGINATION, EMPTY_SEARCH_WRAPPER} from "../../../util/consts";

export default {
  components: {
    BaseFilter,
  },
  props: {
    menuTitle: {
      type: String,
      default: CASE_MENU_ITEMS[0].title,
    },
    missedTerm: {
      type: String,
      default: null,
    },
    filter: {
      type: Object,
      default: () => Object.assign({}, EMPTY_SEARCH_WRAPPER.filter),
    },
    pagination: {
      type: Object,
      default: () => Object.assign({}, EMPTY_PAGINATION),
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
