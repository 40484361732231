<template>
  <modal
    :show.sync="showConfirmation"
    header-classes="justify-content-center"
    footer-classes="px-4 py-3"
    class="modal-default"
    @close="handleCancel()"
  >
    <template>
      <p
        slot="header"
        class="title"
      >
        {{ text }}
      </p>
      <div class="row d-flex justify-content-center">
        <base-button
          size="sm"
          type="primary"
          @click="handleOk()"
        >
          Yes
        </base-button>
        <base-button
          size="sm"
          type="danger"
          @click="handleCancel()"
        >
          No
        </base-button>
      </div>
    </template>
  </modal>
</template>

<script>
import {Modal} from "@/components/index";

export default {
  name: "confirmation-dialog",
  components: {
    Modal,
  },
  props: {
    text: {
      type: String,
      default: "Are you sure?",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showConfirmation: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    handleOk() {
      this.$emit("okClicked");
    },
    handleCancel() {
      this.$emit("cancelClicked");
    },
  },
};
</script>