export const DEFAULT_UPLOADS_DIRNAME = "Unsorted";

export const EMPTY_PAGINATION = {"TotalCount": 0, "PageSize": 10, "CurrentPage": 1, "TotalPages": 1, "HasNext": false, "HasPrevious": false, "ResultsFor": null, "SearchQuery": null};

import {
  ClockIcon,
  LayersIcon,
  LoaderIcon,
  StarIcon,
  ZapIcon,
} from "vue-feather-icons";

export const CASE_MENU_ITEMS = [
  {
    icon: LayersIcon,
    title: "All Cases",
    value: {
      // Undo the below
      Starred: null,
      Status: null,
      Recent: null,
      Preset: null,
    },
  },
  {
    icon: StarIcon,
    title: "High Priority",
    value: {
      Starred: true,
      Status: null,
      Recent: null,
      Preset: null,
    },
  },
  {
    icon: ZapIcon,
    title: "Active",
    value: {
      Status: "Active",
      Starred: null,
      Recent: null,
      Preset: null,
    },
  },
  {
    icon: LoaderIcon,
    title: "New Uploads",
    value: {
      Status: null,
      Starred: null,
      Recent: null,
      Preset: "Upload-New",
    },
  },
  {
    icon: ClockIcon,
    title: "Recently Viewed",
    value: {
      Status: null,
      Starred: null,
      Recent: "case",
      Preset: null,
    },
  },
];

export const EMPTY_SEARCH_WRAPPER = {
  loading: false,
  missedTerm: null,
  filter: {
    // Upper case to keep backend simple
    Term: null,
    Status: null,
    Type: null,
    Starred: null,
    Before: null,
    After: null,
    Creator: null,
    Subject: null,
    Ascending: false,
    Recent: null,
    Preset: null,
  },
  pagination: Object.assign({}, EMPTY_PAGINATION),
  data: [],
};
