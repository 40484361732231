
<template>
  <svg
    class="icon-svg feather"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    :height="size.replace('x', 'rem')"
    :width="size.replace('x', 'rem')"
    viewBox="0 0 400 400"
  >
    <!-- eslint-disable max-len -->
    <path
      d="M184.4 17.3A183 183 0 0 0 19.6 234a183.7 183.7 0 1 0 354.8-91.7c-3.6-11-6.7-15-13-17-14.5-4.6-26 8.5-20.7 23.6A148.8 148.8 0 0 1 200 348.8 146.2 146.2 0 0 1 51.2 200 148.6 148.6 0 0 1 277.4 72.4l6.2 3.7-8 2.8c-16.8 5.8-22.1 15.9-14.7 28 5.8 9.4 11.1 9.3 40.8-.6 37.8-12.6 36.4-10 28.8-51.8-5-28-6-31.1-10.4-34.4-14-10.4-31 1.8-27 19.4l.5 2.3-11.8-5.9c-30.4-15-64-21.5-97.4-18.6m-39.5 116.3a387.8 387.8 0 0 0-50.6 31.7c-5.5 7.8-2.6 20.3 5.6 24.5 7.4 3.8 12.6 2.9 24.3-4.2l8.2-5 .4 45.4.4 45.5 2 3.5a17.1 17.1 0 0 0 29.6 0l2-3.5v-63.3c0-69.7.3-65-4.8-70.2a18 18 0 0 0-17-4.4m88 0c-35.8 6-58.5 54-47 98.9 18.2 71.4 99.7 66.5 112.8-6.9 9.4-52.3-24-98.9-65.9-92m15.4 35.6c23 11.5 23.6 63.4 1 77.8-8.6 5.4-19.8.1-26.1-12.3-15.5-30.7 2.2-76.9 25.1-65.5"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: "skip-10-forward-icon",
  props: {
    size: {
      type: String,
      default: "1x",
    },
  },
};
</script>
