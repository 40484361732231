<template>
  <card class="settings">
    <div
      v-if="canEditNomenclature"
      class="nomenclature-container"
    >
      <nomenclature />
    </div>

    <workflows-selection
      ref="workflowsSelection"
      heading="Default File Analysis"
      class="mb-5"
      allow-actions
      @save="saveWorkflows"
    />

    <preferences
      ref="preferences"
      class="mb-5"
      heading="Preferences"
      @save="savePreferences"
    />

    <code-words :heading="codeWordHeading" />
  </card>
</template>

<script>
import Nomenclature from "./Nomenclature.vue";
import CodeWords from "../CodeWord/CodeWords.vue";
import {mapGetters, mapMutations} from "vuex";
import WorkflowsSelection from "../../Components/WorkflowsSelection.vue";
import {setUserConfig} from "../../../api";
import Preferences from "./Preferences.vue";
export default {
  components: {Nomenclature, CodeWords, WorkflowsSelection, Preferences},
  name: "ethos-settings",
  computed: {
    ...mapGetters("auth", [
      "isAdmin",
      "isRoot",
      "userEmail",
    ]),
    canEditNomenclature() {
      return this.isAdmin || this.isRoot;
    },
  },
  data() {
    return {
      codeWordHeading: "Code Word Settings",
    };
  },
  methods: {
    ...mapMutations("data", [
      "setConfig",
    ]),
    saveWorkflows() {
      this.save(
        null,
        this.$refs.workflowsSelection && this.$refs.workflowsSelection.checked().join(",")
      );
    },
    savePreferences() {
      this.save(this.$refs.preferences && this.$refs.preferences.tz);
    },
    save(tz, selectedWorkflows) {
      setUserConfig(
        this.userEmail,
        null,
        tz,
        selectedWorkflows
      ).then((config) => {
        this.$notifySuccess("Saved Preferences Successfully");
        if (tz && this.$refs.preferences) this.$refs.preferences.showActions = false;
        if (selectedWorkflows && this.$refs.workflowsSelection) this.$refs.workflowsSelection.showActions = false;
        this.setConfig(config);
      }).catch((ex) => {
        this.$notifyError("Saving Preferences Failed", ex);
        this.cloneConfigLabel();
      });
    },
  },
};
</script>
