<template>
  <dashboard-v-2-layout :hide-nav="hasCaseId">
    <div slot="full">
      <div class="d-flex align-items-center">
        <h3 class="mb-0">
          Search Results for: {{ getSearchKeyword }}
        </h3>
        <base-button
          v-if="getSearchKeyword.length !== 0"
          icon-only
          @click="clearSearch"
        >
          <XIcon size="18" />
        </base-button>
      </div>

      <div v-if="alternateSearch.length > 0">
        {{ alternateSearch }}
      </div>
      <el-tabs
        v-model="activeName"
        type="border-card"
      >
        <el-tab-pane
          v-if="!hasCaseId"
          label="Cases"
          name="case"
        >
          <search-results-tab-vue
            :data="caseResults"
            :pagination="casePagination"
            type="case"
            @paginationChanged="handlePaginationChange"
            @view="(d) => handleCaseView(d.data.id)"
          />
        </el-tab-pane>
        <el-tab-pane
          label="Transcripts"
          name="transcription"
        >
          <search-results-tab-vue
            :data="transcriptionResults"
            :pagination="transcriptionPagination"
            type="transcription"
            @paginationChanged="handlePaginationChange"
            @view="(d) => handleUploadView(d.data.uploadId)"
          />
        </el-tab-pane>
        <el-tab-pane
          label="Uploads"
          name="uploads"
        >
          <search-results-tab-vue
            :data="uploadResults"
            :pagination="uploadsPagination"
            type="uploads"
            @paginationChanged="handlePaginationChange"
            @view="(d) => handleUploadView(d.data.id)"
          />
        </el-tab-pane>

        <el-tab-pane
          label="LEO's"
          name="user"
        >
          <search-results-tab-vue
            :data="userResults"
            :pagination="userPagination"
            type="user"
            @paginationChanged="handlePaginationChange"
            @view="(d) => handleUserView(d.data.id)"
          />
        </el-tab-pane>
        <el-tab-pane
          label="Subjects"
          name="actor"
        >
          <search-results-tab-vue
            :data="actorResults"
            :pagination="actorPagination"
            type="actor"
            @paginationChanged="handlePaginationChange"
            @view="(d) => handleActorView(d.data.id)"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </dashboard-v-2-layout>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {
  getUploadCasesData,
  getTranscriptionHeadlines,
  getUploads,
  getCases,
  pagedAttributesOf,
} from "../../../api";
import {isDefined} from "../../../api/helpers";
import DashboardV2Layout from "../../../components/DashboardV2/DashboardV2Layout.vue";
import {ethosRouteNames} from "../../../routes/routeNames";
import SearchResultsTabVue from "./SearchResultsTab.vue";
import {XIcon} from "vue-feather-icons";

export default {
  components: {SearchResultsTabVue, DashboardV2Layout, XIcon},
  name: "ethos-search",
  data() {
    return {
      activeName: "transcription",
      loadingUploadsCases: false,
      transcriptionResults: [],
      transcriptionState: 0,
      transcriptionPagination: {
        TotalCount: 0,
      },
      uploadResults: [],
      uploadsState: 0,
      uploadsPagination: {
        TotalCount: 0,
      },
      caseResults: [],
      caseState: 0,
      casePagination: {
        TotalCount: 0,
      },
      actorResults: [],
      actorState: 0,
      actorPagination: {
        TotalCount: 0,
      },
      userResults: [],
      userState: 0,
      userPagination: {
        TotalCount: 0,
      },
    };
  },
  watch: {
    "$store.state.data.searchKeyword": function(to, from) {
      this.transcriptionState = 0;
      this.uploadsState = 0;
      this.actorState = 0;
      this.userState = 0;
      this.caseState = 0;
      this.load();
    },
    "activeName": function(to, from) {
      this.load();
    },
  },
  computed: {
    ...mapGetters("data", [
      "getSearchKeyword",
    ]),
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    hasCaseId() {
      return isDefined(this.caseId);
    },
    alternateSearch() {
      if (this.activeName === "transcription") {
        if (
          this.transcriptionPagination &&
          isDefined(this.transcriptionPagination.ResultsFor) &&
          this.transcriptionPagination.SearchQuery !== this.transcriptionPagination.ResultsFor
        ) {
          let response = `No results found for "${this.transcriptionPagination.SearchQuery}".`;
          response += ` Showing results for "${this.transcriptionPagination.ResultsFor}"`;
          return response;
        }
      }
      return "";
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapMutations("data", [
      "setSearchKeyword",
    ]),
    clearSearch() {
      this.setSearchKeyword({
        keyword: "",
      });
    },
    load() {
      switch (this.activeName) {
        case "transcription":
          if (this.transcriptionState === 0) {
            this.loadTranscriptions(this.getSearchKeyword);
          }
          break;
        case "case":
          if (this.caseState === 0) {
            this.loadCases(this.getSearchKeyword);
          }
          break;
        case "uploads":
          if (this.uploadsState === 0) {
            this.loadUploads(this.getSearchKeyword);
          }
          break;
        case "actor":
          if (this.actorState === 0) {
            this.loadActors(this.getSearchKeyword);
          }
          break;
        case "user":
          if (this.userState === 0) {
            this.loadUsers(this.getSearchKeyword);
          }
          break;
        default: break;
      }
    },
    loadTranscriptions(keyword) {
      let pageNumber = 1;
      let pageSize = 10;
      if (isDefined(this.transcriptionPagination.pageNumber)) {
        pageNumber = this.transcriptionPagination.pageNumber;
        pageSize = this.transcriptionPagination.pageSize;
      }
      if (this.transcriptionState === 1) return;
      this.transcriptionState = 1;
      getTranscriptionHeadlines(pageNumber, pageSize, keyword, this.caseId)
        .then((response) => {
          this.transcriptionResults = response.data.map((t) => {
            return {
              id: t.id,
              name: t.name,
              createdOn: t.createdOn,
              modifiedOn: t.modifiedOn,
              uploadId: t.uploadId,
              data: t.headlines,
            };
          });
          this.transcriptionPagination = JSON.parse(response.pagination);
        })
        .catch((ex) => {
          this.$notifyError("Loading Data Failed", ex);
        }).finally(() => {
          this.transcriptionState = 2;
        });
    },
    loadCases(keyword) {
      let pageNumber = 1;
      let pageSize = 10;
      if (isDefined(this.casePagination.pageNumber)) {
        pageNumber = this.casePagination.pageNumber;
        pageSize = this.casePagination.pageSize;
      }
      if (this.caseState === 1) return;
      this.caseState = 1;
      getCases(pageNumber, pageSize, keyword)
        .then((response) => {
          this.caseResults = response.data.map((t) => {
            return {
              id: t.id,
              name: t.name,
              createdOn: t.createdOn,
              modifiedOn: t.modifiedOn,
              data: t.title,
            };
          });
          this.casePagination = JSON.parse(response.pagination);
        })
        .catch((ex) => {
          this.$notifyError("Loading Data Failed", ex);
        }).finally(() => {
          this.caseState = 2;
        });
    },
    loadUploads(keyword) {
      let pageNumber = 1;
      let pageSize = 10;
      if (isDefined(this.uploadsPagination.pageNumber)) {
        pageNumber = this.uploadsPagination.pageNumber;
        pageSize = this.uploadsPagination.pageSize;
      }
      if (this.uploadsState === 1) return;
      this.uploadsState = 1;
      getUploads(pageNumber, pageSize, keyword, this.caseId)
        .then((response) => {
          this.uploadResults = response.data.map((t) => {
            return {
              id: t.id,
              name: t.name,
              createdOn: t.createdOn,
              modifiedOn: t.modifiedOn,
              data: t.originalFileName,
            };
          });
          this.uploadsPagination = JSON.parse(response.pagination);
        })
        .catch((ex) => {
          this.$notifyError("Loading Data Failed", ex);
        }).finally(() => {
          this.uploadsState = 2;
        });
    },
    loadActors(keyword) {
      let pageNumber = 1;
      let pageSize = 10;
      if (isDefined(this.actorPagination.pageNumber)) {
        pageNumber = this.actorPagination.pageNumber;
        pageSize = this.actorPagination.pageSize;
      }
      if (this.actorState === 1) return;
      this.actorState = 1;
      pagedAttributesOf("allactors", 0, pageNumber, pageSize, keyword, this.caseId)
        .then((response) => {
          this.actorResults = response.data.map((t) => {
            return {
              id: t.actorId,
              name: t.fullName,
              createdOn: t.createdOn,
              modifiedOn: t.modifiedOn,
              data: isDefined(t.other) ? t.other : "",
            };
          });
          this.actorPagination = JSON.parse(response.pagination);
        })
        .catch((ex) => {
          this.$notifyError("Loading Data Failed", ex);
        }).finally(() => {
          this.actorState = 2;
        });
    },
    loadUsers(keyword) {
      let pageNumber = 1;
      let pageSize = 10;
      if (isDefined(this.userPagination.pageNumber)) {
        pageNumber = this.userPagination.pageNumber;
        pageSize = this.userPagination.pageSize;
      }
      if (this.userState === 1) return;
      this.userState = 1;
      pagedAttributesOf("allusers", 0, pageNumber, pageSize, keyword, this.caseId)
        .then((response) => {
          this.userResults = response.data.map((t) => {
            return {
              id: t.userId,
              name: t.fullName,
              createdOn: t.createdOn,
              modifiedOn: t.modifiedOn,
              data: isDefined(t.other) ? t.other : "",
            };
          });
          this.userPagination = JSON.parse(response.pagination);
        })
        .catch((ex) => {
          this.$notifyError("Loading Data Failed", ex);
        }).finally(() => {
          this.userState = 2;
        });
    },
    handlePaginationChange(e) {
      switch (e.type) {
        case "transcription":
          this.transcriptionPagination = e.pagination;
          this.loadTranscriptions(this.getSearchKeyword);
          break;
        case "uploads":
          this.uploadsPagination = e.pagination;
          this.loadUploads(this.getSearchKeyword);
          break;
        case "case":
          this.casePagination = e.pagination;
          this.loadCases(this.getSearchKeyword);
          break;
        case "actor":
          this.actorPagination = e.pagination;
          this.loadActors(this.getSearchKeyword);
          break;
        case "user":
          this.userPagination = e.pagination;
          this.loadUsers(this.getSearchKeyword);
          break;
        default: break;
      }
    },
    handleActorView( actorId) {
      console.log("actor", actorId);
    },
    handleUserView(userId) {
      console.log("user", userId);
    },
    handleCaseView(id) {
      if (!isDefined(id)) return;
      this.$router.push(
        {
          name: ethosRouteNames.ViewCase,
          params: {
            caseId: id,
          },
        });
    },
    handleUploadView(id) {
      if (!isDefined(id)) return;
      if (this.hasCaseId) {
        return this.showUploadsView(this.caseId, id);
      }
      if (this.loadingUploadsCases) return;
      this.loadingUploadsCases = true;
      getUploadCasesData(id).then((response) => {
        if (response.length === 1) {
          this.showUploadsView(response[0].id, id);
        } else if (response.length === 0) {
          // TODO: - should upload be viewable without a case?
          // TODO: - should user be asked to create a case for it?
          throw new Error("No cases found for upload");
        } else {
          // TODO: - show prompt
          throw new Error("Multiple cases found");
        }
      }).catch((ex) => {
        this.$$notifyError("Unabled to load Upload", ex);
      }).finally(() => {
        this.loadingUploadsCases = false;
      });
    },
    showUploadsView(caseId, uploadId) {
      this.$router.push(
        {
          name: ethosRouteNames.CaseViewUploads,
          params: {
            caseId,
            uploadId,
          },
        });
    },
  },
};
</script>
