<template>
  <dashboard-layout>
    <dashboard-nav
      slot="left"
      @changed="handleMenuChanged"
    />
    <template slot="right">
      <case-container
        :pagination="pagination"
        @changed="handlePaginationChanged"
      >
        <dashboard-filter
          slot="filter"
          :menu-title="menuTitle"
          :pagination="pagination"
          :missed-term="missedTerm"
          :filter="filter"
          :status-options="statusOptions"
          @changed="handleFilterChanged"
        />
        <template v-if="hasData">
          <case-card-item
            v-for="item in caseSummaries"
            :key="item.id"
            :case-item="item"
            @click="onCaseItemClick(item.id)"
          />
        </template>
        <dashboard-empty
          v-else
        />
      </case-container>
    </template>
  </dashboard-layout>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import DashboardEmpty from "../../components/DashboardV2/Dashboard/DashboardEmpty.vue";
import DashboardLayout from "../../components/DashboardV2/DashboardV2Layout.vue";
import DashboardNav from "../../components/DashboardV2/Dashboard/DashboardNav.vue";
import DashboardFilter from "../../components/DashboardV2/Dashboard/DashboardFilter.vue";
import CaseCardItem from "../../components/DashboardV2/Dashboard/CaseCardItem.vue";
import CaseContainer from "../../components/DashboardV2/Case/CaseContainer.vue";
import {CASE_MENU_ITEMS} from "../../util/consts";
import {isDefined} from "../../api/helpers";

export default {
  components: {
    DashboardLayout,
    DashboardEmpty,
    DashboardNav,
    DashboardFilter,
    CaseCardItem,
    CaseContainer,
  },
  data() {
    return {
      menuTitle: CASE_MENU_ITEMS[0].title,
    };
  },
  computed: {
    ...mapGetters("data", ["getCaseSummaries", "availableCaseStates"]),
    ...mapGetters("auth", ["userId"]),
    caseSummaries() {
      return this.getCaseSummaries.data;
    },
    hasData() {
      return Array.isArray(this.caseSummaries) && this.caseSummaries.length > 0;
    },
    pagination() {
      return this.getCaseSummaries.pagination;
    },
    filter() {
      return this.getCaseSummaries.filter;
    },
    missedTerm() {
      return this.getCaseSummaries.missedTerm;
    },
    statusOptions() {
      return this.availableCaseStates.map((s) => {
        return {
          name: s,
          value: s,
        };
      });
    },
  },
  mounted() {
    this.loadCases();
  },
  methods: {
    ...mapActions("data", ["loadCaseSummaries", "updateCaseSummaryFilterCriteria", "updateCaseSummaryPagination"]),
    ...mapMutations("data", ["putCaseSummariesPagination"]),
    handleFilterChanged(filter) {
      this.updateCaseSummaryFilterCriteria(filter);
    },
    handleMenuChanged(menuItem) {
      this.menuTitle = menuItem.title;
      this.updateCaseSummaryFilterCriteria(menuItem.value);
    },
    handlePaginationChanged(newPagination) {
      this.updateCaseSummaryPagination(newPagination);
    },
    loadCases(attempt) {
      if (!isDefined(this.userId)) {
        attempt = isDefined(attempt) ? attempt : 1;
        if (attempt < 5) setTimeout(() => this.loadCases(attempt + 1), 100);
        return;
      }
      this.doLoadCaseSummaries().then(() => {
        // All good
      }).catch((ex) => {
        // Show error? or leave blank?
      });
    },
    async doLoadCaseSummaries() {
      await this.loadCaseSummaries({});
    },
    onCaseItemClick(id) {
      // this.$router.push({name: "case", params: {id}});
    },
  },
};
</script>

<style></style>
