<template>
  <card
    class="p-5 mb-4"
    :class="{ 'bg-black': checkbox, 'card-border': !checkbox }"
    @click="$emit('click')"
  >
    <div class="row">
      <div class="col-4">
        <div class="d-flex">
          <div>
            <base-checkbox
              v-model="checkbox"
              inline
              class="mt-1 mr-0"
            />
          </div>
          <StarIcon
            v-if="!limited"
            class="mr-3 mt-1 flex-shrink-0 cursor-pointer"
            :class="{
              'text-muted': !caseUploadSummary.uploadStarred,
              'text-primary': caseUploadSummary.uploadStarred,
              'fill-primary': caseUploadSummary.uploadStarred,
            }"
            size="20"
            @click="toggleStarred"
          />
          <div class="d-flex flex-column flex-1 overflow-hidden">
            <h6
              class="text-white my-0 text-truncate cursor-pointer"
              @click="viewTranscript"
            >
              {{ caseUploadSummary.name }}
            </h6>
            <div class="d-flex align-items-center">
              <p class="text-lightblue mb-0 text-truncate">
                {{ caseUploadSummary.originalFileName }}
              </p>
              <InfoIcon
                size="16"
                class="ml-1 text-lightblue"
              />
            </div>
          </div>
        </div>
        <div
          v-if="!limited"
          class="mt-3 d-flex flex-wrap"
        >
          <span
            class="bg-secondary px-3 py-2 font-weight-bold rounded-pill mr-3 mb-3"
          >
            <CheckCircleIcon
              v-if="caseUploadSummary.status === 'Evidence'"
              class="mr-2"
              size="18"
            />
            {{ status }}
          </span>
          <span
            class="bg-dark-100 text-lightblue px-3 py-2 font-weight-bold rounded-label mb-3"
          >
            Clips: {{ clipCount }}
          </span>
        </div>
      </div>
      <div class="col-8">
        <div class="d-flex">
          <div class="flex-grow-1 d-flex">
            <div class="flex-1 pl-3">
              <div class="text-lightblue">
                Type
              </div>
              <div class="text-white text-truncate">
                {{ caseUploadSummary.uploadType }}
              </div>
            </div>
            <div
              v-if="!limited"
              class="flex-1 pl-3"
            >
              <div class="text-lightblue">
                Uploaded On
              </div>
              <div class="text-white text-truncate">
                {{
                  $date(caseUploadSummary.uploadMetadata.createdOn)
                    .tz(userTimezone)
                    .format("MMM DD, YYYY")
                }}
              </div>
            </div>
            <div
              v-if="!limited"
              class="flex-1 pl-3"
            >
              <div class="text-lightblue">
                Uploaded By
              </div>
              <div
                class="ellipsis text-white text-truncate"
                :data-text="caseUploadSummary.uploadMetadata.createdBy"
              >
                {{ caseUploadSummary.uploadMetadata.createdBy }}
              </div>
            </div>
            <div class="flex-1 pl-3">
              <div class="text-lightblue">
                Event Date / Time
              </div>
              <div class="text-white">
                {{
                  $date(caseUploadSummary.startTimeStamp)
                    .tz(userTimezone)
                    .format("MMM DD, YYYY")
                }}
                <br>
                {{
                  $date(caseUploadSummary.startTimeStamp)
                    .tz(userTimezone)
                    .format("hh:mm a")
                }}
              </div>
            </div>
            <div
              v-if="!limited"
              class="flex-1 pl-3"
            >
              <div class="text-lightblue">
                Duration
              </div>
              <div class="text-white">
                {{ formattedDuration }}
              </div>
            </div>
            <div v-if="!limited">
              <el-dropdown
                type="primary"
                class="dropdown-actions dropdown-actions-simple"
                :class="{
                  'text-primary-300': !disableActions,
                  'text-muted': disableActions,
                  'cursor-blocked': disableActions
                }"
                popper-class="select-primary"
                @command="(c) => $emit(c, caseUploadSummary)"
              >
                <div class="font-weight-bold">
                  Actions
                  <ChevronDownIcon />
                </div>
                <el-dropdown-menu
                  v-if="!disableActions"
                  slot="dropdown"
                  type="primary"
                  class="dmenu select-primary bg-filter-chip"
                  dark
                >
                  <el-dropdown-item command="export">
                    Export
                  </el-dropdown-item>
                  <el-dropdown-item command="status">
                    Update Status
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div
          v-if="!limited"
          class="d-flex pt-4 ml-3"
        >
          <base-input
            v-model="remark"
            class="mb-0 flex-grow-1"
            @keyup.enter="persistRemark"
          />
          <base-button
            simple
            class="m-0 ml-3 border-secondary text-secondary"
            style="height: 38px"
            :loading="loading"
            @click="persistRemark"
          >
            Update Remark
          </base-button>
        </div>
      </div>
    </div>
    <excerpts
      :headlines="headlines"
      @clicked="handleHighlightClick"
    />
    <div
      v-if="isFailed || (isProcessing && !limited)"
      class="row mt-2"
    >
      <div class="col-4 d-flex align-items-center">
        <base-progress
          :type=" isFailed ? 'danger' : 'primary'"
          class="flex-grow-1 mr-3"
          :value="isFailed ? 100 : uploadProgress"
          value-position="none"
          animated
        />
        <span
          v-if="isFailed"
          class="text-lightblue"
        > Failed </span>
        <span
          v-else
          class="text-lightblue"
        > Processing {{ uploadProgress }}% </span>
      </div>
      <div class="col-8" />
    </div>
  </card>
</template>

<script>
import {CheckCircleIcon, ChevronDownIcon, InfoIcon, StarIcon} from "vue-feather-icons";
import BaseButton from "@/components/BaseButton.vue";
import Card from "@/components/Cards/Card.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseCheckbox from "../../Inputs/BaseCheckbox.vue";
import BaseProgress from "../../BaseProgress.vue";
import Excerpts from "./Excerpts.vue";
import {mapGetters, mapMutations} from "vuex";
import {isDefined} from "../../../api/helpers";
import {putUpload} from "../../../api";
import {ethosRouteNames} from "../../../routes/routeNames";

export default {
  props: {
    caseUploadSummary: {
      type: Object,
      default() {
        return {};
      },
    },
    limited: {
      type: Boolean,
      default: false,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Card,
    StarIcon,
    BaseButton,
    BaseInput,
    BaseCheckbox,
    InfoIcon,
    BaseProgress,
    CheckCircleIcon,
    ChevronDownIcon,
    Excerpts,
  },
  data() {
    return {
      checkbox: false,
      remark: "",
      loading: false,
    };
  },
  watch: {
    checkbox: {
      immediate: false,
      handler(to, from) {
        if (to !== from) this.$emit("selected", {selected: to, data: this.caseUploadSummary});
      },
    },
    caseUploadSummary: {
      immediate: true,
      handler(to) {
        if (isDefined(to.remark)) {
          this.remark = to.remark;
        }
      },
    },
  },
  computed: {
    ...mapGetters("data", ["userTimezone", "availableElementStates", "getWorkflowResponse"]),
    uploadsCount() {
      return this.caseItem.uploadsCount;
    },
    keywordCount() {
      return this.caseItem.caseKeywordCount;
    },
    clipCount() {
      return Math.max(this.caseUploadSummary.clipCount, (this.caseUploadSummary.clips || []).length);
    },
    peopleCount() {
      return (
        this.caseUploadSummary.userAttributionCount + this.caseUploadSummary.actorAttributionCount
      );
    },
    newUploadsCount() {
      return 0;
      // / Replace with a rollup?
      // return (this.caseUploadSummary.upload ?? []).filter((e) => e.status === "new").length;
    },
    headlines() {
      if (
        !isDefined(this.caseUploadSummary.headlines) ||
        this.caseUploadSummary.headlines.length === 0) {
        return [];
      }
      return this.caseUploadSummary.headlines.split("...").map((l) => {
        // split on b tags.
        const spans = l.replaceAll("</b>", "<b>").split("<b>");
        if (l.startsWith("<b>")) return [...spans];
        return spans;
      });
    },
    status() {
      return this.availableElementStates.filter((e) => e === this.caseUploadSummary.status).pop() || "";
    },
    formattedDuration() {
      if (!isDefined(this.caseUploadSummary) || !isDefined(this.caseUploadSummary.durationMilliseconds)) {
        return "- -";
      }
      const millis = this.caseUploadSummary.durationMilliseconds;
      if (millis < 1) return "- -";
      const minutes = millis / 60000;
      const fminutes = Math.floor(minutes);
      const seconds = Math.floor((minutes - fminutes) * 60);
      let response = `${seconds}s`;
      if (minutes >= 60) {
        const hours = Math.floor(minutes / 60);
        response = `${hours}h ${Math.floor(minutes - (hours * 60))}m ${response}`;
      } else if (minutes >= 1) {
        response = `${fminutes}m ${response}`;
      }
      return response;
    },
    workflow() {
      return isDefined(this.getWorkflowResponse) ?
        this.getWorkflowResponse.filter((w) => w.uploadId === this.caseUploadSummary.uploadId).pop() :
        null;
    },
    isProcessing() {
      if (!isDefined(this.workflow)) return false;
      const status = this.workflow.status;
      return isDefined(status) && status.toLowerCase() !== "failed" && status.toLowerCase() !== "complete";
    },
    isFailed() {
      if (!isDefined(this.workflow)) return false;
      const status = this.workflow.status;
      return isDefined(status) && status.toLowerCase() === "failed";
    },
    uploadProgress() {
      if (!this.isProcessing) return 1;
      return Math.floor(this.workflow.progress);
    },
  },
  mounted() {
    if (this.selected === true) this.checkbox = true;
  },
  methods: {
    ...mapMutations("data", ["putCaseUploadSummary", "putHighlightSnippet"]),
    persistRemark() {
      if (this.remark.length < 1 || this.loading) return; // Ignore.
      this.loading = true;
      this.doPutUpload(this.remark).then((model) => {
        this.putCaseUploadSummary(
          {
            caseId: this.caseUploadSummary.caseId,
            data: Object.assign({}, this.caseUploadSummary, {remark: model.remark}),
          });
      }).catch((ex) => {
        this.$notifyError("Failed to set remark", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleHighlightClick(highlights) {
      if (highlights) {
        this.putHighlightSnippet(highlights.join(""));
        this.viewTranscript();
      }
    },
    async doPutUpload(remark, starred) {
      return await putUpload(
        this.caseUploadSummary.uploadId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        remark,
        starred
      );
    },
    toggleStarred() {
      if (this.loading) return; // Ignore.
      this.loading = true;
      this.doPutUpload(undefined, !this.caseUploadSummary.uploadStarred).then((model) => {
        this.putCaseUploadSummary(
          {
            caseId: this.caseUploadSummary.caseId,
            data: Object.assign({}, this.caseUploadSummary, {uploadStarred: model.starred}),
          });
      }).catch((ex) => {
        this.$notifyError("Unable to update favorite status", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    viewTranscript() {
      this.$router.push({
        name: ethosRouteNames.TranscriptV2,
        params: {
          caseId: this.caseUploadSummary.caseId,
          uploadId: this.caseUploadSummary.uploadId,
        },
      });
    },
  },
};
</script>
<style>
.box{
  background:#fff;
  box-shadow: 0 25px 30px 0 rgba(0,0,0,0.15);
  border:rgba(0,0,0,0.3);
  width:10rem;
  margin:2rem auto;
  padding:2rem;
}
.ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .2s linear;
    max-width: 150px;
}
.ellipsis:focus, .ellipsis:hover {
  color:transparent;
}
.ellipsis:focus:after,.ellipsis:hover:after{
    content: attr(data-text);
    overflow: visible;
    text-overflow: inherit;
    background: #fff;
    position: absolute;
    left:auto;
    top:auto;
    /* width: auto; */
    padding: 0.25rem;
    width: auto;
    max-width: 20rem;
    border: 1px solid #eaebec;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.28);
    white-space: normal;
    word-wrap: nowrap;
    display:block;
    color:black;
    margin-top:-1.25rem;
    z-index: 20000;
  }
</style>
