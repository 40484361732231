<template>
  <div>
    <template v-for="(cr, cri) in crs">
      <change-request
        v-if="currentVisible == cri"
        :key="cr.id"
        :cr="cr"
        :people="null"
        @approve="(a) => $emit('approve', a)"
        @reject="(r) => $emit('reject', r)"
      />
    </template>
    <template v-for="(scr, scri) in scrs">
      <change-request
        v-if="currentVisible == (crs || []).length + scri"
        :key="scr.id"
        :cr="scr"
        :people="peopleList[scr.sid]"
        :is-remove="scr.sid && scr.sid < -1000"
        is-speaker
        @approve="(a) => $emit('approve', a)"
        @reject="(r) => $emit('reject', r)"
      />
    </template>
    <div
      v-if="length > 1"
      class="d-flex align-items-start"
    >
      <span class="result pr-sm-2">
        {{ currentVisible + 1 }}
        of
        {{ length }}
      </span>
      <div class="btn-group">
        <base-button
          size="sm"
          type="secondary"
          simple
          class="btn"
          :disabled="currentVisible <= 0"
          @click="previousCR()"
        >
          Prev
        </base-button>
        <base-button
          size="sm"
          type="secondary"
          simple
          class="btn"
          :disabled="currentVisible >= length - 1"
          @click="nextCR()"
        >
          Next
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import ChangeRequest from "./ChangeRequest.vue";
export default {
  name: "change-requests",
  components: {ChangeRequest},
  props: {
    crs: {
      type: Array,
      default() {
        return [];
      },
    },
    scrs: {
      type: Array,
      default() {
        return [];
      },
    },
    peopleList: {
      type: Object,
      default: null,
    },
  },
  computed: {
    length() {
      return (this.crs || []).length + (this.scrs || []).length;
    },
  },
  data() {
    return {
      currentVisible: 0,
    };
  },
  methods: {
    nextCR() {
      this.currentVisible++;
    },
    previousCR() {
      this.currentVisible--;
    },
  },
};
</script>
