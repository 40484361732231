<template>
  <div>
    <p
      :class="showContent ? '' : 'truncated-' + lines"
      class="font-16 text-white cursor-pointer"
      @click="$emit('onClick')"
    >
      <slot></slot>
    </p>
    <button
      @click="showContent = !showContent"
      class="text-primary font-weight-bold text-underline read-more--btn p-0"
    >
      {{ showContent ? "Read Less" : "Read More" }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    lines: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      showContent: false,
    };
  },
  methods: {},
};
</script>

<style>
.read-more--btn {
  background: none !important;
  border: 0px !important;
  margin: 0px;
}
</style>
