<template>
  <div class="flex-grow-1 mr-2 paginated-table">
    <base-button
      class="mt-4"
      type="secondary"
      link
      @click="showArchived = !showArchived"
    >
      {{ buttonCopy }}
    </base-button>
    <el-table
      :data="incidentData"
      style="width: 100%"
    >
      <el-table-column
        prop="color"
        label="Color"
        min-width="100"
        sortable
      >
        <template slot-scope="scope">
          <color-dot :hex="scope.row.color" />
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="Name"
        min-width="200"
        sortable
      />
      <el-table-column
        prop="createdOn"
        label="Created On"
        width="250"
        sortable
      >
        <template slot-scope="scope">
          {{ $date(scope.row.createdOn).format("MMM DD, YYYY [at] hh:mm a") }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createdBy"
        label="Created By"
        min-width="200"
        sortable
      />
      <el-table-column
        prop="modifiedOn"
        label="Modified On"
        width="250"
        sortable
      >
        <template slot-scope="scope">
          {{ $date(scope.row.modifiedOn).format("MMM DD, YYYY [at] hh:mm a") }}
        </template>
      </el-table-column>
      <el-table-column
        prop="modifiedBy"
        label="Modified By"
        min-width="200"
        sortable
      />
      <el-table-column
        align="center"
        min-width="200"
      >
        <template slot-scope="scope">
          <base-button
            size="sm"
            type="primary"
            simple
            @click="display(scope.row)"
          >
            View {{ incidentNameLabel }}
          </base-button>
        </template>
      </el-table-column>
    </el-table>
    <table-pagination
      ref="pagination"
      :pagination="pagination"
      @changed="handlePaginationChange"
    />
    <incident-form
      ref="incidentForm"
      @updated="updated"
    />
  </div>
</template>

<script>
import {getIncidents, getIncidentArchiveCount} from "../../../api";
import IncidentForm from "./IncidentForm.vue";
import ColorDot from "../../Components/ColorDot.vue";
import TablePagination from "../../../../src/components/TablePagination.vue";
import {mapGetters} from "vuex";
import {isDefined} from "../../../api/helpers";
export default {
  name: "incidents-list",
  components: {
    ColorDot,
    IncidentForm,
    TablePagination,
  },
  data() {
    return {
      tableData: [],
      incidentData: [], // Our array to hold all achived incidents
      archiveCount: 0,
      showArchived: false,
      pagination: {
        TotalCount: 0,
      },
    };
  },
  watch: {
    showArchived: function(t, f) {
      if (t !== f) this.loadIncidents();
    },
  },
  computed: {
    ...mapGetters("data", [
      "getTranscriptions",
      "incidentNameLabel",
    ]),
    uploadId() {
      const id = this.$route.params.uploadId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    buttonCopy() {
      if (this.showArchived) return "Exit Archived View";
      return `${this.archiveCount} Archived ${this.incidentNameLabel}`;
    },
  },
  mounted() {
    this.loadIncidents();
  },
  methods: {
    loadIncidents() {
      // this.$refs.pagination is undefined
      if (!this.$refs.pagination) return;
      if (this.uploadId !== null) {
        getIncidents(
          this.$refs.pagination.pageNumber, // page number
          this.$refs.pagination.pageSize, // page size
          null, // filter
          this.showArchived === true, // archive
          this.uploadId,
          null, // noteId
          null, // peopleId
          null // projectId
        ).then((response) => {
          this.incidentData = response.data;
          this.pagination = JSON.parse(response.pagination);
        }).catch((ex) => {
          this.$notifyError(`Loading ${this.uploadId} Uploads's Archived Incidents Failed`, ex);
        });
      }
      if (!this.showArchived) this.doGetArchiveCount();
    },
    doGetArchiveCount() {
      getIncidentArchiveCount()
        .then((response) => {
          this.archiveCount = response.count;
        }).catch((ex) => {
          // dloading?
          this.dloading = false;
          this.$notifyError("Archive Failed", ex);
        });
    },
    handlePaginationChange() {
      this.$nextTick(this.loadIncidents());
    },
    display(incident) {
      if (!this.$refs.incidentForm) return;
      let content = "";
      if (isDefined(incident.transcriptionId) && isDefined(this.getTranscriptions[incident.transcriptionId])) {
        const allContent = this.getTranscriptions[incident.transcriptionId].content;
        if (isDefined(allContent)) content = allContent.slice(incident.startOffset, incident.endOffset);
      }
      this.$refs.incidentForm.display(
        {
          start: incident.startOffset,
          end: incident.endOffset,
          text: content,
          type: incident.type,
          transcriptId: incident.transcriptionId,
          uploadId: this.uploadId,
        },
        incident
      );
    },
    updated(incident) {
      if (incident && incident.archive !== this.showArchived) {
        this.incidentData = this.incidentData.filter((i) => i.id !== incident.id);
        this.doGetArchiveCount();
      }
    },
  },
};
</script>

<style>

</style>
