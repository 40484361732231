<template>
  <div class="ethos-header">
    <base-nav
      class="top-navbar ethos-nav"
      type="vue"
    >
      <div class="logo-container">
        <div class="logo">
          <a
            href="#"
            class="simple-text logo-mini"
          >
            <img
              :src="logo"
              alt="app-logo"
            >
          </a>
          <a
            href="#"
            class="simple-text logo-normal"
          >
            <img :src="logoName">
          </a>
        </div>
      </div>

      <!-- Auth'd users only -->
      <template v-if="authEstablished">
        <div class="search">
          <div class="row">
            <div class="search-container d-flex align-items-center px-2">
              <search-icon size="1.5x" />
              <input
                type="text"
                class="form-control ethosSearch border-0"
                placeholder="SEARCH"
                @keyup.enter="search"
              >
            </div>
          </div>
        </div>

        <div
          v-if="isRoot || isAdmin"
          class="p-0"
        >
          <base-button
            size="sm"
            type="neutral"
            link
            @click="$router.push('/admin')"
          >
            Administration
          </base-button>
        </div>

        <div class="p-0">
          <base-button
            size="sm"
            type="neutral"
            link
            @click="$router.push('/settings')"
          >
            Settings
          </base-button>
        </div>

        <ul class="navbar-nav">
          <base-dropdown
            v-if="!$auth.loading && $auth.isAuthenticated"
            tag="li"
            :menu-on-right="!$rtl.isRTL"
            title-tag="a"
            class="nav-item"
            title-classes="nav-link"
            menu-classes="dropdown-navbar"
          >
            <template slot="title">
              <div class="photo">
                <img
                  v-if="userProfilePhotoUrl"
                  src="userProfilePhotoUrl"
                >
                <div
                  v-else-if="initials && initials.length > 0"
                  class="initials"
                >
                  {{ initials }}
                </div>
              </div>
            </template>
            <li
              v-if="userFullName"
              class="nav-link"
            >
              <div class="nav-item dropdown-item">
                {{ userFullName }}
              </div>
              <!-- <a href="#" class="nav-item dropdown-item">Profile</a> -->
            </li>
            <li
              v-if="userEmail"
              class="nav-link"
            >
              <div class="nav-item dropdown-item">
                {{ userEmail }}
              </div>
              <!-- <a href="#" class="nav-item dropdown-item">Profile</a> -->
            </li>
            <div class="dropdown-divider" />
            <li class="nav-link">
              <div
                class="nav-item dropdown-item"
                style="color:#ffffff66"
                @click="copyVer"
              >
                {{ version }}
              </div>
            </li>
            <li class="nav-link">
              <a
                href="#"
                class="nav-item dropdown-item"
                @click="signOut"
              >Logout</a>
            </li>
          </base-dropdown>
        </ul>
      </template>
      <template v-else-if="$auth.isAuthenticated">
        <base-button
          size="sm"
          link
          @click="signOut"
        >
          Logout
        </base-button>
      </template>
    </base-nav>

    <div v-if="authEstablished">
      <route-breadcrumb />
    </div>
  </div>
</template>

<script>
import {BaseNav} from "@/components";
import {mapGetters, mapMutations} from "vuex";
import {SearchIcon} from "vue-feather-icons";
export default {
  name: "ethos-header",
  components: {
    BaseNav,
    SearchIcon,
  },
  props: {
    logo: {
      type: String,
      default: "img/ethos-logo.svg",
      description: "Sidebar app logo",
    },
    logoName: {
      type: String,
      default: "img/ethos-name.svg",
      description: "Sidebar app name",
    },
  },
  computed: {
    ...mapGetters("auth", [
      "established",
      "userFullName",
      "userFirstName",
      "userLastName",
      "userEmail",
      "userProfilePhotoUrl",
      "isRoot",
      "isAdmin",
    ]),
    authEstablished() {
      return this.$auth.isAuthenticated && this.established;
    },
    version() {
      let ver = process.env.VUE_APP_VERSION || "1.0.0";
      if (ver.length > 15) ver = ver.slice(0, 5);
      return `v${ver}`;
    },
    initials() {
      let initials = "";
      if (this.userFirstName && this.userFirstName.length > 0) initials += this.userFirstName[0];
      if (this.userLastName && this.userLastName.length > 0) initials += this.userLastName[0];
      return initials.toUpperCase();
    },
  },
  methods: {
    ...mapMutations("data", [
      "setSearchKeyword",
    ]),
    search(e) {
      const path = "/search";
      this.setSearchKeyword({
        keyword: e.target.value,
      });
      if (e.target.value != "" && this.$route.path !== path) {
        this.$router.push("/search");
      }
    },
    signOut() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    copyVer(e) {
      e.stopPropagation();
      navigator.clipboard.writeText(this.version).then(() => {
        this.$notifySuccess("Copied to clipboard");
      }).catch((ex) => {
        console.error("Failed to copy to clipboard", ex);
      });
    },
  },
};
</script>
