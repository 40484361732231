<template>
  <div
    data-notify="container"
    class="alert open"
    :class="[
      { 'alert-with-icon': hasIcon },
      verticalAlign,
      horizontalAlign,
      alertType
    ]"
    role="alert"
    :style="customPosition"
    data-notify-position="top-center"
    @click="tryClose"
  >
    <button
      v-if="showClose"
      type="button"
      aria-hidden="true"
      class="close col-xs-1"
      data-notify="dismiss"
      @click="close"
    >
      <x-icon size="1x" />
    </button>

    <alert-triangle-icon
      v-if="type === 'danger' || type === 'warning'"
      size="1.8x"
    />
    <check-circle-icon
      v-else-if="type === 'success'"
      size="1.8x"
    />
    <info-icon
      v-else-if="type === 'info'"
      size="1.8x"
    />

    <span data-notify="message">
      <span
        v-if="title"
        class="alert-title"
      ><b>{{ title }}<br></b></span>
      <span
        v-if="message"
        class="alert-message"
      >
        {{ message }}
      </span>
      <content-render
        v-if="!message && component"
        :component="component"
      />
    </span>
  </div>
</template>
<script>
import Vue from "vue";
import {AlertTriangleIcon, CheckCircleIcon, XIcon, InfoIcon} from "vue-feather-icons";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Notifications",
  components: {
    contentRender: {
      props: ["component"],
      // eslint-disable-next-line no-invalid-this
      render: (h) => h(this.component),
    },
    AlertTriangleIcon,
    CheckCircleIcon,
    XIcon,
    InfoIcon,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    verticalAlign: {
      type: String,
      default: "top",
      validator: (value) => {
        const acceptedValues = ["top", "bottom"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    horizontalAlign: {
      type: String,
      default: "right",
      validator: (value) => {
        const acceptedValues = ["left", "center", "right"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    type: {
      type: String,
      default: "info",
      validator: (value) => {
        const acceptedValues = [
          "info",
          "primary",
          "danger",
          "warning",
          "success",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    timeout: {
      type: Number,
      default: 5000,
      validator: (value) => {
        return value >= 0;
      },
    },
    timestamp: {
      type: Date,
      default: () => new Date(),
    },
    component: {
      type: [Object, Function],
      default() {
        return {};
      },
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    clickHandler: {
      type: Function,
      default() {
        return () => {};
      },
    },
    persist: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasIcon() {
      return ["warning", "danger", "success", "info"].indexOf(this.type) > -1;
    },
    alertType() {
      return `alert-${this.type}`;
    },
    customPosition() {
      const initialMargin = 20;
      const sameAlerts = this.$notifications.state.filter((alert) => {
        return (
          alert.horizontalAlign === this.horizontalAlign &&
          alert.verticalAlign === this.verticalAlign
        );
      });
      // Start witth the initial margin
      let pixels = initialMargin;

      // Find the index of this notification
      const currentIndex = sameAlerts.findIndex((n) => n.timestamp === this.timestamp);

      if (currentIndex !== -1) {
        if (this.$notifications.settings.overlap === true) {
          // If they're designed to overlap - then overlap them by the top margin
          pixels += initialMargin * currentIndex;
        } else if (currentIndex > 0) {
          // Sum the heights of all notifications appearing above this one.
          // Include a 10px margin between each
          pixels += sameAlerts.slice(0, currentIndex).reduce((sum, alert) => {
            sum += (alert.elmHeight ? alert.elmHeight : 0) + 10;
            return sum;
          }, 0);
        }
      }
      const styles = {};
      if (this.verticalAlign === "top") {
        styles.top = `${pixels}px`;
      } else {
        styles.bottom = `${pixels}px`;
      }
      return styles;
    },
  },
  methods: {
    close() {
      this.$emit("close", this.timestamp);
    },
    tryClose(evt) {
      if (this.clickHandler) {
        this.clickHandler(evt, this);
      }
      if (this.closeOnClick) {
        this.close();
      }
    },
  },
  mounted() {
    const elmHeight = this.$el.clientHeight;

    // Find the current alert in the state; and set its height to its calculated value
    this.$notifications.state.forEach((alert) => {
      if (alert.id == this.id) Vue.set(alert, "elmHeight", elmHeight);
    });

    if (this.timeout && !this.persist) {
      setTimeout(this.close, this.timeout);
    }
  },
};
</script>

<style lang="scss">
.notifications .alert {
  position: fixed;
  z-index: 10000;

  &[data-notify='container'] {
    width: 400px;
  }

  &.center {
    margin: 0 auto;
  }
  &.left {
    left: 20px;
  }
  &.right {
    right: 20px;
  }
}
</style>
