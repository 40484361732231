<template>
  <base-select-table
    v-model="selected"
    :columns="columns"
    :data="tableData"
    @nameClick="nameClickAction"
    @input="$emit('input', selected)"
  >
    <template v-slot:header-name>
      Transcripts : {{ tableData.length }} <slot name="tableheader" />
    </template>
    <template v-slot:header-exhibits>
      Exhibits
      <CopyIcon size="16" class="ml-1 text-lightblue" />
    </template>
    <template v-slot:header-signals>
      Signals
      <RepeatIcon size="16" class="ml-1 text-lightblue" />
    </template>
    <!-- eslint-disable-next-line vue/no-template-shadow -->
    <template v-slot:data-actions="{ data }">
      <base-button
        icon
        link
        class="text-white"
        @click="openUploads(data.item.data)"
      >
        <ChevronRightIcon aria-setsize="16" />
      </base-button>
    </template>
    <!-- eslint-disable-next-line vue/no-template-shadow -->
    <template #data-name="{ data: {item, value} }">
      <span
        class="text-white link"
        @click="openUploads(item.data)"
      >{{ value }}</span>
      <div
        v-if="['In Progress'].includes(item.data.__status)"
      >
        <div class="d-flex align-items-center">
          <div
            class="d-inline-block mr-4"
            style="width: 100px; text-align: left"
          >
            Processing
          </div>
          <div class="d-inline-block w-100">
            <base-progress
              :type="'primary'"
              class="w-100"
              :value="item.data.__progress"
              value-position="none"
            />
          </div>
        </div>
      </div>
    </template>
    <!-- eslint-disable-next-line vue/no-template-shadow -->
    <template v-slot:nested-data="{ data }">
      <template v-if="data.item.showNested">
        <template v-if="data.item.nestedDataLoading">
          <tr :key="'nested-' + data.index">
            <td />
            <td :colspan="columns.length" class="text-center">Loading</td>
          </tr>
        </template>
        <template v-else-if="data.item.nestedData.length === 0">
          <tr :key="'nested-' + data.index">
            <td />
            <td :colspan="columns.length" class="text-center">No Exhibits</td>
          </tr>
        </template>
        <template v-else>
          <tr
            v-for="(nestedItem, ni) in data.item.nestedData"
            :key="'nested-' + data.index + '-' + ni"
          >
            <td />
            <td :colspan="columns.length - 2">
              <span class="cursor-pointer" @click="openClip(nestedItem)">{{
                nestedItem.title
              }}</span>
              <span v-if="nestedItem.description">
                ({{ nestedItem.description }})</span
              >
            </td>
            <td class="text-right">
              {{ toSeconds(nestedItem.endOffset - nestedItem.startOffset) }}
            </td>
            <td>
              <base-button
                icon
                link
                class="text-white"
                @click="openClip(nestedItem)"
              >
                <ChevronRightIcon aria-setsize="16" />
              </base-button>
            </td>
          </tr>
        </template>
      </template>
    </template>
  </base-select-table>
</template>

<script>
import { RepeatIcon, CopyIcon, ChevronRightIcon } from "vue-feather-icons";
import { isDefined } from "../../../api/helpers";
import BaseSelectTable from "../../../components/DashboardV2/BaseSelectTable.vue";
import { ethosRouteNames } from "../../../routes/routeNames";
export default {
  name: "uploads-list-table",
  components: {
    BaseSelectTable,
    RepeatIcon,
    CopyIcon,
    ChevronRightIcon,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
      columns: [
        {
          value: "name",
          label: "File Name",
          class: "",
        },
        {
          value: "exhibits",
          label: "Exhibits",
          class: "text-center",
          width: "80px",
        },
        {
          value: "signals",
          label: "Signals",
          class: "text-center",
          width: "80px",
        },
        {
          value: "createdOn",
          label: "Added on",
          class: "text-right",
          width: "250px",
        },
        {
          value: "actions",
          label: "",
          class: "text-right",
          width: "55px",
        },
      ],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(to, from) {
        this.$nextTick(() => {
          this.selected = to.slice();
        });
      },
    },
  },
  methods: {
    nameClickAction(data) {
      this.openUploads(data);
    },
    openUploads(cse) {
      // if still processing (transcribing), don't allow
      if (cse?.__status && cse.__status !== "Complete") {
        this.$notifyError("Upload is not ready yet");
        return;
      }
      this.$router.replace({
        name: ethosRouteNames.CaseViewUploads,
        params: {
          uploadId: cse.uploadId,
        },
      });
    },
    openClip(clip) {
      if (!isDefined(clip)) return;
      this.$router.replace({
        name: ethosRouteNames.Clips,
        params: {
          uploadId: clip.uploadId,
          clipId: clip.id,
        },
      });
    },
    toSeconds(millis) {
      return (millis / 1000).toFixed(2) + "s";
    },
  },
};
</script>
