<template>
  <div>
    <div
      v-if="!user || !user.email"
      class="mb-3"
    >
      <label
        for="email"
        class="form-label"
      > Email </label>
      <input
        id="email"
        v-model="email"
        type="email"
        class="form-control"
        placeholder="Email"
      >
    </div>

    <div class="mb-3">
      <label
        for="title"
        class="form-label"
      > Title </label>
      <input
        id="title"
        v-model="title"
        type="text"
        class="form-control"
        placeholder="Title"
      >
    </div>

    <div class="mb-3">
      <label
        for="first"
        class="form-label"
      > First Name </label>
      <input
        id="first"
        v-model="firstName"
        type="text"
        class="form-control"
        placeholder="First Name"
      >
    </div>

    <div class="mb-3">
      <label
        for="last"
        class="form-label"
      > Last Name </label>
      <input
        id="last"
        v-model="lastName"
        type="text"
        class="form-control"
        placeholder="Last Name"
      >
    </div>

    <el-select
      v-model="roles"
      class="select-primary dark"
      effect="dark"
      popper-class="select-primary"
      :placeholder="rolesPlaceholder"
      multiple
    >
      <el-option
        v-for="item in roleOptions"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>

    <div class="d-flex justify-content-between mt-4">
      <base-button
        class="btn-fixed"
        :loading="isLoading"
        size="sm"
        type="primary"
        @click="cancelClicked"
      >
        Cancel
      </base-button>

      <base-button
        class="btn-fixed"
        :loading="isLoading"
        :disabled="saveIsDisabled"
        size="sm"
        type="primary"
        @click="saveClicked"
      >
        Save
      </base-button>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {patchUser, getUsersOverview, putUser} from "@/api";
import {Roles} from "@/util/enums";

export default {
  name: "UserForm",
  props: {
    user: {
      type: Object,
      default() {
        return null;
      },
    },
    type: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      isLoading: false,
      email: "",
      title: "",
      firstName: "",
      lastName: "",
      roles: [],
      roleOptions: [Roles.admin, Roles.editor, Roles.viewer],
    };
  },
  computed: {
    saveIsDisabled() {
      return !this.email || this.email.length < 5 || this.roles.length < 1;
    },
    canDisableUser() {
      return this.user && this.user.roles.length > 0;
    },
    rolesPlaceholder() {
      return !this.user || this.canDisableUser ? "Assign Roles" : "Assign Roles To Enable";
    },
  },
  mounted() {
    if (!this.user) return;
    this.email = this.user.email;
    this.title = this.user.title;
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.roles = this.user.roles;
  },
  methods: {
    ...mapMutations("data", ["putUserMap"]),
    async saveClicked() {
      if (this.isLoading) return;
      this.isLoading = true;

      if (this.type === "edit") {
        try {
          const response = await patchUser(this.email, this.title, this.firstName, this.lastName, this.roles);
          if (response.message) {
            this.$notifyError(`${this.email} ${response.message}`);
          }
          else {
            this.$notifySuccess(`${this.email} Updated Successfully`);

            const overviewResponse = await getUsersOverview();
            const usersById = {};
            overviewResponse.forEach((e) => usersById[e.id] = e);
            this.putUserMap(usersById);
            this.$emit("complete", response);
          }
        } catch (ex) {
          this.$notifyError("Updating New User Failed", ex);
        } finally {
          this.isLoading = false;
        };
      } else {
        try {
          const response = await putUser(this.email, this.title, this.firstName, this.lastName, this.roles);
          if (response.message) {
            this.$notifyError(`${response.message}: ${this.email}`);
          } else {
            this.$notifySuccess(`${this.email} Added Successfully`);

            this.$emit("complete", response);
          }
        } catch (ex) {
          this.$notifyError("Adding New User Failed", ex);
        } finally {
          this.isLoading = false;
        };
      }
    },
    cancelClicked() {
      this.$emit("handleCanceled");
    },
  },
};
</script>
<style scoped>
</style>