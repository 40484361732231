<template>
  <div class="p-5">
    <div class="d-flex">
      <div
        class="flex-grow-1"
        style="max-width: 300px"
      >
        <h6 class="text-white mb-0">
          {{ title }}
        </h6>
        <div class="text-lightblue font-16">
          {{ itemName }}: {{ pagination.TotalCount }}
        </div>
      </div>
      <div class="d-flex flex-grow-1 justify-content-end">
        <base-dropdown
          class="flex-grow-1 search-menu--container"
          menu-classes="search-menu bg-dark-300 py-0"
        >
          <base-input
            slot="title-container"
            v-model="form.Term"
            placeholder="Search"
            class="mb-0 flex-grow-1"
            @keyup.enter="handleChange"
          >
            <template slot="addonLeft">
              <span class="input-group-prepend input-group-text">
                <SearchIcon size="16" />
              </span>
            </template>
          </base-input>
          <div v-show="false">
            <div class="px-5 pt-5 pb-2 pb-0">
              <div
                v-for="i in 3"
                :key="i"
                class="d-flex align-items-center pb-3"
              >
                <ClockIcon
                  size="18"
                  class="text-lightblue"
                />
                <span class="text-white ml-1"> Recent request </span>
              </div>
            </div>
            <hr class="h-divider m-0">
            <div class="p-5">
              <span
                class="bg-primary px-3 py-2 text-white font-weight-bold rounded-pill"
              >
                Active</span>
              <span
                class="bg-secondary px-3 py-2 ml-3 text-white font-weight-bold rounded-pill"
              >
                In Court Process</span>
              <span
                style="background: #3e5959"
                class="gray px-3 py-2 ml-3 text-white font-weight-bold rounded-pill"
              >
                Inactive</span>
              <span
                style="background: #2d4d00"
                class="px-3 py-2 ml-3 text-white font-weight-bold rounded-pill"
              >
                Closed</span>
            </div>
            <hr class="h-divider m-0">
            <div class="px-5 pb-5 pt-2 d-flex flex-wrap">
              <div
                v-for="(item, i) in infos"
                :key="i"
                class="d-flex align-items-center mr-5 mt-3"
              >
                <span
                  class="text-lightblue font-weight-bold search-badge-info rounded-lg"
                >
                  {{ item.tag }}</span>
                <span class="text-lightblue"> {{ item.title }} </span>
              </div>
            </div>
          </div>
        </base-dropdown>
        <base-button
          simple
          class="m-0 ml-3 px-3"
          style="height: 38px"
          @click="showFilterDetails = !showFilterDetails"
        >
          <FilterIcon
            class="mr-2"
            size="16"
          />
          {{ showFilterDetails ? "Hide" : "Show" }} Filter
        </base-button>
        <base-button
          v-if="showUpload"
          class="m-0 ml-3"
          style="height: 38px"
          @click="$refs.addUploads && $refs.addUploads.display();"
        >
          Upload Media
        </base-button>
      </div>
    </div>

    <div v-if="showFilterDetails">
      <div
        v-if="showFilterDetails"
        class="row mt-3"
      >
        <div
          v-if="statusOptions.length > 0"
          class="col"
        >
          <div class="form-group">
            <label class="text-white font-weight-bold">
              {{ statusLabel }}
            </label>
            <el-select
              v-model="form.Status"
              :placeholder="statusPlaceholder"
              class="select-primary dark"
              effect="dark"
              popper-class="select-primary"
              multiple
              collapse-tags
              @change="handleChange"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div
          v-if="typeOptions.length > 0"
          class="col"
        >
          <div class="form-group">
            <label class="text-white font-weight-bold"> {{ typeLabel }} </label>
            <el-select
              v-model="form.Type"
              :placeholder="typePlaceholder"
              class="select-primary dark"
              effect="dark"
              popper-class="select-primary"
              multiple
              collapse-tags
              @change="handleChange"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="col">
          <base-input
            v-model="form.After"
            placeholder="From"
            @click.prevent="$refs.afterpicker.showPicker"
          >
            <template slot="addonLeft">
              <span class="input-group-prepend input-group-text">
                <CalendarIcon size="16" />
              </span>
            </template>
            <label
              slot="label"
              class="text-white font-weight-bold"
            >
              Date Uploaded
            </label>
          </base-input>
          <el-date-picker
            ref="afterpicker"
            v-model="form.After"
            value-format="yyyy-MM-dd"
            style="visibility: hidden; top: 21px; position: absolute"
            @change="handleChange"
          />
        </div>
        <div class="col">
          <base-input
            v-model="form.Before"
            placeholder="To"
            @click.prevent="$refs.beforepicker.showPicker"
          >
            <label
              slot="label"
              class="text-white font-weight-bold"
              style="visibility: hidden"
            >_</label>
            <template slot="addonLeft">
              <span class="input-group-prepend">
                <div class="input-group-text">
                  <CalendarIcon size="16" />
                </div>
              </span>
            </template>
          </base-input>
          <el-date-picker
            ref="beforepicker"
            v-model="form.Before"
            value-format="yyyy-MM-dd"
            style="visibility: hidden; top: 21px; position: absolute"
            @change="handleChange"
          />
        </div>
        <div
          v-if="creatorOptions.length > 0"
          class="col"
        >
          <div class="form-group">
            <label class="text-white font-weight-bold">
              {{ createdByLabel }}
            </label>
            <el-select
              v-model="form.Creator"
              :placeholder="createdByPlaceholder"
              class="select-primary dark"
              effect="dark"
              popper-class="select-primary"
              collapse-tags
              @change="handleChange"
            >
              <el-option
                v-for="item in creatorOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div
          v-if="subjectOptions.length > 0"
          class="col"
        >
          <div class="form-group">
            <label class="text-white font-weight-bold"> Subject </label>
            <el-select
              v-model="form.Subject"
              placeholder="Choose subjects"
              class="select-primary dark"
              effect="dark"
              popper-class="select-primary"
              multiple
              :multiple-limit="3"
              collapse-tags
              @change="handleChange"
            >
              <el-option
                v-for="item in subjectOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div
          v-if="showPriority"
          class="col"
        >
          <div class="form-group">
            <label class="text-white font-weight-bold"> Priority </label>
            <el-select
              v-model="form.Starred"
              placeholder="Filter by priority"
              class="select-primary dark"
              effect="dark"
              popper-class="select-primary"
              @change="handleChange"
            >
              <el-option
                v-for="item in priorityOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="d-flex mt-3">
        <div class="flex-grow-1 d-flex">
          <template v-if="showFilterDetails">
            <div class="d-flex flex-wrap flex-grow-1">
              <div
                v-for="(chip, i) in filterChips"
                :key="chip.type + chip.value + i"
                class="chip rounded-sm ml-0 mr-2 mt-0 mb-2 bg-filter-chip"
              >
                {{ chip.type }}: {{ chip.display ? chip.display : chip.value }}
                <XIcon
                  class="ml-2 cursor-pointer"
                  @click="() => removeFilter(chip)"
                />
              </div>
            </div>

            <base-button
              link
              class="text-nowrap m-0"
              @click="reset"
            >
              Reset All
            </base-button>
          </template>
        </div>
        <base-button
          link
          class="m-0 text-nowrap"
          @click="toggleDirection"
        >
          <material-icon size="20px">
            swap_vert
          </material-icon>
          {{ direction }}
        </base-button>
        <slot name="actions" />
        <div v-if="changeListStyle">
          <div class="bg-dark-300 rounded-label p-1">
            <base-button
              link
              class="m-0 p-2"
              :class="{ 'bg-table-switch text-white': !isTable }"
              size="xs"
              @click="changeListStyle()"
            >
              <list-icon size="18" />
            </base-button>
            <base-button
              link
              class="m-0 p-2"
              size="xs"
              :class="{ 'bg-table-switch text-white': isTable }"
              @click="changeListStyle()"
            >
              <feather-icon size="18">
                table
              </feather-icon>
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex mt-3"
    >
      <div class="flex-grow-1" />
      <base-button
        link
        class="m-0 text-nowrap"
        @click="toggleDirection"
      >
        <material-icon size="20px">
          swap_vert
        </material-icon>
        {{ direction }}
      </base-button>
      <slot name="actions" />
      <div v-if="changeListStyle">
        <div class="bg-dark-300 rounded-label p-1">
          <base-button
            link
            class="m-0 p-2"
            :class="{ 'bg-table-switch text-white': !isTable }"
            size="xs"
            @click="changeListStyle()"
          >
            <list-icon size="18" />
          </base-button>
          <base-button
            link
            class="m-0 p-2"
            size="xs"
            :class="{ 'bg-table-switch text-white': isTable }"
            @click="changeListStyle()"
          >
            <feather-icon size="18">
              table
            </feather-icon>
          </base-button>
        </div>
      </div>
    </div>
    <add-new-uploads-modal
      ref="addUploads"
      :case-id="caseId"
      @created="$emit('created')"
    />
  </div>
</template>

<script>
import {
  CalendarIcon,
  FilterIcon,
  SearchIcon,
  XIcon,
  ClockIcon,
  ListIcon,
} from "vue-feather-icons";
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import MaterialIcon from "@/components/DashboardV2/MaterialIcon.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import AddNewUploadsModal from "@/pages/DashboardV2/Uploads/AddNewUploadsModal.vue";
import {EMPTY_PAGINATION, EMPTY_SEARCH_WRAPPER} from "../util/consts";
import {isDefined} from "../api/helpers";
import FeatherIcon from "./FeatherIcon.vue";

export default {
  components: {
    BaseInput,
    SearchIcon,
    BaseButton,
    FilterIcon,
    MaterialIcon,
    XIcon,
    CalendarIcon,
    BaseDropdown,
    ClockIcon,
    AddNewUploadsModal,
    FeatherIcon,
    ListIcon,
  },
  props: {
    title: {
      type: String,
      default: "Search",
    },
    itemName: {
      type: String,
      default: "Items",
    },
    statusLabel: {
      type: String,
      default: "Status",
    },
    typeLabel: {
      type: String,
      default: "Type",
    },
    createdByLabel: {
      type: String,
      default: "Created By",
    },
    dateOpenedLabel: {
      type: String,
      default: "Date Opened",
    },
    missedTerm: {
      type: String,
      default: null,
    },
    filter: {
      type: Object,
      default: () => Object.assign({}, EMPTY_SEARCH_WRAPPER.filter),
    },
    pagination: {
      type: Object,
      default: () => Object.assign({}, EMPTY_PAGINATION),
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    creatorOptions: {
      type: Array,
      description: "Users Model",
      default: () => [],
    },
    subjectOptions: {
      type: Array,
      description: "Actors Model",
      default: () => [],
    },
    typeOptions: {
      type: Array,
      default: () => [],
    },
    showPriority: {
      type: Boolean,
      default: false,
    },
    showUpload: {
      type: Boolean,
      default: false,
    },
    caseId: {
      type: Number,
      default: -1,
    },
  },
  watch: {
    filter: {
      immediate: true,
      handler(to) {
        this.form = Object.assign({}, to);
      },
    },
    missedTerm: {
      immediate: true,
      handler(to) {
        if (!to) return;
        this.$notifyWarn(`No search results found for: \"${to}\"`);
      },
    },
  },
  data() {
    return {
      form: Object.assign({}, EMPTY_SEARCH_WRAPPER.filter),
      showFilterDetails: false,
      priorityOptions: [
        {name: "High Priority", value: true},
        {name: "Low Priority", value: false},
      ],
      infos: [
        {
          tag: "#",
          title: "for case number",
        },
        {
          tag: "leo:",
          title: "for law enforcement officer",
        },
        {
          tag: "subject:",
          title: "for subject",
        },
        {
          tag: "date:",
          title: "for date uploaded on",
        },
        {
          tag: "from:",
          title: "and",
        },
        {
          tag: "to:",
          title: "for date range",
        },
        {
          tag: "status:",
          title: "for status",
        },
        {
          tag: "type:",
          title: "for type",
        },
      ],
    };
  },
  inject: {
    isTable: {
      from: "isTable",
      default: false,
    },
    changeListStyle: {
      from: "changeListStyle",
      default: null,
    },
  },
  computed: {
    direction() {
      return this.filter.Ascending ? "The oldest first" : "The newest first";
    },
    filterChips() {
      const selected = [];
      if (this.filter.Term) {
        selected.push(
          ...this.filter.Term.split(" or ")
            .filter((s) => s.length > 0)
            .map((s) => {
              return {type: "Search", key: "Term", value: s};
            })
        );
      }
      if (this.filter.Status) {
        selected.push(
          ...this.filter.Status.map((s) => {
            return {
              type: this.statusLabel,
              key: "Status",
              value: s,
              display: this.statusOptions.filter((o) => o.value === s).pop()
                ?.name,
            };
          })
        );
      }
      if (this.filter.Type) {
        selected.push(
          ...this.filter.Type.map((s) => {
            return {
              type: "Type",
              key: "Type",
              value: s,
              display: this.typeOptions.filter((o) => o.value === s).pop()
                ?.name,
            };
          })
        );
      }
      if (this.filter.Creator) {
        selected.push({
          type: this.createdByLabel,
          key: "Creator",
          value: this.filter.Creator,
          display: this.creatorOptions.filter((o) => o.value === this.filter.Creator).pop()?.name,
        });
      }
      if (this.filter.Subject) {
        selected.push(
          ...this.filter.Subject.map((s) => {
            return {
              type: "Subject",
              key: "Subject",
              value: s,
              display: this.subjectOptions.filter((o) => o.value === s).pop()
                ?.name,
            };
          })
        );
      }
      if (isDefined(this.filter.After)) {
        selected.push({type: "From", key: "After", value: this.filter.After});
      }
      if (isDefined(this.filter.Before)) {
        selected.push({type: "To", key: "Before", value: this.filter.Before});
      }
      if (isDefined(this.filter.Starred)) {
        selected.push({type: "Priority", key: "Starred", value: this.priorityOptions.filter((o) => o.value === this.filter.Starred).pop().name.split(" ")[0]});
      }
      return selected;
    },
    statusPlaceholder() {
      return `Choose ${this.statusLabel.toLowerCase()}`;
    },
    createdByPlaceholder() {
      return `Choose ${this.createdByLabel.toLowerCase()}`;
    },
    typePlaceholder() {
      return `Choose ${this.typeLabel.toLowerCase()}`;
    },
  },
  methods: {
    handleChange() {
      this.$nextTick(() => {
        if (JSON.stringify(this.form) !== JSON.stringify(this.filter)) {
          this.$emit("changed", this.form);
        }
      });
    },
    toggleDirection() {
      this.form.Ascending = !this.form.Ascending;
      this.handleChange();
    },
    removeFilter(chip) {
      if (chip.key === "Term") {
        if (this.form.Term) {
          const res = this.form.Term.split(" or ")
            .filter((v) => v !== chip.value)
            .join(" or ");
          this.form.Term = res.length > 0 ? res : null;
        }
      } else if (Array.isArray(this.form[chip.key])) {
        this.form[chip.key] = this.form[chip.key].filter(
          (v) => v !== chip.value
        );
      } else {
        this.form[chip.key] = null;
      }
      this.handleChange();
    },
    reset() {
      this.$emit("changed", Object.assign({}, EMPTY_SEARCH_WRAPPER.filter));
    },
  },
};
</script>
<style lang="scss">
.search {
  float: right;
}
.search-menu--container {
  max-width: 650px;
}
.search-menu {
  width: 100%;
  &::before {
    display: none !important;
  }
  .search-badge-info {
    background: #5f6969;
    padding: 3px 10px;
    min-width: 30px;
    text-align: center;
    margin-right: 5px;
  }
}
.form-group {
  .el-select {
    .el-input {
      > input.el-input__inner {
        color: #f2f3f3 !important;
        background-color: #dbffff14 !important;
        border-color: #dbffff26 !important;
      }
    }
  }
}
</style>
