<template>
  <div>
    <div class="paginated-table">
      <div class="d-flex align-items-baseline">
        <base-button
          size="sm"
          type="primary"
          @click="showCollectionForm()"
        >
          Add New Collection
          <plus-icon
            size="1.2x"
            class="ml-2"
          />
        </base-button>
      </div>
      <el-table
        ref="tableData"
        :data="collections"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        row-class-name="cursor-pointer"
        :tree-props="{children:'words'}"
        @row-click="rowClicked"
      >
        <el-table-column
          prop="name"
          label="Name"
          sortable
          width="400"
        >
          <div
            slot-scope="{ row }"
            class="d-inline-block"
          >
            <corner-down-right-icon
              v-if="row.displayAs"
              size="1.5x"
              class="mr-2"
            />
            <briefcase-icon
              v-else
              size="1.5x"
              class="mr-2"
            />
            <span>{{ row.phrase || row.name }}</span>
          </div>
        </el-table-column>
        <el-table-column
          prop="displayAs"
          label="Display As"
          sortable
        />
        <el-table-column
          prop="createdOn"
          label="Created On"
          sortable
        >
          <template slot-scope="scope">
            {{ $date(scope.row.createdOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createdBy"
          label="Created By"
          sortable
        />
        <el-table-column
          prop="modifiedOn"
          label="Modified On"
          sortable
        >
          <template slot-scope="scope">
            {{ $date(scope.row.modifiedOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="modifiedBy"
          label="Modified By"
          sortable
        />
        <el-table-column align="center">
          <template slot-scope="scope">
            <base-button
              v-if="scope.row.phrase"
              size="xs"
              type="primary"
              style="padding:5px 26px;"
              simple
              @click="editRowClicked(scope.row)"
            >
              Edit
            </base-button>
            <el-dropdown
              v-else
              size="small"
              split-button
              type="primary"
              class="dropdown-actions"
              @click.stop="editRowClicked(scope.row)"
            >
              Edit
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <a @click="showWordForm(scope.row.id)">
                    Add Word
                  </a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <word-form ref="wordForm" />
    <collection-form ref="collectionForm" />
  </div>
</template>

<script>
import {BriefcaseIcon, PlusIcon, CornerDownRightIcon} from "vue-feather-icons";
import {mapGetters, mapMutations} from "vuex";
import {getCodeWords} from "../../../api";
import {isDefined} from "../../../api/helpers";
import CollectionForm from "./CollectionForm.vue";
import WordForm from "./WordForm.vue";
export default {
  name: "ethos-code-word",
  components: {BriefcaseIcon, PlusIcon, CornerDownRightIcon, WordForm, CollectionForm},
  props: {
    groupId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  watch: {
    groupId: {
      handler(n, o) {
        if (isDefined(n) && n != 0) {
          this.loadWords();
        }
      },
    },
  },
  computed: {
    ...mapGetters("data", [
      "codeWordGroups",
      "userTimezone",
    ]),
    groupName() {
      if (isDefined(this.groupId)) {
        return this.codeWordGroups && this.codeWordGroups[this.groupId].name;
      }
      return "Code Words";
    },
    collections() {
      if (isDefined(this.groupId) && this.codeWordGroups && this.codeWordGroups[this.groupId]) {
        return this.codeWordGroups[this.groupId].collections.map((a) => {
          // This is to correct the table
          // Otherwise it shows words as expandable if the collection.id == word.id
          return Object.assign({}, a, {id: `collection-${a.id}`});
        });
      }
      return [];
    },
  },
  methods: {
    ...mapMutations("data", [
      "putCodeWordGroup",
    ]),
    loadWords() {
      getCodeWords(this.groupId).then((group) => {
        this.putCodeWordGroup(group);
      }).catch((ex) => {
        this.$notifyError("Failed to load Code Word details", ex);
      });
    },
    showCollectionForm(collectionId, collection) {
      this.$refs.collectionForm.display(this.groupId, Object.assign({}, collection, {id: collectionId}));
    },
    showWordForm(collectionId, word) {
      const id = typeof collectionId === "string" ? parseInt(collectionId.replace("collection-", ""), 10) : collectionId;
      this.$refs.wordForm.display(this.groupId, id, word);
    },
    rowClicked(row) {
      console.log(row);
      this.$refs.tableData.toggleRowExpansion(row);
    },
    editRowClicked(row) {
      console.log(row);
      const id = typeof row.id === "string" ? parseInt(row.id.replace("collection-", ""), 10) : row.id;
      if (row.id === id) {
        this.showWordForm(row.codeWordCollectionId, row);
      } else {
        this.showCollectionForm(id, row);
      }
    },
  },
};
</script>
