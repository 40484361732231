<template>
  <dashboard-v-2-layout v-if="!loading">
    <div
      slot="full"
    >
      <template v-if="isRoot && 1 == 2">
        <div>
          <div class="togglebutton switch-change-color mt-3">
            <base-switch
              v-model="displayNotes"
              type="secondary"
              @input="toggleDisplayNotes"
            />
            <span class="label-switch label-right pl-2">Display Transcript Notes</span>
          </div>
          <div class="togglebutton switch-change-color mt-3">
            <base-switch
              v-model="displayIncidents"
              type="secondary"
              @input="toggleDisplayIncidents"
            />
            <span class="label-switch label-right pl-2">Display Transcript Incidents</span>
          </div>
          <div class="togglebutton switch-change-color mt-3">
            <base-switch
              v-model="displayIncidentColors"
              type="secondary"
              @input="toggleDisplayIncidentColors"
            />
            <span class="label-switch label-right pl-2">Display Transcript Incident Colors</span>
          </div>
        </div>
      </template>
      <template v-if="isRoot">
        <h3
          class="heading"
        >
          System Preferences
        </h3>
        <div class="mb-10">
          <label
            for="self-approval"
            class="form-label mr-3"
          > Subject Options: </label>
          <div class="d-flex">
            <tags-input v-model="peopleGroups" />
            <base-button
              class="ml-3 flex-shrink-0"
              size="xxs"
              style="margin-top: 2px"
              icon
              simple
              :disabled="peopleGroupsDisabled"
              @click="savePeopleGroups"
            >
              <save-icon size="1.1x" />
            </base-button>
          </div>
        </div>
      </template>
      <workflows-selection
        ref="workflowsSelection"
        heading="Default File Analysis"
        class="mb-5"
        allow-actions
        @save="saveWorkflows"
      />
      <preferences
        ref="preferences"
        class="mb-5"
        heading="Preferences"
        @save="savePreferences"
      />

      <code-words :heading="codeWordHeading" />
    </div>
  </dashboard-v-2-layout>
</template>

<script>
import CodeWords from "../../Ethos/CodeWord/CodeWords.vue";
import {mapGetters, mapMutations} from "vuex";
import WorkflowsSelection from "../../Components/WorkflowsSelection.vue";
import {setUserConfig} from "../../../api";
import Preferences from "./Preferences.vue";
import DashboardV2Layout from "../../../components/DashboardV2/DashboardV2Layout.vue";
import BaseButton from "../../../components/BaseButton.vue";
import BaseSwitch from "../../../components/BaseSwitch.vue";
import TagsInput from "../../../components/Inputs/TagsInput.vue";
import {SaveIcon} from "vue-feather-icons";

export default {
  components: {
    CodeWords, WorkflowsSelection, Preferences, DashboardV2Layout, BaseButton, BaseSwitch, TagsInput, SaveIcon,
  },
  name: "ethos-settings",
  watch: {
    availablePeopleGroups: {
      handler(to) {
        this.peopleGroups = Array.isArray(to) ? to : [];
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("auth", [
      "userEmail",
      "isRoot",
    ]),
    ...mapGetters("data", [
      "availablePeopleGroups",
    ]),
    peopleGroupsDisabled() {
      return this.availablePeopleGroups.join(",") === this.peopleGroups.join(",");
    },
  },
  data() {
    return {
      loading: false,
      displayNotes: false,
      displayIncidents: false,
      displayIncidentColors: false,
      codeWordHeading: "Code Word Settings",
      peopleGroups: [],
    };
  },
  methods: {
    ...mapMutations("data", [
      "setConfig",
    ]),
    savePeopleGroups() {
      if (this.peopleGroups.length < 1) return;
      this.save(null, null, this.peopleGroups.join(","));
    },
    saveWorkflows() {
      this.save(
        null,
        this.$refs.workflowsSelection && this.$refs.workflowsSelection.checked().join(",")
      );
    },
    savePreferences() {
      this.save(this.$refs.preferences && this.$refs.preferences.tz);
    },
    save(tz, selectedWorkflows, groups) {
      setUserConfig(
        this.userEmail,
        null,
        tz,
        selectedWorkflows,
        null, null, null, null,
        groups
      ).then((config) => {
        this.$notifySuccess("Saved Preferences Successfully");
        if (tz && this.$refs.preferences) this.$refs.preferences.showActions = false;
        if (selectedWorkflows && this.$refs.workflowsSelection) this.$refs.workflowsSelection.showActions = false;
        this.setConfig(config);
      }).catch((ex) => {
        this.$notifyError("Saving Preferences Failed", ex);
        this.cloneConfigLabel();
      });
    },
  },
};
</script>
