<template>
  <modal
    :show.sync="isDisplayed"
    header-classes="justify-content-center"
    footer-classes="px-4 py-3"
    :centered="true"
    class="modal-default"
    @close="() => $emit('close')"
  >
    <!-- Header Slot -->
    <h4
      slot="header"
      class="title title-up"
    >
      {{ headerCopy }}
    </h4>

    <template v-if="isDisplayed">
      <!-- <template v-if="allowMapMatching">
        <div class="mb-3">
          <label class="text-200">Assign to all matching</label>
          <div class="d-flex flex-row flex-wrap">
            <base-checkbox
              v-model="shouldMapMatching"
              class="pull-left mr-3 mt-0 text-200"
              name="shouldMapAll"
            >
              Assign to all matching
            </base-checkbox>
          </div>
        </div>
      </template> -->
      <div class="mb-3">
        <label
          for="content"
          class="form-label"
        > Content </label>
        <input
          id="content"
          :value="eventData.text"
          type="text"
          class="form-control"
          disabled
          placeholder="Content"
        >
      </div>
      <!-- Allow Selection from Existing ETHOS users -->
      <template v-if="showSelect">
        <div class="mb-3 d-flex flex-column">
          <label
            for="select-people"
            class="form-label"
          >
            Select from ETHOS
          </label>
          <el-select
            id="select-people"
            ref="peopleSelect"
            v-model="selectedPeopleIds"
            class="select-primary dark"
            effect="dark"
            popper-class="select-primary"
            :loading="!loadedPeople"
            :loading-text="'Fetching ' + peopleNameLabel"
            filterable
            :multiple="!single"
            :placeholder="peopleNameLabel + ' Groups'"
            @focus="doGetPeople"
          >
            <el-option-group
              v-for="(group, label) in peopleByGroup"
              :key="label"
              :label="label"
            >
              <el-option
                v-for="item in group"
                :key="item.id"
                :label="item.realName"
                :value="item.id"
              />
            </el-option-group>
          </el-select>
        </div>
      </template>

      <!-- Add New -->
      <template v-if="selectedPeopleIds.length < 1">
        <label
          v-if="showSelect"
          for="select-people"
          class="form-label mt-4"
        >
          OR - Add New
        </label>
        <div class="mb-3">
          <label
            for="real-name"
            class="form-label"
          > Real Name </label>
          <input
            id="real-name"
            v-model="realName"
            type="text"
            class="form-control"
            placeholder="Real Name"
          >
        </div>

        <div class="mb-3">
          <label
            for="display-name"
            class="form-label"
          > Display Name </label>
          <input
            id="display-name"
            v-model="displayName"
            type="text"
            class="form-control"
            placeholder="Display Name"
          >
        </div>

        <div class="mb-3">
          <label
            for="description"
            class="form-label"
          > Description </label>
          <textarea
            id="description"
            v-model="description"
            class="form-control p-2"
            rows="3"
          />
        </div>

        <div class="mb-3 d-flex flex-column">
          <label
            for="people-groups"
            class="form-label"
          >
            {{ peopleNameLabel }} Groups
          </label>
          <el-select
            id="people-groups"
            v-model="folders"
            class="select-primary dark"
            effect="dark"
            popper-class="select-primary"
            multiple
            filterable
            :placeholder="peopleNameLabel + ' Groups'"
          >
            <el-option
              v-for="item in availablePeopleGroups"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
      </template>
    </template>

    <!-- Footer Slot -->
    <template slot="footer">
      <base-button
        size="sm"
        class="ml-auto"
        :loading="loading"
        @click="submit"
      >
        Submit
      </base-button>
    </template>
  </modal>
</template>

<script>
import {Modal} from "src/components";
import {Select, Option} from "element-ui";
import {mapGetters, mapMutations} from "vuex";
import {addPeople, getPeople, getPeopleById} from "../../../../api";
import {isDefined} from "../../../../api/helpers";

export default {
  name: "assign-to-speaker-form",
  components: {
    Modal,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props: {
    peopleId: {
      type: Number,
      default: null,
    },
    caseId: {
      type: Number,
      default: null,
    },
    case: {
      type: Object,
      default: null,
    },
    uploadId: {
      type: Number,
      default: null,
    },
    uploads: {
      type: Object,
      default: null,
    },
    projectId: {
      type: Number,
      default: null,
    },
    project: {
      type: Object,
      default: null,
    },
    single: {
      type: Boolean,
      default: true,
    },
    allowMapMatching: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "Add New !!",
    },
  },
  data() {
    return {
      isDisplayed: false,
      loading: false,
      loadingList: true,
      realName: "",
      displayName: "",
      description: "",
      folders: [],
      selectedPeopleIds: [],
      shouldMapMatching: true,
      eventData: null,
    };
  },
  watch: {
    showSelect: function(n, o) {
      if (n && !o) {
        this.doGetPeople();
      }
    },
  },
  computed: {
    ...mapGetters("data", [
      "loadedPeople",
      "peopleByGroup",
      "availablePeopleGroups",
      "peopleNameLabel",
      "personNameLabel",
    ]),
    showSelect() {
      return true;
    },
    headerCopy() {
      return "Assign to Speaker";
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations("data", ["putPeople", "setLoadedPeople"]),
    display(eventData) {
      this.isDisplayed = true;
      this.eventData = eventData;
      if (this.peopleId !== null) {
        this.loading = true;
        getPeopleById(this.peopleId).then((p) => {
          this.realName = p.realName;
          this.displayName = p.displayName;
          this.description = p.description;
          if (p.folders) {
            this.folders = p.folders.split(",");
          }
          this.loading = false;
        }).catch((ex) => {
          this.$notifyError(`Loading ${this.peopleNameLabel} Data Failed`, ex);
          this.reset();
        });
      }
    },
    reset() {
      this.isDisplayed = false;
      this.folders = [];
      this.displayName = "";
      this.realName = "";
      this.description = "";
      this.selectedPeopleIds = [];
      this.loading = false;
      this.$emit("close");
    },
    submit() {
      let selectedId = 0;
      if (this.loading) return;
      this.loading = true;
      // If we've selected people then attribute the person to it
      const selectedIds = [];
      if (Array.isArray(this.selectedPeopleIds)) {
        selectedIds.push(...this.selectedPeopleIds);
      } else if (this.single && isDefined(this.selectedPeopleIds) && this.selectedPeopleIds !== "") {
        selectedIds.push(this.selectedPeopleIds);
      }
      selectedId = selectedIds[0];

      if (selectedId === 0) {
        if (this.folders.length < 1) {
          // They must have at least one folder
          this.$notifyWarn("Must select at least one Group");
          this.loading = false;
          return;
        }

        if (!this.realName || this.realName.length < 1) {
          // They must have at least one folder
          this.$notifyWarn("Must Enter Real Name");
          this.loading = false;
          return;
        }
        addPeople(
          this.realName,
          this.displayName && this.displayName.length > 0 ? this.displayName : this.realName,
          this.description,
          this.folders.join(","),
          this.caseId,
          this.uploadId,
          this.projectId,
          this.case, this.uploads, this.project
        )
          .then((people) => {
            this.$emit("created", people);
            this.assignContentToSpeaker(people.id);
            this.reset();
            this.$notifySuccess(`${this.personNameLabel} Added Successfully`);
          })
          .catch((ex) => {
            this.loading = false;
            this.$notifyError(`Adding New ${this.personNameLabel} Failed`, ex);
          });
        this.$notifyError("Please select a speaker");
        this.loading = false;
        return;
      }

      this.eventData.speakerId = selectedId;
      this.$emit("selectedSpeaker", this.eventData);
      this.reset();
    },
    doGetPeople() {
      if (this.loadedPeople) return;
      getPeople()
        .then((p) => {
          this.putPeople(p);
          this.setLoadedPeople(true);
          this.$refs.peopleSelect && this.$refs.peopleSelect.blur();
          this.$nextTick(() => {
            this.$refs.peopleSelect && this.$refs.peopleSelect.focus();
          });
        })
        .catch((ex) => {
          this.$notifyError(`Loading ${this.peopleNameLabel} Data Failed`, ex);
        });
    },
  },
};
</script>
