<template>
  <i
    :class="{
      'material-icons-outlined': type == 'outlined',
    }"
    class="material-icons"
    :style="{ fontSize: size }"
  >
    <slot></slot>
  </i>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "normal",
    },
    size: {
      type: String,
      default: "18px",
    },
  },
};
</script>

<style>
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}
</style>
