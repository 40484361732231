<template>
  <div class="unclearWords">
    <div class="flex-container-highlight-radio">
      <base-switch
        v-model="removeUnclear"
        type="secondary"
        @input="toggleUnclearMode"
      />
      <h5 class="flex-child-highlight-radio">
        Highlight unclear words
      </h5>
    </div>
    <span
      v-for="entry in unclear"
      :key="entry.s"
      class="cursor-pointer d-block text-100"
      @click="goTo(entry)"
    > {{ content.slice(entry.s, entry.e) }}</span>
  </div>
</template>

<script>
export default {
  name: "unclear-words",

  props: {
    content: {
      type: String,
      default: null,
    },
    metadata: {
      type: Object,
      default: null,
    },
    transcriptionId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    unclear: {
      handler(to) {
        this.$emit("count", to.length);
      },
      immediate: true,
    },
  },
  data() {
    return {
      removeUnclear: true,
    };
  },
  computed: {
    unclear() {
      if (!this.metadata || !this.metadata.entries || !this.content) return [];
      return this.metadata.entries.filter((e) => e.c < 75);
    },
  },
  methods: {
    goTo(entry) {
      const key = `${entry.s}-${entry.e}`;
      const el = document.querySelector(`span[data-key="${key}"`);
      if (el) el.scrollIntoView();
    },
    toggleUnclearMode(type) {
      this.$emit("removeUnclear", this.removeUnclear);
    },
  },
};
</script>
