import {jsonRequest, isDefined, GET, POST, PUT} from "./helpers";

/* Groups */

// All Groups - No Expansions
export const getCodeWordGroups = async () => {
  return await jsonRequest(GET, "CodeWordGroups");
};

// Single Group - No Expansions
export const getCodeWordGroup = async (groupId) => {
  if (!isDefined(groupId)) throw new Error("CodeWordGroup Id Required");
  return await jsonRequest(GET, `CodeWordGroups/${groupId}/`);
};

export const createCodeWordGroup = async (name) => {
  if (!isDefined(name)) throw new Error("CodeWordGroup Name Required");
  return await jsonRequest(POST, "CodeWordGroups", {body: JSON.stringify({name})});
};

export const updateCodeWordGroup = async (groupId, name) => {
  if (!isDefined(groupId)) throw new Error("CodeWordGroup Id Required");
  if (!isDefined(name)) throw new Error("CodeWordGroup Name Required");
  return await jsonRequest(PUT, `CodeWordGroups/${groupId}/`, {body: JSON.stringify({name})});
};

/* Collections */

// Single Group - Expanded Collections
export const getCodeWordCollections = async (groupId) => {
  if (!isDefined(groupId)) throw new Error("CodeWordGroup Id Required");
  return await jsonRequest(GET, `CodeWordGroups/${groupId}/Collections`);
};

// Single Group - No Expansions
export const getCodeWordCollection = async (groupId, collectionId) => {
  if (!isDefined(groupId)) throw new Error("CodeWordGroup Id Required");
  if (!isDefined(collectionId)) throw new Error("CodeWordCollection Id Required");
  return await jsonRequest(GET, `CodeWordGroups/${groupId}/Collection/${collectionId}`);
};

export const createCodeWordCollection = async (groupId, name) => {
  if (!isDefined(groupId)) throw new Error("CodeWordGroup Id Required");
  if (!isDefined(name) || name.length < 1) throw new Error("CodeWordCollection Name Required");
  return await jsonRequest(POST, `CodeWordGroups/${groupId}/Collection`, {body: JSON.stringify({name})});
};

export const updateCodeWordCollection = async (groupId, collectionId, name) => {
  if (!isDefined(groupId)) throw new Error("CodeWordGroup Id Required");
  if (!isDefined(collectionId)) throw new Error("CodeWordCollection Id Required");
  if (!isDefined(name) || name.length < 1) throw new Error("CodeWordCollection Name Required");
  return await jsonRequest(PUT, `CodeWordGroups/${groupId}/Collection/${collectionId}`, {body: JSON.stringify({name})});
};

/* Words */

// Single Group - Expanded Collections && Words
export const getCodeWords = async (groupId) => {
  if (!isDefined(groupId)) throw new Error("CodeWordGroup Id Required");
  return await jsonRequest(GET, `CodeWordGroups/${groupId}/Words`);
};

// Single Collection - Expanded Words
export const getCodeWordsForCollection = async (groupId, collectionId) => {
  if (!isDefined(groupId)) throw new Error("CodeWordGroup Id Required");
  if (!isDefined(collectionId)) throw new Error("CodeWordCollection Id Required");
  return await jsonRequest(GET, `CodeWordGroups/${groupId}/Collection/${collectionId}/Words`);
};

// Single Word
export const getCodeWord = async (groupId, collectionId, wordId) => {
  if (!isDefined(groupId)) throw new Error("CodeWordGroup Id Required");
  if (!isDefined(collectionId)) throw new Error("CodeWordCollection Id Required");
  if (!isDefined(wordId)) throw new Error("CodeWord Id Required");
  return await jsonRequest(GET, `CodeWordGroups/${groupId}/Collection/${collectionId}/Word/${wordId}`);
};

export const createCodeWord = async (groupId, collectionId, phrase, displayAs, soundsLike, ipa) => {
  if (!isDefined(groupId)) throw new Error("CodeWordGroup Id Required");
  if (!isDefined(collectionId)) throw new Error("CodeWordCollection Id Required");
  if (!isDefined(phrase) || phrase.length < 1) throw new Error("CodeWordCollection Phrase Required");
  const data = {
    phrase,
    displayAs: isDefined(displayAs) && displayAs.length > 0 ? displayAs : phrase,
  };
  if (isDefined(soundsLike)) data.soundsLike = soundsLike;
  if (isDefined(ipa)) data.ipa = ipa;
  const options = {body: JSON.stringify(data)};
  return await jsonRequest(POST, `CodeWordGroups/${groupId}/Collection/${collectionId}/Word`, options);
};

export const updateCodeWord = async (groupId, collectionId, wordId, phrase, displayAs, soundsLike, ipa) => {
  if (!isDefined(groupId)) throw new Error("CodeWordGroup Id Required");
  if (!isDefined(collectionId)) throw new Error("CodeWordCollection Id Required");
  if (!isDefined(wordId)) throw new Error("CodeWord Id Required");
  if (
    (!isDefined(phrase) || phrase.length < 1) &&
    !isDefined(displayAs) &&
    !isDefined(soundsLike) &&
    !isDefined(ipa)
  ) throw new Error("Update is empty");
  const data = {};
  if (isDefined(phrase)) data.phrase = phrase;
  if (isDefined(displayAs)) data.displayAs = displayAs;
  if (isDefined(soundsLike)) data.soundsLike = soundsLike;
  if (isDefined(ipa)) data.ipa = ipa;
  const options = {body: JSON.stringify(data)};
  return await jsonRequest(PUT, `CodeWordGroups/${groupId}/Collection/${collectionId}/Word/${wordId}`, options);
};
