<template>
  <modal
    :show.sync="isDisplayed"
    header-classes="justify-content-center"
    footer-classes="px-4 py-3"
    :centered="true"
    class="modal-default modal-wide"
    @close="() => $emit('close')"
  >
    <!-- Header Slot -->
    <h4
      slot="header"
      class="title title-up"
    >
      Update Status
    </h4>

    <template v-if="isDisplayed">
      <div
        class=" mb-3 d-flex flex-column"
      >
        <label
          for="clip-status"
          class="form-label text-lightblue"
        >
          Status
        </label>
        <el-select
          v-model="clipStatus"
          class="select-primary dark"
          effect="dark"
          popper-class="select-primary"
        >
          <el-option
            v-for="item in clipStates"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </div>
    </template>

    <!-- Footer Slot -->
    <template slot="footer">
      <base-button
        size="sm"
        class="ml-auto"
        :loading="loading"
        @click="submit"
      >
        Submit
      </base-button>
    </template>
  </modal>
</template>


<script>
import {Modal} from "src/components";
import {putClip, putUpload} from "../../../api";
import {isDefined} from "../../../api/helpers";
import {mapGetters} from "vuex";

export default {
  name: "clip-status-form",
  components: {
    Modal,
  },
  props: {
    uploadIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    clips: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isDisplayed: false,
      loading: false,
      clipStatus: "",
      clip: null,
      uploadId: null,
    };
  },
  computed: {
    ...mapGetters("data", ["availableElementStates"]),
    clipStates() {
      return this.availableElementStates.filter((s) => s !== "New").map((s) => {
        return {
          name: s,
          value: s,
        };
      });
    },
  },
  methods: {
    display(status, uploadId, clip) {
      this.reset();
      if (isDefined(uploadId)) this.uploadId = uploadId;
      if (isDefined(clip)) this.clip = clip;
      if (isDefined(status)) {
        this.clipStatus = status;
        this.isDisplayed = true;
      } else {
        if (this.availableElementStates && this.availableElementStates.length > 0) {
          this.clipStatus = this.availableElementStates[0];
        }
        this.isDisplayed = true;
      }
    },
    reset(emitClose) {
      this.isDisplayed = false;
      this.clipStatus = "";
      this.clip = null;
      this.uploadId = null;
      this.loading = false;
      if (emitClose === true) this.$emit("close");
    },
    submit() {
      if (this.loading) return;
      this.loading = true;
      const uploadIds = this.uploadIds.slice();
      if (isDefined(this.uploadId)) uploadIds.push(this.uploadId);
      const clips = this.clips.slice();
      if (isDefined(this.clip)) clips.push(this.clip);
      Promise.allSettled([
        ...uploadIds.map((u) => putUpload(u, null, null, null, null, null, this.clipStatus)),
        ...clips.map((c) => putClip(c.uploadId, c.startOffset, c.endOffset, null, null, this.clipStatus)),
      ]).then(() => {
        this.$notifySuccess("Status update success");
        this.$emit("updated");
        this.reset(true);
      }).catch((ex) => {
        this.$notifyError("Status update unsuccessful, please try again");
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
