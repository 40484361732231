<template>
  <div
    class="card"
    :class="[type && `card-${type}`]"
    @click="handleClick"
    :style="{ border: `1px solid ${borderColor}` }"
  >
    <div v-if="$slots.image" class="card-image">
      <slot name="image" />
    </div>
    <div
      v-if="$slots.header || title"
      class="card-header"
      :class="headerClasses"
    >
      <slot name="header">
        <h4 class="card-title">
          {{ title }}
        </h4>
        <p v-if="subTitle" class="card-category">
          {{ subTitle }}
        </p>
      </slot>
    </div>
    <div v-if="$slots.default" class="card-body" :class="bodyClasses">
      <slot />
    </div>
    <div v-if="$slots['image-bottom']" class="card-image">
      <slot name="image-bottom" />
    </div>
    <slot name="raw-content" />
    <div v-if="$slots.footer" class="card-footer" :class="footerClasses">
      <hr v-if="showFooterLine" />
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "card",
  props: {
    title: {
      type: String,
      description: "Card title",
      default: null,
    },
    subTitle: {
      type: String,
      description: "Card subtitle",
      default: null,
    },
    type: {
      type: String,
      description: "Card type (e.g primary/danger etc)",
      default: null,
    },
    showFooterLine: {
      type: Boolean,
      default: false,
    },
    headerClasses: {
      type: [String, Object, Array],
      description: "Card header css classes",
      default: "",
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: "Card body css classes",
      default: "",
    },
    footerClasses: {
      type: [String, Object, Array],
      description: "Card footer css classes",
      default: "",
    },
    borderColor: {
      type: String,
      default: "#474d4d",
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>
<style></style>
