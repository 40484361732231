<template>
  <div>
    <div class="d-flex">
      <span class="text-white font-weight-bold">
        {{ label }}
      </span>
      <div class="flex-1" />
      <a
        class="text-lightblue font-weight-bold"
        @click="clearAll()"
      >Clear all</a>
    </div>
    <div
      class="chip-container font-16 bg-dark-400"
      @click="$refs.input.focus()"
    >
      <div
        v-for="(chip, i) of chips"
        :key="chip.label"
        class="chip"
      >
        {{ chip }}
        <XCircleIcon
          class="ml-2 cursor-pointer"
          @click="deleteChip(i)"
        />
      </div>
      <input
        ref="input"
        v-model="currentInput"
        class="font-16"
        @keypress.enter="saveChip"
        @keydown.delete="backspaceDelete"
        @input="checkForComma"
      >
    </div>
  </div>
</template>

<script>
import {XCircleIcon} from "vue-feather-icons";
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
  },
  components: {
    XCircleIcon,
  },
  data() {
    return {
      chips: [],
      currentInput: "",
    };
  },
  methods: {
    clearAll() {
      this.chips = [];
      this.$emit("input", this.chips);
    },
    saveChip() {
      const {chips, currentInput, set} = this;
      ((set && chips.indexOf(currentInput) === -1) || !set) &&
        chips.push(currentInput);
      this.currentInput = "";
      this.$emit("input", this.chips);
    },
    deleteChip(index) {
      this.chips.splice(index, 1);
      this.$emit("input", this.chips);
    },
    backspaceDelete({which}) {
      which == 8 &&
        this.currentInput === "" &&
        this.chips.splice(this.chips.length - 1);
      this.$emit("input", this.chips);
    },
    checkForComma() {
      if (this.currentInput.includes(",")) {
        this.currentInput = this.currentInput.slice(0, -1);
        this.saveChip();
      }
    },
    getCurrentInput() {
      return this.currentInput;
    },
  },
  mounted() {
    this.chips = this.value || [];
  },
};
</script>

<style lang="scss">
.chip-container {
  width: 100%;
  min-height: 200px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  input {
    flex: 1 1 auto;
    width: 30px;
    border: none;
    margin-top: 4px;
    outline: none;
    padding: 4px;
    color: white;
    background: transparent;
  }
}
.chip {
  padding: 0px 10px;
  margin-left: 8px;
  margin-top: 8px;
  background: #547878;
  border-radius: 8px;
  font-weight: 600;
  height: 36px;
  color: white !important;
  display: flex;
  align-items: center;
}
</style>
