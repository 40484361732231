import {isDefined} from "../api/helpers";

// getter
export const getItemIndex = (obj, name) => {
  if (!Array.isArray(obj) || name === "" || name === undefined || name === null) return -1;
  return obj.findIndex((o) => o.name === name);
};

export const hasChildRoute = (path, pathName) => {
  const route = !path.replace("/", "").endsWith(pathName);
  return route;
};

export const bytesToMegaBytes = (bytes) => bytes / (1024 ** 2);

export const defaultColorOptions = () => {
  return [
    "#1F9C9C",
    "#009C2C",
    "#66ABF4",
    "#1FD6C7",
    "#BD92DD",
    "#F47C92",
    "#EF6C52",
    "#FFB31A",
    "#CBAB2A",
    "#789B9B",
  ];
};

export const stringsForRange = (low, high, pad) => {
  let n = Math.min(low, high);
  const a = [];
  while (n < Math.max(low, high)) {
    a.push(`${n}`.padStart(pad, "0"));
    n++;
  }
  return a;
};

export const secsToTimeString = (secs, skipMillis) => {
  // Formatter
  const hr = "" + Math.floor((secs / 60 / 60) % 60);
  const min = "" + Math.floor((secs / 60) % 60);
  const sec = "" + (secs % 60).toFixed(3);
  const str = `${hr.padStart(2, "0")}:${min.padStart(2, "0")}:${sec.padStart(6, "0")}`; // sec is 6 chars 3dp+dot+2integerpart
  return !skipMillis ? str : str.split(".")[0];
};

const recentSeparator = ",";
const recentKey = (caseId) => `recent_${caseId}`;
const recentPerCaseCount = 8;
export const storeRecentCaseUploads = (caseId, uploadId) => {
  if (isDefined(uploadId) && isDefined(caseId)) {
    const idString = `${uploadId}`;
    const ids = [
      idString,
      ...getRecentCaseUploads(caseId).filter((id) => id !== idString),
    ].slice(0, recentPerCaseCount).join(recentSeparator);
    localStorage.setItem(recentKey(caseId), ids);
  }
};

export const getRecentCaseUploads = (caseId) => {
  if (isDefined(caseId)) {
    const locallyStored = localStorage.getItem(recentKey(caseId));
    if (locallyStored) return locallyStored.split(recentSeparator);
  }
  return [];
};

export const dragElement = (elmnt) => {
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;
  const closeDragElement = () => {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  };

  const elementDrag = (e) => {
    e.preventDefault();
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;

    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    elmnt.style.bottom = "inherit";
  };

  const dragMouseDown = (e) => {
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  };

  if (elmnt) {
    elmnt.onmousedown = dragMouseDown;
  }
};

export const stopDragElement = (elmnt) => {
  if (elmnt) {
    elmnt.removeEventListener("onmousedown", dragElement.dragMouseDown);
  }
};

export const filterToQuery = (filter) => {
  if (!filter) return "";
  return Object.keys(filter)
    .filter((k) => isDefined(filter[k]) && (!Array.isArray(filter[k]) || filter[k].length > 0))
    .map((k) => {
      let key = k;
      let v = filter[key];
      if (Array.isArray(v)) v = v.join(",");
      if (key == "Recent") {
        // Switching, as looking up locally.
        key = "In";
        v = window.localStorage.getItem(`recent-${v}`);
      }
      return `${key}=${v}`;
    })
    .join("&");
};

export default {
  getItemIndex,
  hasChildRoute,
  bytesToMegaBytes,
  stringsForRange,
  secsToTimeString,
  dragElement,
  stopDragElement,
  storeRecentCaseUploads,
  getRecentCaseUploads,
  filterToQuery,
};
