<template>
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
      'has-label': label,
      'has-icon': hasIcon,
      'table-select': tableSelect,
    }"
  >
    <slot name="label">
      <label
        v-if="label"
        class="w-100 mb-0 text-left"
      >
        {{ label }} {{ required ? "*" : "" }}
      </label>
    </slot>
    <div class="mb-0">
      <slot name="addonLeft">
        <span
          v-if="addonLeftIcon"
          class="input-group-prepend"
        >
          <div class="input-group-text"><i :class="addonLeftIcon" /></div>
        </span>
      </slot>
      <slot>
        <select
          :value="value"
          v-bind="$attrs"
          class="form-control bg-dark-400"
          aria-describedby="addon-right addon-left"
          v-on="listeners"
        >
          <option
            v-for="(item, i) in options"
            :key="i"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>
      </slot>
      <slot name="addonRight">
        <span
          v-if="addonRightIcon"
          class="input-group-append"
        >
          <div class="input-group-text"><i :class="addonRightIcon" /></div>
        </span>
      </slot>
    </div>

    <slot
      v-if="error || $slots.error"
      name="error"
    >
      <div class="w-100 text-left my-1">
        <alert-triangle-icon
          size="1x"
          class="error mr-1"
        />
        <label class="error">{{ error }}</label>
      </div>
    </slot>
    <slot name="helperText" />
  </div>
</template>
<script>
import {AlertTriangleIcon} from "vue-feather-icons";
export default {
  components: {
    AlertTriangleIcon,
  },
  inheritAttrs: false,
  name: "base-input",
  props: {
    required: Boolean,
    tableSelect: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      description: "Input label",
      default: "",
    },
    error: {
      type: String,
      description: "Input error",
      default: "",
    },
    value: {
      type: [String, Number],
      description: "Input value",
      default: "",
    },
    addonRightIcon: {
      type: String,
      description: "Input icon on the right",
      default: null,
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left",
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      focused: false,
      touched: false,
    };
  },
  computed: {
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon;
    },
    hasLeftAddon() {
      const {addonLeft} = this.$slots;
      return addonLeft !== undefined || this.addonLeftIcon !== undefined;
    },
    hasRightAddon() {
      const {addonRight} = this.$slots;
      return addonRight !== undefined || this.addonRightIcon !== undefined;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
      };
    },
  },
  methods: {
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit("input", evt.target.value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit("focus", evt);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit("blur", evt);
    },
  },
};
</script>
<style lang="scss">
.table-select {
  margin-bottom: 0px !important;
  select {
    background: none !important;
    border: 0px !important;
    padding: 0px !important;
    color: white;
    font-weight: bold;
  }
}
</style>
