export const IncidentType = {
  timeline: "timeline",
  transcript: "transcript",
};

export const Roles = {
  viewer: "Viewer",
  editor: "Editor",
  admin: "Admin",
};
