<template>
  <card
    class="p-5 mb-4"
    :class="{ 'bg-black': checkbox, 'card-border': !checkbox }"
    @click="$emit('click')"
  >
    <div class="row">
      <div class="col-3">
        <div class="d-flex flex-1 overflow-hidden">
          <div>
            <base-checkbox
              v-model="checkbox"
              inline
              class="mt-1 mr-0"
            />
          </div>
          <div class="d-flex flex-column">
            <StarIcon
              class="mr-3 mt-1 flex-shrink-0 cursor-pointer"
              :class="{
                'text-muted': !caseUploadClipSummary.starred,
                'text-primary': caseUploadClipSummary.starred,
                'fill-primary': caseUploadClipSummary.starred,
              }"
              size="20"
              @click="toggleStarred"
            />
          </div>
          <div class="d-flex flex-column overflow-hidden">
            <h6
              class="text-white my-0 text-truncate cursor-pointer"
              @click="viewClip"
            >
              {{ caseUploadClipSummary.title }}
            </h6>
            <p class="text-lightblue mb-0 text-truncate">
              {{ caseUploadSummary.name }}
            </p>
          </div>
        </div>
        <div class="mt-2 d-flex flex-wrap">
          <span
            class="bg-secondary px-3 py-2 font-weight-bold rounded-pill mr-3 mb-3 d-flex flex-items-center"
          >
            <CheckCircleIcon
              v-if="caseUploadClipSummary.status === 'Evidence'"
              class="mr-2"
              size="18"
            />
            {{ caseUploadClipSummary.status }}
          </span>
        </div>
      </div>
      <div class="col-9">
        <div class="d-flex">
          <div class="flex-grow-1 d-flex">
            <div class="flex-1 pl-3">
              <div class="text-lightblue">
                Type
              </div>
              <div class="text-white">
                {{ caseUploadSummary.uploadType }}
              </div>
            </div>
            <div class="flex-1 pl-3">
              <div class="text-lightblue">
                Created On
              </div>
              <div class="text-white">
                {{
                  $date(caseUploadClipSummary.createdOn)
                    .tz(userTimezone)
                    .format("MMM DD, YYYY")
                }}
              </div>
            </div>
            <div class="flex-1 pl-3">
              <div class="text-lightblue">
                Created By
              </div>
              <div
                class="ellipsis text-white"
                :data-text="caseUploadSummary.uploadMetadata.createdBy"
              >
                {{ caseUploadSummary.uploadMetadata.createdBy }}
              </div>
            </div>
            <div class="flex-1 pl-3">
              <div class="text-lightblue">
                Event Date / Time
              </div>
              <div class="text-white">
                {{
                  $date(caseUploadSummary.startTimeStamp)
                    .tz(userTimezone)
                    .format("MMM DD, YYYY - hh:mm a")
                }}
              </div>
            </div>
            <div class="flex-1 pl-3">
              <div class="text-lightblue">
                Time on Tape
              </div>
              <div class="text-white">
                {{ startTime }} - {{ endTime }}
              </div>
            </div>
            <div class="flex-1 pl-3">
              <div class="text-lightblue">
                Duration
              </div>
              <div class="text-white">
                {{ formattedDuration }}
              </div>
            </div>
            <div>
              <slot name="actions" />
            </div>
          </div>
        </div>
        <div class="pt-4 pl-3">
          <div class="text-white text-truncate">
            <span class="text-lightblue">Description: </span>
            {{ caseUploadClipSummary.description }}
          </div>
        </div>
      </div>
    </div>
    <excerpts
      v-if="headlines.length > 0"
      :headlines="headlines"
      @clicked="handleHighlightClick"
    />
    <div
      v-else-if="caseUploadClipSummary.content"
      class="bg-dark-100 p-3 mt-2 rounded-lg transcript-clip"
    >
      <div class="d-flex align-items-center controls">
        <div class="text-lightblue flex-grow-1">
          Transcript
        </div>
        <base-button
          v-if="showTranscript"
          class="m-0 mr-3"
          size="xs"
          icon-only
          no-fill
          type="primary"
          @click="copyToClipboard"
        >
          <clipboard-icon size="1.2x" />
        </base-button>
        <base-button
          link
          class="m-0"
          @click="showTranscript = !showTranscript"
        >
          {{ showTranscript ? "Hide" : "Show" }}
        </base-button>
      </div>
      <div
        v-if="showTranscript"
        class="text-white mt-1"
      >
        <template v-for="(entry, ei) in transcriptBySpeaker">
          <div
            :key="entry.speakerPos"
            :class="{'mt-5': ei > 0}"
          >
            <transcription-speaker :detail="entry" />
            <div class="mt-1">
              <span
                v-for="(sp, spi) in entry.content"
                :key="entry.speakerPos + '__' + spi"
                :class="{redacted: sp.r}"
              >
                {{ sp.content }}
              </span>
            </div>
          </div>
        </template>
        <template v-if="false">
          <custom-media-controls :sources="['']" />
        </template>
      </div>
    </div>
  </card>
</template>

<script>
import {CheckCircleIcon, StarIcon, ClipboardIcon} from "vue-feather-icons";
import BaseButton from "@/components/BaseButton.vue";
import Card from "@/components/Cards/Card.vue";
import BaseCheckbox from "../../Inputs/BaseCheckbox.vue";
import CustomMediaControls from "../../../pages/DashboardV2/Uploads/AudioTranscript/CustomMediaControls.vue";
import TranscriptionSpeaker from "../../../pages/DashboardV2/Uploads/AudioTranscript/TranscriptionSpeaker.vue";
import {isDefined} from "../../../api/helpers";
import {mapGetters, mapMutations} from "vuex";
import Excerpts from "./Excerpts.vue";
import {ethosRouteNames} from "../../../routes/routeNames";
import {putClip} from "../../../api";
import {convertTranscriptDomToText} from "../../../util/exportUtil";

export default {
  props: {
    caseUploadClipSummary: {
      type: Object,
      default() {
        return null;
      },
    },
    caseUploadSummary: {
      type: Object,
      default() {
        return null;
      },
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Card,
    StarIcon,
    CheckCircleIcon,
    ClipboardIcon,
    BaseButton,
    BaseCheckbox,
    CustomMediaControls,
    TranscriptionSpeaker,
    Excerpts,
  },
  data() {
    return {
      loading: false,
      showTranscript: false,
      checkbox: false,
    };
  },
  watch: {
    checkbox: {
      immediate: false,
      handler(to, from) {
        if (to !== from) this.$emit("selected", {selected: to, data: this.caseUploadClipSummary});
      },
    },
  },
  mounted() {
    if (this.selected === true) this.checkbox = true;
  },
  computed: {
    ...mapGetters("data", ["userTimezone"]),
    uploadsCount() {
      return this.caseUploadClipSummary.uploadsCount;
    },
    keywordCount() {
      return this.caseUploadClipSummary.caseKeywordCount;
    },
    peopleCount() {
      return (
        this.caseUploadClipSummary.userAttributionCount + this.caseUploadClipSummary.actorAttributionCount
      );
    },
    newUploadsCount() {
      return 0;
      // / Replace with a rollup?
      // return (this.caseUploadClipSummary.uploads ?? []).filter((e) => e.status === "new").length;
    },
    formattedDuration() {
      if (!isDefined(this.caseUploadClipSummary)) {
        return "- -";
      }
      const millis = this.caseUploadClipSummary.endOffset - this.caseUploadClipSummary.startOffset;
      if (millis < 1) return "- -";
      const parts = this.durationParts(millis);
      let response = `${parts.s}s`;
      if (parts.m > 0 || parts.h > 0) {
        response = `${parts.m}m ${response}`;
        if (parts.h > 0) {
          response = `${parts.h}h ${response}`;
        }
      }
      return response;
    },
    startTime() {
      return this.timeFromParts(this.durationParts(this.caseUploadClipSummary.startOffset));
    },
    endTime() {
      return this.timeFromParts(this.durationParts(this.caseUploadClipSummary.endOffset));
    },
    headlines() { // TODO: - Mixin
      if (
        !isDefined(this.caseUploadClipSummary.headlines) ||
        this.caseUploadClipSummary.headlines.length === 0) {
        return [];
      }
      return this.caseUploadClipSummary.headlines.split("...").map((l) => {
        // split on b tags.
        const spans = l.replaceAll("</b>", "<b>").split("<b>");
        if (l.startsWith("<b>")) return [...spans];
        return spans;
      });
    },
    transcriptBySpeaker() {
      if (
        !isDefined(this.caseUploadSummary) ||
        !isDefined(this.caseUploadClipSummary.content) ||
        !isDefined(this.caseUploadClipSummary.metadata) ||
        !Array.isArray(this.caseUploadClipSummary.metadata.entries) ||
        !Array.isArray(this.caseUploadClipSummary.metadata.speakers)
      ) {
        return [];
      }
      const content = this.caseUploadClipSummary.content;
      const entries = this.caseUploadClipSummary.metadata.entries.slice();
      const speakers = this.caseUploadClipSummary.metadata.speakers.slice();
      if (entries.length === 0 || speakers.length === 0) return [];
      const offset = entries[0].s;
      let lastIndex = entries[entries.length - 1].e;

      const spans = entries.map((e) => {
        return {
          r: e.r === true,
          content: content.slice(e.s - offset, e.e - offset),
          start: e.s,
          end: e.e,
        };
      });
      return speakers.reverse().map((s) => {
        const slice = spans.filter((sp) => sp.start >= s.s && sp.end <= lastIndex);
        lastIndex = s.s;
        const toReturn = {
          content: slice,
          rawContent: slice.reduce((a, e) => a + e.content, ""),
          speakerPos: s.s,
          speakerActorId: null,
          speakerUserId: null,
          speakerTag: s.tag,
        };
        if (isDefined(s.uid)) {
          toReturn.speakerUserId = s.uid;
        } else if (isDefined(s.aid)) {
          toReturn.speakerActorId = s.aid;
        }
        return toReturn;
      }).reverse();
    },
  },
  methods: {
    ...mapMutations("data", ["putHighlightSnippet"]),
    handleHighlightClick(highlights) {
      if (highlights) {
        this.putHighlightSnippet(highlights.join(""));
        this.viewClip();
      }
    },
    pad(i) {
      return `${i}`.padStart(2, "0");
    },
    timeFromParts(parts) {
      return `${this.pad(parts.h)}:${this.pad(parts.m)}:${this.pad(parts.s)}`;
    },
    durationParts(millis) { // TODO: - Mixin
      if (!isDefined(millis) || millis < 1) return {h: 0, m: 0, s: 0};
      const minutes = millis / 60000;
      const fminutes = Math.floor(minutes);
      const seconds = Math.floor((minutes - fminutes) * 60);
      const response = {h: 0, m: fminutes, s: seconds};

      if (minutes >= 60) {
        response.h = Math.floor(minutes / 60);
        response.m = Math.floor(minutes - (hours * 60));
      }
      return response;
    },
    toggleStarred() {
      if (this.loading) return;
      this.loading = true;
      putClip(
        this.caseUploadSummary.uploadId,
        this.caseUploadClipSummary.startOffset,
        this.caseUploadClipSummary.endOffset,
        null, null, null,
        !this.caseUploadClipSummary.starred
      ).then((clip) => {
        this.$emit("updated", clip);
      })
        .catch((ex) => {
          this.$notifyError("Unable to update favorite status", ex);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewClip() {
      this.$router.push({
        name: ethosRouteNames.ClipV2,
        params: {
          caseId: this.caseUploadSummary.caseId,
          uploadId: this.caseUploadSummary.uploadId,
          clipId: this.caseUploadClipSummary.id,
        },
      });
    },
    async copyToClipboard(e) {
      let n = e.target.parentNode;
      while (n != null && n.className.indexOf("transcript-clip") === -1) {
        n = n.parentNode;
      }
      const text = convertTranscriptDomToText(n, ["controls"]);
      try {
        await navigator.clipboard.writeText(text.trim());
        this.$notifySuccess("Copied to clipboard.");
      } catch (error) {
        console.error(error.message);
      }
    },
  },
};
</script>
<style>
.box{
  background:#fff;
  box-shadow: 0 25px 30px 0 rgba(0,0,0,0.15);
  border:rgba(0,0,0,0.3);
  width:10rem;
  margin:2rem auto;
  padding:2rem;
}
.ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .2s linear;
    max-width: 150px;
}
.ellipsis:focus, .ellipsis:hover {
  color:transparent;
}
.ellipsis:focus:after,.ellipsis:hover:after{
    content: attr(data-text);
    overflow: visible;
    text-overflow: inherit;
    background: #fff;
    position: absolute;
    left:auto;
    top:auto;
    /* width: auto; */
    padding: 0.25rem;
    width: auto;
    max-width: 20rem;
    border: 1px solid #eaebec;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.28);
    white-space: normal;
    word-wrap: nowrap;
    display:block;
    color:black;
    margin-top:-1.25rem;
    z-index: 20000;
  }
  .transcript-clip span.redacted {
    text-decoration: line-through;
  }
</style>
