import {doRequest, jsonRequest, jsonRequestPaginated, isDefined, GET, POST, PUT, DELETE} from "./helpers";

export const addActor = async (
  fullName, aliasCsv
) => {
  if (!isDefined(fullName)) throw new Error("Real Name Required");
  const data = {
    fullName,
    aliasCsv: aliasCsv ?? "",
  };
  return await jsonRequest(POST, "Actors", {body: JSON.stringify(data)});
};

export const getActors = async () => await jsonRequest(GET, "Actors");

export const getActorsSearch = async (pageNumber, pageSize, filter) => {
  return await jsonRequestPaginated(
    GET,
    "Actors/Search",
    {pageNumber, pageSize, filter}
  );
};

export const getActorById = async (id) => {
  if (!isDefined(id)) throw new Error("Id Required");
  return await jsonRequest(GET, `Actors/${id}`);
};

export const updateActor = async (
  id, fullName, aliasCsv, description
) => {
  if (!isDefined(id)) throw new Error("Id Required");
  const data = {
    fullName: fullName || null,
    aliasCsv: aliasCsv || null,
    description: description || null,
  };

  return await jsonRequest(PUT, `Actors/${id}`, {body: JSON.stringify(data)});
};

export const deleteActor = async (id) => {
  if (!isDefined(id)) throw new Error("Id Required");
  return await doRequest(DELETE, `Actors/${id}`);
};

export const getActorIncidents = async (actorId) => {
  if (!isDefined(actorId)) throw new Error("Actor Id Required");
  return await jsonRequest(GET, `Actors/${actorId}/Incidents`);
};
