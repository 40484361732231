<template>
  <div :class="{ 'text-center': isMini }">
    <base-button
      v-if="isMini"
      link
      class="mb-0"
    >
      <PlusIcon />
    </base-button>
    <template v-else>
      <div class="p-5">
        <base-button
          block
          class="m-0 ml-2"
          @click="$refs.addCase.showModal = true"
        >
          <PlusIcon
            size="20"
            class="mr-2"
          />
          New Case
        </base-button>
      </div>
      <div class="h-divider" />
    </template>
    <div
      v-if="isMini"
      class="text-center"
    >
      <base-button
        v-for="(menu, i) in menuItems"
        :key="i"
        link
        class="mb-0"
        :class="{ 'bg-active': i == activeMenu }"
        @click="activeMenu = i"
      >
        <component
          :is="menu.icon"
          class="text-white"
          style="animation: none !important"
        />
      </base-button>
    </div>
    <div
      v-else
      class="p-5"
    >
      <div
        v-for="(menu, i) in menuItems"
        :key="i"
        class="d-flex align-items-center py-2 px-3 mb-2 rounded-lg font-weight-bold cursor-pointer"
        :class="{ 'bg-active': i == activeMenu }"
        @click="activeMenu = i"
      >
        <component
          :is="menu.icon"
          class="text-white"
          style="animation: none !important"
        />
        <div class="flex-grow-1 text-white ml-3">
          {{ menu.title }}
        </div>
        <div v-if="badges[i]">
          <span class="badge bg-primary-200 badge-pill">{{ badges[i] }}</span>
        </div>
      </div>
    </div>

    <add-case-modal ref="addCase" />
  </div>
</template>

<script>
import AddCaseModal from "@/components/DashboardV2/Case/AddCaseModal.vue";
import {PlusIcon} from "vue-feather-icons";
import {CASE_MENU_ITEMS} from "../../../util/consts";

export default {
  components: {
    PlusIcon,
    AddCaseModal,
  },
  data() {
    return {
      activeMenu: 0,
      badges: {3: 0},
    };
  },
  inject: ["isMini"],
  watch: {
    activeMenu(val) {
      this.$emit("changed", this.menuItems[val]);
    },
  },
  computed: {
    menuItems() {
      return CASE_MENU_ITEMS;
    },
  },
};
</script>

<style></style>
