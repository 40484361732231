
<template>
  <svg
    class="icon-svg feather"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    :height="size.replace('x', 'rem')"
    :width="size.replace('x', 'rem')"
    viewBox="0 0 400 400"
  >
    <!-- eslint-disable max-len -->
    <path
      d="M84.8 17.3c-8.6 3-9.6 5.6-15.3 37.2-7.6 41.8-9 39.2 28.8 51.8 29.7 10 35 10 40.8.5 7.5-12.1 2-22.5-15-28l-7.7-2.6 4.1-2.6c98.5-63 228.3 8.8 228.3 126.4a148.6 148.6 0 0 1-196 141.8A150.2 150.2 0 0 1 59 150c4.2-12.4-.4-22.7-11.1-25-11-2.3-17.6 2.7-22.4 17.3A183.6 183.6 0 1 0 380.4 166a183.3 183.3 0 0 0-263-129.6l-11 5.5.5-2.4c3.4-15-8.5-27-22.1-22.2m60.1 116.3a387.8 387.8 0 0 0-50.6 31.7c-5.5 7.8-2.6 20.3 5.6 24.5 7.4 3.8 12.6 2.9 24.3-4.2l8.2-5 .4 45.4.4 45.5 2 3.5a17.1 17.1 0 0 0 29.6 0l2-3.5v-63.3c0-69.7.3-65-4.8-70.2a18 18 0 0 0-17-4.4m88 0c-35.8 6-58.5 54-47 98.9 18.2 71.4 99.7 66.5 112.8-6.9 9.4-52.3-24-98.9-65.9-92m15.4 35.6c23 11.5 23.6 63.4 1 77.8-8.6 5.4-19.8.1-26.1-12.3-15.5-30.7 2.2-76.9 25.1-65.5"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: "skip-10-backward-icon",
  props: {
    size: {
      type: String,
      default: "1x",
    },
  },
};
</script>
