<template>
  <div class="d-flex h-100">
    <collapse-container
      classes="d-flex flex-row-reverse"
      collapse-button-classes="position-relative"
      collapse-button-icon-classes="position-absolute top-0 w-100"
    >
      <tabs
        ref="tabs"
        type="info"
        tab-nav-wrapper-classes="p-2"
        tab-content-classes="w-100 h-100 p-0"
        vertical
        class="d-flex h-100"
        font="Roboto"
        @tab-clicked="tabClicked"
      >
        <!-- Use custom handler to avoid tab selection - just want to handle the form display -->
        <tab-pane id="0">
          <span slot="label">
            <file-text-icon size="1.5x" />
            Transcript
          </span>
        </tab-pane>
        <tab-pane
          id="1"
          :key="dloading"
          :custom-handler="downloadOriginal"
        >
          <span slot="label">
            <loader-icon
              v-if="dloading"
              size="1.5x"
            />
            <download-icon
              v-else
              size="1.5x"
            />
            File
          </span>
        </tab-pane>
        <tab-pane
          id="2"
        >
          <span slot="label">
            <user-plus-icon size="1.5x" />
            {{ peopleNameLabel }}
          </span>
        </tab-pane>
        <tab-pane
          id="3"
          has-submenu
        >
          <span slot="label">
            <el-menu
              ref="noteMenu"
              @select="menuSelected"
            >
              <el-submenu
                index="3-0"
                vertical-align="left"
              >
                <template slot="title">
                  <span
                    slot="title"
                  >
                    <file-icon
                      size="1.5x"
                      class="mr-2"
                    />
                    Notes
                  </span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    index="3-1"
                    @click="added"
                  >
                    <plus-icon
                      size="1.5x"
                      class="mr-2"
                      style="margin-left: -8px"
                    />
                    Add Note
                  </el-menu-item>
                  <el-menu-item
                    index="3-2"
                    @click="viewed"
                  >
                    <eye-icon
                      size="1.5x"
                      class="mr-2"
                      style="margin-left: -8px"
                    />
                    View Notes
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>

          </span>
        </tab-pane>
        <tab-pane id="4">
          <span slot="label">
            <briefcase-icon size="1.5x" />
            {{ incidentNameLabel }}s
          </span>
        </tab-pane>
        <tab-pane id="5">
          <span slot="label">
            <scissors-icon size="1.5x" />
            Clips
          </span>
        </tab-pane>
      </tabs>
      <add-note
        ref="addNoteForm"
        header="Add New Note"
        :upload-id="uploadId"
      />
    </collapse-container>
    <!-- <presentation-view
      display="1x1"
      :upload-id="uploadId"
    /> -->
    <router-view v-if="!clickedView" />
    <div
      v-else
      class="flex-grow-1"
    >
      <notes />
    </div>
    <download-blob
      ref="dlblob"
      :blob="dlFileBlob"
      :file-name="dlFileName"
      :content-type="dlFileContentType"
      :visible="false"
    />
  </div>
</template>

<script>
import {TabPane, Tabs} from "src/components";
import {mapGetters, mapMutations} from "vuex";
import {getUploadById, downloadUpload} from "../../../api";
import {isDefined} from "../../../api/helpers";
import {ethosRouteNames} from "../../../routes/routeNames";
import Notes from "src/pages/Ethos/Notes/Notes.vue";
import AddNote from "src/pages/Ethos/Notes/AddNote/AddNote.vue";
import DownloadBlob from "../DownloadBlob.vue";
import {DownloadIcon, FileTextIcon, FileIcon, ScissorsIcon, PlusIcon, BriefcaseIcon, EyeIcon, LoaderIcon, UserPlusIcon} from "vue-feather-icons";
import "src/assets/sass/custom/paginated-table.scss";
import {storeRecentCaseUploads} from "../../../util/util";

// Test
// import PresentationView from "src/pages/Ethos/Uploads/ViewUploads/PresentationView/PresentationView.vue";
export default {
  name: "view-uploads",
  components: {
    Tabs,
    TabPane,
    Notes,
    AddNote,
    DownloadBlob,
    FileIcon,
    PlusIcon,
    ScissorsIcon,
    BriefcaseIcon,
    EyeIcon,
    FileTextIcon,
    DownloadIcon,
    LoaderIcon,
    UserPlusIcon,
    // PresentationView,
  },
  data() {
    return {
      uploads: null,
      selectedTab: "0",
      lastMenuItemClicked: null,
      clickedView: false,
      dlFileBlob: null,
      dlFileName: null,
      dlFileContentType: null,
      dloading: false,
    };
  },
  watch: {
    uploadId: {
      handler(n, o) {
        if (isDefined(n) && (!isDefined(o) || n !== o)) this.loadUploads();
      },
      immediate: true,
    },
    hasSignedUrl(to, from) {
      if (to && !from) {
        this.$nextTick(this.bindRefs);
      }
    },
  },
  computed: {
    ...mapGetters("data", [
      "peopleNameLabel",
      "uploadsNameLabel",
      "incidentNameLabel",
    ]),
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    uploadId() {
      const id = this.$route.params.uploadId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    noteId() {
      const id = this.$route.params.noteId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
  },
  updated() {
    const name = this.$router.currentRoute.name;
    const root = name === ethosRouteNames.Uploads ||
        name === ethosRouteNames.ProjectUploads ||
        name === ethosRouteNames.ViewUploads;
    if (root) this.displayTranscript();
  },
  mounted() {
    storeRecentCaseUploads(this.caseId, this.uploadId);
    let quickNavTo = 0;
    if (this.$router.currentRoute.name === ethosRouteNames.Incidents) {
      quickNavTo = 4;
    } else if (this.$router.currentRoute.name === ethosRouteNames.Clips) {
      quickNavTo = 5;
    }
    if (quickNavTo > 0) {
      const me = this;
      this.$nextTick(() => {
        if (me.$refs.tabs) {
          me.$refs.tabs.activateTab(me.$refs.tabs.tabs[quickNavTo]);
        }
      });
    } else {
      this.displayTranscript();
    }
    if (this.noteId !== null) {
      const me = this;
      this.$nextTick(() => {
        // Set Notes as the active tab
        // IMPORTANT: - needs updating if any indices change.
        if (me.$refs.tabs) {
          me.$refs.tabs.activateTab(me.$refs.tabs.tabs[3]);
        }
        if (me.$refs.noteMenu) {
          me.$refs.noteMenu.openMenu("3-0", 0);
          me.$refs.noteMenu.activeIndex = "3-2";
          me.clickedView = true;
        }
      });
    }
  },
  methods: {
    ...mapMutations("data", [
      "setBreadcrumbHint",
      "putUpload",
    ]),
    loadUploads() {
      this.dlFileBlob = null;
      this.dlFileName = null;
      this.dlFileContentType = null;
      this.uploads = null;
      getUploadById(this.uploadId).then((e) => {
        this.uploads = e;
        this.putUpload(e);
        this.setBreadcrumbHint({name: ethosRouteNames.ViewUploads, value: e.name});
      }).catch((ex) => {
        this.$notifyError("Failed to find uploads", ex);
      });
    },
    downloadOriginal() {
      if (!isDefined(this.uploadId) || !isDefined(this.uploads)) return;
      if (this.dloading) return;
      const uploadName = this.uploads.originalFileName;
      const contentType = this.uploads.originalFileContentType;
      this.dloading = true;
      // TRUE for original
      downloadUpload(this.uploadId, true).then((blob) => {
        this.dlFileBlob = blob;
        this.dlFileName = uploadName;
        this.dlFileContentType = contentType;
        this.$nextTick(() => {
          this.$refs.dlblob.directDownload();
          this.dloading = false;
        });
      }).catch((ex) => {
        this.$notifyError("Unable to download uploads file", ex);
        this.dloading = false;
      });
    },
    displayTranscript() {
      const newPath = `/uploads/${this.uploadId}/transcript`;
      this.currentOption = "transcript";
      if (this.$route.path === newPath) return;
      this.$router.push(newPath);
    },
    menuSelected(x) {
      if (x === "3-1") {
        this.$refs.noteMenu.activeIndex = this.lastMenuItemClicked;
        return;
      }
      this.lastMenuItemClicked = x;
    },
    added() {
      this.$refs.addNoteForm.display();
    },
    viewed() {
      this.clickedView = true;
    },
    tabClicked(id) {
      switch (id) {
        case "3":
          break;
        default:
          if (this.$refs.noteMenu) {
            this.$refs.noteMenu.activeIndex = null;
            this.lastMenuItemClicked = null;
            this.clickedView = false;
          }
          this.routeTo(id);
          break;
      }
      this.selectedTab = id;
    },
    routeTo(id) {
      const prefix = `/uploads/${this.uploadId}`;
      const suffix = {
        "0": "transcript",
        "2": "people",
        "4": "incidents",
        "5": "clips",
      }[id];
      if (!isDefined(suffix)) return;
      const fullPath = `${prefix}/${suffix}`;
      if (this.$route && this.$route.path.startsWith(prefix) && this.$route.path !== fullPath) {
        this.$router.push(fullPath);
      }
    },
  },
};
</script>
