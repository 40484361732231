<template>
  <dashboard-v-2-layout >
    <template slot="full">
      <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-md-5">
            <div >
              <h1 class="display-4 mt-10">
                Need Help?
              </h1>
              <p>Our team is here to provide you with the support you need.</p>
              <hr class="my-4">
              <p>
                If you're experiencing issues or have any questions, feel free to reach out to our
                support team. We're dedicated to ensuring you have the best experience possible.
              </p>
              <div class=" mt-10">
                <p>Email us at support@ethos360.io</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </dashboard-v-2-layout>
</template>

<script>
import DashboardV2Layout from "../../../components/DashboardV2/DashboardV2Layout.vue";
export default {
  name: "AdminWrapper",
  components: {
    DashboardV2Layout,
  },
};
</script>
