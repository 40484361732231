<template>
  <router-view />
</template>

<script>
import {mapActions, mapMutations} from "vuex";
export default {
  watch: {
    "$auth.isAuthenticated": function(n, o) {
      if (o && !n) this.doSignout();
    },
    "authenticatedAndLoaded": function(n, o) {
      if (!o && n) this.doSignin();
    },
  },
  computed: {
    authenticatedAndLoaded() {
      return !this.$auth.loading && this.$auth.isAuthenticated;
    },
  },
  mounted() {
    this.initializeLayout();
  },
  methods: {
    ...mapMutations("auth", [
      "reset",
    ]),
    ...mapActions([
      "authenticateAsync",
    ]),
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    },
    doSignin() {
      this.authenticateAsync()
        .then(() => {})
        .catch((ex) => {
          console.error("Authentication failed", ex);
          // TODO: - Handle error?
        });
    },
    doSignout() {
      this.reset();
    },
  },
};
</script>
