<template>
  <div
    :class="classes"
    class="collapse-container"
  >
    <base-button
      size="xs"
      link
      class="collapse-toggle"
      :class="collapseButtonClasses"
      @click="toggleCollapse"
    >
      <div :class="collapseButtonIconClasses">
        <chevrons-right-icon
          v-if="isCollapse"
          size="1.5x"
        />
        <chevrons-left-icon
          v-if="!isCollapse"
          size="1.5x"
        />
      </div>
      {{ collapseButtonText }}
    </base-button>
    <el-menu
      default-active="2"
      :collapse="isCollapse"
      @open="handleOpen"
      @close="handleClose"
    >
      <slot />
    </el-menu>
  </div>
</template>

<script>
import {ChevronsRightIcon, ChevronsLeftIcon} from "vue-feather-icons";
export default {
  components: {
    ChevronsRightIcon,
    ChevronsLeftIcon,
  },
  props: {
    classes: {
      type: String,
      default: "",
    },
    collapseButtonClasses: {
      type: String,
      default: "",
    },
    collapseButtonText: {
      type: String,
      default: "",
    },
    collapseButtonIconClasses: {
      type: String,
      default: "",
    },
  },
  name: "collapse-container",
  data() {
    return {
      isCollapse: false,
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
      this.$emit("collapse", this.isCollapse);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
