<template>
  <a
    v-if="ready && visible"
    :href="safeUrl"
    :download="safeFileName"
    :target="target"
  >
    Download {{ safeFileName }}
  </a>
</template>

<script>

import mimeLite from "mime/lite";

export default {
  name: "view-uploads",
  components: {

  },
  props: {
    blob: {
      type: Blob,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    fileName: {
      type: String,
      default: null,
    },
    contentType: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lastObjectUrl: null,
    };
  },
  watch: {
    blob: function(n, o) {
      if (n !== o) {
        this.revokeObjectURL();
      }
    },
  },
  computed: {
    ready() {
      return this.blob && this.fileName && this.fileName.length > 0;
    },
    safeFileName() {
      if (!this.ready) return null;
      if (this.contentType) {
        const fileExtension = mimeLite.getExtension(this.contentType);
        if (!this.fileName.endsWith(fileExtension)) {
          return `${this.fileName}${this.fileName.endsWith(".") ? "" : "."}${fileExtension}`;
        }
      }
      return this.fileName;
    },
    safeUrl() {
      if (!this.ready) return null;
      if (this.url) return this.url;
      if (!this.visible) return null;
      this.setObjectUrl();
      return this.lastObjectUrl;
    },
    target() {
      return typeof tempLink.download === "undefined" ? "_blank" : "";
    },
  },
  beforeDestroy() {
    this.revokeObjectURL();
  },
  methods: {
    setObjectUrl() {
      if (this.lastObjectUrl) return this.lastObjectUrl;
      this.lastObjectUrl = URL.createObjectURL(this.blob);
      return this.lastObjectUrl;
    },
    revokeObjectURL() {
      const last = this.lastObjectUrl;
      this.lastObjectUrl = null;
      if (last) {
        URL.revokeObjectURL(last);
      }
    },
    directDownload() {
      const shouldRevoke = this.lastObjectUrl === null;
      const blobURL = this.setObjectUrl();
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = blobURL;
      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
      } else {
        tempLink.setAttribute("download", this.safeFileName);
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        if (shouldRevoke) window.URL.revokeObjectURL(blobURL);
      }, 100);
    },
  },
};
</script>
