var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(_vm.tag,{tag:"component",class:[
      { 'col-md-4': _vm.vertical && !_vm.tabNavWrapperClasses },
      { 'col-12': _vm.centered && !_vm.tabNavWrapperClasses },
      _vm.tabNavWrapperClasses,
    ]},[_c('ul',{staticClass:"nav nav-pills",class:[
        `nav-pills-${_vm.type}`,
        { 'nav-pills-icons': _vm.square },
        { 'flex-column': _vm.vertical },
        { 'justify-content-center': _vm.centered },
        _vm.tabNavClasses,
      ],attrs:{"role":"tablist"}},_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.id,staticClass:"nav-item active",class:{
          'submenu-wrapper': tab.hasSubmenu,
          'hidden-tab': tab.isHidden
        },attrs:{"data-toggle":"tab","role":"tablist","aria-expanded":"true"}},[_c('a',{staticClass:"nav-link",class:{ active: tab.active },attrs:{"data-toggle":"tab","role":"tablist","ßß":"","href":`#${tab.id}`,"aria-expanded":tab.active},on:{"click":function($event){$event.preventDefault();tab.customHandler ? tab.customHandler(tab) : _vm.activateTab(tab)}}},[_c('tab-item-content',{attrs:{"tab":tab}})],1)])}),0),_vm._t("empty")],2),_c('div',{staticClass:"tab-content",class:[
      { 'tab-space': !_vm.vertical },
      { 'col-md-8': _vm.vertical && !_vm.tabContentClasses },
      _vm.tabContentClasses,
    ]},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }