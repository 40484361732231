<template>
  <modal
    :show.sync="isDisplayed"
    header-classes="justify-content-center"
    footer-classes="px-4 py-3"
    :centered="true"
    class="modal-default modal-wide"
    @close="() => $emit('close')"
  >
    <!-- Header Slot -->
    <h4
      slot="header"
      class="title title-up"
    >
      Profile
    </h4>

    <template v-if="isDisplayed">
      <div class="mb-3">
        <label
          for="title"
          class="form-label"
        > Title / Display Name </label>
        <input
          id="title"
          v-model="title"
          type="text"
          class="form-control"
          placeholder="Title"
        >
      </div>

      <div class="mb-3">
        <label
          for="description"
          class="form-label"
        > Description </label>
        <input
          id="description"
          v-model="description"
          type="text"
          class="form-control"
          placeholder="Description"
        >
      </div>

      <div class="mb-3">
        <date-time-offset-form
          label="Event Date / Time"
          :original-date-iso-utc="eventOn"
          @changed="handleEventOnChange"
        />
      </div>

      <div class="mb-3">
        <label
          for="uploadStatus"
          class="form-label"
        > Upload Status </label>
        <el-select
          id="uploadStatus"
          v-model.number="status"
          class="select-primary dark d-block"
          effect="dark"
          popper-class="select-primary"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </div>

      <div class="mb-3">
        <label
          for="createdOn"
          class="form-label"
        > Uploaded On </label>
        <input
          id="createdOn"
          type="text"
          class="form-control"
          placeholder="CreatedOn"
          :value="createdOn"
          disabled
        >
      </div>

      <div class="mb-3">
        <label
          for="description"
          class="form-label"
        > Tags </label>
        <div class="tag-set">
          <div class="row">
            <div class="col-6">
              <div>Name</div>
            </div>
            <div class="col-6">
              <div>Value</div>
            </div>
          </div>
          <div
            v-if="tags.length === 0"
            class="row my-1"
          >
            <div class="col-12">
              <p>Add Tags Below</p>
            </div>
          </div>
          <div
            v-for="tag in tags"
            v-else
            :key="tag.name === '' ? Math.random() : tag.name"
            class="row my-1"
          >
            <div class="col-6">
              <el-select
                v-model="tag.name"
                class="select-primary dark d-block"
                filterable
                allow-create
                effect="dark"
                popper-class="select-primary"
                placeholder="Select Tag"
              >
                <el-option
                  v-for="item in tagNames"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="col-6 d-flex align-items-center">
              <input
                v-model="tag.value"
                type="text"
                class="form-control"
                placeholder="Value"
              >
              <base-button
                size="sm"
                type="danger"
                class="ml-3 mr-0 mt-0 mb-0"
                icon-only
                no-fill
                @click="removeTag(tag)"
              >
                <x-icon
                  class="mr-2"
                  size="1.5x"
                />
              </base-button>
            </div>
          </div>
          <base-button
            size="sm"
            type="primary"
            class="ml-0"
            simple
            @click="addTag"
          >
            <plus-icon
              class="mr-2"
              size="1.5x"
            />
            Add Tag
          </base-button>
        </div>
      </div>
    </template>

    <!-- Footer Slot -->
    <template slot="footer">
      <base-button
        size="sm"
        class="ml-auto"
        :loading="loading"
        @click="submit"
      >
        Submit
      </base-button>
    </template>
  </modal>
</template>


<script>
import {Modal} from "src/components";
import {putUpload} from "../../../api";
import {isDefined} from "../../../api/helpers";
import DateTimeOffsetForm from "../../Ethos/Util/DateTimeOffsetForm.vue";
import {PlusIcon, XIcon} from "vue-feather-icons";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "metadata-form-modal",
  components: {
    Modal,
    DateTimeOffsetForm,
    PlusIcon,
    XIcon,
  },
  data() {
    return {
      isDisplayed: false,
      loading: false,
      uploadId: null,
      title: "",
      description: "",
      status: 0,
      createdOn: "",
      startTimeStamp: new Date(),
      eventOn: null,
      tags: [],
      tagNames: [
        {label: "Case #", value: "case"},
        {label: "Person", value: "person"},
        {label: "Address", value: "address"},
      ],
    };
  },
  computed: {
    ...mapGetters("data", ["userTimezone", "availableElementStates"]),
    statusOptions() {
      return this.availableElementStates.map((t) => {
        return {
          name: t,
          value: t,
        };
      });
    },
  },
  methods: {
    ...mapMutations("data", [
      "putUpload",
    ]),
    addTag() {
      this.tags.push({name: "", value: ""});
    },
    removeTag(tag) {
      const index = this.tags.indexOf(tag);
      if (index > -1) this.tags.splice(index, 1);
    },
    display(upload) {
      this.reset();
      if (upload) {
        this.uploadId = upload.id;
        this.title = upload.name;
        this.description = upload.description;
        this.startTimeStamp = upload.startTimeStamp;
        this.tags = upload.tags;
        this.status = upload.status;
        this.eventOn = upload.eventOn;
        this.createdOn = this.$date(upload.createdOn).tz(this.userTimezone).format("MMM DD, YYYY [at] hh:mm a");
        this.isDisplayed = true;
      }
    },
    reset() {
      this.isDisplayed = false;
      this.title = "";
      this.description = "";
      this.startTimeStamp = new Date();
      this.tags = [];
      this.createdOn = "";
      this.loading = false;
      this.$emit("close");
    },
    submit() {
      if (this.loading) return;
      if (!isDefined(this.title) || this.title.length < 1) {
        this.$notifyWarn("Title required");
        return;
      }
      if (!isDefined(this.startTimeStamp)) {
        this.$notifyWarn("Date/Time required");
        return;
      }
      this.loading = true;

      const status = isDefined(this.status) ? this.status : null;
      putUpload(this.uploadId,
        this.title,
        this.description,
        this.tags,
        this.startTimeStamp,
        undefined,
        status,
        undefined,
        undefined,
        this.eventOn)
        .then((e) => {
          this.$notifySuccess("Profile Updated");
          this.$emit("upload", e);
          this.putUpload(e);
          this.reset();
        }).catch((ex) => {
          this.loading = false;
          this.$notifyError("Failed to update profile", ex);
        });
    },
    handleEventOnChange(o) {
      this.eventOn = o.iso;
    },
  },
};
</script>
