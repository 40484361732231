<template>
  <div>
    <div class="d-flex">
      <base-button
        class="m-0"
        link
        @click="back"
      >
        <ArrowLeftIcon
          size="18"
          class="mr-2"
        />
        {{ title }}
      </base-button>
      <div class="flex-1" />
      <slot />
    </div>
    <div class="h-divider" />
  </div>
</template>

<script>
import {ArrowLeftIcon} from "vue-feather-icons";
import BaseButton from "../../BaseButton.vue";
export default {
  props: {
    to: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      default: "Back",
    },
  },
  components: {
    ArrowLeftIcon,
    BaseButton,
  },
  methods: {
    back() {
      if (this.to) {
        this.$router.replace({
          name: this.to,
        });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style></style>
