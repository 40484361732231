<template>
  <dashboard-v-2-layout :hide-nav="hasUploadsId">
    <div slot="full">
      <template v-if="hasIndividual">
        <individual
          :user-type="userType"
          :people="individual"
        />
      </template>
      <template v-else>
        <h2>Law Enforcement Officers</h2>
        <div class="paginated-table">
          <el-table
            class="mt-4"
            :data="userTableData"
            style="width: 100%"
          >
            <el-table-column
              prop="fullName"
              label="Full Name"
              min-width="200"
              sortable
            />
            <el-table-column
              prop="createdOn"
              label="Created On"
              width="250"
              sortable
            >
              <template slot-scope="scope">
                {{ $date(scope.row.createdOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createdBy"
              label="Created By"
              min-width="200"
              sortable
            />
            <el-table-column
              prop="modifiedOn"
              label="Modified On"
              width="250"
              sortable
            >
              <template slot-scope="scope">
                {{ $date(scope.row.modifiedOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
              </template>
            </el-table-column>
            <el-table-column
              prop="modifiedBy"
              label="Modified By"
              min-width="200"
              sortable
            />
            <el-table-column
              align="center"
              min-width="100"
            >
              <template slot-scope="scope">
                <base-button
                  size="sm"
                  type="primary"
                  simple
                  @click="handleUserView(scope.row)"
                >
                  View
                </base-button>
              </template>
            </el-table-column>
          </el-table>
          <table-pagination
            ref="userPagination"
            :pagination="userPagination"
            @changed="handleUserPaginationChange"
          />
        </div>

        <h2 class="mt-2">
          Subjects
        </h2>
        <div class="paginated-table">
          <el-table
            class="mt-4"
            :data="actorTableData"
            style="width: 100%"
          >
            <el-table-column
              prop="fullName"
              label="Full Name"
              min-width="200"
              sortable
            />
            <el-table-column
              prop="aliases"
              label="AliasCsv"
              min-width="300"
              sortable
            />
            <el-table-column
              prop="createdOn"
              label="Created On"
              width="250"
              sortable
            >
              <template slot-scope="scope">
                {{ $date(scope.row.createdOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createdBy"
              label="Created By"
              min-width="200"
              sortable
            />
            <el-table-column
              prop="modifiedOn"
              label="Modified On"
              width="250"
              sortable
            >
              <template slot-scope="scope">
                {{ $date(scope.row.modifiedOn).tz(userTimezone).format("MMM DD, YYYY [at] hh:mm a") }}
              </template>
            </el-table-column>
            <el-table-column
              prop="modifiedBy"
              label="Modified By"
              min-width="200"
              sortable
            />
            <el-table-column
              align="center"
              min-width="100"
            >
              <template slot-scope="scope">
                <base-button
                  size="sm"
                  type="primary"
                  simple
                  @click="handleActorView(scope.row)"
                >
                  View
                </base-button>
              </template>
            </el-table-column>
          </el-table>
          <table-pagination
            ref="actorPagination"
            :pagination="actorPagination"
            @changed="handleActorPaginationChange"
          />
        </div>
      </template>
    </div>
  </dashboard-v-2-layout>
</template>
<script>
import {mapGetters} from "vuex";
import "src/assets/sass/custom/paginated-table.scss";
import {isDefined} from "../../../api/helpers";
import TablePagination from "../../../components/TablePagination.vue";
import {getActorById, getActorsSearch, getUsers} from "../../../api";
import DashboardV2Layout from "../../../components/DashboardV2/DashboardV2Layout.vue";
import Individual from "./Individual/Individual.vue";

export default {
  name: "list-people",
  components: {
    TablePagination,
    DashboardV2Layout,
    Individual,
  },
  props: {
  },
  data() {
    return {
      actorTableData: [],
      actorPagination: {
        TotalCount: 0,
      },
      userTableData: [],
      userPagination: {
        TotalCount: 0,
      },
      individual: null,
    };
  },
  computed: {
    ...mapGetters("data", [
      "getSearchKeyword",
      "userTimezone",
    ]),
    userType() {
      const type = this.$route.params.userType;
      return type === "subject" || type === "leo" ? type : null;
    },
    peopleId() {
      const id = this.$route.params.peopleId;
      return isDefined(id) ? parseInt(id) : null;
    },
    hasIndividual() {
      return isDefined(this.peopleId) && isDefined(this.userType) && isDefined(this.individual);
    },
    uploadId() {
      const id = this.$route.params.uploadId;
      return isDefined(id) ? parseInt(id) : null;
    },
    hasUploadsId() {
      return isDefined(this.uploadId);
    },
  },
  mounted() {
    this.loadPeople(this.getSearchKeyword);
  },
  methods: {
    loadPeople(keyword) {
      this.loadActors(keyword);
      this.loadUsers(keyword);
      if (this.userType === "subject" && isDefined(this.peopleId)) {
        getActorById(this.peopleId).then((resp) => {
          this.individual = resp;
        }).catch((ex) => {
          this.$notifyError("Unable to find Subject");
        });
      } else if (this.userType === "leo" && isDefined(this.peopleId)) {
        // getUser(this.peopleId).then((resp) => {
        //   this.individual = resp;
        // }).catch((ex) => {
        //   this.$notifyError("Unable to find Subject");
        // });
      }
    },
    loadActors(keyword) {
      if (!this.$refs.actorPagination) return;
      getActorsSearch(this.$refs.actorPagination.pageNumber, this.$refs.actorPagination.pageSize, keyword)
        .then((response) => {
          this.actorTableData = response.data;
          this.actorPagination = JSON.parse(response.pagination);
          this.loading = false;
        })
        .catch((ex) => {
          this.loading = false;
          this.$notifyError("Loading Actor Data Failed", ex);
        });
    },
    loadUsers(keyword) {
      if (!this.$refs.actorPagination) return;
      getUsers(this.$refs.actorPagination.pageNumber, this.$refs.actorPagination.pageSize, keyword)
        .then((response) => {
          this.userTableData = response.data;
          this.userPagination = JSON.parse(response.pagination);
          this.loading = false;
        })
        .catch((ex) => {
          this.loading = false;
          this.$notifyError("Loading User Data Failed", ex);
        });
    },
    handleUserPaginationChange() {
      this.loadUsers();
    },
    handleActorPaginationChange() {
      this.loadActors();
    },
    handleActorView(actor) {
      if (!isDefined(actor) || !isDefined(actor.id)) return;
      this.individual = actor;
      this.$router.replace(`/v2/people/subject/${actor.id}`);
    },
    handleUserView(user) {
      if (!isDefined(user) || !isDefined(user.id)) return;
      this.individual = user;
      this.$router.replace(`/v2/people/leo/${user.id}`);
    },
  },
  watch: {
    "$store.state.data.searchKeyword": function() {
      this.loadPeople(this.$store.state.data.searchKeyword);
    },
  },
};
</script>
