import {jsonRequest, isDefined, GET} from "./helpers";

// AUTH
export const authenticate = async () => await jsonRequest(GET, "Auth");

// ACTORS
export {addActor, deleteActor, getActorById, getActorIncidents, getActors, getActorsSearch, updateActor} from "./actors";
export {attributesOf, attributeActor, attributeUser, unattributeActor, unattributeUser, pagedAttributesOf} from "./person";

// SEARCH
export const getSearch = async (searchType, searchParams) => {
  if (!isDefined(searchParams)) throw new Error("Nothing to search by");
  return await jsonRequest(GET, `Search/${searchType}?${new URLSearchParams(searchParams)}`);
};

// UPLOADS
export {getUploads, getUploadById, getUploadByIdAndUrl, getUploadsForLookup, getUploadCases, getUploadsTranscriptions, postUpload, putUpload, downloadUpload, getUploadsPeopleByGroup, getUploadIncidents, putClip, getClip, getClips, deleteClip, downloadClip, getUploadCasesData} from "./uploads";

// WORKFLOWS
export {getWorkflow, runWorkflow} from "./workflows";

// CASES
export {getCaseUploads, getCaseProjects, getCase, getCaseSummary, getCases, getCaseSummaries, addUploadIdsToCase as addUploadIdsToCase, putCase, getAllCases, archiveCase, getArchiveCount, getCaseUploadSummaries as getCaseUploadsSummaries, getCaseUploadSummariesPaged, getCaseUploadClipSummariesPaged, getCaseUploadEvidenceSummariesPaged, postCaseUploadsSummary, deleteCaseUploadsSummary, getCaseClips} from "./cases";
export {deleteCaseKeywords, getCaseKeywords, postCaseKeywords} from "./caseKeywords";

// TRANSCRIPTIONS
// getUrl might need to be moved elsewhere
export {getTranscriptions, getAudioTranscript, getUrl, getTranscription, postChangeRequests, getRevision, postRestoreRevision, postSpeakerChangeRequest, postChangeRequestApproval, playBackPosition, getTranscriptionHeadlines} from "./transcriptions";
export {postMarkClear, postRedaction, postRemoveRedaction} from "./transcriptions";

// PROJECTS
export {getProjects, addProject, deleteProject, getProjectPeopleByGroup, postProjectPreferences, getAllStarredProjects, getRecentProjects, getProjectIncidents} from "./projects";
export {getProjectTimeline, getProjectTimelines, postProjectTimline, putProjectTimline, deleteProjectTimline} from "./projects";

//  PEOPLE
export {addPeople, deleteIndividual, getAllFromPeopleGroup, getPeople, getPeopleSearch, updateIndividual, getPeopleById, getPeopleIncidents} from "./people";

// USERS
export {deleteUser, getUser, getUsers, getUsersOverview, patchUser, putUser, setUserConfig} from "./users";

// CODE WORDS
export {getCodeWordGroups, getCodeWordGroup, createCodeWordGroup, updateCodeWordGroup} from "./codeWords";
export {getCodeWordCollections, getCodeWordCollection, createCodeWordCollection, updateCodeWordCollection} from "./codeWords";
export {getCodeWords, getCodeWordsForCollection, getCodeWord, createCodeWord, updateCodeWord} from "./codeWords";

// NOTES
export {addNote, getNoteById, getAllNotes, getProjectNotes, getUploadsNotes, updateNote, getNoteIncidents} from "./notes";

// Incidents
export {getIncident, getIncidents, getAllIncidents, archiveIncident, getIncidentArchiveCount, postIncident, putIncident, linkIncidentTo} from "./incidents";

// CONFIG
export {postAutoApproveRoles} from "./config";
